import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Col,
  Row,
  Typography,
  Space,
  Tooltip,
  notification,
  Table,
} from 'antd';
import useAuth from '../../hooks/useAuth';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  getWorkmenLimit,
  jobOrderCount,
  getSportsGroup,
} from '../../services/auth';
const { Option } = Select;
const { Title } = Typography;

const Jobdetails = ({
  initialValues,
  onNext,
  onPrev,
  isViewOnly,
  masterData,
  onUpdateFields,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* eslint-disable */
  const [data, setData] = React.useState(initialValues);
  console.log('data of jobDetails', data);
  const [Isworking, setIsworking] = React.useState(true);
  const [Empstatus, setEmpstatus] = React.useState(true);
  const [EsicDisabled, setEsicDisabled] = React.useState(true);
  const [isDlDate, setIsDlDate] = React.useState(false);
  const [workingArea, setWorkingArea] = React.useState([]);
  const [masterDataShop, setMasterDataShop] = React.useState([]);
  const [isCommonService, setIsCommonService] = useState(false);

  // const isDisabled = data?.approvalStage > 0;
  // const [selectedContractorCode, setSelectedContractorCode] = React.useState(null);

  //console.log(masterDataState)

  const [contractorList, setContractorList] = React.useState([]);
  const [jobOrderList, setJobOrderList] = React.useState([]);
  const [selectedContractorCode, setSelectedContractorCode] =
    React.useState(null);
  const [contractorNameList, setContractorNameList] = React.useState([]);
  // const [selectedContractorName, setSelectedContractorCode] = React.useState(null);
  const [selectedJobOrder, setSelectedJobOrder] = React.useState(null);
  // const [selectedJobOrder, setSelectedJobOrder] = useState(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState(false);
  const [separatedVariables, setSeperatedVariables] = React.useState([]);
  const [contractorName, setContractorName] = React.useState([]);
  const [workerType, setWorkerType] = React.useState([]);
  const [workType, setWorkType] = React.useState([]);
  const [skillType, setSkillType] = React.useState([]);
  const [bloodGroup, setBloodGroup] = React.useState([]);
  const [workerCategory, setWorkerCategory] = React.useState([]);
  const [technicalDetails, setTechnicalDetails] = React.useState([]);
  const [department, setDepartment] = React.useState([]);
  const [checkedList, setCheckedList] = React.useState([]);
  const [primaryJobSkilldata, setPrimaryJobSkill] = useState([]);

  const onChangeApproval = (value, checked) => {
    let updatedCheckedList;
    if (checked) {
      // If checkbox is checked, add its value to the checkedList array
      updatedCheckedList = [...checkedList, value];
    } else {
      // If checkbox is unchecked, remove its value from the checkedList array
      updatedCheckedList = checkedList.filter((item) => item !== value);
    }
    setCheckedList(updatedCheckedList);
    console.log('checkedList', checkedList);
  };

  const { userConfig } = useAuth();
  // console.log("userConfig",userConfig)
  const supplierId = userConfig?.supplierId;
  const orgId = userConfig?.orgId;
  const isDisabled = userConfig.role === 'approver';
  const isEditingDisabled =
    userConfig.role === 'supervisor' && initialValues?.approvalStage >= 0;

  const onFinish = (values) => {
    values.contractorCode = userConfig?.supplierId;

    console.log('Values is', values);
    onNext(values);
  };

  // const Esictoggle = () => {
  //   setEsicDisabled(!EsicDisabled);
  // };
  const Esictoggle = (e) => {
    // Check if the input value is empty or not
    const { name, value } = e.target;
    const isInputEmpty = e.target.value.trim() === '';

    // Enable or disable based on whether the input is empty
    setEsicDisabled(isInputEmpty);
    handleFieldChange(name, value);
  };
  const Isworkingtoggle = () => {
    setIsworking(!Isworking);
  };
  const Empstatustoggle = () => {
    setEmpstatus(!Empstatus);
  };

  const onChange = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    const { name, value } = e.target;
    if (value?.trim() !== '') {
      handleFieldChange(name, value);
    }
  };

  const normfile = () => {
    // console.log('object');
  };

  const handleFieldChange = (fieldName, value) => {
    // Notify the parent component about the updated field
    // if (typeof onUpdateFields === 'function') {
    //   onUpdateFields(fieldName, value);
    // } else {
    //   console.error('onUpdateFields is not a function');
    //   return;
    // }
    onUpdateFields?.(fieldName, value);
  };

  React.useEffect(() => {
    // Fetch data when the component mounts
    workmenLimit();
  }, []);

  const workmenLimit = async () => {
    // console.log(contractorId);
    getWorkmenLimit(orgId)
      .then((res) => {
        // console.log(res)

        const contractorIdsAndJobOrderNumbers = res.data.map((item) => {
          if (item && item.addJobDetails && Array.isArray(item.addJobDetails)) {
            return item.addJobDetails.map((details) => ({
              id: details?.id,
              jobOrderNumber: details?.jobordernumber,
              name: item.name,
            }));
          }
          return null;
        });

        const newArr = res.data.map((item) => {
          const jobOrderNumber = item?.addJobDetails?.map((jobDetail) => {
            return jobDetail?.jobordernumber;
          });
          if (jobOrderNumber) {
            return {
              id: item.id,
              name: item.name,
              jobOrderNumber: jobOrderNumber,
            };
          }
        });

        // console.log("22",newArr);

        setSeperatedVariables(newArr);

        // Flatten the array of objects into an array of { id, jobOrderNumber }
        const flattenedContractorIdsAndJobOrderNumbers =
          contractorIdsAndJobOrderNumbers.flat();

        // console.log('1', flattenedContractorIdsAndJobOrderNumbers);

        // console.log("1",flattenedContractorIdsAndJobOrderNumbers)

        // console.log("suppplierid",supplierId)

        const filteredContractors =
          flattenedContractorIdsAndJobOrderNumbers.filter(
            (contractor) => contractor.id === supplierId
          );

        // console.log("bye",filteredContractors)

        const jobOrders = filteredContractors?.map(
          (contractor) => contractor.jobOrderNumber
        );
        // console.log("hii",jobOrders)
        const name = filteredContractors?.map((contractor) => contractor.name);
        // console.log("name",name)
        const uniqueNames = [...new Set(name)];
        // console.log("name",uniqueNames)
        setContractorName(uniqueNames);

        setJobOrderList(jobOrders);
      })
      .catch((error) => {
        // setLoader(false);
        console.log('error', error);
      });
  };

  // const handleJobOrderChange = async (value) => {
  //   setSelectedJobOrder(value);
  //   // console.log(selectedJobOrder);
  //   const jobId = value;
  //   const contractorCode = userConfig?.supplierId;

  //   jobOrderCount({ jobId, contractorCode })
  //     .then((res) => {
  //       console.log('joborderdeatils', res.data);

  //       // Make a request to the backend to get information from MongoDB

  //       const { maxContractors, jobCount } = res.data;

  //       // Check if jobCount exceeds the limit
  //       if (jobCount == maxContractors - 1) {
  //         notification.error({
  //           message: 'Limit Reached',
  //           description: 'Adding one more contractor would exceed the limit.',
  //         });

  //         // Set isNextButtonDisabled to true to disable the "Next" button
  //         //  setIsNextButtonDisabled(false);
  //       } else {
  //         // Display a notification if jobCount is greater than maxContractors
  //         if (jobCount > maxContractors - 1) {
  //           notification.info({
  //             message: 'Notice',
  //             description:
  //               'The number of contractors is more than the permitted limit.',
  //           });
  //           // If the limit is not reached, you might reset the state to enable the button
  //           setIsNextButtonDisabled(true);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       // setLoader(false);
  //       console.log('error', error);
  //     });
  // };

  React.useEffect(() => {
    workerTypeInput(),
      workTypeInput(),
      skillTypeInput(),
      qualificationInput(),
      bloodGroupInput(),
      workerCategoryInput(),
      deptCodeTypeInput();
    primaryJobSkill();
  }, [masterData]);

  function primaryJobSkill() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Primary_Job_Skill';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const primaryJobSkillAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Contractor_Primary_Job_Skill;
    });
    console.log('primaryJobSkillAreas', primaryJobSkillAreas);
    setPrimaryJobSkill(primaryJobSkillAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      // console.log('bloodGroupInputAreas', bloodGroupInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function workerTypeInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Worker_Type';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const workerTypeInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.WORKER_TYPE;
    });
    setWorkerType(workerTypeInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      console.log('workerTypeInput', workerTypeInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function workTypeInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Work_Type';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const workTypeInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Work_Type + ' ' + item?.Discription;
    });
    setWorkType(workTypeInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      console.log('workTypeInputAreas', workTypeInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function skillTypeInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Skill_Type';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const skillTypeInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Skill_Type;
    });
    setSkillType(skillTypeInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      console.log('workTypeInputAreas', skillTypeInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function qualificationInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Qualification';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const qualificationInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Qualification;
    });
    setTechnicalDetails(qualificationInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      console.log('qualificationInputAreas', qualificationInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function bloodGroupInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Blood_Group';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const bloodGroupInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Blood_Group;
    });
    setBloodGroup(bloodGroupInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      console.log('bloodGroupInputAreas', bloodGroupInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function workerCategoryInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Worker_Category';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const workerCategoryInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Worker_Category;
    });
    setWorkerCategory(workerCategoryInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      console.log('workerCategoryInputAreas', workerCategoryInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function deptCodeTypeInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Contractor_Department_Master';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    setMasterDataShop(dataFromArray);
    // console.log("Hii",dataFromArray)
    const deptCodeInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Department;
    });
    setDepartment(deptCodeInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      console.log('deptCodeInputAreas', deptCodeInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  console.log('shop data', masterDataShop);
  const [form] = Form.useForm();

  // const fieldChanges = (changedFields, allFields) => {
  //   console.log('changes in field', allFields, changedFields);
  //   if (changedFields[0].name == 'workingArea') {
  //     const changedValue = changedFields[0]?.value?.split(' ');
  //     // console.log('changedValue', changedValue[0]);

  //     // let index = masterDataShop?.dataParsed?.find((item) => {
  //     //   return item.Production_Centre == changedValue[0];
  //     // });
  //     // console.log('index', index);
  //     const filteredDepartments = masterDataShop?.dataParsed?.filter((item) => {
  //       return item.Production_Centre === changedValue[0];
  //     });

  //     console.log('filteredDepartments', filteredDepartments);

  //     if (filteredDepartments.length > 0) {
  //       // Extract department codes from the filtered data
  //       const departmentCodes = filteredDepartments.map((dept) => {
  //         return `${dept.Dept_Code} ${dept.Department_Name}`;
  //       });

  //       // Update the department code options
  //       setWorkingArea(departmentCodes);

  //       // Optionally, set the default value of department code
  //       form.setFieldsValue({
  //         departmentCode: departmentCodes[0], // Set the first department code as default
  //       });
  //     }
  //   }
  // };
  const fieldChanges = (changedFields, allFields) => {
    console.log('changes in field', allFields, changedFields);
    if (changedFields[0].name == 'workingArea') {
      const changedValue = changedFields[0]?.value?.split(' ');
      console.log('changedValue', changedValue[0]);

      let index = masterDataShop?.dataParsed?.find((item) => {
        return item.Department == changedValue[0];
      });
      console.log('index--->', index);
      if (index) {
        form.setFieldsValue({
          departmentCode: index?.Department_code,
        });
      } else {
        form.setFieldsValue({
          departmentCode: 'Null',
        });
      }
    }
  };

  console.log('working area', workingArea);
  const uniqueDepartments = new Set(department);

  return (
    <>
      <Title level={5}>Job Details</Title>
      <Form
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        onFieldsChange={fieldChanges}
        form={form}>
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item name='employerCode' label='Principal Employer Code'>
              <Input
                size='large'
                disabled='true'
                defaultValue={userConfig.orgId}
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            {/* <Form.Item name='contractorCode' label='Contractor Code'>
        <Select
          size='large'
          onChange={handleContractorCodeChange}
          // disabled={true}
          defaultValue={selectedContractorCode}
          
        >
          {contractorList.map((code) => (
            <Select.Option key={code} value={code} >
              {code}
            </Select.Option>
          ))}
        </Select>
      </Form.Item> */}
            <Form.Item name='contractorCode' label='Contractor Code'>
              <Input
                size='large'
                disabled='true'
                defaultValue={userConfig?.supplierId}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='contractorName'
              label='Contractor Name'
              rules={[{ required: true, message: 'Enter  Contact Name' }]}>
              <Select
                size='large'
                disabled={isDisabled}
                // onChange={handleContractorNameChange}
                // disabled={true}
                value={contractorName}
                onChange={(value) =>
                  onChange({ target: { name: 'contractorName', value } })
                }>
                {contractorName?.map((code) => (
                  <Select.Option key={code} value={code}>
                    {code}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='workingArea'
              label='Working Area'
              rules={[{ required: true, message: 'Enter Working Area' }]}>
              <Select
                size='large'
                disabled={isDisabled}
                // onChange={handleWorkingAreaChange}
              >
                {department?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='departmentCode'
              label='Department Code'
              rules={[
                {
                  required: true,
                  message: 'Enter Department Code',
                },
              ]}>
              <Select size='large' disabled={isDisabled}>
                {workingArea?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='workorderCode' label='Work Order Number'>
              <Select
                size='large'
                placeholder='Work Order Number'
                disabled={isDisabled}>
                <Option value='JM-LMG-IT-47839'>JM-LMG-IT-47839</Option>
                <Option value='FT-DRS-OU-97563'>FT-DRS-OU-97563</Option>
                <Option value='AJ-MXV-HG-42371A'>AJ-MXV-HG-42371A</Option>{' '}
              </Select>
            </Form.Item>
          </Col> */}
          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='itemServiceCode' label='Item Service Code'>
              <Select
                size='large'
                placeholder='Item Service Code'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'itemServiceCode', value } })
                }>
                <Option value=''></Option>
                <Option value='975632'>975632</Option>
                <Option value='991106'>991106</Option>
                <Option value='850219'>850219</Option>{' '}
              </Select>
            </Form.Item>
          </Col> */}

          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='labourType' label='Labour Code'>
              <Select
                size='large'
                placeholder='Labour Code'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'labourType', value } })
                }>
                <Option value=''></Option>
                <Option value='Monthly'>Monthly</Option>
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>{' '}
              </Select>
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            {/* <Form.Item name='jobOrderNumber' label='Job Order Number'>
              <Select size='large' placeholder='Job Code' disabled={isDisabled}>
                <Option value=''></Option>
                <Option value='WSC-923'>WSC-923</Option>
                <Option value='ITS-321'>ITS-321</Option>
                <Option value='FIN-003'>FIN-003</Option>
                <Option value='ADM-411'>ADM-411</Option>{' '}
              </Select>
            </Form.Item> */}
            <Form.Item
              name='jobOrderNumber'
              label='Job Order Number'
              rules={[{ required: true, message: 'Enter Job Order Number' }]}>
              <Select
                size='large'
                disabled={isDisabled}
                //onChange={(value) => handleJobOrderChange(value)}
              >
                {jobOrderList.map((number) => (
                  <Select.Option key={number} value={number}>
                    {number}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='skillCode' label='Skill Code'>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'skillCode', value } })
                }>
                {skillType?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='subSkillCode' label='Sub Skill Code'>
              <Select
                size='large'
                placeholder='Sub Skill Code'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'subSkillCode', value } })
                }>
                <Option value=''></Option>

                <Option value='Skilled'>Skilled</Option>

                <Option value='Unskilled'>Unskilled</Option>
                <Option value='Semi-skilled'>Semi-Skilled</Option>
                <Option value='Highly-skilled'>Highly-Skilled</Option>
              </Select>
            </Form.Item>
          </Col> */}
          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='subSkillDate' label='SubSkill Date'>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                disabled={isDisabled}
              />
            </Form.Item>
          </Col> */}

          <Col className='gutter-row' span={6}>
            <Form.Item name='designation' label='Worker Type'>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'designation', value } })
                }>
                {workerType?.map((area, index) => (
                  <Option
                    key={index}
                    value={area}
                    onChange={onChange}
                    name='designation'>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='workType' label='Work Type'>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'workType', value } })
                }>
                {workType?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='duration' label='Duration'>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='duration'
              />
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item name='workerCategory' label='Worker Category'>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'workerCategory', value } })
                }>
                {workerCategory?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>{' '}
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='contractorReferenceNumber'
              label={
                <Space>
                  Contractor Reference Number
                  <span></span>
                  <Tooltip
                    title='Generated by Contractor Organisation'
                    placement='top'>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              }>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='contractorReferenceNumber'></Input>
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item name='pmeRequired'>
              <Checkbox
                size='large'
                name='pmeRequired'
                // onChange={PmeCheckbox}
                onClick={Isworkingtoggle}
                style={{ paddingTop: '40px' }}
                disabled={isDisabled}>
                PME Required?
              </Checkbox>
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='pmeDonedate' label='PME Done Date '>
              <DatePicker
                size='large'
                style={{ width: '100%' }}
                disabled={Isworking}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='pmeValidityDate' label='PME Validity Date'>
              <DatePicker
                size='large'
                style={{ width: '100%' }}
                disabled={Isworking}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='doj'
              label='Date Of Joining'
              rules={[{ required: true, message: 'Enter DOJ' }]}
              hasFeedback
              // valuePropName="date"
              // getValueFromEvent={normfile}
            >
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                disabled={isDisabled || isEditingDisabled}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='primaryJobSkill' label='Primary Job Skill'>
              <Select size='large' disabled={isDisabled}>
                {primaryJobSkilldata?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='employmentStatus'>
              <Checkbox
                size='large'
                onChange={onChange}
                onClick={Empstatustoggle}
                style={{ paddingTop: '40px' }}
                disabled={isDisabled}>
                Termination Status
              </Checkbox>
            </Form.Item> */}
          {/* <Form.Item name='sectionCode' label='Section Code'>
              <Select
                size='large'
                placeholder='Section Code'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'sectionCode', value } })
                }>
                <Option value=''></Option>
                <Option value='HR001'>HR001</Option>
                <Option value='FIN001'>FIN001</Option>
                <Option value='ACC001'>ACC001</Option>
                <Option value='LEG001X'>LEG001X</Option>
                <Option value='MFG001'>MFG001</Option>
                <Option value='DEL001'>DEL001</Option>
                <Option value='OPS001'>OPS001</Option>
                <Option value='STR001'>STR001</Option>
                <Option value='ADM001'>ADM001</Option>
                <Option value='MED001'>MED001</Option>
                <Option value='SEC001'>SEC001</Option>
                <Option value='IT001'>IT001</Option>
                <Option value='PRC001'>PRC001</Option>{' '}
              </Select>
            </Form.Item> */}
          {/* </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateOfTermination' label='Date Of Termination'>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                placeholder='DD-MM-YYYY'
                disabled={Empstatus}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='terminationReason' label='Reason For Termination'>
              <Input
                size='large'
                disabled={Empstatus}
                onChange={onChange}
                name='terminationReason'
              />
            </Form.Item>{' '}
          </Col> */}
        </Row>
        {/*<hr />}
        {/* <Title level={5}>Work Experience</Title>
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item name='totalExperience' label='Total Experience'>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='totalExperience'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='previousOrg'
              label='Previous Organization'
              rules={[
                // { required: true, message: "Enter Address" },
                {
                  min: 3,
                  message: 'Minimum 3 Characters',
                },
                {
                  max: 30,
                  message: 'Maximum 300 characters allowed ',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='previousOrg'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='referredBy'
              label='Refered By'
              rules={[
                // { required: true, message: "Enter Address" },
                {
                  min: 3,
                  message: 'Minimum 3 Characters',
                },
                {
                  max: 30,
                  message: 'Maximum 300 characters allowed ',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='referredBy'
              />
            </Form.Item>{' '}
          </Col>
        </Row> */}

        <hr />
        <Title level={5}>Bank Details</Title>
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='bankname'
              label='Bank Name'
              rules={[
                { required: true, message: 'Enter Bank Name' },

                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabetic Allowed',
                },
                // { required: true, message: "Enter Address" },
                {
                  min: 3,
                  message: 'Minimum 3 Characters',
                },
                {
                  max: 300,
                  message: 'Maximum 300 characters allowed ',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='bankname'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='AccountNo'
              label='Bank Account Number'
              rules={[
                { required: true, message: 'Enter Bank Account Number' },
                // {
                //   min: 1,
                //   max: 20,
                //   message: "Account number should be less than 20 characters",
                // },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='AccountNo'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='ifsc'
              label='IFSC Code'
              rules={[
                { required: true, message: 'Enter IFSC Code' },
                {
                  pattern: /^[A-Za-z]{4}\d{7}$/,
                  message: 'Enter Valid IFSC Code',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='ifsc'
              />
            </Form.Item>{' '}
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='userName'
              label='Name As Per Bank Account'
              rules={[
                // { required: true, message: "Enter Name" },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabetic Allowed',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='userName'
              />
            </Form.Item>{' '}
          </Col> */}
        </Row>
        <hr />
        <Title level={5}>Other Details</Title>
        <Row gutter={16}>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='shoeSize' label='Shoe Size'>
              <Select
                size='large'
                placeholder='Shoe Size'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'shoeSize', value } })
                }>
                <Option value=''></Option>
                <Option value='5'>5 in</Option>
                <Option value='6'>6 in</Option>
                <Option value='7'>7 in</Option>
                <Option value='8'>8 in</Option>
                <Option value='9'>9 in</Option>
                <Option value='10'>10 in</Option>
                <Option value='11'>11 in</Option>
                <Option value='12'>12 in</Option>
              </Select>
            </Form.Item>{' '}
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item name='bloodGroup' label='Blood Group' hasFeedback>
              <Select size='large' disabled={isDisabled || isEditingDisabled}>
                {bloodGroup?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='academicQualification'
              label='Academic Qualification'>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='academicQualification'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='techQualification' label='Technical Qualification'>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'techQualification', value } })
                }>
                {technicalDetails?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dl' label='Driving Licence Number'>
              <Input size='large' onChange={Esictoggle} disabled={isDisabled} />
            </Form.Item>{' '}
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='dlExpiry'
              label='DL Validity Date'
              // disabled={EsicDisabled ? true : false}
              dependencies={['dl']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!getFieldValue('dl') || value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      // new Error('Please select DL Validity Date')
                      {
                        required: true,
                        message: 'Enter First Name ',
                      }
                    );
                  },
                }),
              ]}>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                disabled={isDisabled}
              />
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='dlExpiry'
              label='DL Validity Date'
              dependencies={['dl']}
              rules={[
                ({ getFieldValue }) => ({
                  required: !!getFieldValue('dl'), // Set required based on the value of 'dl'
                  validator(_, value) {
                    if (!getFieldValue('dl') || value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Please select DL Validity Date')
                    );
                  },
                }),
              ]}>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button
            type='primary'
            htmlType='submit'
            disabled={isNextButtonDisabled}>
            Next
          </Button>
        </Form.Item>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button onClick={() => onPrev(data)} type='primary'>
            Prev
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default Jobdetails;
