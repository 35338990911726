import React from 'react';
import { useState } from 'react';
import {  Layout,  theme,Button, Flex,  Modal, Form, Input } from 'antd';
// import MyForm from '../permission/Components/MyForm';

// import MyPermission from '../../Components/MyPermission';
import Dashborad from '../permission/Dashboard';
import MyDropdown from './Components/MyDropdown';



const { Header } = Layout;

const Permission=()=>{

    const {
        token: { colorBgContainer },
      } = theme.useToken();
      const [visible, setVisible] = useState(false);
      
      const showModal = () => {
        setVisible(true);
      };
    
      const handleCancel = () => {
        setVisible(false);
      };
    
      const handleCreate = (values) => {
        // Handle form submission logic here
        console.log('Received values:', values);
        setVisible(false);
      };
    
    
      return (
       
        
          <Flex
        vertical
        gap="small"
        style={{
          width: '100%',
        }}
      >
       
        
      
        {/* <MyForm visible={visible} onCancel={handleCancel} onCreate={handleCreate} /> */}
        <MyDropdown  visible={visible} onCancel={handleCancel} onCreate={handleCreate}/>
        <Dashborad />
        
        
       
        
      </Flex>
    
        
          
          
         
       
      );


}

export default Permission;