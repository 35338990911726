import React, { useState, useEffect, useRef } from 'react';
import {
  Col,
  Row,
  Form,
  Button,
  Typography,
  Divider,
  DatePicker,
  notification,
  Checkbox,
  Modal,
  Input,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Approved, Approved1 } from '../../services/auth';
import { Email } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from '../../services/axiosInstance';
import useAuth from '../../hooks/useAuth';
import dayjs from 'dayjs';
import { on } from 'stream';
import { updatePass } from '../../services/auth';
import { SmileOutlined } from '@ant-design/icons';
import { color } from 'echarts';
import '../EmployeeOnboard/summaryStyle.css';

const { Title } = Typography;

const Summarygeneration = ({
  initialValues,
  onNext,
  jobDetails,
  Summary,
  wageDetails,
  onPrev,
  imageSrc,
  setImageSrc,
  uploadDocument,
}) => {
  const formRef = React.useRef();
  const navigate = useNavigate();
  const { _id } = useParams();
  const { id } = useParams();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { user, userConfig } = useAuth();

  const [PfDisabled, setPfDisabled] = React.useState(true);
  const [data, setData] = React.useState(initialValues);
  console.log('initialValues is', initialValues);
  const [jobdata, setjobData] = React.useState(jobDetails);
  const [wagedata, setwagedata] = React.useState(wageDetails);
  const [uploadDoc, setuploaddoc] = React.useState(uploadDocument);
  console.log('data is', data);
  // console.log("data children",data.children);

  const date = dayjs(data.Dob);
  const formattedDate = date.format('DD-MM-YYYY');
  //-----------------------
  const date1 = dayjs(jobdata.dlExpiry);
  const dlExpiry = date1.format('DD-MM-YYYY');
  //-----------------------
  const date2 = dayjs(jobdata.doj);
  const doj = date2.format('DD-MM-YYYY');
  //-----------------------
  const date3 = dayjs(jobdata.effectiveDate);
  const effectiveDate = date3.format('DD-MM-YYYY');

  //-----------------
  const date4 = dayjs(jobdata.subSkillDate);
  const subSkillDate = date4.format('DD-MM-YYYY');

  // Create a new event object based on the updated job details
  const newEvent = {
    effDate: jobDetails.effDate || '',
    event: jobDetails.event || '',
    functionCode: jobDetails.functionCode || '',
    departmentCode: jobDetails.departmentCode || '',
    fromCadre: jobDetails.fromCadre || '',
    toCadre: jobDetails.toCadre || '',
  };

  const effDate = new Date(newEvent.effDate);
  // newEvent.effDate = effDate.toISOString().split('T')[0];

  if (effDate instanceof Date && !isNaN(effDate)) {
    newEvent.effDate = effDate.toISOString().split('T')[0];
  } else {
    // Handle the case where effDate is not a valid date
    console.error('Invalid effDate:', effDate);
  }

  const locationId = data?.locationId;
  const orgId = data?.orgId;
  const [children, setChildren] = useState([]);
  const prevDataRef = useRef(data);
  useEffect(() => {
    if (prevDataRef.current !== data) {
      console.log('data', data);
      const updatedChildren = [];
      for (let i = 0; i < data.childrenNo; i++) {
        const child = {
          name: data[`name${i + 1}`] || '',
          male: data[`male${i + 1}`] || '',
          birthDate: data[`birthDate${i + 1}`]
            ? new Date(data[`birthDate${i + 1}`])
            : '',
          medium: data[`medium${i + 1}`] || '',
          mediclaim: data[`mediclaim${i + 1}`] || '',
          occupation: data[`occupation${i + 1}`] || '',
          effDate: data[`effDate${i + 1}`]
            ? new Date(data[`effDate${i + 1}`])
            : '',
        };
        updatedChildren.push(child);
      }
      console.log('updatedChildren', updatedChildren);
      setChildren(updatedChildren);
      prevDataRef.current = data;
    }
  }, [data]);

  const update = () => {
    const updatedChildrenData = [];
    const childrenCount = Object.keys(data).filter((key) =>
      key.startsWith('name')
    ).length;
    for (let index = 0; index < childrenCount; index++) {
      updatedChildrenData.push({
        name: data[`name${index + 1}`] || '',
        male: data[`male${index + 1}`] || '',
        birthDate: data[`birthDate${index + 1}`]
          ? new Date(data[`birthDate${index + 1}`])
          : '',
        medium: data[`medium${index + 1}`] || '',
        mediclaim: data[`mediclaim${index + 1}`] || '',
        occupation: data[`occupation${index + 1}`] || '',
        effDate: data[`effDate${index + 1}`]
          ? new Date(data[`effDate${index + 1}`])
          : '',
      });
    }

    setChildren(updatedChildrenData); // Update the state
    const updatedEventHistory = [...jobdata.eventHistory, newEvent];

    const updatedJobDetails = {
      ...jobdata,
      eventHistory: updatedEventHistory,
    };

    const passDetails = {
      // personalDetails: data,
      personalDetails: {
        ...data,
        children: updatedChildrenData, // Spread the updated children data here
      },
      // jobDetails: jobdata,
      jobDetails: updatedJobDetails,
      wageDetails: wagedata,
      locationId: locationId,
      livePhoto: imageSrc,
      uploadDocument: uploadDoc,
      status: 'approved',
    };
    console.log('passDetails', passDetails);

    updatePass(id, passDetails)
      .then((res) => {
        notification.success({ message: 'Record updated Successfully' });
        window.location.reload();
      })
      .catch((err) => {
        notification.error({ message: err.message });
      });
    navigate('/home/ir/employee');
  };

  const onFinish = (values) => {
    navigate('/home/approver/list');

    const data1 = {
      id: data.id,
      medicalCheckupDate: values.medicalCheckupDate,
      reason: values.reason,
      // status: 'approved',
      status: 'panding',
    };

    onNext(values);
    Approved(data1)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.res.data.message });
      });

    Email({
      medicalCheckupDate: values.medicalCheckupDate,
      workmenName: data.firstName + ' ' + data.lastName,
      // templateId: 'MEDICAL_TEST_SCHEDULED',
      templateId: 'WORKMEN_APPROVED_FOR_MEDICAL',
      orgId: userConfig.orgId,
      level: 2,
      role: userConfig.role,
      locationId: userConfig.locationId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };
  const Reject = () => {
    navigate('/home/approver/list');
    const data = {
      id: id,
      medicalCheckupDate: '24-03-2023',
      status: 'rejected',
      reason: 'Not Valid Data',
    };
    Approved1(data)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
    Email({
      templateId: 'RECORD_RESUBMITTED',
      // VYNApplication: 'https://demo.wikiworks.in/login',
      VYNApplication: process.env.REACT_APP_URL,
      orgId: userConfig.orgId,
      level: userConfig.level,
      role: userConfig.role,
      locationId: userConfig.locationId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };

  const downloadFile = async (evt) => {
    evt.preventDefault();

    let status = data.status;
    if (
      status === 'Suspended' ||
      status === 'Terminated' ||
      status === 'recoverloss' ||
      status === 'warning' ||
      status === 'ActiontakenforIncident'
    ) {
      navigate('/home/ir/employee');

      notification.open({
        message: 'Unable to Download Pass',
        description: `Workmen profile is ${status}.`,
        icon: (
          <SmileOutlined
            style={{
              color: '#108ee9',
            }}
          />
        ),
      });
    } else {
      navigate('/home/ir/employee');

      axios
        .post('/pass/pdf', { id: id }, { responseType: 'arraybuffer' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Employment Card.pdf');
          document.body.appendChild(link);
          link.click();
        });
    }
  };

  let status = data.status;

  const Pftoggle = () => {
    setPfDisabled(!PfDisabled);
  };

  // for Rejection Feedback
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState('');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    Reject(rejectReason);

    console.log('Reject Reason:', rejectReason);
    // Close the modal
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    // Close the modal without taking any action
    setIsModalVisible(false);
  };
  console.log('data is', data);

  return (
    <div>
      <Form initialValues={initialValues} ref={formRef} onFinish={onFinish}>
        <Row class='row'>
          <Col span={12} class='column'>
            <Title level={5} style={{ color: 'red' }}>
              Personal Details
            </Title>
            <Row>
              <span>First Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.firstName}</span>
            </Row>
            <Row>
              <span>Last Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.lastName}</span>
            </Row>
            <Row>
              <span>Long Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.LongName}</span>
            </Row>
            <Row>
              <span>Gender</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.gender}</span>
            </Row>
            <Row>
              <span>Date Of Birth</span>
              <span class='span2'>:</span>
              <span class='span3'>{formattedDate}</span>
            </Row>
            <Row>
              <span>Aadhaar Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{`XXXX XXXX ${`${data.aadharNo}`.slice(
                -4
              )}`}</span>
            </Row>
            <Row>
              <span>PAN</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pan}</span>
            </Row>
            <Row>
              <span>Father Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.fatherName}</span>
            </Row>
            <Row>
              <span>DOB Of Father</span>
              <span class='span2'>:</span>
              <span class='span3'>{dlExpiry}</span>
            </Row>
            <Row>
              <span>Mother Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.fatherName}</span>
            </Row>
            <Row>
              <span>DOB Of Mother</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.dobMother ? data.dobMother.format('DD-MM-YYYY') : ''}
              </span>
            </Row>
            <Row>
              <span>Health Issue</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.healthIssue}</span>
            </Row>
            <Row>
              <span>Extra Information</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.extraInformation}</span>
            </Row>
            <Row>
              <span>Age</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.age}</span>
            </Row>
            <Row>
              <span>Initials</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.initials}</span>
            </Row>
            <Row>
              <span>Birth Place</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.birthPlace}</span>
            </Row>
            <Row>
              <span>No. Of Dependents</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.noOFDependents}</span>
            </Row>
            <Row>
              <span>No. Of Children</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.noOFChildren}</span>
            </Row>
            <Row>
              <span>Marital Status</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.martialStatus}</span>
            </Row>
            <Row>
              <span>Name of Spouse</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.spousename}</span>
            </Row>
            <Row>
              <span>Relation</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.relation}</span>
            </Row>
            <Row>
              <span>Qualification of Spouse</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.qualificationOfSpouse}</span>
            </Row>
            <Row>
              <span>Occupation of Spouse</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.occupationOfSpouse}</span>
            </Row>
            <Row>
              <span>DOB of Spouse</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.dobOFSpouse ? data.dobOFSpouse.format('DD-MM-YYYY') : ''}
              </span>
            </Row>
            <Row>
              <span>Date Of Marriage</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.dobOFMarriage
                  ? data.dobOFMarriage.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Child No.</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.childrenNo}</span>
            </Row>
            {Object.keys(data)
              .filter((key) => key.startsWith('name'))
              .map((key, index) => (
                <div key={index}>
                  <Row>
                    <span>
                      <b>{[`Child Number ${index + 1}`]}</b>
                    </span>
                  </Row>
                  <Row>
                    <span>Name</span>
                    <span className='span2'>:</span>
                    <span className='span3'>{data[`name${index + 1}`]}</span>
                  </Row>
                  <Row>
                    <span>Male/Female</span>
                    <span className='span2'>:</span>
                    <span className='span3'>{data[`male${index + 1}`]}</span>
                  </Row>
                  <Row>
                    <span>Birth Date</span>
                    <span className='span2'>:</span>
                    <span className='span3'>
                      {data[`birthDate${index + 1}`]
                        ? new Date(
                            data[`birthDate${index + 1}`]
                          ).toLocaleDateString('en-GB')
                        : ''}
                    </span>
                  </Row>
                  <Row>
                    <span>Medium</span>
                    <span className='span2'>:</span>
                    <span className='span3'>{data[`medium${index + 1}`]}</span>
                  </Row>
                  <Row>
                    <span>Occupation</span>
                    <span className='span2'>:</span>
                    <span className='span3'>
                      {data[`occupation${index + 1}`]}
                    </span>
                  </Row>
                  <Row>
                    <span>Mediclaim</span>
                    <span className='span2'>:</span>
                    <span className='span3'>
                      {data[`mediclaim${index + 1}`]}
                    </span>
                  </Row>
                  <Row>
                    <span>Eff.Date</span>
                    <span className='span2'>:</span>
                    <span className='span3'>
                      {data[`effDate${index + 1}`]
                        ? new Date(
                            data[`effDate${index + 1}`]
                          ).toLocaleDateString('en-GB')
                        : ''}
                    </span>
                  </Row>
                </div>
              ))}
            <Row>
              <span>Religion</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.religion}</span>
            </Row>
            <Row>
              <span>Caste</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.caste}</span>
            </Row>
            <Row>
              <span>Domicile</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.domicile}</span>
            </Row>
            <Row>
              <span>Based In Gujarat</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.basedinGujarath}</span>
            </Row>
            <Row>
              <span>Years In Gujarat(Since When)</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {data.yearsInGujarath
                  ? data.yearsInGujarath.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            {/* <Row>
              <span>Number Of year spent in Gujarat</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.numberOfYearSpent}</span>
            </Row> */}
            <Row>
              <span>Disable</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.disable}</span>
            </Row>
            <Row>
              <span>Disability By Birth or while Working</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.disabilityByBirth}</span>
            </Row>
            <Row>
              <span>Disability Type</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.disabilityType}</span>
            </Row>
            <Row>
              <span>Disability %</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.disabilityPercentage}</span>
            </Row>
            <Row>
              <span>Workmen Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.empCode}</span>
            </Row>
            <Row>
              <span>Unit Name</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.unitName}</span>
            </Row>
            <Row>
              <span>Location Id</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.locationId}</span>
            </Row>
            <Row>
              <span>Experience Details</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.experienceDetails}</span>
            </Row>{' '}
            <Row>
              <span>Email Id</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.emailId}</span>
            </Row>
            <Row>
              <span>PF Exempt</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pfAccountNo ? 'No' : 'Yes'}</span>
            </Row>
            <Row>
              <span>PF Account Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pfAccountNo}</span>
            </Row>
            <Row>
              <span>ESIC Exempt</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.esicno ? 'No' : 'Yes'}</span>
            </Row>
            <Row>
              <span>ESIC Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.esicno}</span>
            </Row>
            <Row>
              <span>UAN Exempt</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.uan ? 'No' : 'Yes'}</span>
            </Row>
            <Row>
              <span>UAN</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.uan}</span>
            </Row>
            <Title level={5}>Aadhaar Addess</Title>
            <Row>
              <Col>Permanent Address Line 1</Col>
              <span class='span2'>:</span>
              <span class='address'>{data.address1}</span>
            </Row>
            <Row>
              <span>Permanent Address Line 2</span>
              <span class='span2'>:</span>
              <span class='address'>{data.address2}</span>
            </Row>
            <Row>
              <span>Permanent Address Line 3</span>
              <span class='span2'>:</span>
              <span class='address'>{data.address3}</span>
            </Row>
            <Row>
              <span>Village</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pvillage}</span>
            </Row>
            <Row>
              <span>District</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.district}</span>
            </Row>
            <Row>
              <span>State</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.state}</span>
            </Row>
            <Row>
              <span>Pincode</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pincode}</span>
            </Row>
            <Row>
              <span>Contact Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.mobile}</span>
            </Row>
            <Row>
              <span>Post Office</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.postOffice}</span>
            </Row>
            <Title level={5}>Present Address</Title>
            <Row>
              <Col>Present Address Line 1</Col>
              <span class='span2'>:</span>
              <span class='paddress'>{data.paddress1}</span>
            </Row>
            <Row>
              <span>Present Address Line 2</span>
              <span class='span2'>:</span>
              <span class='paddress'>{data.paddress2}</span>
            </Row>
            <Row>
              <span>Present Address Line 3</span>
              <span class='span2'>:</span>
              <span class='paddress'>{data.paddress3}</span>
            </Row>
            <Row>
              <span>Village</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pvillage}</span>
            </Row>
            <Row>
              <span>District</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pdistrict}</span>
            </Row>
            <Row>
              <span>State</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pstate}</span>
            </Row>
            <Row>
              <span>Pincode</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.ppincode}</span>
            </Row>
            <Row>
              <span>Contact Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.pmobile}</span>
            </Row>
            <Row>
              <span>Post Office</span>
              <span class='span2'>:</span>
              <span class='span3'>{data.ppostOffice}</span>
            </Row>
            <hr />
            <Title level={5} style={{ color: 'red' }}>
              Wage Details
            </Title>
            <Row>
              <span>Daily/Monthly Wages</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.dailywages}</span>
            </Row>
            <Row>
              <span>Current Basic</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.currentBasic}</span>
            </Row>
            <Row>
              <span>Joining Basic</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.joiningBasic}</span>
            </Row>
            <Row>
              <span>Confirmation Basic</span>
              <span class='span2'>:</span>
              <span class='span3'>{wagedata.confirmationBasic}</span>
            </Row>
          </Col>

          <Col span={12} class='column2' style={{ paddingLeft: '50px' }}>
            <Title level={5} style={{ color: 'red' }}>
              Job Details
            </Title>
            <Row>
              <span>Company</span>
              <span class='span2'>:</span>
              <span class='span3'>{userConfig?.organisation?.name}</span>
            </Row>
            <Row>
              <span>Dept Head</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.deptHead}</span>
            </Row>
            <Row>
              <span>Function Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.functionCode}</span>
            </Row>
            <Row>
              <span>Immediate Adm. Supervisor</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.immediateadmsupervisor}</span>
            </Row>
            <Row>
              <span>Immediate Func. Supervisor</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.immediatefuncsupervisor}</span>
            </Row>
            <Row>
              <span>Next Func. Supervisor</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.nextfuncsupervisor}</span>
            </Row>{' '}
            <Row>
              <span>Contact Supervisor</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.contactSupervisor}</span>
            </Row>
            {/* <Row>
              <span>Location</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.location}</span>
            </Row> */}
            <Row>
              <span>CSN Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.csnCode}</span>
            </Row>
            <Row>
              <span>Office Tel.</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.officeTel}</span>
            </Row>
            <Row>
              <span>Transport User</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.transportUser}</span>
            </Row>{' '}
            <Row>
              <span>Cadre At Joining</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.cadreAtJoining}</span>
            </Row>{' '}
            <Row>
              <span>With Company</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.withCompany}</span>
            </Row>{' '}
            <Row>
              <span>Department Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.departmentCode}</span>
            </Row>{' '}
            <Row>
              <span>Current Cadre</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.currentCadre}</span>
            </Row>{' '}
            <Row>
              <span>Date Of Joining</span>
              <span class='span2'>:</span>
              <span class='span3'>{doj}</span>
            </Row>
            <Row>
              <span>Designation</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.designation}</span>
            </Row>
            <Row>
              <span>Category Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.categoryCode}</span>
            </Row>
            <Row>
              <span>Grade/Training Year</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.gradeYear}</span>
            </Row>
            <Row>
              <span>Date Of Starting Probation</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateofStartingProbation
                  ? jobdata.dateofStartingProbation.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Date Of Confirmation</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateofConfirmation
                  ? jobdata.dateofConfirmation.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Shift Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.shiftCode}</span>
            </Row>
            <Row>
              <span>Date Of Retirement</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateofRetiremenet
                  ? jobdata.dateofRetiremenet.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Locker No.</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.lockerNo}</span>
            </Row>
            <Row>
              <span>Sport Group</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.sportGroup}</span>
            </Row>
            <Row>
              <span>Direct Workmen</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.directWorkmen}</span>
            </Row>
            <Row>
              <span>Last Promotion</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.lastPromotion
                  ? jobdata.lastPromotion.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Shoe Size</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.shoeSize}</span>
            </Row>
            <Row>
              <span>Blood Group</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.bloodGroup}</span>
            </Row>
            <Row>
              <span>Educational Qualification</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.academicQualification}</span>
            </Row>
            {/* <Row>
              <span>Support Document</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.supportDocument}</span>
            </Row> */}
            <Row>
              <span>Current status</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.currentStatus}</span>
            </Row>
            <Row>
              <span>Appointment Ref. No.</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.appoinmentRefNo}</span>
            </Row>
            {/* <Row>
              <span>Ex Trainees?</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.exTrainee}</span>
            </Row> */}
            <Row>
              <span>Previous PS Number</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata?.psNo}</span>
            </Row>
            <Row>
              <span>Appointment Ref. Date.</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.appointmentRefDate
                  ? jobdata.appointmentRefDate.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>TRT Location(1)</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.trtLocation1}</span>
            </Row>{' '}
            <Row>
              <span>TRT Location(2)</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.trtLocation2}</span>
            </Row>{' '}
            <Row>
              <span>Remark</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.remark}</span>
            </Row>{' '}
            <Row>
              <span>Bus Route Code</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.busRouteCode}</span>
            </Row>{' '}
            <Row>
              <span>Event</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.event}</span>
            </Row>{' '}
            <Row>
              <span>Joining Route</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.joiningRoute}</span>
            </Row>{' '}
            <Row>
              <span>Committee/Post</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.committee}</span>
            </Row>{' '}
            <Row>
              <span>Achievement</span>
              <span class='span2'>:</span>
              <span class='span3'>{jobdata.achievement}</span>
            </Row>{' '}
            {/* <Row>
              <span>Long Service Award 15 Years Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.longServiceAward15
                  ? jobdata.longServiceAward15.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>{' '}
            <Row>
              <span>Date Of Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateOfEvent15
                  ? jobdata.dateOfEvent15.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Long Service Award 20 Years Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.longServiceAward20
                  ? jobdata.longServiceAward20.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Date Of Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateOfEvent20
                  ? jobdata.dateOfEvent20.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Long Service Award 25 Years Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.longServiceAward25
                  ? jobdata.longServiceAward25.format('DD-MM-YYYY')
                  : ''}
              </span>{' '}
            </Row>
            <Row>
              <span>Date Of Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateOfEvent25
                  ? jobdata.dateOfEvent25.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Long Service Award 30 Years Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.longServiceAward30
                  ? jobdata.longServiceAward30.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Date Of Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateOfEvent30
                  ? jobdata.dateOfEvent30.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row>
            <Row>
              <span>Long Service Award 35 Years Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.longServiceAward35
                  ? jobdata.longServiceAward35.format('DD-MM-YYYY')
                  : ''}
              </span>{' '}
            </Row>
            <Row>
              <span>Date Of Event</span>
              <span class='span2'>:</span>
              <span class='span3'>
                {jobdata.dateOfEvent35
                  ? jobdata.dateOfEvent35.format('DD-MM-YYYY')
                  : ''}
              </span>
            </Row> */}
          </Col>
        </Row>
        <Divider />

        {/* {status === "pending" ? (
          <Row>
            <Col span={6}>
              <Form.Item>
                <Checkbox onClick={Pftoggle} size="large">
                  Medical Test Date
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="medicalCheckupDate">
                <DatePicker
                  size="large"
                  placeholder="Medical Test Date"
                  disabled={PfDisabled}
                  style={{ width: "80%" }}
                  format="DD-MM-YYYY"
                  
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item style={{ float: "right", marginLeft: "10px" }}>
                <Button type="primary" htmlType="submit">
                  Approve
                </Button>
                <Button type="primary" onClick={evt => update(evt)} style={{marginLeft:"30px"}}>
                  Update
                </Button>
               
              </Form.Item>
              <Form.Item style={{ float: "right", marginLeft: "10px" }}>
              <Button style={{  marginRight: "10px" }} onClick={() => onPrev(formRef.current?.getFieldsValue())}>
                  Back
                </Button>
                <Button onClick={evt => Reject(evt)}>Reject</Button>
               

              </Form.Item>
            </Col>
            
            <Row>
              <Form.Item name="reason">
                <TextArea
                  style={{ width: "250px" }}
                  placeholder="Enter FeedBack"
                ></TextArea>
              </Form.Item>
            </Row>
          </Row>
        ) : (
          <Row>
            <Col span={12}>
             <Title level={5}>Approver List</Title>
                <Row>{data.approvedBy[0]}</Row>
                <Row>{data.approvedBy[1]}</Row>            
                <Row>{data.approvedBy[2]}</Row>             
            
                </Col>
            <Col span={12}>
              <Form.Item style={{ float: "right", marginLeft: "10px" }}>
                <Button type="primary" onClick={evt => downloadFile(evt)}>Download Pass</Button>
              </Form.Item>
              <Form.Item style={{ float: "right", marginLeft: "10px" }}>
          <Button
            onClick={() => onPrev(formRef.current?.getFieldsValue())}
            type="primary"
          >
            Prev
          </Button>
        </Form.Item>
            </Col>
          </Row>
        )} */}

        {userConfig.role === 'ir' && (
          <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
            <Button
              style={{ marginRight: '10px' }}
              onClick={() => onPrev(formRef.current?.getFieldsValue())}>
              Back
            </Button>
            <Button
              type='primary'
              onClick={(evt) => update(evt)}
              style={{ marginLeft: '30px' }}>
              {/* Update */}
              Approve
            </Button>
          </Form.Item>
        )}
        {/* {data.approvalStage === 0 && userConfig.role === 'approver' && (
          <Row>
            <Col span={6}>
              <Form.Item>
                <Checkbox onClick={Pftoggle} size='large'>
                  Medical Test Date
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name='medicalCheckupDate'>
                <DatePicker
                  size='large'
                  placeholder='Medical Test Date'
                  disabled={PfDisabled}
                  style={{ width: '80%' }}
                  format='DD-MM-YYYY'
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
                <Button type='primary' htmlType='submit'>
                  Approve
                </Button>
              </Form.Item>
              <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
                <Button
                  style={{ marginRight: '10px' }}
                  onClick={() => onPrev(formRef.current?.getFieldsValue())}>
                  Back
                </Button>
                <Button onClick={showModal}>Reject</Button>

                <Modal
                  title='Reject Reason'
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}>
                  <Input
                    placeholder='Enter reason for rejection'
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                  />
                </Modal>
              </Form.Item>
            </Col>

            <Row>
              <Form.Item name='reason'>
                <TextArea
                  style={{ width: '250px' }}
                  placeholder='Enter FeedBack'></TextArea>
              </Form.Item>
            </Row>
          </Row>
        )} */}
        {/* {(data.autoApproved ||
          (data.approvalStage === 2 && userConfig.role === 'supervisor')) && (
          <Row>
            <Col span={12}>
              <Title level={5}>Approver List</Title>
              {data.approvedBy &&
                data.approvedBy.length &&
                data?.approvedBy.map((approver, index) => {
                  return <Row key={index}>{approver}</Row>;
                })}

              <Form.Item style={{ float: 'right', marginLeft: '15px' }}>
                <Button type='primary' onClick={(evt) => downloadFile(evt)}>
                  Download Pass
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )} */}
      </Form>
    </div>
  );
};

export default Summarygeneration;

// const data =[{"name":"test1"},{"name":"test2"}];
// return (
//   <div>
//   {data.map(function(d, idx){
//      return (<li key={idx}>{d.name}</li>)
//    })}
//   </div>
// );
// }
