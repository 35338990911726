import CryptoJS from "crypto-js";

// Constants
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY; // Replace with your encryption key

// Custom hook for encryption
const encrypt = (dataToEncrypt) => {
  const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
  const iv = CryptoJS.lib.WordArray.random(16); // For AES-256-CBC, IV length is 16 bytes
  const encrypted = CryptoJS.AES.encrypt(dataToEncrypt, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const ivHex = iv.toString(CryptoJS.enc.Hex);
  const encryptedHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  return ivHex + ":" + encryptedHex;
};

const decrypt = (dataToDecrypt) => {
  const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
  const parts = dataToDecrypt.split(":");
  const ivHex = parts[0];
  const encryptedHex = parts[1];
  const iv = CryptoJS.enc.Hex.parse(ivHex);
  const encrypted = CryptoJS.enc.Hex.parse(encryptedHex);
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: encrypted }, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export { encrypt, decrypt };
