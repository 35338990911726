import React from 'react';
import WorkerDetailMaster from './WorkerDetailMaster';
import ContractorWiseStaffReport from './ContractorWiseStaffReport';
import ContractorMasterReport from './ContractorMasterReport';
import StateWiseMaleFemaleReport from './StateWiseMaleFemaleReport';
import EPNStatusReport from './EPNStatusReport';
// import DesignationWiseReport from './DesignationWiseReport';
import StateWiseContractLabourStrengthChartReport from './StateWiseContractLabourStrengthChartReport';
import CategoryWiseContractLabourStrengthChartReport from './CategoryWiseContractLabourStrengthChartReport';
import AreaWiseContractLabourStrengthChartReport from './AreaWiseContractLabourStrengthChartReport';
import ContractLabourAgeGroupChartReport from './ContractLabourAgeGroupChartReport';
import ContractorsNatureofWorkChartReport from './ContractorsNatureofWorkChartReport';
import DeactivatedLabourReport from './DeactivatedLabourReport';
import LabourHistoryReport from './LabourHistoryReport';
import WorkerHibernateReport from './WorkerHibernateReport';

const ReportsModalContent = ({ selectedReport }) => {
  if (!selectedReport) return null;
  const reportKey = selectedReport.key;

  switch (reportKey) {
    case '1':
      return <WorkerDetailMaster />;
    case '2':
      return <ContractorWiseStaffReport />;
    case '3':
      return <ContractorMasterReport />;
    case '4':
      return <StateWiseMaleFemaleReport />;
    // case '5':
    //   return <DesignationWiseReport />;
      case '5':
      return <EPNStatusReport/>;
    case '6':
      return <StateWiseContractLabourStrengthChartReport />;
    case '7':
      return <CategoryWiseContractLabourStrengthChartReport />;
    case '8':
      return <AreaWiseContractLabourStrengthChartReport />;
    case '9':
      return <ContractLabourAgeGroupChartReport />;
    case '10':
      return <ContractorsNatureofWorkChartReport />;
    case '11':
      return <DeactivatedLabourReport />;
    case '12':
      return <LabourHistoryReport />;
    case '13':
      return <WorkerHibernateReport />;
    default:
      return null;
  }
};

export default ReportsModalContent;
