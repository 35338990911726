import React from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { orgEmailValidator } from '../../validators';
import useAuth from '../../hooks/useAuth';
import axios from '../../services/axiosInstance';
import { getPermissionList, getDetails } from '../../services/auth';

const { Option } = Select;

export default function AdminFormModal({
  open,
  onOk,
  onCancel,
  initialValues = {},
  checkIsValidAddition,
  isEdit = false,
  locationsData,
}) {
  const { userConfig } = useAuth();
  const formRef = React.useRef();
  const [users, setUsers] = React.useState({});
  const [dataFetched, setDataFetched] = React.useState(false);
  const [permissions, setPermissions] = React.useState([]);

  const orgId = userConfig.orgId;

  React.useEffect(() => {
    EmployeeList();
    formRef.current?.setFieldsValue(initialValues);
  }, [open, initialValues]);

  const currentValues = formRef.current?.getFieldsValue(); // Get current form values
  const updatedValues = {
    ...currentValues,
    roleName: currentValues?.roleName?.selectedRoleType, // Set roleName to roleName.selectedRoleType value
  };
  formRef.current?.setFieldsValue(updatedValues);

  async function EmployeeList() {
    const Response = await axios.get('/supplier');
    const Response1 = Response.data.suppliers;
    setUsers({ Response1 });
  }

  console.log('locationsData is ', locationsData);

  const onSubmit = (value) => {
    console.log('value is', value);
    formRef.current?.submit();
  };

  const onFinish = (value) => {
    // Extract the role name from the form values
    const roleName = value.roleName?.selectedRoleType || initialValues.roleName;

    const selectedPermission = permissions.find((item) => {
      return item._id == value.roleName;
    });
    // value.roleName = selectedPermission?.permissions;
    // console.log('value is', value);

    // if (checkIsValidAddition(value)) {
    //   onOk(value, isEdit);
    // }
    // Create an object with permissions and selectedRoleType
    value.roleName = {
      permissions: selectedPermission?.permissions,
      selectedRoleType: selectedPermission?.selectedRoleType,
    };

    //value.name = `${value.firstName} ${value.lastName}`;

    // if (checkIsValidAddition(value)) {
    //   onOk(value, isEdit);
    // }
    if (checkIsValidAddition(value)) {
      onOk(value, isEdit, roleName); // Pass the role name here
      // selectedPermission.selectedRoleType
    }
  };

  const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };

  React.useEffect(() => {
    console.log('data fetched', dataFetched);
    if (!dataFetched) {
      fetchData();
    }
  }, [dataFetched]); // The empty dependency array ensures that the effect runs only once on mount

  const fetchData = () => {
    getDetails(orgId)
      .then((response) => {
        const filteredData = response?.data
          .map((item) => {
            // Check if the selectedRole is "Approver"
            if (item.selectedRole === 'Admin') {
              // If it is "Approver," return the modified item or just the property you need
              return {
                createdAt: item.createdAt,
                orgId: item.orgId,
                permissions: item.permissions,
                selectedRole: item.selectedRole,
                selectedRoleType: item.selectedRoleType,
                _id: item._id,
                // Add other properties as needed
              };
            }

            // If selectedRole is not "Approver," return null or an empty object
            return null;
          })
          .filter((filteredItem) => filteredItem !== null); // Remove null entries from the result

        // console.log("Filtered data:", filteredData);

        setPermissions(filteredData);
        setDataFetched(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  return (
    <Modal
      title={isEdit ? 'Edit' : 'Add Admin'}
      open={open}
      onOk={onSubmit}
      onCancel={_onCancel}
      okText={isEdit ? 'Save' : 'Add '}
      autoComplete='off'>
      <Form
        name='admin'
        ref={formRef}
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}>
        <Form.Item
          label='First Name'
          name='firstName'
          rules={[{ required: true, message: 'Enter First Name' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label='Last Name'
          name='lastName'
          rules={[{ required: true, message: 'Enter Last Name' }]}>
          <Input />
        </Form.Item>

        <Form.Item label='Role' name='role' hidden={true}>
          <Select disabled>
            <Option value='admin'>Admin</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label='Role Name'
          name='roleName'
          initialValue={initialValues?.roleName}
          rules={[{ required: true, message: 'Enter Role Name' }]}>
          <Select
            // disabled={isEdit ? true : false}
            style={{ fontWeight: 'bold', color: 'gray' }}>
            {permissions?.map((level, index) => (
              <Option key={index} value={level?._id}>
                {level?.selectedRoleType}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* <Form.Item label='Contractor Agency' name='supplierId'>
          <Select>
            {users.Response1?.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item label='Location' name='location'>
          <Select disabled={isEdit ? true : false}>
            {locationsData?.locations?.slice(1).map((option, index) => (
              <Option key={index} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          label='Unit Id'
          name='unitId'
          rules={[{ required: true, message: 'Enter Unit Id' }]}>
          <Input disabled={isEdit ? true : false} />
        </Form.Item> */}

        <Form.Item
          label='User Id'
          name='userid'
          rules={[{ required: true, message: 'Enter User Id' }]}
          getValueFromEvent={(e) => e.target.value.toUpperCase()}>
          <Input autoComplete='off' disabled={isEdit ? true : false} />
        </Form.Item>

        <Form.Item
          label='Password'
          name='password'
          rules={[
            { required: true, message: 'Enter Password' },
            {
              pattern: '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$',
              message:
                'Must contain minimum eight characters, atleast one number and one special character',
            },
          ]}>
          <Input.Password maxLength={20} autoComplete='new-password' />
        </Form.Item>
        <Form.Item
          label='Email'
          name='emailId'
          rules={[
            { required: true, message: 'Enter Email' },
            { type: 'email', message: 'Enter valid Email' },
            // {
            //   validator: (_, value) => {
            //     const emailRegex =
            //       /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            //     if (emailRegex.test(value)) {
            //       return orgEmailValidator(value, userConfig.orgId);
            //     }
            //   },
            // },
          ]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

// import React from 'react';
// import { Form, Input, Modal, Select } from 'antd';
// import { orgEmailValidator } from '../../validators';
// import useAuth from '../../hooks/useAuth';
// import axios from '../../services/axiosInstance';
// import { getPermissionList, getDetails } from '../../services/auth';

// const { Option } = Select;

// export default function AdminFormModal({
//   open,
//   onOk,
//   onCancel,
//   initialValues = {},
//   checkIsValidAddition,
//   isEdit = false,
//   locationsData,
// }) {
//   const { userConfig } = useAuth();
//   const formRef = React.useRef();
//   const [users, setUsers] = React.useState({});
//   const [dataFetched, setDataFetched] = React.useState(false);
//   const [permissions, setPermissions] = React.useState([]);

//   const orgId = userConfig.orgId;

//   React.useEffect(() => {
//     EmployeeList();
//     formRef.current?.setFieldsValue(initialValues);
//   }, [open, initialValues]);

//   const currentValues = formRef.current?.getFieldsValue(); // Get current form values
// const updatedValues = {
//   ...currentValues,
//   roleName: currentValues?.roleName?.selectedRoleType, // Set roleName to roleName.selectedRoleType value
// };
// formRef.current?.setFieldsValue(updatedValues);

//   async function EmployeeList() {
//     const Response = await axios.get('/supplier');
//     const Response1 = Response.data.suppliers;
//     setUsers({ Response1 });
//   }

//   console.log('initialValues is ', initialValues);

//   const onSubmit = (value) => {
//     formRef.current?.submit();
//   };

//   const onFinish = (value) => {
//     const selectedPermission = permissions.find((item) => {
//       return item._id == value.roleName;
//     });

//     value.roleName = {
//       permissions: selectedPermission?.permissions,
//       selectedRoleType: selectedPermission?.selectedRoleType
//     };
//     console.log('value is', value);

//     if (checkIsValidAddition(value)) {
//       onOk(value, isEdit, selectedPermission.selectedRoleType); // Pass the role name here
//     }
//   };

//   const _onCancel = () => {
//     formRef.current?.resetFields();
//     onCancel();
//   };

//   React.useEffect(() => {
//     console.log('data fetched', dataFetched);
//     if (!dataFetched) {
//       fetchData();
//     }
//   }, [dataFetched]); // The empty dependency array ensures that the effect runs only once on mount

//   const fetchData = () => {
//     getDetails(orgId)
//       .then((response) => {
//         const filteredData = response?.data
//           .map((item) => {
//             if (item.selectedRole === 'Admin') {
//               return {
//                 _id: item._id,
//                 selectedRoleType: item.roleName.selectedRoleType // Adjusted here to get selectedRoleType
//               };
//             }
//             return null;
//           })
//           .filter((filteredItem) => filteredItem !== null);

//         setPermissions(filteredData);
//         setDataFetched(true);
//       })
//       .catch((error) => {
//         console.error('Error fetching data:', error);
//       });
//   };

//   console.log("data value is",initialValues?.roleName?.selectedRoleType)

//   console.log("permissions",permissions)

//   return (
//     <Modal
//       title={isEdit ? 'Edit' : 'Add Admin'}
//       open={open}
//       onOk={onSubmit}
//       onCancel={_onCancel}
//       okText={isEdit ? 'Save' : 'Add '}
//       autoComplete='off'>
//       <Form
//         name='admin'
//         ref={formRef}
//         layout='vertical'
//         initialValues={initialValues}
//         onFinish={onFinish}>
//         <Form.Item
//           label='First Name'
//           name='firstName'
//           rules={[{ required: true, message: 'Enter First Name' }]}>
//           <Input />
//         </Form.Item>

//         <Form.Item
//           label='Last Name'
//           name='lastName'
//           rules={[{ required: true, message: 'Enter Last Name' }]}>
//           <Input />
//         </Form.Item>

//         <Form.Item label='Role' name='role' hidden={true}>
//           <Select disabled>
//             <Option value='admin'>Admin</Option>
//           </Select>
//         </Form.Item>

//         <Form.Item
//           label='Role Name'
//           name='roleName'
//           rules={[{ required: true, message: 'Enter Role Name' }]}>
//           <Select disabled={isEdit ? true : false}>
//             {permissions?.map((level, index) => (
//               <Option key={index} value={level?._id}>
//                 {level.selectedRoleType}
//               </Option>
//             ))}
//           </Select>
//         </Form.Item>

//         <Form.Item label='Location' name='location'>
//           <Select disabled={isEdit ? true : false}>
//             {locationsData?.locations?.slice(1).map((option, index) => (
//               <Option key={index} value={option.id}>
//                 {option.name}
//               </Option>
//             ))}
//           </Select>
//         </Form.Item>
//         {/* <Form.Item
//           label='Unit Id'
//           name='unitId'
//           rules={[{ required: true, message: 'Enter Unit Id' }]}>
//           <Input disabled={isEdit ? true : false} />
//         </Form.Item> */}

//         <Form.Item
//           label='User Id'
//           name='userid'
//           rules={[{ required: true, message: 'Enter User Id' }]}
//           getValueFromEvent={(e) => e.target.value.toUpperCase()}>
//           <Input autoComplete='off' disabled={isEdit ? true : false} />
//         </Form.Item>

//         <Form.Item
//           label='Password'
//           name='password'
//           rules={[
//             { required: true, message: 'Enter Password' },
//             {
//               pattern: '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$',
//               message:
//                 'Must contain minimum eight characters, atleast one number and one special character',
//             },
//           ]}>
//           <Input.Password maxLength={20} autoComplete='new-password' />
//         </Form.Item>
//         <Form.Item
//           label='Email'
//           name='emailId'
//           rules={[
//             { required: true, message: 'Enter Email' },
//             { type: 'email', message: 'Enter valid Email' },
//           ]}>
//           <Input />
//         </Form.Item>
//       </Form>
//     </Modal>
//   );
// }
