import React from 'react';
import {
  Button,
  Form,
  notification,
  Popconfirm,
  Space,
  Table,
  Typography,
} from 'antd';
import { PlusOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';
import AdminFormModal from './AdminFormModal';
import axios from '../../services/axiosInstance';

const DEFAULT_ADMIN_FORM_VALUE = {
  firstName: '',
  lastName: '',
  role: 'admin',
  userid: '',
  password: '',
  emailId: '',
};

export default function OrganisationAdminForm({
  initialValues = [],
  // initialValues,
  onNext,
  onPrev,
}) {
  const [data, setData] = React.useState(initialValues);
  const [formInitialValue, setFormInitialValue] = React.useState(
    DEFAULT_ADMIN_FORM_VALUE
  );

  const [editIndex, setEditIndex] = React.useState(null);
  const isEdit = editIndex !== null;

  const [adminFormOpen, setAdminFormOpen] = React.useState(false);

  const tableData = React.useMemo(() => {
    return data.map((userData, index) => ({
      index: index,
      id: userData._id,
      name: userData.firstName + ' ' + userData.lastName,
      userid: userData.userid,
      role: userData.role[0].toUpperCase() + userData.role.slice(1),
    }));
  }, [data]);
  console.log('tableData IS', tableData);

  const adminData = tableData.filter((item) => item.role === 'Admin');

  const openAdminModal = () => {
    setFormInitialValue(DEFAULT_ADMIN_FORM_VALUE);
    setAdminFormOpen(true);
  };

  const addAdmin = (value, isEdit = false) => {
    if (isEdit) {
      setData((currData) => {
        return currData.map((d, index) => {
          if (index === editIndex) {
            return value;
          }

          return d;
        });
      });

      setEditIndex(null);
    } else {
      setData((currData) => [...currData, value]);
    }
    setAdminFormOpen(false);
  };

  const edit = (record) => {
    setEditIndex(record.index);
    setFormInitialValue(
      data.find((d) => d.id === record.id) || DEFAULT_ADMIN_FORM_VALUE
    );
    setAdminFormOpen(true);
  };

  const deleteRecord = (record) => {
    setData(data.filter((_, i) => record.index !== i));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      // width: "25%",
      editable: true,
    },
    {
      title: 'User ID',
      dataIndex: 'userid',
      // width: "15%",
      editable: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: '40%',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        return (
          <Space>
            <Typography.Link onClick={() => edit(record)}>
              <EditFilled />
              Edit
            </Typography.Link>
            {/* <Popconfirm title='Delete' onConfirm={() => deleteRecord(record)}>
              <Typography.Link>
                <DeleteFilled />
                Delete
              </Typography.Link>
            </Popconfirm> */}
          </Space>
        );
      },
    },
  ];

  const checkIsValidAddition = (userData = {}) => {
    if (userData) {
      if (
        data.filter(
          (user) =>
            user.userid !== formInitialValue.userid &&
            user.userid === userData.userid
        ).length > 0
      ) {
        notification.error({ message: 'User ID already exists' });
        return false;
      }

      if (
        data.filter(
          (user) =>
            user.emailId !== formInitialValue.emailId &&
            user.emailId === userData.emailId
        ).length > 0
      ) {
        notification.error({ message: 'Email ID already exists' });
        return false;
      }

      return true;
    }
    return false;
  };

  const Title = () => {
    return (
      <Form.Item>
        <Button
          type='dashed'
          onClick={openAdminModal}
          block
          icon={<PlusOutlined />}>
          Add Super Admin
        </Button>
      </Form.Item>
    );
  };
  const onFinish = (values) => {
    onNext(values);
  };
  return (
    <Form onFinish={onFinish} initialValues={initialValues}>
      <Space
        direction='vertical'
        style={{ margin: '22px 12px', display: 'flex' }}>
        <Table
          bordered
          dataSource={adminData}
          columns={columns}
          title={Title}
          rowKey='userid'
        />

        <AdminFormModal
          open={adminFormOpen}
          onOk={addAdmin}
          onCancel={() => setAdminFormOpen(false)}
          checkIsValidAddition={checkIsValidAddition}
          initialValues={formInitialValue}
          isEdit={isEdit}
        />

        <Space style={{ width: '100%', justifyContent: 'end' }}>
          <Button onClick={() => onPrev(data)}>Prev</Button>
          <Button onClick={() => onNext(data)}>Next</Button>
          {/* <Button type="primary" htmlType="submit"> Submit</Button>        */}
        </Space>
      </Space>
    </Form>
  );
}
