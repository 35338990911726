const validatePassword = (_, value) => {
  // Define regular expressions for capital letter, number, and special character
  const capitalLetterRegex = /[A-Z]/;
  const numberRegex = /[0-9]/;
  const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/; // Add more special characters as needed

  if (value.length < 8) {
    return Promise.reject("Password must be at least 8 characters long");
  }

  if (!capitalLetterRegex.test(value)) {
    return Promise.reject("Password must contain at least one capital letter");
  }

  if (!numberRegex.test(value)) {
    return Promise.reject("Password must contain at least one number");
  }

  if (!specialCharacterRegex.test(value)) {
    return Promise.reject(
      "Password must contain at least one special character"
    );
  }

  return Promise.resolve();
};

export default validatePassword;
