import React from 'react';
import { notification, Steps } from 'antd';
import OrganisationAdminForm from '../components/organisation/OrganisationAdminForm';
import OrganisationInfoForm from '../components/organisation/OrganisationInfoForm';
import OrganisationLocationForm from '../components/organisation/OrganisationLocationForm';
import createOrg from '../services/organisation';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import useAuth from '../hooks/useAuth';
import { Email } from '../services/auth';

const ORG_INFO_DEFAULT = {
  name: '',
  id: '',
  aadharCheck: false,
  secureCheck: false,
  pf: '',
  esi: '',
  logoFile: [],
};

export default function OrganisationOnboard() {
  const [currentPageNo, setCurrentPageNo] = React.useState(0);
  const [orgInfoData, setOrgInfoData] = React.useState(ORG_INFO_DEFAULT);
  const [orgAdminData, setOrgAdminData] = React.useState();
  const [locationsData, setLocationsData] = React.useState({ locations: [] });
  const { user, userConfig } = useAuth();

  //Api Call
  const submit = (_locationsData) => {
    const data = {
      ...orgInfoData,
      orgUsers: orgAdminData,
      locations: _locationsData.locations,
    };

    if (orgInfoData.logoFile) {
      const fileList = orgInfoData.logoFile || [];
      if (fileList.length > 0) {
        data['logoFile'] = fileList[0].name;
        data['logoFileData'] = fileList[0].thumbUrl;
      } else {
        delete data['logoFile'];
      }
    }
    createOrg(data)
      .then((res) => {
        console.log("dataNew", data)
        notification.success({ message: res.data.message });
        setOrgInfoData(ORG_INFO_DEFAULT);
        setOrgAdminData([]);
        setLocationsData([]);
        setCurrentPageNo(0);

        sendEmailNotification(data);
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

      const sendEmailNotification = (data) => {
        Email({
          workmenName: data.orgUsers[0].firstName + ' ' + data.orgUsers[0].lastName,
          templateId: 'CREATE_ADMIN',
          // VYNApplication: 'https://demo.wikiworks.in/login',
          VYNApplication: process.env.REACT_APP_URL,
          orgId: data.id,
          role: data.orgUsers[0].role,
          level: userConfig.level,
          userId:data.orgUsers[0].userid,
          location: data.locations[0].name,
          locationId: null,
          password: data.orgUsers[0].password,
          correctRecipientEmail: data.orgUsers[0].emailId
        })
          .then((res) => {
            console.log("Email notification sent:", res.data);
            notification.success({ message: res.data.message });
          })
          .catch((err) => {
            console.error("Error sending email notification:", err);
            notification.error({ message: err.response.data.message });
          });
      };
  };

  const pageItems = [
    {
      key: 'orgInfo',
      title: 'Organisation Info',
    },
    {
      key: 'admin',
      title: 'Super Admin',
    },
    {
      key: 'location',
      title: 'HO Location',
    },
  ];

  const next = (values) => {
    if (currentPageNo === 0) {
      setOrgInfoData((currData) => ({ ...currData, ...values }));
    }

    if (currentPageNo === 1) {
      // if (values.length === 0) {
      //   notification.error({ message: "Add atleast one admin" });
      //   return;
      // }
      // isDisabled(values.length >1)

      setOrgAdminData(values);
      // submit the data
      // submit(values);

      // return;
    }

    if (currentPageNo === 2) {
      // if (values.length === 0) {
      //   notification.error({ message: "Add atleast one Location" });
      //   return;
      // }

      setLocationsData(values);

      // submit the data
      submit(values);

      return;
    }

    setCurrentPageNo(currentPageNo + 1);
  };

  const prev = (values) => {
    if (currentPageNo === 1) {
      setOrgAdminData(values);
    }

    if (currentPageNo === 2) {
      setLocationsData(values);
    }

    setCurrentPageNo(currentPageNo - 1);
  };

  return (
    <>
      <h2>Principal Employer Onboarding </h2>

      <Steps current={currentPageNo} items={pageItems} />
      {currentPageNo === 0 && (
        <OrganisationInfoForm initialValues={orgInfoData} onNext={next} />
      )}
      {currentPageNo === 1 && (
        <OrganisationAdminForm
          initialValues={orgAdminData}
          onNext={next}
          onPrev={prev}
        />
      )}
      {currentPageNo === 2 && (
        <OrganisationLocationForm
          initialValues={locationsData}
          onNext={next}
          onPrev={prev}
          orgInfoData={orgInfoData}
          orgAdminData={orgAdminData}
        />
      )}
    </>
  );
}
