import React, { useEffect } from 'react';
import { notification, Steps } from 'antd';
import OrganisationAdminForm from '../components/organisationOnboard/OrganisationAdminForm';
import OrganisationInfoForm from '../components/organisationOnboard/OrganisationInfoForm';
import OrganisationLocationForm from '../components/organisationOnboard/OrganisationLocationForm';
import { organizationUpdate } from '../services/auth';
import axios from '../services/axiosInstance';
import { useParams } from 'react-router-dom';
import ColumnGroup from 'antd/es/table/ColumnGroup';
import dayjs from 'dayjs';

const ORG_INFO_DEFAULT = {
  name: '',
  id: '',
  aadharCheck: false,
  secureCheck: false,
  pf: '',
  esi: '',
  logoFile: [],
};
const DEFAULT_ADMIN_FORM_VALUE = {
  firstName: '',
  lastName: '',
  role: 'admin',
  userid: '',
  password: '',
  emailId: '',
};

export default function OrganisationEdit() {
  const [currentPageNo, setCurrentPageNo] = React.useState(0);
  const [orgInfoData, setOrgInfoData] = React.useState(ORG_INFO_DEFAULT);
  const [orgAdminData, setOrgAdminData] = React.useState();
  const [locationsData, setLocationsData] = React.useState({ locations: [] });

  const { id } = useParams();

  //Api Call By Id
  useEffect(() => {
    OrganizationInfo();
  }, []);

  const OrganizationInfo = async () => {
    const response = await axios.get(`org/detail?id=${id}`);
    const orgData = response.data.org;
    const orgDataorgUsers = response.data.org.orgUsers;
    const orgDataLocation = response.data.org.locations;
    let logoFile = [];
    if (orgData.logoFile) {
      logoFile.push({
        name: orgData.logoFile,
        thumbUrl: orgData.logoFileData,
      });
    }

    setOrgInfoData({
      name: orgData.name,
      id: orgData.id,
      aadharCheck: orgData.aadharCheck,
      secureCheck: orgData.secureCheck,
      licenceNumber: orgData.licenceNumber,
      pan: orgData.pan,
      validity: orgData.validity ? dayjs(new Date(orgData.validity)) : '',
      profTaxNumber: orgData.profTaxNumber,
      nameOfOccupier: orgData.nameOfOccupier,
      nameOfFactoryManager: orgData.nameOfFactoryManager,
      departmentName: orgData.departmentName,
      departmentCode: orgData.departmentCode,
      departmentAbbreviation: orgData.departmentAbbreviation,
      pf: orgData.pf,
      esi: orgData.esi,
      logoFile: logoFile,
    });

    // add additional value id
    setOrgAdminData(orgDataorgUsers.map((d) => ({ ...d, id: d._id })));
    // setOrgAdminData(orgDataorgUsers);

    setLocationsData({ locations: orgDataLocation });
  };

  //Api Call
  const submit = (_locationsData) => {
    const data = {
      ...orgInfoData,
      orgUsers: orgAdminData,
      locations: _locationsData.locations,
    };
    if (orgInfoData.logoFile) {
      const fileList = orgInfoData.logoFile || [];
      if (fileList.length > 0) {
        data['logoFile'] = fileList[0].name;
        data['logoFileData'] = fileList[0].thumbUrl;
      } else {
        delete data['logoFile'];
      }
    }
    organizationUpdate(data)
      .then((res) => {
        notification.success({ message: res.data.message });
        setOrgInfoData(ORG_INFO_DEFAULT);
        // setOrgInfoData();
        setOrgAdminData([]);
        setLocationsData([]);
        setCurrentPageNo(0);
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };

  const pageItems = [
    {
      key: 'orgInfo',
      title: 'Organisation Info',
    },
    {
      key: 'admin',
      title: 'Super Admin',
    },
    {
      key: 'location',
      title: 'HO Location ',
    },
  ];

  const next = (values) => {
    if (currentPageNo === 0) {
      setOrgInfoData((currData) => ({ ...currData, ...values }));
    }

    if (currentPageNo === 1) {
      // if (values.length === 0) {
      //   notification.error({ message: "Add atleast one admin" });
      //   return;
      // }

      setOrgAdminData(values);
      // submit the data
      // submit(values);

      // return;
    }

    if (currentPageNo === 2) {
      setLocationsData(values);

      // submit the data
      submit(values);

      return;
    }

    setCurrentPageNo(currentPageNo + 1);
  };

  const prev = (values) => {
    if (currentPageNo === 1) {
      setOrgAdminData(values);
    }

    if (currentPageNo === 2) {
      setLocationsData(values);
    }

    setCurrentPageNo(currentPageNo - 1);
  };

  return (
    <>
      <Steps current={currentPageNo} items={pageItems} />
      {currentPageNo === 0 && (
        <OrganisationInfoForm initialValues={orgInfoData} onNext={next} />
      )}
      {currentPageNo === 1 && (
        <OrganisationAdminForm
          initialValues={orgAdminData}
          onNext={next}
          onPrev={prev}
          // orgDataorgUser={orgDataorgUsers}
        />
      )}
      {currentPageNo === 2 && (
        <OrganisationLocationForm
          initialValues={locationsData}
          onNext={next}
          onPrev={prev}
          orgInfoData={orgInfoData}
        />
      )}
    </>
  );
}
