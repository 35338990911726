import React, { useEffect } from 'react';
import { Divider, notification, Steps, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { getContractorOnboard, getLocation } from '../services/auth';
import { contractorOnboard } from '../services/auth';
import { Approver } from '../services/auth';
import useAuth from '../hooks/useAuth';
import { Email } from '../services/auth';
import ContractorList from './ContractorList';
import JobOrderDetails from '../components/ContractorOnboard/JobOrderDetails';

const { Title } = Typography;

const ORG_INFO_DEFAULT = {
  name: '',
  id: '',
  aadharCheck: false,
  secureCheck: false,
  pf: '',
  esi: '',
  logoFile: [],
};

export default function Contractor() {
  const { user, userConfig } = useAuth();
  const params = useParams()
  console.log("kokl",params)
  const token = params.token;

  console.log(token)
  const [currentPageNo, setCurrentPageNo] = React.useState(0);
  const [orgInfoData, setOrgInfoData] = React.useState(ORG_INFO_DEFAULT);
  const [orgAdminData, setOrgAdminData] = React.useState();
  const [orgSupervisorData, setOrgSupervisorData] = React.useState();
  const [addContractorModalOpen, setAddContractorModalOpen] = React.useState(
    []
  );

  const [locationsData, setLocationsData] = React.useState({ locations: [] });
  const [Tab, setTab] = React.useState('Contractor Onboard');

  const pageItems = [
    {
      key: 'orgInfo',
      title: 'Contractor Details',
    },
    {
      key: 'location',
      title: 'JO & Statutory Details ',
    },
  ];

  useEffect(() => {
    OrganizationInfo();
    if (userConfig.role === 'contractor') {
      locationInfo();
    }
  }, []);

  const OrganizationInfo = async () => {
    getContractorOnboard().then((res) => {
      const orgsup = res.data.org.orgUsers;
      const orgsup1 = orgsup.shift();

      const orgData = res.data.org;
      const orgDataLocation = res.data.org.locations;

      //Approver
      Approver().then((res) => {
        const Approver = res.data.approver;
      });

      let logoFile = [];
      if (orgData.logoFile) {
        logoFile.push({
          name: orgData.logoFile,
          thumbUrl: orgData.logoFileData,
        });
      }

      setOrgInfoData({
        name: orgData.name,
        id: orgData.id,
        // aadharCheck: orgData.aadharCheck,
        // secureCheck: orgData.secureCheck,
        licenceNumber: orgData.licenceNumber,

        pf: orgData.pf,
        esi: orgData.esi,
        logoFile: logoFile,
      });
      setLocationsData({ locations: orgDataLocation });
      setOrgAdminData(orgsup.map((d) => ({ ...d, id: d._id })));

      // setLocationsData({Approver:Approver})
    });
  };

  const locationInfo = async () => {
    getLocation().then((res) => {
      const orgDataLocation = res.data.org.locations;
      // setLocationsData({Approver:Approver})
      setLocationsData({ locations: orgDataLocation });
    });
  };

  const submit = (_locationsData) => {
    const data = {
      ...orgInfoData,
      ...locationsData,

      orgUsers: [...orgAdminData, ..._locationsData],
    };

    console.log('this is the new data', data);

    contractorOnboard(data)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };

  const next = (values) => {
    if (currentPageNo === 0) {
      setTab('Job Order Details');
      setOrgInfoData((currData) => ({ ...currData, ...values }));
    }

    if (currentPageNo === 1) {
      setLocationsData(values);
      setTab('Admin Onboard');

      submit(values);

      return;
    }

    setCurrentPageNo(currentPageNo + 1);
  };

  const prev = (values) => {
    if (currentPageNo === 1) {
      setTab('Contractor Onboard');

      setLocationsData(values);
    }

    if (currentPageNo === 2) {
      setTab('Job Order Onboard');

      setOrgAdminData(values);
    }

    setCurrentPageNo(currentPageNo - 1);
  };

  return (
    <>
      <h2>{Tab}</h2>

      <Steps
        current={currentPageNo}
        items={pageItems}
        style={{ marginBottom: '10px' }}
      />

      {currentPageNo === 0 && (
        <ContractorList
          initialValues={orgInfoData}
          onNext={next}
          addContractorModalOpen={addContractorModalOpen}
          locationsData={locationsData}
        />
      )}
      {currentPageNo === 1 && (
        <JobOrderDetails
          initialValues={locationsData}
          onNext={next}
          onPrev={prev}
          orgInfoData={orgInfoData}
          orgAdminData={orgAdminData}
          locationsData={locationsData}
        />
      )}
    </>
  );
}
