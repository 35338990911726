import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Input, Space, Typography } from 'antd';
import { DownloadOutlined, EyeFilled, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import axios from '../../services/axiosInstance';
import useAuth from '../../hooks/useAuth';

const Reportsafety = () => {
  const [sortedInfo, setSortedInfo] = useState({});
  const [Employee, setEmployee] = useState([]);
  const [loading, setloading] = useState(true);
  // Search icon
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const { user, userConfig } = useAuth();

  useEffect(() => {
    getEmpList();
  }, []);

  const getEmpList = async () => {
    const response = await axios.get('/pass?pageSize=100&pageNo=1');

    setloading(false);
    setEmployee(
      response.data.passList
        .filter(
          (row) =>
            row.status === 'approved' || row.status === 'pendingwithSupervisor'
        ) // Filter based on "approved" status
        .map((row) => {
          const aadharNo = row.personalDetails?.aadharNo;
          const maskedAadharNo =
            typeof aadharNo === 'number'
              ? `${'********'}${aadharNo.toString().slice(-4)}`
              : aadharNo;

          return {
            key: row.id,
            id: row._id,
            empCode: row.personalDetails.empCode,
            firstName:
              row.personalDetails?.firstName +
              ' ' +
              row.personalDetails?.lastName,
            mobile: row.personalDetails?.mobile,
            aadharNo: maskedAadharNo,
            createdBy: row.personalDetails.empCode,
            status: row.status[0].toUpperCase() + row.status.slice(1),
            locationId: row.locationId,
            departmentCode: row.jobDetails.departmentCode,
            sectionCode: row.jobDetails.sectionCode,
          };
        })
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Emp Id',
      dataIndex: 'empCode',
      key: 'empCode',
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...getColumnSearchProps('firstName'),

      filterMode: 'tree',
      filterSearch: true,
      // width: "25%",
      // sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'firstName' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Aadhaar No',
      dataIndex: 'aadharNo',
      key: 'aadharNo',
    },
    {
      title: 'Department',
      dataIndex: 'departmentCode',
      key: 'departmentCode',
    },
    {
      title: 'Section',
      dataIndex: 'sectionCode',
      key: 'sectionCode',
    },

    {
      title: 'Status',
      dataIndex: 'status',

      key: 'status',
    },

    {
      title: userConfig.level === 3 ? 'Report Incident' : 'Manage Incident',
      dataIndex: 'id',
      render: (id) =>
        // {
        //   if (userConfig.level === 3) {
        //     return (
        //       <Typography>
        //         <Link to={`/home/approver/incidentreport/${id}/safety`}>
        //           <EyeFilled />
        //         </Link>
        //       </Typography>
        //     );
        //   }
        //  else {
        //     return (
        //       <Typography>
        //         <Link to={`/home/approver/incidentreport/${id}`}>
        //           <EyeFilled />
        //         </Link>
        //       </Typography>
        //     );
        //   }
        // },
        {
          if (userConfig.level === 2) {
            if (userConfig.role === 'supervisor') {
              return (
                <Typography>
                  <Link to={`/home/supervisor/incidentreport/${id}/safety`}>
                    <EyeFilled />
                  </Link>
                </Typography>
              );
            } else {
              return (
                <Typography>
                  <Link to={`/home/approver/incidentreport/${id}/safety`}>
                    <EyeFilled />
                  </Link>
                </Typography>
              );
            }
          } else {
            if (userConfig.role === 'supervisor') {
              return (
                <Typography>
                  <Link to={`/home/supervisor/incidentreport/${id}`}>
                    <EyeFilled />
                  </Link>
                </Typography>
              );
            } else {
              return (
                <Typography>
                  <Link to={`/home/approver/incidentreport/${id}`}>
                    <EyeFilled />
                  </Link>
                </Typography>
              );
            }
          }
        },
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setSortedInfo(sorter);
  };

  return (
    <div>
      <h1>
        <b>Incident Report</b>
      </h1>

      <Table columns={columns} dataSource={Employee} onChange={onChange} />
    </div>
  );
};

export default Reportsafety;
