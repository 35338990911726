import axios from './axiosInstance';

export const getCronCompactLogs = (
  startDate,
  endDate,
  status,
) => {
  return axios.get(
    `/cron/getFullCronLogs?startDate=${startDate}&endDate=${endDate}&status=${status}`
  );
};
