import React, { useEffect, useState } from 'react';
import FormItem from 'antd/es/form/FormItem';
import { Shortonboarding, UpdateCronStatus } from '../services/auth';
import axios from 'axios';
import {
  Button,
  Form,
  notification,
  Checkbox,
  Col,
  Row,
  Typography,
  Divider,
  Collapse,
  Upload,
  Input,
  Select,
  DatePicker,
  Table,
} from 'antd';
import {
  UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { inputType, validator, Area } from './constantFile';
import {
  createCustomizationField,
  getCustomizationField,
  updateCustomizationField,
  sendToBackend,
} from '../services/auth';
import { getCronCompactLogs } from '../services/cron';
//import { updateCustomizationField } from '../services/auth';

//import template from "../template"
const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Togglequickonboarding = () => {
  const [UanDisabled, setUanDisabled] = React.useState(true);
  const [PfDisabled, setPfDisabled] = React.useState(true);
  const [EsicDisabled, setEsicDisabled] = React.useState(true);
  const [MaritalDisabled, setMaritalDisabled] = React.useState(true);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldsId, setCustomFieldsId] = useState('');
  const [cronData, setCronData] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    status: '',
  });
  const navigate = useNavigate();

  const { Panel } = Collapse;

  //Id
  const formRef = React.useRef();

  const Uantoggle = () => {
    setUanDisabled(!UanDisabled);
  };
  const Pftoggle = () => {
    setPfDisabled(!PfDisabled);
  };
  const Esictoggle = () => {
    setEsicDisabled(!EsicDisabled);
  };
  const Maritaltoggle = (values) => {
    if (Option.value == values.married) {
      setMaritalDisabled(!MaritalDisabled);
    } else {
      setMaritalDisabled(MaritalDisabled);
    }
  };
  // const userFields = [{ fieldName: firstName, fieldValue: '' , selected: false}]

  const onFinish = (value) => {
    const data = {
      // value
      firstName: value.firstname,
      lastName: value.lastname,
      gender: value.gender,
      age: value.age,
      dob: value.dob,
      mobile: value.mobile,
      aadharNo: value.aadharNo,
      fatherName: value.fatherName,
      idMark: value.idMark,
      emergencyContactName: value.emergencyContactName,
      emergencyContactNo: value.emergencyContactNo,
      spousename: value.spousename,
      childrenNo: value.childrenNo,
      idmark: value.idmark,
      religion: value.religion,
      domicile: value.domicile,
      numberOfYearSpent: value.numberOfYearSpent,
      height: value.height,
      pan: value.pan,
      handicapped: value.handicapped,
      disabilityType: value.disabilityType,
      disabilityPercentage: value.disabilityPercentage,
      votercardno: value.votercardno,
      rationcardno: value.rationcardno,
      biometricBypass: value.biometricBypass,
      biometricImage: value.biometricImage,
      electronicSignature: value.electronicSignature,
      pmeRequired: value.pmeRequired,
      profilePic: value.profilePic,
      livePhoto: value.livePhoto,
      address: value.address,
      paddress: value.paddress,
      epnNumber: value.epnNumber,
      epnRenew: value.epnRenew,
      hazardousProcessArea: value.hazardousProcessArea,
      technicalDetails: value.technicalDetails,
      natureOfWork: value.natureOfWork,
      policeVerification: value.policeVerification,
      safetyTrainingFromDate: value.safetyTrainingFromDate,
      safetyTrainingUptoDate: value.safetyTrainingUptoDate,
      safetyTrainingRemarks: value.safetyTrainingRemarks,
      blockRemark: value.blockRemark,
      workingArea: value.workingArea,
      gateNumber: value.gateNumber,
      esicno: value.esicno,
      uan: value.uan,
      pfAccountNo: value.pfAccountNo,
      //Job Details
      departmentCode: value.departmentCode,
      sectionCode: value.sectionCode,
      workorderCode: value.workOrderCode,
      itemserviceCode: value.itemServiceCode,
      workType: value.workType,
      labourType: value.labourType,
      jobCode: value.jobCode,
      skillCode: value.skillCode,
      subSkillCode: value.subSkillCode,
      subSkillDate: value.subSkillDate,
      designation: value.designation,
      duration: value.duration,
      workerCategory: value.workerCategory,

      contractorReferenceNumber: value.contractorReferenceNumber,
      doj: value.doj,
      shoeSize: value.shoeSize,
      bloodGroup: value.bloodGroup,
      academicQualification: value.academicQualification,
      techQualification: value.techQualification,
      dl: value.dl,
      dld: value.dld,
      terminationstatus: value.terminationstatus,
      workexperience: value.workexperience,
      relativeworking: value.relativeworking,
      bankdetails: value.bankdetails,

      basic: value.basic,
      da: value.da,
      allowance1: value.allowance1,
      allowance2: value.allowance2,
      vda: value.vda,
      hra: value.hra,
      conveyance: value.conveyance,
      specialAllowance: value.specialAllowance,
      medicalAllowance: value.medicalAllowance,
      eduAllowance: value.eduAllowance,
      travelAllowance: value.travelAllowance,
      ot: value.ot,
      effectiveDate: value.effectiveDate,
    };

    Shortonboarding(data)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.res.data.message });
      });
    navigate('/home/admin/organisation');
  };

  // Import Excel Sheet
  const customRequest = ({ file, onSuccess }) => {
    onSuccess(file);
  };
  // const beforeUpload = (file) => {
  //   const reader = new FileReader();

  //   reader.onload = (e) => {
  //     const data = new Uint8Array(e.target.result);
  //     const workbook = XLSX.read(data, { type: 'array' });

  //     // Access the second sheet (index 1) by its name
  //     const secondSheet = workbook.Sheets[workbook.SheetNames[1]];
  //     const sheetData = XLSX.utils.sheet_to_json(secondSheet);
  //     console.log('jason data is', sheetData);

  //     // Convert date values to the desired format
  //     const formattedSheetData = sheetData.map((item) => ({
  //       ...item,
  //     }));
  //     console.log("format data",formattedSheetData)
  //     // uploadMinimumWageHistory({ minWageData: formattedSheetData });
  //   };

  //   reader.readAsArrayBuffer(file);
  //   return false; // Prevent default Upload behavior
  // };

  const beforeUpload = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetData = workbook.SheetNames.map((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        return { sheetName, dataParsed: XLSX.utils.sheet_to_json(sheet) };
      });
      // Convert date values to the desired format
      const formattedSheetData = sheetData.map((item) => ({ ...item }));
      console.log('info', formattedSheetData);

      // Send data to the backend using the imported function
      return sendToBackend(formattedSheetData)
        .then(() => {
          console.log('Upload successful');
          notification.success({
            message: 'Upload Successful',
            description: 'The data has been successfully uploaded.',
          });
          return { success: true, message: 'Upload successful' };
        })
        .catch((error) => {
          console.error('Upload failed', error);
          notification.error({
            message: 'Upload Failed',
            description: 'There was an error during the upload.',
          });
          return { success: false, message: 'Upload failed' };
        });

      // // Wait for all promises to settle
      // Promise.all(promises)
      //   .then((results) => {
      //     const allSuccess = results.every((result) => result.success);

      //     if (allSuccess) {
      //       console.log('All sheets successfully sent to the backend.');
      //     } else {
      //       console.error('Failed to send data for one or more sheets to the backend.');
      //     }
      //   })
      //   .catch((error) => {
      //     console.error('Error while processing promises:', error);
      //   });
    };

    reader.readAsArrayBuffer(file);
    return false; // Prevent default Upload behavior
  };

  const handleDownload = async () => {
    try {
      // const response = await fetch(
      //   'http://localhost:3000/api/download-excel-masterSetting',
      //   {
      //     // const response = await fetch("https://vyn.wikiworks.in:443/api/download-excel", {
      const response = await fetch(
        // 'https://demo.wikiworks.in:443/api/download-excel',
        `${process.env.REACT_APP_API_URL}/download-excel`,
        {
          method: 'GET',
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, 'Template.xlsx');
      } else {
        console.error('Failed to download Excel sheet');
      }
    } catch (error) {
      console.error('Failed to download Excel sheet', error);
    }
  };

  const handleFinish = (value) => {
    console.log('Inside handleFinish', value);

    createCustomizationField({
      customFields: customFields,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.res.data.message });
      });
  };

  const getCustomFields = () => {
    getCustomizationField()
      .then((res) => {
        console.log('value of data', res.data.data);
        setCustomFields(res?.data?.data?.customFields || []);
        setCustomFieldsId(res?.data?.data?._id || '');
      })
      .catch((err) => {
        console.log('error in get');
      });
  };

  const updateCustomFields = () => {
    navigate('/home/admin/organisation');

    updateCustomizationField(customFieldsId, {
      customFields: customFields,
    })
      .then((res) => {
        console.log('value of res in update', res);
      })
      .catch((err) => {
        console.log('error in update');
      });
  };

  useEffect(() => {
    getCustomFields();
  }, []);

  const handleCustomField = (index, name, value) => {
    let data = customFields;
    data[index][name] = value;
    // data[index]["key"] =
    console.log('value of data', data);
  };


  const getCron = () => {

    getCronCompactLogs(filter.startDate, filter.endDate, filter.status)
      .then((res) => {
        console.log(res?.data)
        if (Array.isArray(res.data)) {
          setCronData(
            res.data.map((item) => ({
              id:item.ID || "",
              key: item.jobName,
              jobName: item.jobName,
              status: item.status,
              info : item.psNumber + " " + item.error || "",
              timestamp: item.updatedAt ? new Date(item.updatedAt) : null,
              action: item.status === 'Failed' ? 'Re-run' : '', // Add action based on status
            }))
          );
        } else {
          // Handle when response data is not an array
          setCronData([]);
        }
      })
      .catch((error) => {
        console.log('Error fetching cron:', error);
        setCronData([]); // Reset data in case of error
      });
  };

  useEffect(() => {
    getCron();
  }, [filter]);

  const columns = [
    {
      title: 'Job Name',
      dataIndex: 'jobName',
      key: 'jobName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Information',
      dataIndex: 'info',
      key: 'info',
    },
    {
      title: 'Time',
      dataIndex: 'timestamp',
      render: (text, record) => {
        const formattedTime = new Date(text).toLocaleString();
        return <span>{formattedTime}</span>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        console.log("record from:",record)
        if (text === 'Re-run') {
          return (
            <Button type='primary' onClick={() => handleReRun(record)}>
              Re-run
            </Button>
          );
        }
        return null;
      },
    },
  ];

  const handleReRun = (record) => {
    console.log(record)
    // Send a request to rerun the cron job and update status
    // axios.post('http://localhost:3000/api/cron/update', { jobName: record.jobName })
    // axios.post('https://demo.wikiworks.in:443/api/cron/update', { jobName: record.jobName })
    axios.post(`${process.env.REACT_APP_API_URL}/cron/update`, { jobName: record.jobName, record : record.id })
      .then(response => {
        console.log('Cron job rerun initiated and status updated:', response.data);
        // Optionally, you can update the UI to reflect the success of the operation
        notification.success({ message: response.data.message });
        // After updating, you might want to refresh the cron data to reflect the changes
        getCron();
      })
      .catch(error => {
        console.log("error==>",error)
        console.error('Error rerunning cron job:', error);
        // Handle the error, show a notification, or display an error message to the user
        notification.error({ message: 'Error rerunning cron job' });
      });
  }


  return (
    <>
      <Collapse style={{ fontWeight: 'bold' }}>
        <Panel header='Quick Employee Onboarding' key='1'>
          <Title
            level={4}
            style={{
              textAlign: 'center',
              color: 'black',
              fontFamily: 'sans-serif',
              fontWeight: 'bolder',
            }}>
            Quick Employee Onboarding
          </Title>

          <Title level={4} className='qot' style={{ color: '#808000' }}>
            Personal Details
          </Title>
          <Form layout='vertical' onFinish={onFinish}>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='firstname'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Location
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='lastname'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Worker Code
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={8}>
                <Form.Item name='firstname'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    First Name
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='lastname'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Last Name{' '}
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={8}>
                <Form.Item name='gender'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Gender
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='aadharNo'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Aadhaar Number{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='age'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Age
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={8}>
                <Form.Item name='mobile'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Mobile Number
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='dob'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    DOB
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='spousename'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Spouse Name
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='childrenNo'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Number Of Children
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='idmark'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Id Mark{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>{' '}
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='religion'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Religion
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='domicile'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Domicile{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='numberOfYearSpent'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Number Of year spent in state{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='height'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Height
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='pan'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    PAN{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='handicapped?'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Handicapped?
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='disabilityType'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Disability Type{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='disabilityPercentage'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Disability Percentage
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='votercardno'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Voter Card Number
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>{' '}
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='pmeRequired'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    PME Required?
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='rationcardno'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Ration Card Number
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='biometricBypass?'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Biometric Bypass
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='livePhoto' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Live Photo
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='address' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Aadhaar Address{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='paddress' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Present Address{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item
                  name='emergencyContactName'
                // className='exempt'
                >
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    disabled
                    defaultChecked>
                    Emergency Contact Name{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='emergencyContactNo'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    disabled
                    defaultChecked>
                    Emergency Contact Number{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='profilePic' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Capture Photo{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='fatherName' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Father/Guardian Name{' '}
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={8}>
                <Form.Item name='epnNumber' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    EPN Number{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='epnRenew' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    EPN Renew{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='hazardousProcessArea' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Hazardous Process Area{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='technicalDetails' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Technical Details{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='natureOfWork' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Nature Of Work{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='policeVerification' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Police Verification{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item
                  name='safetyTrainingFromDate'
                  valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Safety Training From Date{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item
                  name='safetyTrainingUptoDate'
                  valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Safety Training Upto Date{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='safetyTrainingRemarks' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Safety Training Remarks{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='blockRemark' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Block Remark{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='workingArea' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Working Area{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='gateNumber' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Gate Number{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='esicno' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    ESI Number{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='uan' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    UAN
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='pfAccountNo' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    PF Account Number{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='fatherName'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    // defaultChecked
                    disabled>
                    Father Name
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Title level={4} className='qot' style={{ color: '#808000' }}>
              Job Details
            </Title>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='departmentCode' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Principal Employer Code{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='contractorCode' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Contractor code{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='contractorName' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Contractor Name{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='departmentCode' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Department code{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='sectionCode' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Section Code{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='doj' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Date Of Joining{' '}
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={8}>
                <Form.Item name='workOrderCode' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    WorkOrder Number{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='itemServiceCode' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Item Service Code{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='workType' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Work Type{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='labourType' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Labour Code{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='jobOrderNumber' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Job Order Number{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='skillCode' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Skill Code{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='subSkillCode' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Sub Skill Code{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='subSkillDate' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Sub Skill Date{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='designation' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Designation{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='duration' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Duration{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='workerCategory' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Worker Category{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item
                  name='contractorReferenceNumber'
                  valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Contractor Reference Number{' '}
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={8}>
                <Form.Item name='shoeSize' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Shoe Size
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='bloodGroup' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Blood Group{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='academicQualification' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Academic Qualification{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='techQualification' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Technical Qualification{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='dl' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Driving Licence Number{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='dld' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Driving Licence Validity{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='terminationstatus' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Termination Status{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='workexperience' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Work Experience{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='relativeworking' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Relative Working In Company?{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='bankdetails' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Bank Details{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Title level={4} className='qot' style={{ color: '#808000' }}>
              Wage Details
            </Title>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='basic' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Basic{' '}
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={8}>
                <Form.Item name='da' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    DA{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='allowance1' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Allowance1{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='allowance2' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Allowance2{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='hra' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    HRA{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='conveyance' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Conveyance{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='specialAllowance' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Special Allowance{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='medicalAllowance' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Medical Allowance{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='eduAllowance' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Education Allowance{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className='gutter-row' span={8}>
                <Form.Item name='travelAllowance' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    Leave/Travel Allowance{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='ot' valuePropName='checked'>
                  <Checkbox onClick={Uantoggle} size='large' className='exempt'>
                    OT Applicable{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={8}>
                <Form.Item name='effectiveDate' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    className='exempt'
                    defaultChecked
                    disabled>
                    Wage Effective Date{' '}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
      <Divider></Divider>
      <Collapse>
        <Panel header='Master Settings' style={{ fontWeight: 'bolder' }}>
          <Form layout='vertical' onFinish={onFinish}>
            <Row>
              <Col span={6}>
                <Form.Item name='dot' label='Download Template'>
                  <Button type='primary' onClick={handleDownload}>
                    Download Template
                  </Button>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='locationId'
                  label='Location Id'
                  rules={[{ required: true, message: 'Enter LocationID' }]}>
                  <Select style={{ width: '60%' }}>
                    <Option>NA</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label='Export Excel Sheet'>
                  <Upload
                    customRequest={customRequest}
                    beforeUpload={beforeUpload}
                    showUploadList={false}>
                    <Button className='mr2 HeaderButtonColor'>
                      <UploadOutlined />
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
      <Divider></Divider>
      <Collapse>
        <Panel
          header='WFD Integration'
          style={{ fontWeight: 'bolder' }}></Panel>
      </Collapse>
      <Divider></Divider>
      <Collapse>
        <Panel header='Custom Fields' style={{ fontWeight: 'bolder' }}>
          <Form
            layout='vertical'
            onFinish={customFieldsId ? updateCustomFields : handleFinish}>
            <Form.List name='names'>
              {(fields, { add, remove }, { errors }) => (
                <>
                  {customFields.map(
                    (field, index) => (
                      console.log('fiels', field),
                      (
                        <Form.Item key={field.key}>
                          <Row gutter={24}>
                            <Col span={5}>
                              <Form.Item
                                name={`label_${index}`}
                                {...field}
                                label='Label'
                              // validateTrigger={['onChange', 'onBlur']}
                              >
                                <Input
                                  defaultValue={field?.label || ''}
                                  value={field?.label || ''}
                                  onChange={(e) => {
                                    handleCustomField(
                                      index,
                                      'label',
                                      e.target.value
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                name={`inputType_${index}`}
                                {...field}
                                label='Input Type'
                                // validateTrigger={['onChange', 'onBlur']}
                                disabled='true'>
                                <Select
                                  value={field?.inputType || ''}
                                  defaultValue={field?.inputType || ''}
                                  disabled={field.isDefault}
                                  onChange={(e) => {
                                    console.log('data is', e);
                                    handleCustomField(index, 'inputType', e);
                                  }}>
                                  {inputType?.map((option) => (
                                    <Option
                                      key={option.key}
                                      value={option.value}>
                                      {option.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item
                                name={`validator_${index}`}
                                {...field}
                                label='Validator'
                              // validateTrigger={['onChange', 'onBlur']}
                              >
                                <Select
                                  value={field?.validator || ''}
                                  defaultValue={field?.validator || ''}
                                  disabled={field.isDefault}
                                  onChange={(e) => {
                                    console.log('value', e);
                                    handleCustomField(index, 'validator', e);
                                  }}>
                                  {validator?.map((option) => (
                                    <Option
                                      key={option.key}
                                      value={option.value}>
                                      {option.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>{' '}
                            <Col span={5}>
                              <Form.Item
                                name={`Area_${index}`}
                                {...field}
                                label='Area'
                              // validateTrigger={['onChange', 'onBlur']}
                              >
                                <Select
                                  value={field?.Area || ''}
                                  defaultValue={field?.Area || ''}
                                  disabled={field.isDefault}
                                  onChange={(e) => {
                                    console.log('value', e);
                                    handleCustomField(index, 'Area', e);
                                  }}>
                                  {Area?.map((option) => (
                                    <Option
                                      key={option.key}
                                      value={option.value}>
                                      {option.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>{' '}
                            {!field.isDefault && (
                              <Col span={4}>
                                {/* {fields.length >= 1 ? ( */}
                                <Form.Item label='Delete'>
                                  <DeleteOutlined
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => {
                                      console.log(index, 'index');
                                      const newCustomFields = [...customFields];
                                      newCustomFields.splice(index, 1);
                                      setCustomFields(newCustomFields);
                                    }}
                                  />
                                </Form.Item>
                                {/* ) : null} */}
                              </Col>
                            )}
                          </Row>
                        </Form.Item>
                      )
                    )
                  )}

                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => {
                        setCustomFields([...customFields, {}]);
                        // add();
                      }}
                      block
                      icon={<PlusOutlined />}>
                      Add Custom Field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item style={{ textAlign: 'center' }}>
              <Button type='primary' htmlType='submit'>
                {customFieldsId ? 'Update' : 'Submit'}
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
      <>
        <Divider />
        <Collapse>
          <Panel header='Cron Scheduler' style={{ fontWeight: 'bolder' }}>
            <>
              <Divider />

              {isFilter && (
                <>
                  <Divider></Divider>
                  <Row style={{ marginBottom: '5px' }} gutter={24}>
                    <Col span={8}>
                      <label>Date Range</label>
                    </Col>
                    <Col span={8}>
                      <label>Status</label>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={8}>
                      <RangePicker
                        showTime={{ format: 'HH:mm' }}
                        format='YYYY-MM-DD HH:mm'
                        onChange={(dates, dateStrings) => {
                          setFilter({
                            ...filter,
                            startDate: dateStrings[0]
                              ? new Date(dateStrings[0]).getTime()
                              : '',
                            endDate: dateStrings[1]
                              ? new Date(dateStrings[1]).getTime()
                              : '',
                          });
                        }}
                      ></RangePicker>
                    </Col>
                    <Col span={8}>
                      <Select
                        style={{ width: '100%' }}
                        value={filter.status}
                        onChange={(e) => {
                          setFilter({ ...filter, status: e });
                        }}
                      >
                        <Option value='Success' key='success'>
                          Success
                        </Option>
                        <Option value='Failed' key='failed'>
                          Failed
                        </Option>
                      </Select>
                    </Col>
                  </Row>
                  <Divider></Divider>
                </>
              )}

              <Row></Row>
              <Table
                columns={columns}
                dataSource={cronData}
                onChange={() => { }}
                pagination={true}
              />
            </>
          </Panel>
        </Collapse>
        <Divider />

      </>
    </>
  );
};

export default Togglequickonboarding;
