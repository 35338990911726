import React, { useState, useEffect } from 'react';

import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Col,
  Row,
  Typography,
  Space,
  Tooltip,
  notification,
  Divider,
  Upload,
  Modal,
} from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';

import useAuth from '../../hooks/useAuth';
import EventHistoryModal from './EventHistoryModal';
import {
  QuestionCircleOutlined,
  UploadOutlined,
  PlusSquareOutlined,
  EyeFilled,
} from '@ant-design/icons';
import {
  getWorkmenLimit,
  jobOrderCount,
  getExcelData,
  getSportsGroup,
  getPSNumber,
  getRNNumber,
} from '../../services/auth';
const { Option } = Select;
const { Title } = Typography;

const Jobdetails = ({
  initialValues,
  onNext,
  onPrev,
  isViewOnly,
  masterData,
  personalDetails,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // console.log(contractorCode)

  /* eslint-disable */
  const [data, setData] = React.useState(initialValues);
  console.log('Initialvalue---->', initialValues);
  // const [datas, setDatas] = React.useState(initialValues);

  const [Isworking, setIsworking] = React.useState(true);
  const [Empstatus, setEmpstatus] = React.useState(true);
  const [addEvent, setAddEvent] = React.useState(false);
  const [EsicDisabled, setEsicDisabled] = React.useState(true);
  // const isDisabled = data?.approvalStage > 0;
  // const [selectedContractorCode, setSelectedContractorCode] = React.useState(null);
  const [eventHistoryTableOpen, setEventHistoryTableOpen] =
    React.useState(false);

  const [jobOrderList, setJobOrderList] = React.useState([]);

  // const [selectedContractorName, setSelectedContractorCode] = React.useState(null);
  const [selectedJobOrder, setSelectedJobOrder] = React.useState(null);
  // const [selectedJobOrder, setSelectedJobOrder] = useState(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState(false);
  const [separatedVariables, setSeperatedVariables] = React.useState([]);
  const [contractorName, setContractorName] = React.useState([]);
  const [functionCodeMaster, setFunctionCodeMaster] = React.useState([]);
  const [currentStatusMaster, setCurrentStatusMaster] = React.useState([]);
  const [cadreCodeMaster, setCadreCodeMaster] = React.useState([]);
  const [bloodGroup, setBloodGroup] = React.useState([]);
  const [busRouteMaster, setBusRouteMaster] = React.useState([]);
  const [categoryCode, setCategoryCode] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [shiftList, setShiftList] = React.useState([]);
  const [cadreCode, setCadreCode] = React.useState([]);
  const [qualification, setQualification] = React.useState([]);
  const [department, setDepartment] = React.useState([]);
  const [masterDataShop, setMasterDataShop] = React.useState([]);
  const [drmr, setDrmr] = React.useState([]);
  const [Area, setArea] = React.useState([]);
  const [CategoryProbation, setCategoryProbation] = React.useState([]);
  const [psNumber, setPSNumber] = useState('');
  const [rnNumber, setRNNumber] = useState('');
  const [currentCadre, setCurrentCadre] = useState('');

  console.log('CategoryProbation', CategoryProbation);

  // CategoryCode
  const [CategoryCode, setCategoryCodes] = React.useState([]);
  const [IsworkingHandicaped, setIsworkingHandicaped] = React.useState(false);

  const [primaryJobSkilldata, setPrimaryJobSkill] = useState([]);
  const [BoilerSuitSize, setboilerSuitSize] = useState([]);
  const [SafetyShoeSize, setSafetyShoeSize] = useState([]);
  const [workingArea, setWorkingArea] = React.useState([]);

  const { userConfig } = useAuth();
  console.log('userConfig.....', userConfig?.organisation?.name);
  const supplierId = userConfig?.supplierId;
  const orgId = userConfig?.orgId;
  const isDisabled = userConfig.role === 'approver';
  const isEditingDisabled = initialValues?.autoApproved == true;

  const onFinish = (values) => {
    console.log('Values....', values);
    values.contractorCode = userConfig?.supplierId;
    values.exTrainee = IsworkingHandicaped;

    onNext(values);
  };
  const IsworkingHandicapped = () => {
    setIsworkingHandicaped(!IsworkingHandicaped);
  };

  const Esictoggle = (e) => {
    const isInputEmpty = e.target.value.trim() === '';

    setEsicDisabled(isInputEmpty);
  };
  const Isworkingtoggle = () => {
    setIsworking(!Isworking);
  };
  const Empstatustoggle = () => {
    setEmpstatus(!Empstatus);
  };

  const onChange = (e) => {};

  const normfile = () => {
    // console.log('object');
  };

  const historyData = initialValues && initialValues?.eventHistory;

  const openEventHistoryTableModal = () => {
    // console.log('Button clicked!');
    setEventHistoryTableOpen(true);
  };

  React.useEffect(() => {
    // Fetch data when the component mounts
    workmenLimit();
    getExcel();
    OrganizationInfo();
  }, []);
  const OrganizationInfo = async () => {
    getSportsGroup().then((res) => {
      console.log('Response......>', res?.data?.nextSportGroup);
      // form.setFieldValue({ sportGroup: res.data?.nextSportGroup });
      // form.setFieldValue('sportGroup', res.data?.nextSportGroup);
      const currentSportGroup = form.getFieldValue('sportGroup');

      if (currentSportGroup === undefined || currentSportGroup === null) {
        // Set the field value only if it's not already set
        form.setFieldValue('sportGroup', res?.data?.nextSportGroup);
      }
    });
  };

  const getExcel = async () => {
    getExcelData(orgId)
      .then((res) => {
        setMasterData(res?.data?.data?.data);
      })
      .catch((error) => {
        console.error('Error fetching Excel data:', error);
      });
  };

  const workmenLimit = async () => {
    // console.log(contractorId);
    getWorkmenLimit(orgId)
      .then((res) => {
        // console.log(res)

        const contractorIdsAndJobOrderNumbers = res?.data?.map((item) => {
          if (
            item &&
            item?.addJobDetails &&
            Array?.isArray(item?.addJobDetails)
          ) {
            return item?.addJobDetails?.map((details) => ({
              id: details?.id,
              jobOrderNumber: details?.jobordernumber,
              name: item?.name,
            }));
          }
          return null;
        });

        const newArr = res.data?.map((item) => {
          const jobOrderNumber = item?.addJobDetails?.map((jobDetail) => {
            return jobDetail?.jobordernumber;
          });
          if (jobOrderNumber) {
            return {
              id: item?.id,
              name: item?.name,
              jobOrderNumber: jobOrderNumber,
            };
          }
        });

        // console.log("22",newArr);

        setSeperatedVariables(newArr);

        // Flatten the array of objects into an array of { id, jobOrderNumber }
        const flattenedContractorIdsAndJobOrderNumbers =
          contractorIdsAndJobOrderNumbers.flat();

        // console.log('1', flattenedContractorIdsAndJobOrderNumbers);

        // console.log("1",flattenedContractorIdsAndJobOrderNumbers)

        // console.log("suppplierid",supplierId)

        const filteredContractors =
          flattenedContractorIdsAndJobOrderNumbers.filter(
            (contractor) => contractor.id === supplierId
          );

        // console.log("bye",filteredContractors)

        const jobOrders = filteredContractors?.map(
          (contractor) => contractor.jobOrderNumber
        );
        // console.log("hii",jobOrders)
        const name = filteredContractors?.map((contractor) => contractor?.name);
        // console.log("name",name)
        const uniqueNames = [...new Set(name)];
        // console.log("name",uniqueNames)
        setContractorName(uniqueNames);

        setJobOrderList(jobOrders);

        // // Get unique contractor IDs
        // const uniqueContractorIds = [...new Set(flattenedContractorIdsAndJobOrderNumbers.map(item => item.id))];

        // // Get unique job order numbers
        // const uniqueJobOrderNumbers = [...new Set(flattenedContractorIdsAndJobOrderNumbers.map(item => item.jobOrderNumber))];

        // const uniqueContractorNames = [...new Set(flattenedContractorIdsAndJobOrderNumbers.map(item => item.name))];

        // // Set state variables with unique IDs
        // console.log(uniqueContractorIds);
        // console.log(uniqueJobOrderNumbers);
        // console.log(uniqueContractorNames);
        // setContractorList(uniqueContractorIds);
        // setJobOrderList(uniqueJobOrderNumbers);
        // setContractorNameList(uniqueContractorNames)
        // // setData(res.data);
        // setMaxContractorLimit(res.data.maxContractors)
        // setlln(res.data.lln)

        // Filter job order numbers based on the selected contractor code
        // if (res && res.data) {
        // const filteredJobOrderNumbers = res.data
        //   .flatMap(item => item.addJobDetails)
        //   .filter(details => details?.contractorCode === value)
        //   .map(details => details?.jobOrderNumber)
        //   .filter(number => number !== undefined);
        //  console.log("asdf",filteredJobOrderNumbers);
        // // Set the filtered job order numbers to the state variable
        // setJobOrderList(filteredJobOrderNumbers);
        // }
      })
      .catch((error) => {
        // setLoader(false);
        console.log('error', error);
      });
  };

  const handleJobOrderChange = async (value) => {
    setSelectedJobOrder(value);
    // console.log(selectedJobOrder);
    const jobId = value;
    const contractorCode = userConfig?.supplierId;

    jobOrderCount({ jobId, contractorCode })
      .then((res) => {
        console.log('joborderdeatils', res.data);

        // Make a request to the backend to get information from MongoDB

        const { maxContractors, jobCount } = res.data;

        // Check if jobCount exceeds the limit
        if (jobCount == maxContractors - 1) {
          notification.error({
            message: 'Limit Reached',
            description: 'Adding one more contractor would exceed the limit.',
          });

          // Set isNextButtonDisabled to true to disable the "Next" button
          //  setIsNextButtonDisabled(false);
        } else {
          // Display a notification if jobCount is greater than maxContractors
          if (jobCount > maxContractors - 1) {
            notification.info({
              message: 'Notice',
              description:
                'The number of contractors is more than the permitted limit.',
            });
            // If the limit is not reached, you might reset the state to enable the button
            setIsNextButtonDisabled(true);
          }
        }
      })
      .catch((error) => {
        // setLoader(false);
        console.log('error', error);
      });
  };

  // const handleContractorNameChange = (value) => {
  //     // console.log("33",value)
  //     const id = separatedVariables.find((item)=>{
  //        return item?.name === value
  //     })
  //     // console.log("44",id)
  //     setSelectedContractorCode(id.id)
  //     setContractorList([id.id]);

  //     setJobOrderList(id.jobOrderNumber)

  // }

  // const handleContractorCodeChange = (value) => {
  //   setSelectedContractorCode(value);

  // }
  // const handleContractorNameChange = (value) => {
  //   const selectedContractor = contractorList.find((contractor) => contractor.name === value);
  //   if (selectedContractor) {
  //     setSelectedContractorCode(selectedContractor.id);
  //     setJobOrderList(selectedContractor.addJobDetails.map((details) => details.jobOrderNumber));
  //   } else {
  //     setSelectedContractorCode(null);
  //     setJobOrderList([]);
  //   }
  // };

  //  console.log("scc",selectedContractorCode)

  // console.log(contractorName)
  React.useEffect(() => {
    functionCodeMasterInput(),
      currentStatusMasterInput(),
      cadreCodeMasterInput(),
      bloodGroupInput(),
      busRouteMasterInput(),
      categoryCodeMasterInput(),
      eventsInput(),
      shiftListInput();
    educationalQualification();
    deptCodeTypeInput();
    primaryJobSkill();
    boilerSuitSize();
    safetyShoeSize();
    CategoryCodeMaster();
  }, [masterData]);

  function deptCodeTypeInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Department_Master';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    setMasterDataShop(dataFromArray);
    // console.log("Hii",dataFromArray)
    const deptCodeInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Dept_Code + ' ' + item?.Department_Name;
    });
    setDepartment(deptCodeInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      console.log('deptCodeInputAreas', deptCodeInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  function primaryJobSkill() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Primary_Job_Skill';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const primaryJobSkillAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Skills_for_Regular_Workman;
    });
    console.log('primaryJobSkillAreas', primaryJobSkillAreas);
    setPrimaryJobSkill(primaryJobSkillAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      // console.log('bloodGroupInputAreas', bloodGroupInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  function CategoryCodeMaster() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Category_Code_Master';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const primaryJobSkillAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Category_Code + ' ' + item?.Description;
    });
    console.log('primaryJobSkillAreas', primaryJobSkillAreas);
    setCategoryCodes(primaryJobSkillAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      // console.log('bloodGroupInputAreas', bloodGroupInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function boilerSuitSize() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Boiler_Suit_Size';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const biolersuitsize1 = dataFromArray?.dataParsed?.map((item) => {
      return item?.BoilerSuitSize;
    });
    // setPrimaryJobSkill(primaryJobSkillAreas);
    setboilerSuitSize(biolersuitsize1);

    // console.log("hii",workingAreas);

    if (dataFromArray) {
      // console.log('bloodGroupInputAreas', bloodGroupInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  function safetyShoeSize() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Safety_Shoes_Size';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const biolersuitsize1 = dataFromArray?.dataParsed?.map((item) => {
      return item?.SafetyShoesSize;
    });
    // setPrimaryJobSkill(primaryJobSkillAreas);
    setSafetyShoeSize(biolersuitsize1);

    // console.log("hii",workingAreas);

    if (dataFromArray) {
      // console.log('bloodGroupInputAreas', bloodGroupInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  const fieldChanges = (changedFields, allFields) => {
    console.log('changes in field', changedFields);
    // if (changedFields[0].name == 'departmentCode') {
    //   const changedValue = changedFields[0]?.value?.split(' ');
    //   // console.log('changedValue', changedValue[0]);

    //   let index = masterDataShop?.dataParsed?.find((item) => {
    //     return item?.Dept_Code + ' ' + item?.Department_Name == changedValue[0];
    //   });
    //   console.log('Index......<', index);

    //   console.log('index', index);
    //   const filteredDepartments = masterDataShop?.dataParsed?.filter((item) => {
    //     return item?.Production_Centre === changedValue[0];
    //   });

    //   if (filteredDepartments.length > 0) {
    //     // Extract department codes from the filtered data
    //     const departmentCodes = filteredDepartments?.map((dept) => {
    //       return `${dept.Dept_Code} ${dept.Department_Name}`;
    //     });

    //     // Update the department code options
    //     setWorkingArea(departmentCodes);

    //     // Optionally, set the default value of department code
    //     form.setFieldsValue({
    //       departmentCode: departmentCodes[0], // Set the first department code as default
    //     });
    //   }
    // }
    if (changedFields[0]?.name[0] == 'departmentCode') {
      // const changedValue = changedFields[0]?.value?.split(' ');

      const changedValue = changedFields[0]?.value
        ? changedFields[0].value.split(' ')
        : [];

      let index = masterDataShop?.dataParsed?.find((item) => {
        return item?.Dept_Code == changedValue[0];
      });
      console.log('index', index);
      if (index) {
        form.setFieldsValue({
          workingArea:
            index?.Production_Centre +
            ' ' +
            index?.Production_Centre_Discription,
        });
        setArea([
          index?.Production_Centre + ' ' + index?.Production_Centre_Discription,
        ]);
        form.setFieldValue(
          'immediateadmsupervisor',
          index?.IS_PS_Number + ' ' + index?.IS
        );
        form.setFieldValue(
          'nextfuncsupervisor',
          index?.NS_PS_Number + ' ' + index?.NS
        );
        form.setFieldValue('deptHead', index?.DH_PS_Number + ' ' + index?.DH);
        form.setFieldValue(
          'immediatefuncsupervisor',
          index?.IS_PS_Number + ' ' + index?.IS
        );

        // form.setFieldValue('contactSupervisor', index?.Shop_Supervisor);
      }
    }
    if (changedFields[0]?.name[0] == 'cadreAtJoining') {
      const changedValue = changedFields[0]?.value?.split(' ');
      console.log('changedValue', changedValue[0]);
      console.log('cadreCodeMaster', cadreCodeMaster);

      let index = drmr?.find((item) => {
        return item?.Cadre == changedValue[0];
      });
      console.log('index', index);
      if (index) {
        setCategoryCodes([index?.Category]);
        form.setFieldsValue({ categoryCode: index?.Category });
      }
    }
  };
  console.log('masterDataShop?.dataParsed?', masterDataShop?.dataParsed);
  // const fieldChangesCategoryCode = (changedFields, allFields) => {
  //   console.log('changes in field', changedFields);
  //   if (changedFields[0]?.name[0] == 'cadreAtJoining') {
  //     const changedValue = changedFields[0]?.value?.split(' ');
  //     console.log('changedValue', changedValue[0]);

  //     let index = masterDataShop?.dataParsed?.find((item) => {
  //       return item.DR_MR == changedValue[0];
  //     });
  //     console.log('index', index);
  //     if (index) {
  //       setArea([
  //         index?.Category,
  //       ]);
  //     }
  //   }
  // };
  console.log('Area', Area);

  function functionCodeMasterInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Function_Code_Master';
    // console.log(masterData)
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const functionCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //console.log("hi3",item)
        return item?.Function_Code + ' ' + item?.Description;
      }
    );
    // console.log("bye",functionCodeMasterInputAreas)
    setFunctionCodeMaster(functionCodeMasterInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      // console.log('functionCodeMasterInputAreas', functionCodeMasterInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function bloodGroupInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Blood_Group';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const bloodGroupInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Blood_Group;
    });
    setBloodGroup(bloodGroupInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      // console.log('bloodGroupInputAreas', bloodGroupInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function primaryJobSkill() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Primary_Job_Skill';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const primaryJobSkillAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Skills_for_Regular_Workman;
    });
    setPrimaryJobSkill(primaryJobSkillAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      // console.log('bloodGroupInputAreas', bloodGroupInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function currentStatusMasterInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Current_Status_Master';
    // console.log(masterData)
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const functionCurrentStatusMasterInputAreas =
      dataFromArray?.dataParsed?.map((item) => {
        //console.log("hi3",item)
        return item?.Description;
      });
    //console.log("bye",functionCurrentStatusMasterInputAreas)
    setCurrentStatusMaster(functionCurrentStatusMasterInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      //console.log('functionCurrentStatusMasterInputAreas', functionCurrentStatusMasterInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  // function cadreCodeMasterInput() {
  //   if (!Array?.isArray(masterData)) {
  //     console.error('not an array');
  //     return;
  //   }

  //   let sheetName = 'DR_MR';
  //   // console.log(masterData)
  //   const dataFromArray = masterData?.find(
  //     (item) => item?.sheetName === sheetName
  //   );
  //   console.log('Hii', dataFromArray);
  //   setDrmr(dataFromArray?.dataParsed);
  //   const functionCadreCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
  //     (item) => {
  //       //console.log("hi3",item)
  //       return item?.Cadre_Code + ' ' + item?.Description;
  //     }
  //   );
  //   //console.log("bye",functionCurrentStatusMasterInputAreas)
  //   setCadreCodeMaster(functionCadreCodeMasterInputAreas);
  //   // console.log("hii",workingAreas);

  //   if (dataFromArray) {
  //     console.log(
  //       'functionCadreCodeMasterInputAreas',
  //       functionCadreCodeMasterInputAreas
  //     );
  //   } else {
  //     console.error(
  //       "Object with sheetName '" + sheetName + "' not found in masterData."
  //     );
  //   }
  // }

  function cadreCodeMasterInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Cadre_Master_Regular';
    // console.log(masterData)
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    console.log('Hii', dataFromArray);
    setDrmr(dataFromArray?.dataParsed);
    const functionCadreCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //console.log("hi3",item)
        return item?.Cadre_Code + ' ' + item?.Cadre_Description;
      }
    );
    //console.log("bye",functionCurrentStatusMasterInputAreas)
    setCadreCodeMaster(functionCadreCodeMasterInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      console.log(
        'functionCadreCodeMasterInputAreas',
        functionCadreCodeMasterInputAreas
      );
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function busRouteMasterInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Bus_Route_Master';
    // console.log(masterData)
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const functionbusRouteMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //console.log("hi3",item)
        return item?.Bus_Route_Code + ' ' + item?.Description;
      }
    );
    //console.log("bye",functionCurrentStatusMasterInputAreas)
    setBusRouteMaster(functionbusRouteMasterInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      //console.log('functionbusRouteMasterInputAreas', functionbusRouteMasterInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function categoryCodeMasterInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Category_Code_Master';
    // console.log(masterData)
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const functioncategoryCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //console.log("hi3",item)
        return item?.Category_Code + ' ' + item?.Description;
      }
    );
    const functionCadreCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //console.log("hi3",item)
        return item?.Cadre_Code;
      }
    );

    //console.log("bye",functionCurrentStatusMasterInputAreas)
    setCategoryCode(functioncategoryCodeMasterInputAreas);
    setCadreCode(functionCadreCodeMasterInputAreas);

    // console.log("hii",workingAreas);

    if (dataFromArray) {
      // console.log('functioncategoryCodeMasterInputAreas', functioncategoryCodeMasterInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  //Educational Qualification

  function educationalQualification() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Qualification';
    // console.log(masterData)
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const functioncategoryCodeMasterInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //console.log("hi3",item)
        return item?.Qualification;
      }
    );

    //console.log("bye",functionCurrentStatusMasterInputAreas)
    setQualification(functioncategoryCodeMasterInputAreas);

    // console.log("hii",workingAreas);

    if (dataFromArray) {
      // console.log('functioncategoryCodeMasterInputAreas', functioncategoryCodeMasterInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  // function deptCodeTypeInput() {
  //   if (!Array.isArray(masterData)) {
  //     console.error('not an array');
  //     return;
  //   }

  //   let sheetName = 'SHOP';
  //   const dataFromArray = masterData?.find(
  //     (item) => item?.sheetName === sheetName
  //   );
  //   setMasterDataShop(dataFromArray);
  //   // console.log("Hii",dataFromArray)
  //   const deptCodeInputAreas = dataFromArray?.dataParsed?.map((item) => {
  //     return item?.Department_Code + ' ' + item?.Department;
  //   });
  //   setDepartment(deptCodeInputAreas);
  //   // console.log("hii",workingAreas);

  //   if (dataFromArray) {
  //     console.log('deptCodeInputAreas', deptCodeInputAreas);
  //   } else {
  //     console.error(
  //       "Object with sheetName '" + sheetName + "' not found in masterData."
  //     );
  //   }
  // }
  // const fieldChanges = (changedFields, allFields) => {
  //   console.log('changes in field', allFields, changedFields);
  //   if (changedFields[0].name == 'departmentCode') {
  //     const changedValue = changedFields[0]?.value?.split(' ');
  //     console.log('changedValue', changedValue[0]);

  //     let index = masterDataShop?.dataParsed?.find((item) => {
  //       return item.Department_Code == changedValue[0];
  //     });
  //     console.log('index', index);
  //     if (index) {
  //       setWorkingArea([
  //         index?.Production_Centre + ' ' + index?.Production_Centre_Discription,
  //       ]);
  //     }
  //   }
  // };

  function eventsInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Events';
    // console.log(masterData)
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const functionEventsInputAreas = dataFromArray?.dataParsed?.map((item) => {
      //console.log("hi3",item)
      return item?.Event_Code + ' ' + item?.Description;
    });
    //console.log("bye",functionCurrentStatusMasterInputAreas)
    setEvents(functionEventsInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      //console.log('functionEventsInputAreas', functionEventsInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function shiftListInput() {
    if (!Array?.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Shift_List';
    // console.log(masterData)
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const functionShiftListInputAreas = dataFromArray?.dataParsed?.map(
      (item) => {
        //console.log("hi3",item)
        return item?.Shift_Code + ' ' + item?.Shift_Name;
      }
    );
    //console.log("bye",functionCurrentStatusMasterInputAreas)
    setShiftList(functionShiftListInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      // console.log('functionShiftListInputAreas', functionShiftListInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  //support document upload
  const handleCancel = () => setPreviewOpen(false);

  const normFile = (e) => {
    if (Array?.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewOpen, setPreviewOpen] = useState(true);

  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file?.url || file?.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file?.name || file?.url?.substring(file?.url?.lastIndexOf('/') + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader?.result);
      reader.onerror = (error) => reject(error);
    });
  const [form] = Form.useForm();

  const handleDateChange = (date) => {
    console.log('date is........', date);

    // Calculate age based on the selected date of birth
    // const age = date ? moment().diff(date, 'years') : undefined;
    // Set the value of the age field in the form
    form.setFieldsValue({ currentCadre: date });
  };

  //Date of Retirement
  const [dob, setDob] = useState(null);
  const [retirementDate, setRetirementDate] = useState(null);

  useEffect(() => {
    // const retirementAge = 58; // Retirement age is 58
    // const currentYear = moment().year();
    // // console.log('personalDetails.Dob', personalDetails.Dob);
    // console.log('personalDetails.age', personalDetails?.age);

    // const retirementYear = currentYear + (retirementAge - personalDetails?.age);
    // const retirementDate = moment().year(retirementYear).startOf('day');
    // console.log('retirementDate is', retirementDate);
    // setRetirementDate(retirementDate);
    // const retirementDateString = dayjs(retirementDate, 'YYYY-MM-DD');
    // console.log('retirementDateString');
    // form.setFieldsValue({ dateofRetiremenet: retirementDate });
    // Assuming personalDetails.Dob is an instance of a date object or a similar object with a $d property
    if (!initialValues?.departmentCode) {
      const dob = personalDetails?.Dob?.$d;

      // Assuming retirement age is 58 years
      const retirementAge1 = 58;

      // Create a new Date object from the DOB
      const dobDate = new Date(dob);

      // Calculate the retirement date
      const retirementYear = dobDate.getFullYear() + retirementAge1;
      const retirementMonth = dobDate.getMonth(); // Months are zero-indexed
      const retirementDay = dobDate.getDate();

      const retirementDate = new Date(
        retirementYear,
        retirementMonth,
        retirementDay
      );

      console.log('retirementDate1 is', retirementDate);
      const formattedRetirementDate =
        dayjs(retirementDate).format('DD-MM-YYYY');

      // Function to format date as DD-MM-YYYY

      form.setFieldsValue({ dateofRetiremenet: dayjs(retirementDate) });
    }
  }, []);

  const addEventTableModal = () => {
    setAddEvent(true);
  };
  const uniqueDepartments = new Set(department);

  const locationId = userConfig.locationId;

  const handleCadreChange = async (value) => {
    form.setFieldsValue({ currentCadre: value });
    setCurrentCadre(value);
    // console.log('locationId===>', locationId);
    // console.log('cadreAtJoining===>', currentCadre, value);
    const cadre = value.split(' ')[0];
    if (locationId === 'RNW') {
      try {
        const response = await getRNNumber(locationId, cadre);
        const responseData = response.data;

        console.log('response rnnumber', responseData);

        if (responseData.message === 'New entry') {
          // If it's a new entry, setPSNumber to '0001'
          const newRNNumber = 1;
          const updatedRNNumber = `RN${newRNNumber}`;
          setRNNumber(updatedRNNumber);

          form.setFieldsValue({
            // Specify the field names and their new values
            // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
            psNumber: updatedRNNumber,
            // Add more fields as needed
          });

          // You can do other processing or actions if needed

          console.log('Set RNNumber to', newRNNumber);
        } else {
          // If it's an existing entry, increment the existing numeric part by 1
          let existingNumericPart = responseData.lastRNNumber;
          let numericPart;

          numericPart = parseInt(existingNumericPart) + 1;
          console.log('existingNumericPart', numericPart);

          // Pad the numeric part with leading zeros based on your desired length
          const paddedNumericPart = numericPart.toString().padStart(4, '0');

          // Concatenate with any prefix or other formatting you may need
          const updatedRNNumber = paddedNumericPart;

          // Update the state with the new PSNumber
          setRNNumber(updatedRNNumber);

          form.setFieldsValue({
            // Specify the field names and their new values
            // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
            psNumber: updatedRNNumber,
            // Add more fields as needed
          });

          // console.log("Set PSNumber to", updatedPSNumber);
        }
      } catch (error) {
        console.error(error);
        // Handle error if necessary
      }
    } else {
      try {
        // console.log('locationChangedId==>', newLocationId, orgId);
        const response = await getPSNumber(locationId, cadre);
        const responseData = response.data;

        console.log('response psnumber', responseData);

        if (responseData.message === 'New entry') {
          // If it's a new entry, setPSNumber to '0001'
          const newPSNumber = 1;
          const updatedPSNumber = `PS${newPSNumber}`;
          // setPSNumber(updatedPSNumber);
          form.setFieldsValue({
            // Specify the field names and their new values
            // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
            psNumber: updatedPSNumber,
            // Add more fields as needed
          });

          // You can do other processing or actions if needed

          console.log('Set PSNumber to for new', newPSNumber);
        } else {
          // If it's an existing entry, increment the existing numeric part by 1
          let existingNumericPart = responseData.lastPSNumber;
          console.log('existingNumericPart', existingNumericPart);
          let numericPart;

          numericPart = parseInt(existingNumericPart) + 1;
          console.log('existingNumericPart', numericPart);

          // Pad the numeric part with leading zeros based on your desired length
          const paddedNumericPart = numericPart.toString().padStart(4, '0');

          // Concatenate with any prefix or other formatting you may need
          const updatedPSNumber = paddedNumericPart;

          // Update the state with the new PSNumber
          setPSNumber(updatedPSNumber);
          form.setFieldsValue({
            // Specify the field names and their new values
            // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
            psNumber: updatedPSNumber,
            // Add more fields as needed
          });

          console.log('Set PSNumber to for existing', updatedPSNumber);
        }
      } catch (error) {
        console.error(error);
        // Handle error if necessary
      }
    }
  };

  const handleButtonClick = async () => {
    const currentCadre = form.getFieldValue('currentCadre');
    const cadre = currentCadre.split(' ')[0];

    if (locationId === 'RNW') {
      try {
        const response = await getRNNumber(locationId, cadre);
        const responseData = response.data;

        console.log('response rnnumber', responseData);

        if (responseData.message === 'New entry') {
          // If it's a new entry, setPSNumber to '0001'
          const newRNNumber = 1;
          const updatedRNNumber = `RN${newRNNumber}`;
          setRNNumber(updatedRNNumber);

          form.setFieldsValue({
            // Specify the field names and their new values
            // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
            psNumber: updatedRNNumber,
            // Add more fields as needed
          });

          // You can do other processing or actions if needed

          console.log('Set RNNumber to', newRNNumber);
        } else {
          // If it's an existing entry, increment the existing numeric part by 1
          let existingNumericPart = responseData.lastRNNumber;
          let numericPart;

          numericPart = parseInt(existingNumericPart) + 1;
          console.log('existingNumericPart', numericPart);

          // Pad the numeric part with leading zeros based on your desired length
          const paddedNumericPart = numericPart.toString().padStart(4, '0');

          // Concatenate with any prefix or other formatting you may need
          const updatedRNNumber = paddedNumericPart;

          // Update the state with the new PSNumber
          setRNNumber(updatedRNNumber);

          form.setFieldsValue({
            // Specify the field names and their new values
            // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
            psNumber: updatedRNNumber,
            // Add more fields as needed
          });

          // console.log("Set PSNumber to", updatedPSNumber);
        }
      } catch (error) {
        console.error(error);
        // Handle error if necessary
      }
    } else {
      try {
        // console.log('locationChangedId==>', newLocationId, orgId);
        const response = await getPSNumber(locationId, cadre);
        const responseData = response.data;

        console.log('response psnumber', responseData);

        if (responseData.message === 'New entry') {
          // If it's a new entry, setPSNumber to '0001'
          const newPSNumber = 1;
          const updatedPSNumber = `PS${newPSNumber}`;
          // setPSNumber(updatedPSNumber);
          form.setFieldsValue({
            // Specify the field names and their new values
            // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
            psNumber: updatedPSNumber,
            // Add more fields as needed
          });

          // You can do other processing or actions if needed

          console.log('Set PSNumber to for new', newPSNumber);
        } else {
          // If it's an existing entry, increment the existing numeric part by 1
          let existingNumericPart = responseData.psNumber;
          if (existingNumericPart === '144092') {
            const updatedPSNumber = '144098';

            // Update the state with the new PSNumber
            setPSNumber(updatedPSNumber);
            form.setFieldsValue({
              psNumber: updatedPSNumber,
            });

            console.log(
              'Set PSNumber to 126465 as existing was not 126464',
              updatedPSNumber
            );
          } else if (existingNumericPart === '125650') {
            const updatedPSNumber = '126465';

            // Update the state with the new PSNumber
            setPSNumber(updatedPSNumber);
            form.setFieldsValue({
              psNumber: updatedPSNumber,
            });

            console.log(
              'Set PSNumber to 126465 as existing was not 126464',
              updatedPSNumber
            );
          } else if (existingNumericPart === '591533') {
            const updatedPSNumber = '591535';

            // Update the state with the new PSNumber
            setPSNumber(updatedPSNumber);
            form.setFieldsValue({
              psNumber: updatedPSNumber,
            });

            console.log(
              'Set PSNumber to 126465 as existing was not 126464',
              updatedPSNumber
            );
          } else {
            console.log('existingNumericPart', existingNumericPart);
            let numericPart;

            numericPart = parseInt(existingNumericPart) + 1;
            console.log('existingNumericPart', numericPart);

            // Pad the numeric part with leading zeros based on your desired length
            const paddedNumericPart = numericPart.toString().padStart(4, '0');

            // Concatenate with any prefix or other formatting you may need
            const updatedPSNumber = paddedNumericPart;

            // Update the state with the new PSNumber
            setPSNumber(updatedPSNumber);
            form.setFieldsValue({
              // Specify the field names and their new values
              // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
              psNumber: updatedPSNumber,
              // Add more fields as needed
            });

            console.log('Set PSNumber to for existing', updatedPSNumber);
          }
        }
      } catch (error) {
        console.error(error);
        // Handle error if necessary
      }
    }
  };

  return (
    <>
      <Title level={5}>Job Details</Title>
      <Form
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        form={form}
        onFieldsChange={fieldChanges}>
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='company'
              label='Company'
              // rules={[{ required: true, message: 'Enter Company' }]}
            >
              <Input
                size='large'
                disabled={isDisabled}
                defaultValue={userConfig?.organisation?.name}
              />
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='workingArea'
              label='Working Area'
              rules={[{ required: true, message: 'Enter Working Area' }]}>
              <Select size='large' disabled={isDisabled}>
                
                {Array?.from(uniqueDepartments)?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='departmentCode'
              label='Department Code'
              rules={[{ required: true, message: 'Select Department Code' }]}>
              <Select size='large' disabled={isDisabled}>
                {workingArea?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='departmentCode'
              label='Department Code'
              rules={[
                {
                  required: true,
                  message: 'Enter Department Code',
                },
              ]}>
              <Select size='large' disabled={isDisabled}>
                {department?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='workingArea'
              label='Working Area'
              rules={[{ required: true, message: 'Enter Working Area' }]}>
              <Select size='large' disabled={isDisabled}>
                {workingArea?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item name='deptHead' label='Dept Head'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            {/* <Form.Item name='functionCode' label='Function Code'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item> */}

            <Form.Item name='functionCode' label='Function Code'>
              <Select size='large' disabled={isDisabled}>
                {functionCodeMaster?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='immediateadmsupervisor'
              label='Immediate Adm. Supervisor'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='immediatefuncsupervisor'
              label='Immediate Func. Supervisor'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='nextfuncsupervisor' label='Next Func. Supervisor'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='contactSupervisor' label='Contact Supervisor'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='location'
              label='Location ID'
              rules={[{ required: true, message: 'Select Location Name' }]}>
              <Select size='large' disabled={isDisabled}>
                <Option value='RNW'>RNW</Option>
                <Option value='HZW'>HZW</Option>
              </Select>
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item name='csnCode' label='CSN Code'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='officeTel'
              label='Office Tel.'
              rules={[
                {
                  required: true,
                  message: 'Enter Office Tel.',
                },
                {
                  pattern: /^[0-9]{9}$/,
                  message: 'Enter Valid Office Tel.',
                },
                // {
                //   min: 10,
                //   max: 10,
                //   message: "Mobile number should be 10 digits",
                // },
              ]}>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='transportUser'
              label='Transport User'
              rules={[{ required: true, message: 'Select Transport User' }]}>
              <Select size='large' disabled={isDisabled}>
                <Option value='Yes'>Yes</Option>
                <Option value='No'>No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='withCompany' label='With Company'>
              <Select size='large' disabled={isDisabled}>
                <Option value='yes'>Yes</Option>
                <Option value='no'>No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='designation' label='Designation'>
              <Select size='large' disabled={isDisabled}>
                {cadreCodeMaster?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='cadreAtJoining'
              label='Cadre At Joining'
              rules={[{ required: true, message: 'Select Cadre At Joining' }]}>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) => {
                  // handleCadreChange(value);
                  handleDateChange(value);
                }}>
                {cadreCodeMaster?.map((area, index) => (
                  <Option key={index} value={area.split(' ')[0]}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='currentCadre'
              label='Current Cadre'
              rules={[{ required: true, message: 'Select Current Cadre' }]}>
              {/* <Select size='large' disabled={isDisabled}>
                {cadreCodeMaster?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select> */}
              <Select
                size='large'
                // onChange={handleDateChange}
              >
                {cadreCodeMaster?.map((area, index) => (
                  <Option key={0} value={area.split(' ')[0]}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item>
              <Button type='primary' onClick={handleButtonClick}>
                Genrate PSNumber
              </Button>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='categoryCode' label='Category Code'>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) => {
                  setCategoryProbation(value);
                }}>
                {CategoryCode?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
              {/* <Select size='large' disabled={isDisabled}>
                <Option value='Staff'>STAFF</Option>
                <Option value='Workman'>WORKMAN</Option>
                <Option value='Apprentice'>APPRENTICE</Option>
                <Option value='Atstaff'>AT STAFF</Option>
                <Option value='TrialWorkmen'>TRIAL/TEMP WORKMAN</Option>
              </Select> */}
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={6}
            // hidden={categoryCode[0] == 'Workmen'}
          >
            <Form.Item
              name='dateofStartingProbation'
              label='Date Of Starting Probation'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={
                  isDisabled ||
                  CategoryProbation == '3 APPRENTICE' ||
                  CategoryProbation == '2 TRIAL/TEMP WORKMAN'
                }
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateofConfirmation' label='Date Of Confirmation'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={
                  isDisabled ||
                  CategoryProbation == '3 APPRENTICE' ||
                  CategoryProbation == '2 TRIAL/TEMP WORKMAN'
                }
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='lastDateOfEmployement'
              label='Last Date Of Employement'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='gradeYear' label='Grade/Training Year'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='shiftCode'
              label='Shift Code'
              rules={[{ required: true, message: 'Select Shift Code' }]}>
              <Select size='large' disabled={isDisabled}>
                {shiftList?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateofRetiremenet' label='Date Of Retirement'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={true}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='lockerNo'
              label='Locker No.'
              rules={[
                {
                  pattern: /^\d*$/,
                  message: 'Only Numbers Allowed',
                },
              ]}>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='sportGroup' label='Sports Group'>
              <Select size='large' disabled={isDisabled}>
                <Option value='mercury'>Mercury</Option>
                <Option value='venus'>Venus</Option>
                <Option value='mars'>Mars</Option>
                <Option value='jupiter'>Jupiter</Option>
              </Select>
              {/* <Input /> */}
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='directWorkmen'
              label='Direct Workmen'
              rules={[{ required: true, message: 'Select Direct Workmen' }]}>
              <Select size='large' disabled={isDisabled}>
                <Option value='Y'>Yes</Option>
                <Option value='N'>No</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='lastPromotion' label='Last Promotion'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='currentStatus'
              label='Current status'
              rules={[{ required: true, message: 'Select Current status' }]}>
              <Select size='large' disabled={isDisabled}>
                {currentStatusMaster?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='appoinmentRefNo' label='Appointment Ref. No.'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='appointmentRefDate' label='Appointment Ref. Date.'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='psNumber'
              label='PS Number'
              rules={[{ required: true, message: 'Enter PS Number' }]}>
              <Input
                size='large'
                //defaultValue={psNumber || 'PS1'}
                value={psNumber}
                //readOnly
                style={{ color: 'grey' }}
                disabled
              />
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='exTrainee'
              label='Ex Trainees?'
              rules={[{ required: true, message: 'Select Ex Trainees?' }]}>
              <Select size='large' disabled={isDisabled}>
                <Option value='yes'>Yes</Option>
                <Option value='no'>No</Option>
              </Select>
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item name='exTrainee'>
              <Checkbox
                size='large'
                // name='handicapped'
                // onChange={PmeCheckbox}
                className='exempt'
                onClick={IsworkingHandicapped}
                style={{ paddingTop: '40px' }}
                disabled={isDisabled}>
                Ex Trainees?
              </Checkbox>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='psNo' label='Previous PS Number'>
              <Input
                size='large'
                disabled={!IsworkingHandicaped}
                // onChange={(e) =>
                //   handleFieldChange('disabilityType', e.target.value)
                // }
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='area' label='Area(LEMF/East Shops/CS)'>
              <Select size='large' disabled={isDisabled}>
                {Area?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='trtLocation1' label='TRT Location(1)'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='trtLocation2' label='TRT Location(2)'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='remark' label='Remark'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='busRouteCode' label='Bus Route Code'>
              <Select size='large' disabled={isDisabled}>
                {busRouteMaster?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item name='joiningRoute' label='Joining Route'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='committee' label='Committee/Post'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='achievement' label='Achievement'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='longServiceAward15'
              label='Long Service Award 15 Years Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateOfEvent15' label='Date Of Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>{' '}
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='longServiceAward20'
              label='Long Service Award 20 Years Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateOfEvent20' label='Date Of Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='longServiceAward25'
              label='Long Service Award 25 Years Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateOfEvent25' label='Date Of Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='longServiceAward30'
              label='Long Service Award 30 Years Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateOfEvent30' label='Date Of Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='longServiceAward35'
              label='Long Service Award 35 Years Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateOfEvent35' label='Date Of Event'>
              <DatePicker
                style={{ width: '100%' }}
                size='large'
                disabled={isDisabled}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='doj'
              label='Date Of Joining'
              rules={[{ required: true, message: 'Enter DOJ' }]}
              hasFeedback
              // valuePropName="date"
              // getValueFromEvent={normfile}
            >
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                disabled={isEditingDisabled}
              />
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='supportDocument' label='Support Document'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item
              label='Support Document'
              name='supportDocument'
              valuePropName='fileList'
              getValueFromEvent={normFile}>
              <Upload
                // type="file"
                listType='picture'
                accept='image/*'
                onPreview={handlePreview}
                maxCount={1}
                beforeUpload={() => false}
                // disabled={IsworkingBio}
                size='large'
                style={{ width: '100%' }}>
                <Button
                  icon={<UploadOutlined />}
                  size='large'
                  style={{ width: '100%' }}>
                  Upload Image
                </Button>
              </Upload>
            </Form.Item>

            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}>
              <img
                alt='example'
                style={{
                  width: '100%',
                }}
                src={previewImage}
              />
            </Modal>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='primaryJobSkill' label='Primary Job Skill'>
              <Select size='large' disabled={isDisabled}>
                {primaryJobSkilldata?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Divider hidden={data?.status !== 'approved'}></Divider>
          <Col
            className='gutter-row'
            span={3}
            hidden={data?.status !== 'approved'}>
            <Form.Item name='eventHistory' label='Event History'>
              <Button
                style={{
                  width: '100%',
                  height: '40px',
                  backgroundColor: '#FAFAFA',
                }}
                icon={<EyeFilled />}
                size='large'
                type='default'
                // htmlType='submit'
                onClick={openEventHistoryTableModal}>
                View
              </Button>
            </Form.Item>
          </Col>
          <Col
            className='gutter-row'
            span={3}
            hidden={data?.status !== 'approved'}>
            <Form.Item name='addEvent' label='Add Event'>
              <Button
                style={{
                  width: '100%',
                  height: '40px',
                }}
                icon={<PlusSquareOutlined />}
                size='large'
                // style={{ width: '100%' }}
                type='default'
                onClick={addEventTableModal}>
                Add
              </Button>
            </Form.Item>
          </Col>
          {addEvent && (
            <Col
              className='gutter-row'
              span={6}
              hidden={data?.status !== 'approved'}>
              <Form.Item name='event' label='Event'>
                <Select size='large' disabled={isDisabled}>
                  {events?.map((area, index) => (
                    <Option key={index} value={area}>
                      {area}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {addEvent && (
            <Col
              className='gutter-row'
              span={6}
              hidden={data?.status !== 'approved'}>
              <Form.Item
                name='fromCadre'
                label='From Cadre'
                // rules={[{ required: true, message: 'Select Cadre At Joining' }]}
              >
                <Select size='large' disabled={isDisabled}>
                  {cadreCodeMaster?.map((area, index) => (
                    <Option key={index} value={area}>
                      {area}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {addEvent && (
            <Col
              className='gutter-row'
              span={6}
              hidden={data?.status !== 'approved'}>
              <Form.Item
                name='toCadre'
                label='To Cadre'
                // rules={[{ required: true, message: 'Select Cadre At Joining' }]}
              >
                <Select size='large' disabled={isDisabled}>
                  {cadreCodeMaster?.map((area, index) => (
                    <Option key={index} value={area}>
                      {area}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {addEvent && (
            <Col className='gutter-row' span={6}>
              <Form.Item
                name='effDate'
                label='Eff. Date'
                rules={[{ required: true, message: 'Select Eff. Date' }]}>
                <DatePicker
                  style={{ width: '100%' }}
                  size='large'
                  disabled={isDisabled}
                  format='DD-MM-YYYY'
                  value={initialValues?.effDate}
                  // onChange={(date) => handleInputChange('effDate', date)}
                  // defaultValue={new Date(initialValues.effDate)}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Divider></Divider>
        <Title level={5}>Other Details</Title>

        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item name='shoeSize' label='Safety Shoes Size'>
              <Select size='large' disabled={isDisabled}>
                {SafetyShoeSize?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='bloodGroup' label='Blood Group'>
              <Select size='large' disabled={isDisabled}>
                {bloodGroup?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='boilerSuitSize' label='Boiler Suit Size'>
              {/* <Select
                size='large'
                placeholder='Boiler Suit Size'
                disabled={isDisabled}>
                <Option value=''></Option>

                <Option value='E'>E</Option>
                <Option value='P'>P</Option>
              </Select> */}
              <Select size='large' disabled={isDisabled}>
                {BoilerSuitSize?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='transferFromDate' label='Transfer From Date'>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='transferToDate' label='Transfer To Date'>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='experience' label='Experience'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='academicQualification'
              label='Educational Qualification'>
              <Select size='large' disabled={isDisabled}>
                {qualification?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='yearOfPassing' label='Year Of Passing'>
              <Input size='large' disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button
            type='primary'
            htmlType='submit'
            disabled={isNextButtonDisabled}>
            Next
          </Button>
        </Form.Item>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button onClick={() => onPrev(data)} type='primary'>
            Prev
          </Button>
        </Form.Item>
      </Form>
      {historyData && historyData.length > 0 && (
        <EventHistoryModal
          visible={eventHistoryTableOpen} // Change prop name to 'visible'
          onCancel={() => setEventHistoryTableOpen(false)}
          eventHistoryData={initialValues?.eventHistory}
        />
      )}
    </>
  );
};
export default Jobdetails;
