import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Col,
  Row,
  Space,
  Collapse,
  notification,
  Typography,
  Modal,
  Divider,
  Image,
  message,
  Tooltip,
  Upload,
  Table,
} from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader';
import axios from 'axios';
import axios1 from '../../services/axiosInstance';
import Webcam from 'react-webcam';
import {
  SendOtp,
  callCheckAadharUniquenessApi,
  EpnRenew,
  getContractWorkmenEpnNumber,
  getContractWorkmenWorkerCodeNumber,
} from '../../services/auth';
import { VerifyOtp } from '../../services/auth';
import dayjs from 'dayjs';
import useAuth from '../../hooks/useAuth';
import { State } from '../../JSON/data';
import {
  Dadra,
  Delhi,
  Jharkhand,
  Karnataka,
  AndhraPradesh,
  ArunachalPradesh,
  Assam,
  Bihar,
  Chhattisgarh,
  Chandigarh,
  Goa,
  Gujarat,
  Haryana,
  HimachalPradesh,
  Jammu,
  Kerala,
  Lakshadweep,
  MadhyaPradesh,
  Maharashtra,
  Manipur,
  Meghalaya,
  Mizoram,
  Nagaland,
  Odisha,
  Punjab,
  Puducherry,
  Rajasthan,
  TamilNadu,
  Telangana,
  Tripura,
  Uttarakhand,
  UttarPradesh,
  WestBengal,
} from '../../JSON/data';
import { Navigate, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { init } from 'echarts';
import debounce from '../../utils/helperFunction';
import { setLoader } from '../../actions';
import CustomFields from '../customFields';
import { getAppStore } from '../../store';
import getLabel from '../../utils/getLabel';
import moment from 'moment';
import { updatePass } from '../../services/auth';

var convert = require('xml-js');

const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const Personaldetails = ({
  initialValues,
  onNext,
  setImageSrc,
  imageSrc,
  masterData,
  onUpdateFields,
  onUpdateNewData,
  epn,
}) => {
  const { user, userConfig } = useAuth();
  console.log('initialValues of Personal Details', initialValues);
  console.log('epn', epn);
  const shouldDisplayEpnButton = epn === 'No';

  const { id } = useParams();

  const isDisabled = userConfig.role === 'approver';
  const isEditingDisabled =
    userConfig.role === 'supervisor' && initialValues?.approvalStage >= 0;

  // const isDisabled = initialValues?.approvalStage > 0 && userConfig.role !== "supervisor";

  const formRef = React.useRef();
  const formRef1 = React.useRef();
  const [form] = Form.useForm();
  const [location, setlocation] = React.useState({});
  const [values, setValues] = React.useState(initialValues);
  const [selected, setSelected] = React.useState('environment');
  const [livePhoto, setlivePhoto] = React.useState('');
  const [aadharPhoto, setaadharPhoto] = React.useState('');
  const [isFetchedFromAadhar, setISFetchedFromAadhar] = React.useState(false);
  const [Isworking, setIsworking] = React.useState(true);
  const [IsworkingBio, setIsworkingBio] = React.useState(true);
  const [updatedEpn, setUpdatedEpn] = React.useState('');
  const [ranoliEpnNumber, setRanoliEpnNumber] = React.useState('RNW1');
  const [locationChangedId, setLocationId] = useState(null);
  // const [masterDataState, setmasterDataState] = React.useState(
  //   masterData || [] // Set masterData to the provided value or an empty object if not provided

  // );

  // console.log('ranoliEpnNumber==>', ranoliEpnNumber);

  const [IsworkingHandicaped, setIsworkingHandicaped] = React.useState(true);
  const [workingArea, setWorkingArea] = React.useState([]);
  const [gateNumber, setGateNumber] = React.useState([]);
  const [technicalDetails, settechnicalDetails] = React.useState([]);
  const [natureOfWork, setnatureOfWork] = React.useState([]);
  const [ranoliWorkmenCode, setRanoliWorkmenNumber] = React.useState('WC1');
  const [tableData, setTableData] = useState([]);

  const { customFields = [] } = getAppStore();
  console.log('customFields is', customFields);

  const [disabledButton, setDisabledButton] = React.useState({
    sendOtp: initialValues && initialValues.aadharNo ? false : true,
    verifyOtp: true,
  });

  const [aadharNumber, setAadharNumber] = React.useState('');
  const [isAadharValid, setIsAadharValid] = React.useState(false);
  const [isExistingEntry, setIsExistingEntry] = React.useState(
    initialValues?.aadharNo
  );
  const [modalForm] = Form.useForm();

  const Isworkingtoggle = () => {
    setIsworking(!Isworking);
  };
  const BiometricDisable = () => {
    setIsworkingBio(!IsworkingBio);
  };

  const IsworkingHandicapped = () => {
    setIsworkingHandicaped(!IsworkingHandicaped);
  };
  const PmeCheckbox = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const setExemptStatus = () => {
    console.log('value of initil VALUES', initialValues);
    let uanDisabled =
      !id || (id && initialValues?.uan)
        ? initialValues?.Uan
          ? true
          : false
        : true;
    let PfDisabled =
      !id || (id && initialValues?.pfAccountNo)
        ? initialValues?.pf
          ? true
          : false
        : true;
    let esicDisabled =
      !id || (id && initialValues?.esicno)
        ? initialValues?.esicexempt
          ? true
          : false
        : true;

    console.log('value of esi, pf, uan', uanDisabled, PfDisabled, esicDisabled);
    setUanDisabled(uanDisabled);
    setPfDisabled(PfDisabled);
    setEsicDisabled(esicDisabled);
  };

  const handleFieldChange = (fieldName, value) => {
    console.log('===test is', fieldName, value);

    form.setFieldsValue({ [fieldName]: value });
    onUpdateFields?.(fieldName, value);
  };

  useEffect(() => {
    EmployeeList();
    setExemptStatus();
  }, []);
  async function EmployeeList() {
    const Response = await axios1.get('/org/locations');
    console.log('Response', Response);
    const Response2 = Response.data.org.locations;
    setlocation({ Response2 });
  }

  useEffect(() => {
    formRef.current?.setFieldsValue(initialValues);
    formRef1.current?.setFieldsValue(
      initialValues?.epnRenewHistory[initialValues?.epnRenewHistory.length - 1]
    );

    setExemptStatus();
    setlivePhoto(initialValues?.livePhoto);
    setaadharPhoto(initialValues?.aadharPhoto);
    setISFetchedFromAadhar(initialValues?.isFetchedFromAadhar);
    setEmploymentStatus(initialValues?.statusToBeImposed);
  }, [initialValues]);

  const [startScan, setStartScan] = React.useState(false);
  const [loadingScan, setLoadingScan] = React.useState(false);
  const [data, setData] = useState();
  const [date1, setdate1] = React.useState({});
  const [state, setstate] = React.useState({});
  const { Panel } = Collapse;
  const [loading, setLoading] = useState(false);
  const [otploading, setotploading] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [isAadharChecked, setIsAadharChecked] = useState(false);
  const [ViewOnly, setViewOnly] = useState(false);
  const navigate = useNavigate();

  const handleScan = async (scanData) => {
    setViewOnly(true);
    setLoadingScan(true);
    if (scanData && scanData !== '') {
      const xmlDocument1 = new DOMParser().parseFromString(
        scanData,
        'text/xml'
      );
      var xml = scanData;
      var result1 = convert.xml2js(xml, { compact: true, spaces: 4 });
      const fullName = result1.PrintLetterBarcodeData._attributes.name;
      const parts = fullName.split(' ');
      const lastName = parts.pop();
      const firstName = parts.join(' ');
      const gender = result1.PrintLetterBarcodeData._attributes.gender;
      const yob = result1.PrintLetterBarcodeData._attributes.yob;
      const dob = dayjs(
        result1.PrintLetterBarcodeData._attributes.dob,
        'DD-MM-YYYY'
      );
      var theAge = 2023 - yob;
      const uid = result1.PrintLetterBarcodeData._attributes.uid;
      const endNum = uid.slice(-4);
      const maskNum = endNum.padStart(uid.length, '*');
      const co = result1.PrintLetterBarcodeData._attributes.co;
      const house = result1.PrintLetterBarcodeData._attributes.house;
      const street = result1.PrintLetterBarcodeData._attributes.street;
      const lm = result1.PrintLetterBarcodeData._attributes.lm;
      const loc = result1.PrintLetterBarcodeData._attributes.loc;
      const village = result1.PrintLetterBarcodeData._attributes.vtc;
      const dist = result1.PrintLetterBarcodeData._attributes.dist;
      const state = result1.PrintLetterBarcodeData._attributes.state;
      const pc = result1.PrintLetterBarcodeData._attributes.pc;
      const address =
        result1.PrintLetterBarcodeData._attributes.house +
        ', ' +
        street +
        ', ' +
        lm +
        ', ' +
        loc;
      setdate1({ dob });
      setData(xml);
      setStartScan(false);
      setLoadingScan(false);

      setstate({ state });

      form.setFieldsValue({
        firstName: firstName,
        lastName: lastName,
        gender: gender,
        aadharNo: maskNum,
        fatherName: co,
        address: address,
        village: village,
        district: dist,
        state: state,
        pincode: pc,
        age: theAge,
        dob: dob,
      });
    }
  };

  const handleError = (err) => {
    console.error(err);
  };
  const onFinish = (values) => {
    console.log('=====values is', values);
    values.aadharPhoto = uidai?.profilePic
      ? 'data:image/png;base64,' + uidai?.profilePic
      : initialValues?.aadharPhoto || '';
    values.livePhoto = imageSrc || initialValues?.livePhoto;

    if (
      !(values.firstName || initialValues.firstName) ||
      !(values.locationId || initialValues.locationId)
    ) {
      message.error('Please open all the section to proceed.');
      return false;
    }
    console.log('values', values);
    values.isFetchedFromAadhar = isFetchedFromAadhar;
    values.statusFromDate = new Date(values?.statusFromDate)?.getTime();
    values.statusToDate = new Date(values?.statusToDate)?.getTime();
    values.statusTerminateNoticeDate = new Date(
      values?.statusTerminateNoticeDate
    )?.getTime();

    // return 0;
    onNext(values);
  };

  const [UanDisabled, setUanDisabled] = useState();
  const [PfDisabled, setPfDisabled] = useState();
  const [FormDisabled, setFormDisabled] = useState();

  const [EsicDisabled, setEsicDisabled] = useState();

  useEffect(() => {
    form.setFieldValue('esicexempt', EsicDisabled);
    form.setFieldValue('pf', PfDisabled);
    form.setFieldValue('Uan', UanDisabled);
  }, [EsicDisabled, PfDisabled, UanDisabled]);
  const [MaritalDisabled, setMaritalDisabled] = useState(true);
  const [date, setDate] = useState(new Date());
  const [uidai, setuidai] = useState({});
  const [adhar, setadhar] = useState('');
  const [otp, setotp] = useState('');
  const [Request, setRequest] = useState({});
  const [disable, setDisable] = React.useState(false);
  const [otpdisable, setotpDisable] = React.useState(false);
  const [consent, setconsent] = React.useState(true);
  const [openedAccordian, setOpenAccordian] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const onChange1 = (date) => {
    setDate(date);
  };

  //on preview Usestate
  /* eslint-disable */

  const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewOpen, setPreviewOpen] = useState(true);

  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [presentAdress, setpresentAdress] = useState({
    address: '',
    village: '',
    district: '',
    state: '',
    pincode: '',
  });
  const onChange = (e) => {
    const { name, value } = e.target;
    setpresentAdress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    handleFieldChange(name, value);
  };

  const agecalculated = (e) => {
    console.log(e);
  };

  const Uantoggle = () => {
    form.setFieldValue('uan', '');
    setUanDisabled(!UanDisabled);
  };
  const Pftoggle = (e) => {
    form.setFieldValue('pfAccountNo', '');
    setPfDisabled(!PfDisabled);
  };
  // const toggle = (e) => {
  //   form.setFieldValue('pfAccountNo', '');
  //   setPfDisabled(!PfDisabled);
  // };
  const Esictoggle = (e) => {
    form.setFieldValue('esicno', '');
    // form.setFieldValue('esicexempt', e.target.value);
    setEsicDisabled(!EsicDisabled);
  };
  const Maritaltoggle = (values) => {
    if (Option.value == values.married) {
      setMaritalDisabled(!MaritalDisabled);
    } else {
      setMaritalDisabled(MaritalDisabled);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewOpen(false);
  function addressFunction(e) {
    if (e.target.checked) {
      form.setFieldsValue({
        pstate: form.getFieldValue('state'),
        // paddress: form.getFieldValue('address'),
        paddress1: form.getFieldValue('address1'),
        paddress2: form.getFieldValue('address2'),
        paddress3: form.getFieldValue('address3'),
        pvillage: form.getFieldValue('village'),
        pdistrict: form.getFieldValue('district'),
        ppincode: form.getFieldValue('pincode'),
        ptaluka: form.getFieldValue('taluka'),
        ptehsil: form.getFieldValue('tehsil'),
        pcity: form.getFieldValue('city'),
        pcountry: form.getFieldValue('country'),
      });
    } else {
      form.setFieldsValue({
        pstate: form.getFieldValue(''),
        // paddress: form.getFieldValue(''),
        paddress1: form.getFieldValue(''),
        paddress2: form.getFieldValue(''),
        paddress3: form.getFieldValue(''),
        pvillage: form.getFieldValue(''),
        pdistrict: form.getFieldValue(''),
        ppincode: form.getFieldValue(''),
        ptaluka: form.getFieldValue(''),
        ptehsil: form.getFieldValue(''),
        pcity: form.getFieldValue(''),
        pcountry: form.getFieldValue(''),
      });
    }
  }

  // const Sendotp = () => {
  //   const aadharNo = formRef.current.getFieldValue('aadharNo');
  //   // setLoading(true);
  //   // setTimeout(() => {
  //   //   setLoading(false);
  //   // }, 3500);
  //   // setDisable(true);
  //   setLoader(true);

  //   SendOtp({ aadhaarNo: aadharNo, consent: 'Y' })
  //     .then((res) => {
  //       setLoader(false);
  //       const requestId = res.data.responseObject.requestId;

  //       setRequest({ requestId });
  //       setDisabledButton({ ...disabledButton, verifyOtp: false });
  //       notification.success({
  //         message: 'OTP sent to registered mobile number',
  //       });
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //       notification.error({ message: 'OTP Not Generated' });
  //       setDisable(false);
  //     });
  // };
  // const adharvalue = (event) => {
  //   setadhar(event.target.value);
  //   if (event.target.value.length < 12) {
  //     setDisabledButton({ sendOtp: true, verifyOtp: true });
  //   }
  //   setIsAadharChecked(false);
  //   // checkAadharUniqueness();
  // };

  //new send otp
  const Sendotp = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        // 'https://demo.wikiworks.in:443/api/newSendOtp',
        `${process.env.REACT_APP_API_URL}/newSendOtp`,
        {
          // If running on different domains or ports in development, specify the full URL, e.g., "http://localhost:5000/api/getOkycOtp"
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            aadhaarNumber: adhar,
          }),
        }
      );
      console.log('response is', response);

      if (response.ok) {
        const data = await response.json();
        setDisabledButton({ ...disabledButton, verifyOtp: false });

        console.log(data);
        console.log('1', data.data.requestId);
        const requestId = data.data.requestId;
        setRequest({ requestId });

        notification.success({
          message: 'OTP sent to registered mobile number',
        });
      } else {
        console.error('Failed to send OTP');
        alert('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Error sending OTP. Please check your network and try again.');
    } finally {
      setLoader(false);
    }
  };

  const adharvalue = (event) => {
    const inputValue = event.target.value;
    setAadharNumber(inputValue);
    setadhar(event.target.value);

    // Check Aadhar length
    if (inputValue.length === 12) {
      setIsAadharValid(true); // Set Aadhar as valid if it's 12 digits
    } else {
      setIsAadharValid(false);
    }

    setIsAadharChecked(false);
    // Other logic for checking Aadhar uniqueness if needed
  };

  React.useEffect(() => {
    setIsExistingEntry(initialValues?.aadharNo);
  }, [initialValues]);

  const otpvalue = (event) => {
    setotp(event.target.value);
  };
  // const Verifyotp = () => {
  //   const aadharNo = formRef.current.getFieldValue('aadharNo');

  //   setotpDisable(true);

  //   setotploading(true);
  //   setTimeout(() => {
  //     setotploading(false);
  //   }, 3500);
  //   setDisable(true);

  //   const existingValuesForCheck = {
  //     FirstName: initialValues?.firstName,
  //     LastName: initialValues?.lastName,
  //     Gender: initialValues?.gender,
  //     DateOfBirth: dayjs(initialValues?.dob).format('YYYY-MM-DD'),
  //   };

  //   VerifyOtp({
  //     otp: otp,
  //     aadhaarNo: aadharNo,
  //     requestId: Request.requestId,
  //     consent: 'Y',
  //   })
  //     .then((res) => {
  //       console.log(
  //         'aadhar data is',
  //         res?.data?.responseObject1?.result?.dataFromAadhaar
  //       );

  //       const fetchedValuesforcheck = {
  //         Name: res?.data?.responseObject1?.result?.dataFromAadhaar?.name,
  //         LastName:
  //           res?.data?.responseObject1?.result?.dataFromAadhaar?.name?.split(
  //             ' '
  //           ),
  //         Gender: res?.data?.responseObject1?.result?.dataFromAadhaar?.gender,
  //         DateOfBirth: res?.data?.responseObject1?.result?.dataFromAadhaar?.dob,
  //       };

  //       Modal.confirm({
  //         title: 'Information Mismatch Alert',
  //         content: (
  //           <div style={{ display: 'flex', flexDirection: 'row' }}>
  //             <div style={{ marginRight: '20px' }}>
  //               <p>
  //                 <strong>Data from Aadhar:</strong>
  //               </p>
  //               <p>
  //                 <strong>FirstName:</strong> {fetchedValuesforcheck?.Name}
  //               </p>
  //               <p>
  //                 <strong>LastName:</strong>{' '}
  //                 {fetchedValuesforcheck?.LastName[1]}
  //               </p>
  //               <p>
  //                 <strong>DateOfBirth:</strong>{' '}
  //                 {fetchedValuesforcheck?.DateOfBirth}
  //               </p>
  //               <p>
  //                 <strong>Gender:</strong> {fetchedValuesforcheck?.Gender}
  //               </p>
  //             </div>
  //             <div>
  //               <p>
  //                 <strong>Existing Records:</strong>
  //               </p>
  //               <p>
  //                 <strong>FirstName:</strong>{' '}
  //                 {existingValuesForCheck?.FirstName}{' '}
  //               </p>
  //               <p>
  //                 <strong>LastName:</strong> {existingValuesForCheck?.LastName}{' '}
  //               </p>
  //               <p>
  //                 <strong>DateOfBirth:</strong>{' '}
  //                 {existingValuesForCheck?.DateOfBirth}{' '}
  //               </p>
  //               <p>
  //                 <strong>Gender:</strong> {existingValuesForCheck?.Gender}{' '}
  //               </p>
  //             </div>
  //           </div>
  //         ),

  //         onOk() {
  //           // Continue with the rest of your code here if "Approve" is clicked
  //           setViewOnly(true);
  //           // ... rest of your code ...
  //           const name = res.data.responseObject1.result.dataFromAadhaar.name;
  //           const parts = name.split(' ');
  //           const lastName = parts.pop();
  //           const firstName = parts.join(' ');
  //           const dob2 = res.data.responseObject1.result.dataFromAadhaar.dob;
  //           const dob3 = dayjs(dob2, 'YYYY-MM-DD');

  //           const reverse = dob2.split('-').reverse().join('-');
  //           var dob = new Date(dob2);
  //           var month_diff = Date?.now() - dob.getTime();
  //           var age_dt = new Date(month_diff);
  //           var year = age_dt.getUTCFullYear();
  //           var age = Math.abs(year - 1970);
  //           setdate1(reverse);
  //           const fatherName =
  //             res.data.responseObject1.result.dataFromAadhaar.fatherName;
  //           // const maskNum = res.data.responseObject1.result.dataFromAadhaar.maskedAadhaarNumber;
  //           var profilePic =
  //             res.data.responseObject1.result.dataFromAadhaar.image;
  //           const gender =
  //             res.data.responseObject1.result.dataFromAadhaar.gender;
  //           const state =
  //             res.data.responseObject1.result.dataFromAadhaar.address
  //               .splitAddress.state;
  //           const district =
  //             res.data.responseObject1.result.dataFromAadhaar.address
  //               .splitAddress.district;
  //           const country =
  //             res.data.responseObject1.result.dataFromAadhaar.address
  //               .splitAddress.country;
  //           const pincode =
  //             res.data.responseObject1.result.dataFromAadhaar.address
  //               .splitAddress.pincode;
  //           const village =
  //             res.data.responseObject1.result.dataFromAadhaar.address
  //               .splitAddress.vtcName;
  //           // const address =
  //           //   res.data.responseObject1.result.dataFromAadhaar.address.splitAddress.postOffice;
  //           const address =
  //             res.data.responseObject1.result.dataFromAadhaar.address
  //               .combinedAddress;
  //           // const endNum = aadharNo.slice(-4);
  //           // const maskNum = endNum.padStart(adhar.length, "*");

  //           setuidai({
  //             firstName,
  //             lastName,
  //             fatherName,
  //             gender,
  //             state,
  //             district,
  //             pincode,
  //             village,
  //             address,
  //             // reverse,
  //             // dob3,
  //             profilePic,
  //             country,
  //           });

  //           form.setFieldsValue({
  //             firstName: firstName,
  //             lastName: lastName,
  //             gender: gender,
  //             // aadharNo: maskNum,
  //             fatherName: fatherName,
  //             address: address,
  //             age: age,
  //             village: village,
  //             district: district,
  //             state: state,
  //             pincode: pincode,
  //             dob: dob3,
  //             country: country,
  //           });

  //           setISFetchedFromAadhar(true);

  //           notification.success({
  //             message: 'Fetching Details From UIDAI Server',
  //           });
  //         },
  //         onCancel() {
  //           // Handle the case where "Reject" is clicked (if needed)
  //           // For now, you can leave it empty or add any specific logic
  //           notification.error({ message: 'You Cannot Continue onBoarding' });
  //           navigate('/home/supervisor/employee');
  //           form.resetFields();
  //         },
  //         okText: 'Approve',
  //         cancelText: 'Reject',
  //       });
  //       //  return
  //       //   setViewOnly(true);

  //       //   const name = res.data.responseObject1.result.dataFromAadhaar.name;
  //       //   const parts = name.split(' ');
  //       //   const lastName = parts.pop();
  //       //   const firstName = parts.join(' ');
  //       //   const dob2 = res.data.responseObject1.result.dataFromAadhaar.dob;
  //       //   const dob3 = dayjs(dob2, 'YYYY-MM-DD');

  //       //   const reverse = dob2.split('-').reverse().join('-');
  //       //   var dob = new Date(dob2);
  //       //   var month_diff = Date?.now() - dob.getTime();
  //       //   var age_dt = new Date(month_diff);
  //       //   var year = age_dt.getUTCFullYear();
  //       //   var age = Math.abs(year - 1970);
  //       //   setdate1(reverse);
  //       //   const fatherName =
  //       //     res.data.responseObject1.result.dataFromAadhaar.fatherName;
  //       //   // const maskNum = res.data.responseObject1.result.dataFromAadhaar.maskedAadhaarNumber;
  //       //   var profilePic = res.data.responseObject1.result.dataFromAadhaar.image;
  //       //   const gender = res.data.responseObject1.result.dataFromAadhaar.gender;
  //       //   const state =
  //       //     res.data.responseObject1.result.dataFromAadhaar.address.splitAddress
  //       //       .state;
  //       //   const district =
  //       //     res.data.responseObject1.result.dataFromAadhaar.address.splitAddress
  //       //       .district;
  //       //   const country =
  //       //     res.data.responseObject1.result.dataFromAadhaar.address.splitAddress
  //       //       .country;
  //       //   const pincode =
  //       //     res.data.responseObject1.result.dataFromAadhaar.address.splitAddress
  //       //       .pincode;
  //       //   const village =
  //       //     res.data.responseObject1.result.dataFromAadhaar.address.splitAddress
  //       //       .vtcName;
  //       //   // const address =
  //       //   //   res.data.responseObject1.result.dataFromAadhaar.address.splitAddress.postOffice;
  //       //   const address =
  //       //     res.data.responseObject1.result.dataFromAadhaar.address
  //       //       .combinedAddress;
  //       //   // const endNum = aadharNo.slice(-4);
  //       //   // const maskNum = endNum.padStart(adhar.length, "*");

  //       //   setuidai({
  //       //     firstName,
  //       //     lastName,
  //       //     fatherName,
  //       //     gender,
  //       //     state,
  //       //     district,
  //       //     pincode,
  //       //     village,
  //       //     address,
  //       //     // reverse,
  //       //     // dob3,
  //       //     profilePic,
  //       //     country,
  //       //   });

  //       //   form.setFieldsValue({
  //       //     firstName: firstName,
  //       //     lastName: lastName,
  //       //     gender: gender,
  //       //     // aadharNo: maskNum,
  //       //     fatherName: fatherName,
  //       //     address: address,
  //       //     age: age,
  //       //     village: village,
  //       //     district: district,
  //       //     state: state,
  //       //     pincode: pincode,
  //       //     dob: dob3,
  //       //     country: country,
  //       //   });

  //       //   setISFetchedFromAadhar(true);

  //       //   notification.success({ message: 'Fetching Details From UIDAI Server' });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       notification.error({
  //         message: 'UIDAI server issue! inconvenience is regretted',
  //       });
  //       setotpDisable(false);
  //       setDisable(false);
  //     });
  // };

  //new verify otp
  const existingValuesForCheck = {
    FirstName: initialValues?.firstName,
    LastName: initialValues?.lastName,
    Gender: initialValues?.gender,
    DateOfBirth: dayjs(initialValues?.dob).format('DD-MM-YYYY'),
    Age: initialValues?.age,
  };

  const Verifyotp = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        // 'https://demo.wikiworks.in:443/api/verifyOtp',
        `${process.env.REACT_APP_API_URL}/verifyOtp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ requestId: Request.requestId, otp: otp }),
        }
      );
      console.log(' verify otp response is', response);
      if (response.ok) {
        const data = await response.json();
        console.log('rrrr data is', data);
        console.log(JSON.stringify(data, null, 2));
        // const name = data.data.full_name;
        const dob2 = data.data.dob;
        const dob3 = dayjs(dob2, 'YYYY-MM-DD');

        const reverse = dob2?.split('-').reverse().join('-');
        // const dob3 = dayjs(reverse, "DD-MM-YYYY");

        var dob = new Date(dob2);
        var month_diff = Date.now() - dob.getTime();
        var age_dt = new Date(month_diff);
        var year = age_dt.getUTCFullYear();
        var age = Math.abs(year - 1970);
        // const parts = name?.split(' ');
        // const lastName = parts?.pop();
        // const firstName = parts?.join(' ');
        // const fetchedValuesforcheck = {
        //   Name: firstName,
        //   LastName: data.data.full_name?.split(' '),
        //   Gender: data.data.gender,
        //   DateOfBirth: dayjs(data.data.dob).format('DD-MM-YYYY'),
        //   Age: age,
        // };
        const highlightCellStyle = { backgroundColor: 'yellow' };
        // console.log(
        //   'fetchedValuesforcheck?.Name',
        //   fetchedValuesforcheck?.Gender
        // );

        // console.log(
        //   'existingValuesForCheck?.Gender',
        //   existingValuesForCheck?.Gender
        // );

        // if (initialValues?.approvalStage >= 0) {
        //   Modal.confirm({
        //     title: 'Information Mismatch Alert',
        //     width: 800, // Set your desired width here

        //     content: (
        //       <div>
        //         <Table
        //           width='100%'
        //           bordered
        //           dataSource={[
        //             {
        //               key: '1',

        //               label: 'Data from Aadhar',

        //               firstName: fetchedValuesforcheck?.Name,

        //               lastName: fetchedValuesforcheck?.LastName[1],

        //               dateOfBirth: fetchedValuesforcheck?.DateOfBirth,

        //               gender: fetchedValuesforcheck?.Gender,
        //               age: fetchedValuesforcheck?.Age,
        //             },

        //             {
        //               key: '2',

        //               label: 'Data From excel upload',

        //               firstName: existingValuesForCheck?.FirstName,

        //               lastName: existingValuesForCheck?.LastName,

        //               dateOfBirth: existingValuesForCheck?.DateOfBirth,

        //               gender: existingValuesForCheck?.Gender,
        //               age: existingValuesForCheck?.Age,
        //             },
        //           ]}
        //           columns={[
        //             {
        //               // title: 'Label',

        //               dataIndex: 'label',

        //               key: 'label',
        //               width: '25%',
        //             },

        //             {
        //               title: 'FirstName',

        //               dataIndex: 'firstName',

        //               key: 'firstName',
        //               width: '20%',

        //               // className: (record) =>
        //               //   record.label === 'Existing Records' &&
        //               //   record.firstName !== fetchedValuesforcheck?.Name
        //               //     ? 'highlight-cell'
        //               //     : '',
        //               render: (text, record) => (
        //                 <span
        //                   className={
        //                     existingValuesForCheck?.FirstName !==
        //                     fetchedValuesforcheck?.Name
        //                       ? 'highlight-cell'
        //                       : 'highlight-green'
        //                   }>
        //                   {text}
        //                 </span>
        //               ),
        //             },

        //             {
        //               title: 'LastName',

        //               dataIndex: 'lastName',

        //               key: 'lastName',
        //               width: '20%',

        //               // className: (record) =>
        //               //   record.label === 'Existing Records' &&
        //               //   record.lastName !== fetchedValuesforcheck?.LastName[1]
        //               //     ? 'highlight-cell'
        //               //     : '',
        //               render: (text, record) => (
        //                 <span
        //                   className={
        //                     existingValuesForCheck.LastName !== 'Bangargi'
        //                       ? 'highlight-cell'
        //                       : 'highlight-green'
        //                   }>
        //                   {text}
        //                 </span>
        //               ),
        //             },
        //             {
        //               title: 'Gender',

        //               dataIndex: 'gender',

        //               key: 'gender',
        //               width: '5%',

        //               render: (text, record) => (
        //                 <span
        //                   className={
        //                     existingValuesForCheck?.Gender !==
        //                     fetchedValuesforcheck?.Gender
        //                       ? 'highlight-cell'
        //                       : 'highlight-green'
        //                   }>
        //                   {text}
        //                 </span>
        //               ),
        //             },

        //             {
        //               title: 'DOB',

        //               dataIndex: 'dateOfBirth',

        //               key: 'dateOfBirth',
        //               width: '20%',

        //               // className: (record) =>
        //               //   record.label === 'Existing Records' &&
        //               //   record.dateOfBirth !== fetchedValuesforcheck?.DateOfBirth
        //               //     ? 'highlight-cell'
        //               //     : '',
        //               render: (text, record) => (
        //                 <span
        //                   className={
        //                     existingValuesForCheck.DateOfBirth !==
        //                     fetchedValuesforcheck?.DateOfBirth
        //                       ? 'highlight-cell'
        //                       : 'highlight-green'
        //                   }>
        //                   {text}
        //                 </span>
        //               ),
        //             },

        //             {
        //               title: 'Age',

        //               dataIndex: 'age',

        //               key: 'age',
        //               width: '10%',

        //               // className: (record) =>
        //               //   record.label === 'Existing Records' &&
        //               //   record.gender !== fetchedValuesforcheck?.Gender
        //               //     ? 'highlight-cell'
        //               //     : '',
        //               render: (text, record) => (
        //                 <span
        //                   className={
        //                     existingValuesForCheck?.Age !==
        //                     fetchedValuesforcheck?.Age
        //                       ? 'highlight-cell'
        //                       : 'highlight-green'
        //                   }>
        //                   {text}
        //                 </span>
        //               ),
        //             },
        //           ]}
        //           pagination={false}
        //           // Apply inline style for highlighting cells

        //           // rowClassName={(record) =>
        //           //   record.label === 'Existing Records' ? 'highlight-cell' : ''
        //           // }
        //         />
        //         <div>
        //           <p>
        //             <span style={{ color: 'red' }}>*</span> Entries highlighted
        //             in red are not matching with excel data upload
        //           </p>
        //         </div>
        //       </div>
        //     ),

        //     onOk() {
        //       // Continue with the rest of your code here if "Approve" is clicked
        //       setViewOnly(true);
        //       const name = data.data.full_name;
        //       const parts = name?.split(' ');
        //       const lastName = parts.pop();
        //       const firstName = parts.join(' ');

        //       // var age_dt = new Date(month_diff);
        //       // var year = age_dt.getUTCFullYear();
        //       // var age = Math.abs(year - 1970);
        //       setdate1(reverse);

        //       // const mobile =
        //       //   res.data.responseObject1.result.dataFromAadhaar.mobileHash;

        //       // const age=moment(dob, "YYYYMMDD").fromNow();
        //       const fatherName = data.data.care_of.split(':')[1].trim();
        //       const profilePic = data.data.profile_image;

        //       const gender = data.data.gender;
        //       const state = data.data.address.state;
        //       const district = data.data.address.dist;
        //       const pincode = data.data.zip;
        //       const village = data.data.address.po;
        //       const country = data.data.address.country;

        //       // const address =
        //       //   res.data.responseObject1.result.dataFromAadhaar.address.splitAddress.postOffice;
        //       const address =
        //         data.data.address?.vtc +
        //         ',' +
        //         data.data.address?.subdist +
        //         ',' +
        //         data.data.address?.dist +
        //         ',' +
        //         data.data.address?.state;
        //       const endNum = adhar.slice(-4);
        //       const maskNum = endNum.padStart(adhar.length, '*');

        //       setuidai({
        //         firstName,
        //         lastName,
        //         fatherName,
        //         gender,
        //         state,
        //         district,
        //         pincode,
        //         village,
        //         address,
        //         // dob,
        //         profilePic,
        //         country,
        //       });

        //       form.setFieldsValue({
        //         firstName: firstName,
        //         lastName: lastName,
        //         gender: gender,
        //         // aadharNo: maskNum,
        //         fatherName: fatherName,
        //         address: address,
        //         village: village,
        //         district: district,
        //         state: state,
        //         pincode: pincode,
        //         dob: dob3,
        //         age: age,
        //         country: country,
        //       });
        //       setISFetchedFromAadhar(true);

        //       notification.success({
        //         message: 'Fetching Details From UIDAI Server',
        //       });
        //     },
        //     onCancel() {
        //       // Handle the case where "Reject" is clicked (if needed)
        //       // For now, you can leave it empty or add any specific logic
        //       notification.error({ message: 'You cannot continue onBoarding' });
        //       navigate('/home/supervisor/employee');
        //       form.resetFields();
        //     },
        //     // okText: 'Approve',
        //     // cancelText: 'Reject',

        //     okText: (
        //       <Tooltip title='If you accept then excel upload data will be replaced with Aadhaar data'>
        //         <span>Approve</span>
        //       </Tooltip>
        //     ),
        //     cancelText: (
        //       <Tooltip title='If you reject then onboarding will not be initiated'>
        //         <span>Reject</span>
        //       </Tooltip>
        //     ),
        //   });
        // }
        // else {
        setViewOnly(true);
        const name = data.data.full_name;
        const parts = name?.split(' ');
        const lastName = parts.pop();
        const firstName = parts.join(' ');

        // var age_dt = new Date(month_diff);
        // var year = age_dt.getUTCFullYear();
        // var age = Math.abs(year - 1970);
        setdate1(reverse);

        // const mobile =
        //   res.data.responseObject1.result.dataFromAadhaar.mobileHash;

        // const age=moment(dob, "YYYYMMDD").fromNow();
        // const fatherName = data.data.care_of;
        // const fatherName = data.data.care_of.split(':')[1].trim();
        const fatherName = data?.data?.care_of?.split(' ')[1];

        const profilePic = data?.data?.profile_image;

        const gender = data?.data?.gender;
        const state = data?.data?.address?.state;
        const district = data?.data?.address?.dist;
        const pincode = data?.data?.zip;
        const village = data?.data?.address?.po;
        const country = data?.data?.address?.country;

        // const address =
        //   res?.data.responseObject1.result.dataFromAadhaar.address.splitAddress.postOffice;
        const address =
          data.data.address?.vtc +
          ',' +
          data.data.address?.subdist +
          ',' +
          data.data.address?.dist +
          ',' +
          data.data.address?.state;
        const address1 = data?.data?.address?.house || '---';
        const address2 = data?.data?.address?.street || '---';
        const address3 =
          data?.data?.address?.loc + ' ' + data?.data?.address?.landmark ||
          '---';
        const endNum = adhar.slice(-4);
        const maskNum = endNum.padStart(adhar.length, '*');

        setuidai({
          firstName,
          lastName,
          fatherName,
          gender,
          state,
          district,
          pincode,
          village,
          // address,
          address1,
          address2,
          address3,
          // dob,
          profilePic,
          country,
        });

        form.setFieldsValue({
          firstName: firstName,
          lastName: lastName,
          gender: gender,
          longName: firstName + ' ' + lastName,
          LongName: firstName + ' ' + lastName,

          // aadharNo: maskNum,
          fatherName: fatherName,
          // address: address,
          address1: address1,
          address2: address2,
          address3: address3,
          village: village,
          district: district,
          state: state,
          pincode: pincode,
          dob: dob3,
          Dob: dob3,

          age: age,
          country: country,
        });
        setISFetchedFromAadhar(true);

        notification.success({
          message: 'Fetching Details From UIDAI Server',
        });
      }
      // }
      else {
        console.error('Failed to verify OTP');
        alert('Failed to verify OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Error verifying OTP. Please check your network and try again.');
    } finally {
      setLoader(false);
      // setOtp('');
    }
  };

  const [dob, setDob] = useState(null);

  const handleDateChange = (date) => {
    console.log('date is');
    setDob(date);
    var dob = new Date(date);
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);
    // Calculate age based on the selected date of birth
    // const age = date ? moment().diff(date, 'years') : undefined;
    // Set the value of the age field in the form
    form.setFieldsValue({ age });
  };

  // function highlightDifference(fetchedValue, existingValue, key) {
  //   const fetchedString = String(fetchedValue)?.toLowerCase();
  //   const existingString = String(existingValue)?.toLowerCase();

  //   if (key === 'FirstName' || key === 'LastName' || key === 'Gender') {
  //     if (fetchedString !== existingString) {
  //       const highlightColor = 'red';
  //       const differenceText = `(${fetchedValue})`;

  //       return (
  //         <span style={{ backgroundColor: highlightColor }}>
  //           {differenceText}
  //         </span>
  //       );
  //     }
  //   } else if (key === 'DateOfBirth') {
  //     const fetchedDate = new Date(fetchedValue).getTime();
  //     const existingDate = new Date(existingValue).getTime();

  //     if (fetchedDate !== existingDate) {
  //       const highlightColor = 'red';
  //       const differenceText = `(${fetchedValue})`;

  //       return (
  //         <span style={{ backgroundColor: highlightColor }}>
  //           {differenceText}
  //         </span>
  //       );
  //     }
  //   } else {
  //     const difference = fetchedValue.length - existingValue.length;

  //     if (difference !== 0) {
  //       const highlightColor = difference > 0 ? 'green' : 'red';
  //       const differenceText = difference > 0 ? `(+${difference})` : `(${difference})`;

  //       return (
  //         <span style={{ backgroundColor: highlightColor }}>
  //           {differenceText}
  //         </span>
  //       );
  //     }
  //   }

  //   return fetchedValue;
  // }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const consentreview = (event) => {
    setconsent(!consent);
  };

  //State And District
  const states = State;
  const citi = {
    'Andhra Pradesh': AndhraPradesh,
    'Andaman and Nicobar Islands': AndhraPradesh,
    'Arunachal Pradesh': ArunachalPradesh,
    Assam: Assam,
    Bihar: Bihar,
    Karnataka: Karnataka,
    Chhattisgarh: Chhattisgarh,
    Chandigarh: Chandigarh,
    Dadra: Dadra,
    Delhi: Delhi,
    Goa: Goa,
    Gujarat: Gujarat,
    Haryana: Haryana,
    HimachalPradesh: HimachalPradesh,
    Jammu: Jammu,
    Jharkhand: Jharkhand,
    Kerala: Kerala,
    Lakshadweep: Lakshadweep,
    MadhyaPradesh: MadhyaPradesh,
    Maharashtra: Maharashtra,
    Manipur: Manipur,
    Meghalaya: Meghalaya,
    Mizoram: Mizoram,
    Nagaland: Nagaland,
    Odisha: Odisha,
    Punjab: Punjab,
    Puducherry: Puducherry,
    Rajasthan: Rajasthan,
    TamilNadu: TamilNadu,
    Telangana: Telangana,
    Tripura: Tripura,
    Uttarakhand: Uttarakhand,
    UttarPradesh: UttarPradesh,
    WestBengal: WestBengal,
  };
  const [selectedState, setSelectedState] = useState('');
  const [selectedstate, setSelectedstate] = useState('');
  //Live Capture
  const [visible, setVisible] = useState(false);
  const [imageSrc1, setImageSrc1] = useState(null);
  const [isCapturing, setIsCapturing] = useState(false);
  const webcamRef = useRef(null);

  const toggleCamera = () => {
    setVisible(!visible);
    setIsCapturing(true);
  };

  const handleOk = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    setVisible(false);
    setIsCapturing(true);
  };

  const handleCancel1 = () => {
    setIsCapturing(false);
    setVisible(false);
  };
  //Marital Status
  const handleMaritalStatusChange = (value) => {
    setMaritalStatus(value);
  };

  const isMarried = maritalStatus === 'married';

  const validateage = (_, value) => {
    const age = Number(value);

    if (age < 18 || age > 60) {
      return Promise.reject('Age must be between 18 and 60');
    }

    return Promise.resolve();
  };

  const changeAccordian = (e) => {
    console.log('e---------->', e);
    if (e[0] && !openedAccordian.includes(e[0])) {
      setOpenAccordian([...openedAccordian, e[0]]);
    }
  };

  //for enable and disble aadhaar details
  const [employmentStatus, setEmploymentStatus] = useState(
    initialValues?.statusToBeImposed
  ); // Default value

  const handleStatusChange = (value) => {
    setEmploymentStatus(value);
  };

  // useEffect(() => {
  //   const checkAadhar = setTimeout(() => {
  //     callCheckAadharUniquenessApi(adhar)
  //       .then((res) => {
  //         console.log('value of response', res);
  //       })
  //       .catch((error) => {
  //         console.log('value of error');
  //       });
  //   }, 1000);

  //   return () => clearTimeout(checkAadhar);
  // }, [adhar]);

  const checkAadharUniqueness = async (data) => {
    try {
      setLoader(true);
      const res = await callCheckAadharUniquenessApi(data);
      setLoader(false);

      if (!res.data.isAadhaarAvailable) {
        setDisabledButton({ ...disabledButton, sendOtp: false });
        return true;
        // throw Error('Aadhar already exist.');
      }
      return false;
      // console.log('value of response', res);
    } catch (error) {
      return false;
    }
  };
  // console.log('location', location);

  useEffect(() => {
    // workingAreaInput();
    gateAreaInput();
    technicalDetilsInput();
    natureOfWorkInput();
    //console.log("Hii",masterData)
  }, [masterData]);

  // function workingAreaInput() {
  //   if (!Array.isArray(masterData)) {
  //     console.error('not an array');
  //     return;
  //   }

  //   let sheetName = 'Working_Area';
  //   const dataFromArray = masterData?.find(
  //     (item) => item?.sheetName === sheetName
  //   );
  //   // console.log("Hii",dataFromArray)
  //   const workingAreas = dataFromArray?.dataParsed?.map((item) => {
  //     return item?.Working_Area;
  //   });
  //   setWorkingArea(workingAreas);
  //   // console.log("hii",workingAreas);

  //   if (dataFromArray) {
  //     console.log('Hii', workingAreas);
  //   } else {
  //     console.error(
  //       "Object with sheetName '" + sheetName + "' not found in masterData."
  //     );
  //   }
  // }

  function gateAreaInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Gate_Number';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const gateInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Gate_Number;
    });
    setGateNumber(gateInputAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      console.log('gateNumber', gateInputAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function technicalDetilsInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Technical_Details';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    // console.log("Hii",dataFromArray)
    const techinicalDetailsAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Technical_Details + ' ' + item?.Description;
    });
    settechnicalDetails(techinicalDetailsAreas);
    // console.log("hii",workingAreas);

    if (dataFromArray) {
      console.log('techinicalDetailsAreas', techinicalDetailsAreas);
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  //console.log("Hii",workingArea)

  // const handleEpnRenew = () => {
  //   console.log('namaste', initialValues?.id);
  //   const idepn = initialValues?.id;

  //   EpnRenew(idepn)
  //     .then((res) => {
  //       console.log("res", res, idepn);
  //       setLoader(true);
  //       console.log('response on epn is', res);
  //       const updateContractWorkerRs =
  //         res?.data?.responseData?.updateContractWorkerRs;

  //       setUpdatedEpn(updateContractWorkerRs?.EPNCode);

  //       setTimeout(() => {
  //         setLoader(false);
  //         form.setFieldsValue({
  //           epnNumber: updateContractWorkerRs?.EPNCode,
  //         });

  //         // Check if the status is 'success' and show notification
  //         if (updateContractWorkerRs?.status === 'Success') {
  //           notification.success({
  //             message: 'EPN Renewed Successfully',
  //             description: 'The EPN has been successfully renewed.',
  //           });
  //         }
  //       }, 8000);

  //       form.setFieldsValue({});
  //     })
  //     .catch((error) => {
  //       console.error('error is epn', error);
  //     });
  // };

  const handleModal = () => setModalVisible(true);
  const handleModalOk = () => setModalVisible(false);
  const handleModalCancel = () => setModalVisible(false);

  const handleEpnRenew = async () => {
    console.log('namaste', initialValues?.id);
    const idepn = initialValues?.id;
    try {
      // const formData = await form.validateFields();
      const formData = await modalForm.validateFields();
      // const response = await EpnRenew(idepn);
      const response = [];
      console.log('res', response, idepn);
      setLoader(true);
      console.log('response on epn is', response);
      const updateContractWorkerRs =
        response?.data?.responseData?.updateContractWorkerRs;
      console.log('updateContractWorkerRs', updateContractWorkerRs);
      setUpdatedEpn(updateContractWorkerRs?.EPNCode);

      // if (!updateContractWorkerRs) {
      //   throw new Error('No data found for EPN renewal');
      // }

      // Get form values
      //  const formValues = await form.validateFields();
      const formValues = await modalForm.validateFields();
      //  // Update main form data with modal form data
      //  form.setFieldsValue(formData);

      // Fetch the previous EPN number from your data
      const oldEpnNumber = initialValues?.epnNumber;

      console.log(oldEpnNumber);
      // Add the newly added data to the table

      // if (isEmptyObject(newData)) {
      if (formData) {
        const newData = {
          epnNumber: updateContractWorkerRs?.epnNumber,
          // Add other fields as needed
          // oldEpnNumber: updateContractWorkerRs?.oldEpnNumber,
          oldEpnNumber: oldEpnNumber,
          epnValidityFromDate: formValues?.epnValidityFromDate,
          epnValidityToDate: formValues?.epnValidityToDate,
          cardLostType: formValues?.cardLostType,
          cardLostDate: formValues?.cardLostDate,
          cardLostRemark: formValues?.cardLostRemark,
          epnRenew: formValues?.epnRenew,
          oldEpnCancelDate: formValues?.oldEpnCancelDate,
        };
        console.log('newData', newData);
        // Call the callback function to pass newData to the parent component
        onUpdateNewData(newData);
      }

      setTimeout(() => {
        setLoader(false);

        // Check if the status is 'success' and show notification
        // if (updateContractWorkerRs?.status === 'Success') {
        //   notification.success({
        //     message: 'EPN Renewed Successfully',
        //     description: 'The EPN has been successfully renewed.',
        //   });
        // }
      }, 8000);
      setModalVisible(false);
    } catch (error) {
      console.error('error is epn', error);
      // Handle error if necessary
    }
  };

  // Function to check if an object is empty
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length !== 0 && obj.constructor === Object;
  };
  // useEffect(() => {
  //   handleEpnRenew(data);

  //  }, [handleEpnRenew]);

  // Nature of Work
  function natureOfWorkInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Type_of_Contract';
    const dataFromArray = masterData.find(
      (item) => item.sheetName === sheetName
    );

    if (!dataFromArray) {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
      return;
    }

    const natureOfWorkAreas = dataFromArray.dataParsed.map(
      (item) => item['Dropdown for Nature of Work']
    );
    setnatureOfWork(natureOfWorkAreas);
    console.log('natureOfWorkAreas', natureOfWorkAreas);
  }

  // useEffect(() => {
  //   contractWorkmenEpnNumber(),
  //   contractWorkmenCodeNumber()
  // }, []);

  const contractWorkmenEpnNumber = async (orgId, locationId) => {
    try {
      const response = await getContractWorkmenEpnNumber(orgId, locationId);
      const responseData = response.data;

      console.log('response EPnnumber', responseData);

      if (responseData.message === 'New entry') {
        // If it's a new entry, setPSNumber to '0001'
        const newRNWNumber = 1;
        const updatedEPNNumber = `RNW${newRNWNumber}`;
        setRanoliEpnNumber(updatedEPNNumber);

        // You can do other processing or actions if needed

        console.log('Set RNWNumber to for new', newRNWNumber);
      } else {
        // If it's an existing entry, increment the existing numeric part by 1
        let existingNumericPart = responseData.lastEPNNumber;
        let numericPart = existingNumericPart?.split(/\D+/);

        numericPart = parseInt(numericPart[1]) + 1;
        console.log('existingNumericPart', numericPart);

        // Pad the numeric part with leading zeros based on your desired length
        const paddedNumericPart = numericPart.toString().padStart(4, '0');

        // Concatenate with any prefix or other formatting you may need
        const updatedEPNRNWNumber = `RNW${paddedNumericPart}`;

        // Update the state with the new PSNumber
        setRanoliEpnNumber(updatedEPNRNWNumber);

        console.log('Set RNWNumber to for existing', updatedEPNRNWNumber);
      }
    } catch (error) {
      console.error(error);
      // Handle error if necessary
    }
  };

  const contractWorkmenCodeNumber = async (orgId, locationId) => {
    try {
      const response = await getContractWorkmenWorkerCodeNumber(
        orgId,
        locationId
      );
      const responseData = response.data;

      console.log('response workerCodenumber', responseData);

      if (responseData.message === 'New entry') {
        // If it's a new entry, setPSNumber to '0001'
        const newWorkerCodeNumber = 1;
        const updatedWorkerCodeNumber = `RNW${newWorkerCodeNumber}`;
        setRanoliEpnNumber(updatedWorkerCodeNumber);

        // You can do other processing or actions if needed

        console.log('Set RNWNumber to for new', newRNWNumber);
      } else {
        // If it's an existing entry, increment the existing numeric part by 1
        let existingNumericPart = responseData.lastEPNNumber;
        let numericPart = existingNumericPart?.split(/\D+/);

        numericPart = parseInt(numericPart[1]) + 1;
        console.log('existingNumericPart', numericPart);

        // Pad the numeric part with leading zeros based on your desired length
        const paddedNumericPart = numericPart.toString().padStart(4, '0');

        // Concatenate with any prefix or other formatting you may need
        const updatedEPNRNWNumber = `RNW${paddedNumericPart}`;

        // Update the state with the new PSNumber
        setRanoliEpnNumber(updatedEPNRNWNumber);

        console.log('Set RNWNumber to for existing', updatedEPNRNWNumber);
      }
    } catch (error) {
      console.error(error);
      // Handle error if necessary
    }
  };

  const orgId = userConfig.orgId;

  const handleFieldChangeEPN = async (changedField, allField) => {
    //console.log('abbcv', changedField);
    if (changedField[0]?.name[0] === 'locationId') {
      if (changedField[0]?.value === 'ranoli') {
        try {
          const response = await getContractWorkmenEpnNumber(orgId);
          const responseData = response.data;

          console.log('response EPnnumber', responseData);

          if (responseData.message === 'New entry') {
            // If it's a new entry, setPSNumber to '0001'
            const newRNWNumber = 1;
            const updatedEPNNumber = `RNW${newRNWNumber}`;
            //setRanoliEpnNumber(updatedEPNNumber);

            form.setFieldsValue({
              // Specify the field names and their new values
              // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
              epnNumber: updatedEPNNumber,
              // Add more fields as needed
            });

            // You can do other processing or actions if needed

            console.log('Set RNWNumber to for new', newRNWNumber);
          } else {
            // If it's an existing entry, increment the existing numeric part by 1
            let existingNumericPart = responseData.lastEPNNumber;
            let numericPart = existingNumericPart?.split(/\D+/);

            numericPart = parseInt(numericPart[1]) + 1;
            console.log('existingNumericPart', numericPart);

            // Pad the numeric part with leading zeros based on your desired length
            const paddedNumericPart = numericPart.toString().padStart(4, '0');

            // Concatenate with any prefix or other formatting you may need
            const updatedEPNRNWNumber = `RNW${paddedNumericPart}`;

            // Update the state with the new PSNumber
            setRanoliEpnNumber(updatedEPNRNWNumber);
            form.setFieldsValue({
              // Specify the field names and their new values
              // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
              epnNumber: updatedEPNRNWNumber,
              // Add more fields as needed
            });

            console.log('Set RNWNumber to for existing', updatedEPNRNWNumber);
          }
        } catch (error) {
          console.error(error);
          // Handle error if necessary
        }
      } else {
        form.setFieldsValue({
          epnNumber: '', // Clear EPN number
          empCode: '', // Clear worker code number
          // Add more fields as needed
        });
      }

      if (changedField[0]?.value === 'ranoli') {
        try {
          const response = await getContractWorkmenWorkerCodeNumber(orgId);
          const responseData = response.data;

          console.log('response workerCodenumber', responseData);

          if (responseData.message === 'New entry') {
            // If it's a new entry, setPSNumber to '0001'
            const newWorkerCodeNumber = 1;
            const updatedWorkerCodeNumber = `WC${newWorkerCodeNumber}`;
            // setRanoliWorkmenNumber(updatedWorkerCodeNumber);

            form.setFieldsValue({
              // Specify the field names and their new values
              // For example, if you want to update a field with name 'fieldName' to a new value 'newValue'
              empCode: updatedWorkerCodeNumber,
              // Add more fields as needed
            });

            // You can do other processing or actions if needed

            console.log('Set RNWNumber to for new', newRNWNumber);
          } else {
            // If it's an existing entry, increment the existing numeric part by 1
            let existingNumericPart = responseData?.lastWorkmenCodeNumber;
            let numericPart = existingNumericPart?.split(/\D+/);

            numericPart = parseInt(numericPart[1]) + 1;
            console.log('existingNumericPart', numericPart);

            // Pad the numeric part with leading zeros based on your desired length
            const paddedNumericPart = numericPart.toString().padStart(4, '0');

            // Concatenate with any prefix or other formatting you may need
            const updatedWorkmenRNWNumber = `WC${paddedNumericPart}`;

            // Update the state with the new PSNumber
            //setRanoliWorkmenNumber(updatedWorkmenRNWNumber);
            // console.log('Set RNWNumber to for new', updatedWorkmenRNWNumber);

            form.setFieldsValue({
              empCode: updatedWorkmenRNWNumber,
            });

            console.log('Set RNWNumber to for existing', updatedEPNRNWNumber);
          }
        } catch (error) {
          console.log('error', error);
          console.error(error);
          // Handle error if necessary
        }
      }
    }
  };

  const handleLocationChange = (newLocationId) => {
    console.log('newLocationId==>', newLocationId);
    setLocationId(newLocationId); // Update the state with the new location ID
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const columns = [
    {
      title: 'EPN Code',
      dataIndex: 'epnNumber',
      width: '10%',
      editable: true,
    },
    {
      title: 'OLD EPN Number',
      dataIndex: 'oldEpnNumber',
      width: '10%',
      editable: true,
    },
    {
      title: 'EPN Validity From Date',
      dataIndex: 'epnValidityFromDate',
      width: '10%',
      render: (date) => formatDate(date),
      editable: true,
    },
    {
      title: 'EPN Validity To Date',
      dataIndex: 'epnValidityToDate',
      width: '10%',
      render: (date) => formatDate(date),
      editable: true,
    },
    {
      title: 'Card Lost Type',
      dataIndex: 'cardLostType',
      width: '10%',
      editable: true,
    },
    {
      title: 'Card Lost Date',
      dataIndex: 'cardLostDate',
      width: '10%',
      render: (date) => formatDate(date),
      editable: true,
    },
    {
      title: 'Card Lost Remark',
      dataIndex: 'cardLostRemark',
      width: '10%',
      editable: true,
    },
    {
      title: 'EPN Renew Date',
      dataIndex: 'epnRenew',
      width: '10%',
      render: (date) => formatDate(date),
      editable: true,
    },
    {
      title: 'Old EPN Cancel Date',
      dataIndex: 'oldEpnCancelDate',
      width: '10%',
      render: (date) => formatDate(date),
      editable: true,
    },

    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   width: '20%',

    //   render: (_, record) => {
    //     return (
    //       <Space>
    //         <Typography.Link
    //           disabled={
    //             userConfig.roleName && !roleName?.permissions?.Org_Setup?.edit
    //           }
    //           onClick={() => edit(record)}>
    //           <EditFilled />
    //           Edit
    //         </Typography.Link>
    //         {/* <Popconfirm title='Delete' onConfirm={() => deleteRecord(record)}>
    //           <Typography.Link disabled={userConfig.roleName && !roleName?.Principal_Employer?.edit}>
    //             <DeleteFilled />
    //             Delete
    //           </Typography.Link>
    //         </Popconfirm>  */}
    //       </Space>
    //     );
    //   },
    // },
  ];

  const latestEpnRenew =
    initialValues?.epnRenewHistory?.[initialValues?.epnRenewHistory.length - 1];

  const initialValuesFromHistory = {};
  if (latestEpnRenew) {
    Object.keys(latestEpnRenew).forEach((key) => {
      initialValuesFromHistory[key] = latestEpnRenew[key];
    });
  }

  return (
    <>
      <Form
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        onFieldsChange={handleFieldChangeEPN}
        ref={formRef}
        form={form}>
        <Collapse onChange={changeAccordian}>
          <Panel header='Aadhaar Details' key='1'>
            <Row>
              <Checkbox
                disabled={
                  initialValues?.approvalStage === 0 &&
                  userConfig.role === 'approver'
                }
                onClick={consentreview}>
                I hereby, at my own discretion, voluntarily submit the physical
                copy of my Aadhaar card / physical e-Aadhaar / masked Aadhaar /
                offline electronic Aadhaar / copies of my Aadhaar card in
                physical or electronic form or xml as issued by UIDAI (Aadhaar),
                for the purpose of establishing my identity / age / address
                proof and voluntarily give my consent for the purpose of
                employment-related verification. I understand that I have the
                right to withdraw this consent at any point in time and that I
                have the right to complain to the Data Protection Board of India
                for any breach of my personal identity.
              </Checkbox>
            </Row>
            <Divider />
            <Row>
              {/* <Col
                span={12}
                style={{
                  borderRight: '2px solid black',
                  paddingRight: '26px',
                }}>
                <Form.Item hidden={consent}>
                  <div className='App' style={{ alignItems: 'start' }}>
                    <Button
                      className='glow-on-hover'
                      style={{
                        borderRadius: '10px',
                        type: 'primary',
                        color: 'white',
                        height: '40px',
                        justifyContent: 'end',
                        fontWeight: 'bolder',
                      }}
                      onClick={() => {
                        setStartScan(!startScan);
                      }}
                      type='primary'
                      disabled={isDisabled}>
                      {startScan
                        ? 'Scan QR Code Stop '
                        : 'Scan QR Code from Aadhaar '}
                    </Button>

                    {startScan && (
                      <>
                        <QrReader
                          facingMode={selected}
                          delay={2000}
                          onError={handleError}
                          onScan={handleScan}
                          chooseDeviceId={() => selected}
                          style={{ width: '250px' }}
                        />
                      </>
                    )}
                  </div>
                </Form.Item>{' '}
              </Col> */}

              <Col span={12}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name='aadharNo'
                      hidden={consent}
                      rules={[
                        {
                          validator: async (_, value) => {
                            if (!id) {
                              if (!/^[0-9]{12}$/.test(value)) {
                                return Promise.reject(
                                  'Enter Valid Aadhaar No.'
                                );
                                // throw new Error('Enter Valid Aadhar No.');
                                // return 0;
                              }
                              if (
                                value.length === 12 &&
                                !(isFetchedFromAadhar || isAadharChecked)
                              ) {
                                const isUnique = await checkAadharUniqueness(
                                  value
                                );
                                console.log('value of isuniw', isUnique);
                                if (!isUnique) {
                                  return Promise.reject(
                                    'Aadhaar already exist.'
                                  );
                                }
                                setIsAadharChecked(true);
                              }
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}>
                      <Input
                        size='large'
                        placeholder='Enter Aadhaar Number'
                        // value={adhar}
                        onChange={adharvalue}
                        disabled={isDisabled}
                      />
                    </Form.Item>
                  </Col>
                  {/* {!disabledButton.sendOtp && ( */}

                  <Col span={12}>
                    <Form.Item hidden={consent}>
                      {(isAadharValid || isExistingEntry) && (
                        <Button
                          className='glow-on-hover'
                          style={{ marginLeft: '30px' }}
                          onClick={Sendotp}
                          disabled={disable}
                          type='primary'>
                          Send OTP
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {!disabledButton.verifyOtp && (
                  <Row style={{ marginTop: '20px' }}>
                    <Col span={12}>
                      <Form.Item
                        name='otp'
                        hidden={consent}
                        rules={[
                          {
                            pattern: /^\d{6}$/,
                            message: 'Six Digit Required',
                          },
                        ]}>
                        <Input
                          size='large'
                          placeholder='Enter OTP'
                          value={otp}
                          onChange={otpvalue}
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name='Verify Otp' hidden={consent}>
                        {/* <div className="App" > */}
                        <Button
                          className='glow-on-hover'
                          style={{ marginLeft: '30px' }}
                          type='primary'
                          onClick={Verifyotp}
                          disabled={otpdisable}>
                          Verify OTP
                        </Button>
                        {/* </div> */}
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Divider />

        {/* <Title level={5}>Personal Details</Title> */}
        {/* <h3>Personneldetails</h3> */}
        {/* <Form layout="vertical" initialValues={initialValues} onFinish={onFinish} form={form}

      >  */}
        <Collapse onChange={changeAccordian}>
          <Panel header='Personal Details' key='2'>
            <Row gutter={16}>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='firstName'
                  // label={getLabel('firstName')}
                  label='First Name'
                  rules={[
                    { required: true, message: 'Enter First Name ' },

                    {
                      pattern: /^[A-Za-z]+[A-Za-z\s]*$/,
                      message: 'Only Alphabetic Allowed',
                    },
                    {
                      min: 3,
                      message: 'Minimum length must be 3 characters',
                    },
                    {
                      max: 30,
                      message: 'Maximum length allowed is 30 characters',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isEditingDisabled}
                    onChange={(e) => {
                      const firstName = e.target.value;
                      const middleName = form.getFieldValue('middleName') || '';
                      const lastName = form.getFieldValue('lastName') || '';
                      const LongName = `${firstName} ${middleName} ${lastName}`;
                      form.setFieldsValue({ LongName });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='middleName'
                  label='Middle Name'
                  // label={getLabel('lastName')}
                  rules={[
                    // { required: true, message: 'Enter  Middle Name' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabetic Allowed',
                    },
                    // {
                    //   min: 3,
                    //   message: 'Minimum length must be 3 characters',
                    // },
                    // {
                    //   max: 30,
                    //   message: 'Maximum length allowed is 30 characters',
                    // },
                  ]}>
                  <Input
                    size='large'
                    disabled={isEditingDisabled}
                    onChange={(e) => {
                      const middleName = e.target.value;
                      const firstName = form.getFieldValue('firstName') || '';
                      const lastName = form.getFieldValue('lastName') || '';
                      const LongName = `${firstName} ${middleName} ${lastName}`;
                      form.setFieldsValue({ LongName });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='lastName'
                  label='Last Name'
                  // label={getLabel('lastName')}
                >
                  <Input
                    size='large'
                    disabled={isEditingDisabled}
                    onChange={(e) => {
                      const lastName = e.target.value;
                      const firstName = form.getFieldValue('firstName') || '';
                      const middleName = form.getFieldValue('middleName') || '';
                      const LongName = `${firstName} ${middleName} ${lastName}`;
                      form.setFieldsValue({ LongName });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='LongName'
                  label='Long Name'
                  // label={getLabel('lastName')}
                >
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='longName'
                  // label={getLabel('firstName')}
                  label='Aadhaar Long Name'
                  rules={[
                    // { required: true, message: 'Enter Long Name ' },

                    {
                      pattern: /^[A-Za-z]+[A-Za-z\s]*$/,
                      message: 'Only Alphabetic Allowed',
                    },
                    {
                      min: 3,
                      message: 'Minimum length must be 3 characters',
                    },
                    {
                      max: 50,
                      message: 'Maximum length allowed is 50 characters',
                    },
                  ]}>
                  <Input
                    size='large'
                    // value={longName}
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='gender'
                  label='Gender'
                  // label={getLabel('gender')}
                  rules={[{ required: true, message: 'Enter gender' }]}>
                  <Select
                    size='large'
                    disabled={
                      isDisabled || isFetchedFromAadhar || isEditingDisabled
                    }>
                    <Option value='male'>Male</Option>
                    <Option value='female'>Female</Option>
                    <Option value='other'>Other</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='fatherName'
                  label="Father / Guardian's Name"
                  // label={getLabel('fatherName')}
                  rules={[
                    // { required: true, message: "Enter Father/Gardian Name" },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                    {
                      min: 3,
                      message: 'Minimum length must be 3 characters',
                    },
                    {
                      max: 30,
                      message: 'Maximum length allowed is 30 characters',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('fatherName', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='Dob'
                  label='Date of Birth'
                  rules={[
                    {
                      required: true,
                      message: 'Select Date Of Birth',
                    },
                  ]}>
                  <DatePicker
                    style={{ width: '100%', height: '40px' }}
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    disabled={isEditingDisabled || isDisabled}
                    onChange={handleDateChange}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='dob'
                  label='Aadhaar Date of Birth'
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Select Date Of Birth',
                  //   },
                  // ]}
                >
                  <DatePicker
                    style={{ width: '100%', height: '40px' }}
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    disabled={isEditingDisabled || isFetchedFromAadhar}
                    // onChange={handleDateChange}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='mobile'
                  label='Mobile Number'
                  // label={getLabel('mobile')}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Mobile Number',
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: 'Enter Valid Mobile No.',
                    },
                    // {
                    //   min: 10,
                    //   max: 10,
                    //   message: "Mobile number should be 10 digits",
                    // },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('mobile', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='alternateMobile'
                  label='Alternate Contact Number'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Alternate Contact Number',
                    },
                    {
                      pattern: /^[0-9]{10}$/,
                      message: 'Enter Valid Mobile No.',
                    },
                    // {
                    //   min: 10,
                    //   max: 10,
                    //   message: "Mobile number should be 10 digits",
                    // },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('alternateMobile', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='age'
                  label='Age'
                  // label={getLabel('age')}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Age',
                    },
                    { pattern: /^[0-9]*$/, message: 'Age must be numeric' },
                    { validator: validateage },
                  ]}>
                  <Input
                    size='large'
                    // disabled={
                    //   isDisabled || isFetchedFromAadhar || isEditingDisabled
                    // }
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='aadharNo'
                  label='Aadhaar Number'
                  onBlur={() => {
                    // console.log("i am getting called in aadhar");
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Aadhaar',
                    },

                    // {
                    //   pattern: /^[0-9]{12}$/,
                    //   message: 'Enter Valid Aadhar No.',
                    // },
                    {
                      validator: async (_, value) => {
                        if (!id) {
                          if (!/^[0-9]{12}$/.test(value)) {
                            return Promise.reject('Enter Valid Aadhaar No.');
                            // throw new Error('Enter Valid Aadhar No.');
                            // return 0;
                          }
                          console.log('value i am getting called', value);
                          if (
                            value.length === 12 &&
                            !(isFetchedFromAadhar || isAadharChecked)
                          ) {
                            const isUnique = await checkAadharUniqueness(value);
                            console.log('value of isuniw', isUnique);
                            if (!isUnique) {
                              return Promise.reject('Aadhaar already exist.');
                            }
                            setIsAadharChecked(true);
                          }
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled || isFetchedFromAadhar}
                    onChange={adharvalue}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='martialStatus' label='Marital Status'>
                  <Select
                    size='large'
                    placeholder='Marital Status'
                    onChange={handleMaritalStatusChange}
                    disabled={isDisabled}>
                    <Option value=''></Option>
                    <Option value='married'>Married</Option>
                    <Option value='unmarried'>Unmarried</Option>
                    <Option value='widow'>Widow</Option>
                    <Option value='divorced'>Divorced</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='spousename'
                  label='Spouse Name'
                  rules={[
                    {
                      pattern: '^[a-zA-Z]*$',
                      message: 'Only Alphabets Allowed',
                    },
                    {
                      min: 3,
                      message: 'Minimum length must be 3 characters',
                    },
                    {
                      max: 30,
                      message: 'Maximum length allowed is 30 characters',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={!isMarried || isDisabled}
                    onChange={(e) =>
                      handleFieldChange('spousename', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='childrenNo'
                  label='Number Of Children'
                  rules={[
                    {
                      pattern: '^[0-9]*$', // This allows only numeric (0-9) characters
                      message: 'Only Numeric Values Allowed',
                    },
                    // {
                    //   min: 1,
                    //   message: 'Minimum length must be 3 characters',
                    // },
                    {
                      max: 2,
                      message: 'Maximum length allowed is 2 Number',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('childrenNo', e.target.value)
                    }
                  />
                </Form.Item>
              </Col> */}
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='emergencyContactName'
                  label='Emergency Contact Name'
                  rules={[
                    { required: true, message: 'Enter Emergency Contact Name' },
                    // {
                    //   pattern: '^[a-zA-Z]*$',
                    //   message: 'Only Alphabets Allowed',
                    // },
                    {
                      min: 3,
                      message: 'Minimum length must be 3 characters',
                    },
                    {
                      max: 30,
                      message: 'Maximum length allowed is 30 characters',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('emergencyContactName', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='emergencyContactNo'
                  label='Emergency Contact Number'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Emergency Contact Number',
                    },
                    {
                      min: 10,
                      max: 10,
                      message: 'Mobile number should be 10 digits',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('emergencyContactNo', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='idmark'
                  label={
                    <Space>
                      <span>Id Mark</span>
                      <Tooltip
                        title='Any physical mark on the body such as birth mark'
                        placement='top'>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                  // rules={[
                  //   {
                  //     min: 5,
                  //     max: 100,
                  //     message: 'Maximum 5 to 100 characters allowed',
                  //   },
                  // ]}
                >
                  <Input
                    size='large'
                    disabled={isDisabled || isEditingDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='religion' label='Religion'>
                  {/* <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('religion', e.target.value)
                    }
                  /> */}
                  <Select
                    size='large'
                    disabled={isDisabled}
                    // onChange={(e) =>
                    //   handleFieldChange('religion', e.target.value)
                    // }
                  >
                    <Option value='bhudist'>BUDHIST</Option>
                    <Option value='christian'>CHRISTIAN</Option>
                    <Option value='hindu'>HINDU</Option>
                    <Option value='jain'>JAIN</Option>
                    <Option value='jew'>JEW</Option>
                    <Option value='muslim'>MUSLIM</Option>
                    <Option value='sikh'>SIKH</Option>
                    <Option value='zerostrian'>ZEROSTRIAN</Option>
                    <Option value='others'>OTHERS</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='domicile' label='Domicile'>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('domicile', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='numberOfYearSpent'
                  label='Number of years spent in State'>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('numberOfYearSpent', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='height' label='Height'>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('height', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='votercardno'
                  label='Voter Card Number'
                  rules={[
                    // { required: true, message: "Enter Emergency Contact Name" },
                    {
                      pattern: '^[a-zA-Z]{3}[0-9]{7}$',
                      message: 'Please enter a valid Voter Card number',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    placeholder='ABC1234567'
                    onChange={(e) =>
                      handleFieldChange('votercardno', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pan'
                  label='PAN'
                  rules={[
                    {
                      pattern: '[A-Z]{5}[0-9]{4}[A-Z]{1}',
                      message: 'Enter Valid PAN',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled || isEditingDisabled}
                  />
                </Form.Item>
              </Col> */}
              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='rationcardno'
                  label='Ration Card Number'
                  rules={[
                    {
                      pattern: '^[0-9]{12}$',
                      message:
                        'Please enter a valid 12-digit Ration Card number',
                    },
                  ]}>
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={(e) =>
                      handleFieldChange('rationcardno', e.target.value)
                    }
                  />
                </Form.Item>
              </Col> */}

              <Col className='gutter-row' span={6}>
                <Form.Item name='handicapped'>
                  <Checkbox
                    size='large'
                    name='handicapped'
                    onChange={PmeCheckbox}
                    onClick={IsworkingHandicapped}
                    style={{ paddingTop: '40px' }}
                    disabled={isDisabled}>
                    Handicapped?
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='disabilityType' label='Disability Type'>
                  <Input
                    size='large'
                    disabled={IsworkingHandicaped}
                    onChange={(e) =>
                      handleFieldChange('disabilityType', e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='disabilityPercentage'
                  label='Disability Percentage'>
                  <Input
                    size='large'
                    disabled={IsworkingHandicaped}
                    onChange={(e) =>
                      handleFieldChange('disabilityPercentage', e.target.value)
                    }
                  />
                </Form.Item>{' '}
              </Col>
              <Col className='gutter-row' span={6} hidden={IsworkingHandicaped}>
                <Form.Item
                  label='Attachment'
                  name='attachment'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: IsworkingHandicaped ? false : true,
                      message: 'Upload  Attachment',
                    },

                    // {
                    //   min: 12,
                    //   max: 12,
                    //   message: "UAN number should be 12 digits",
                    // },
                  ]}>
                  <Upload
                    // type="file"
                    listType='picture'
                    accept='image/*'
                    onPreview={handlePreview}
                    maxCount={1}
                    beforeUpload={() => false}
                    size='large'
                    style={{ width: '100%' }}
                    // disabled={PfDisabled || isDisabled}
                    onChange={onchange}>
                    <Button
                      icon={<UploadOutlined />}
                      size='large'
                      style={{ width: '100%' }}
                      onChange={onchange}>
                      Upload Image
                    </Button>
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </Col>
              <Col span={6}>
                <Form.Item label='Live Capture' name='livePhoto'>
                  <>
                    <Button
                      size='large'
                      style={{ width: '100%' }}
                      onClick={toggleCamera}
                      disabled={isDisabled}>
                      {imageSrc ? 'Retake Photo' : 'Capture Photo'}
                    </Button>

                    <Image
                      src={livePhoto || imageSrc}
                      // alt='Captured photo'
                      preview={true}
                    />

                    <Modal
                      open={visible}
                      onOk={handleOk}
                      onCancel={handleCancel1}
                      style={{ width: '150' }}>
                      {isCapturing && (
                        <Webcam
                          audio={false}
                          height={200}
                          ref={webcamRef}
                          screenshotFormat='image/jpeg'
                          width={200}
                        />
                      )}
                    </Modal>
                  </>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='aadharPhoto'
                  label='Aadhaar Photo'
                  initialValue={uidai.profilePic}>
                  <img
                    src={
                      (uidai?.profilePic &&
                        'data:image/png;base64,' + uidai.profilePic) ||
                      aadharPhoto
                    }
                    // alt='aadhar-img'
                    width='60px'
                    style={{ marginLeft: '20px' }}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='biometricBypass'>
                  <Checkbox
                    size='large'
                    name='biometricBypass'
                    // onChange={BioCheckbox}
                    onChange={(e) =>
                      handleFieldChange('biometricBypass', e.target.value)
                    }
                    onClick={BiometricDisable}
                    style={{ paddingTop: '40px' }}
                    disabled={isDisabled}>
                    Biometric Bypass
                  </Checkbox>
                </Form.Item>{' '}
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  label='Biometric Image'
                  name='biometricImage'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}>
                  <Upload
                    // type="file"
                    listType='picture'
                    accept='image/*'
                    onPreview={handlePreview}
                    maxCount={1}
                    beforeUpload={() => false}
                    disabled={IsworkingBio}
                    size='large'
                    style={{ width: '100%' }}>
                    <Button
                      icon={<UploadOutlined />}
                      size='large'
                      style={{ width: '100%' }}>
                      Upload Image
                    </Button>
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </Col>
              <Col
                className='gutter-row'
                span={6}
                // hidden={users.profilePic === true ? false : true}
              >
                <Form.Item
                  label='Electronic Signature'
                  name='electronicSignature'
                  // onChange={(e) =>
                  //   handleFieldChange('electronicSignature', e.target.value)
                  // }
                  valuePropName='fileList'
                  getValueFromEvent={normFile}>
                  <Upload
                    // type="file"
                    listType='picture'
                    accept='image/*'
                    onPreview={handlePreview}
                    maxCount={1}
                    beforeUpload={() => false}
                    disabled={IsworkingBio}>
                    <Button
                      icon={<UploadOutlined />}
                      size='large'
                      style={{ width: '100%' }}>
                      Upload Image
                    </Button>
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
                {/* <img
                  src={'data:image/png;base64,' + uidai.profilePic}
                  width='60px'
                /> */}
              </Col>
            </Row>

            <br />
            <br />
            <Title level={5}>Aadhaar Address</Title>
            <Row gutter={16}>
              {/* <Col className='gutter-row' span={24}>
                <Form.Item
                  name='address'
                  label='Address'
                  rules={[
                    { required: true, message: 'Enter Address' },
                    {
                      min: 3,
                      message: 'Minimum 3 Characters',
                    },
                    {
                      max: 300,
                      message: 'Maximum 600 characters allowed ',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='address'
                    name='address'
                    onChange={onChange}
                    disabled={isDisabled || isFetchedFromAadhar}
                  />
                </Form.Item>
              </Col> */}
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='address1'
                  label='Permanent Address Line 1'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Permanent Address Line 1',
                    },
                    {
                      min: 3,
                      message: 'Minimum 3 Characters',
                    },
                    {
                      max: 300,
                      message: 'Maximum 600 characters allowed ',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='address'
                    name='address'
                    onChange={onChange}
                    disabled={isDisabled || isFetchedFromAadhar}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='address2'
                  label='Permanent Address Line 2'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Permanent Address Line 2',
                    },
                    {
                      min: 3,
                      message: 'Minimum 3 Characters',
                    },
                    {
                      max: 300,
                      message: 'Maximum 600 characters allowed ',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='address'
                    name='address'
                    onChange={onChange}
                    disabled={isDisabled || isFetchedFromAadhar}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='address3'
                  label='Permanent Address Line 3'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Permanent Address Line 3',
                    },
                    {
                      min: 3,
                      message: 'Minimum 3 Characters',
                    },
                    {
                      max: 300,
                      message: 'Maximum 600 characters allowed ',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='address'
                    name='address'
                    onChange={onChange}
                    disabled={isDisabled || isFetchedFromAadhar}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='village'
                  label='Village/Mandal'
                  rules={[
                    { required: true, message: 'Enter Village/Mandal' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='village'
                    onChange={onChange}
                    disabled={isDisabled || isFetchedFromAadhar}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6} id='state'>
                <Form.Item
                  name='state'
                  label='State'
                  rules={[{ required: true, message: 'Enter State' }]}
                  onChange={onChange}>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // onChange={(e) => {
                    //   setSelectedState(e);
                    // }}
                    // onChange={(value) =>
                    //   onChange({ target: { name: 'state', value } })
                    // }
                    onChange={(e) => {
                      setSelectedState(e);
                      onChange({ target: { name: 'state', value: e } });
                    }}
                    size='large'
                    placeholder='Select State'
                    disabled={isDisabled || isFetchedFromAadhar}>
                    {states.map((state, index) => (
                      <Select.Option key={`state-${index}`} value={state}>
                        {state}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6} id='district'>
                <Form.Item
                  name='district'
                  label='District'
                  rules={[{ required: true, message: 'Enter District' }]}
                  onChange={onChange}>
                  {
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      size='large'
                      disabled={isDisabled || isFetchedFromAadhar}
                      onChange={(value) =>
                        onChange({ target: { name: 'district', value } })
                      }>
                      {citi[selectedState]?.map((city, index) => (
                        <Select.Option key={`city-${index}`} value={city}>
                          {city}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pincode'
                  label='Pincode'
                  rules={[{ required: true, message: 'Enter Pincode' }]}>
                  <Input
                    size='large'
                    id='pincode'
                    disabled={isDisabled || isFetchedFromAadhar}
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='taluka'
                  label='Taluka'
                  rules={[
                    { required: true, message: 'Enter Taluka' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='taluka'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='tehsil'
                  label='Tehsil'
                  rules={[
                    { required: true, message: 'Enter Tehsil' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='tehsil'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='city'
                  label='City'
                  rules={[
                    { required: true, message: 'Enter City' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='city'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='country'
                  label='Country'
                  rules={[
                    { required: true, message: 'Enter Country' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='country'
                    onChange={onChange}
                    disabled={isDisabled || isFetchedFromAadhar}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className='gutter-row' span={24} id='homepostalcheck'>
                <Form.Item>
                  <input
                    type='checkbox'
                    id='same'
                    name='same'
                    size='large'
                    style={{ fontWeight: 'bold' }}
                    onClick={addressFunction}
                    disabled={isDisabled}
                  />
                  <label
                    htmlFor='same'
                    style={{ marginLeft: '10px', fontWeight: 'bolder' }}>
                    Same As Above
                  </label>
                </Form.Item>
                <Title level={5} style={{ marginTop: '30px' }}>
                  Local Address
                </Title>
              </Col>

              <br />

              {/* <Col className='gutter-row' span={24}>
                <Form.Item
                  name='paddress'
                  label='Address'
                  rules={[
                    { required: true, message: 'Enter Address' },
                    {
                      min: 3,
                      message: 'Minimum 3 Characters',
                    },
                    {
                      max: 300,
                      message: 'Maximum 300 characters allowed ',
                    },
                  ]}>
                  <Input
                    size='large'
                    id='paddress'
                    value={presentAdress.address}
                    disabled={isDisabled}
                    onChange={onChange}
                  />
                </Form.Item>
              </Col> */}
              <Col className='gutter-row' span={6}>
                <Form.Item name='paddress1' label='Present Address Line 1'>
                  <Input
                    size='large'
                    id='paddress'
                    value={presentAdress.address}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item name='paddress2' label='Present Address Line 2'>
                  <Input
                    size='large'
                    id='paddress'
                    value={presentAdress.address}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='paddress3' label='Present Address Line 3'>
                  <Input
                    size='large'
                    id='paddress'
                    value={presentAdress.address}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pvillage'
                  label='Village/Mandal'
                  rules={[
                    { required: true, message: 'Enter Village/Mandal' },
                    // {
                    //   pattern: "^[a-zA-Z]*$",
                    //   message: "Only Alphabets Allowed",
                    // },
                  ]}>
                  <Input
                    size='large'
                    id='pvillage'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>

              {/* State And District */}

              <Col className='gutter-row' span={6} id='pstate'>
                <Form.Item
                  name='pstate'
                  label='State'
                  rules={[{ required: true, message: 'Enter State' }]}
                  onChange={onChange}>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    size='large'
                    // onChange={(e) => {
                    //   setSelectedstate(e);
                    // }}
                    // onChange={(value) =>
                    //   onChange({ target: { name: 'pstate', value } })
                    // }
                    onChange={(e) => {
                      setSelectedstate(e);
                      onChange({ target: { name: 'pstate', value: e } });
                    }}
                    placeholder='Select State'
                    disabled={isDisabled}>
                    {states.map((state, index) => (
                      <Select.Option key={`state-${index}`} value={state}>
                        {state}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6} id='pdistrict'>
                <Form.Item
                  name='pdistrict'
                  label='District'
                  rules={[{ required: true, message: 'Enter Distrcit' }]}
                  onChange={onChange}>
                  {
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      size='large'
                      disabled={isDisabled}
                      onChange={(value) =>
                        onChange({ target: { name: 'pdistrict', value } })
                      }>
                      {citi[selectedstate]?.map((city, index) => (
                        <Select.Option key={`city-${index}`} value={city}>
                          {city}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                </Form.Item>
              </Col>

              {/* State And District */}

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='ppincode'
                  label='Pincode'
                  rules={[
                    { required: true, message: 'Enter Pincode' },
                    // {
                    //   pattern: "^[1-9][0-9]{5}$",
                    //   message: "Invalid Pincode",
                    // },
                  ]}>
                  <Input
                    size='large'
                    id='ppincode'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='ptaluka'
                  label='Taluka'
                  rules={[
                    { required: true, message: 'Enter Taluka' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='ptaluka'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='ptehsil'
                  label='Tehsil'
                  rules={[
                    { required: true, message: 'Enter Tehsil' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='ptehsil'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pcity'
                  label='City'
                  rules={[
                    { required: true, message: 'Enter City' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='pcity'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pcountry'
                  label='Country'
                  rules={[
                    { required: true, message: 'Enter Country' },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message: 'Only Alphabets Allowed',
                    },
                  ]}>
                  <Input
                    size='large'
                    name='pcountry'
                    onChange={onChange}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Divider></Divider>

        <Collapse onChange={changeAccordian}>
          <Panel header='Professional Details' key='3'>
            <Row gutter={16}>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='unitName'
                  label='Unit Name'
                  rules={[{ required: true, message: 'Enter Unit Name' }]}>
                  <Select size='large' disabled={isDisabled}>
                    <Option value='haziraWest'>Hazira West</Option>
                    <Option value='haziraEast'>Hazira East</Option>
                    <Option value='Ranoli'>RANOLI</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='empCode'
                  label={
                    <Space>
                      Worker Code
                      <span></span>
                      <Tooltip
                        title='Generated by the Principal Employer'
                        placement='top'>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                  // rules={[
                  //   { required: true, message: 'Enter Worker Code' },
                  //   {
                  //     pattern: /^[a-zA-Z0-9]+$/,
                  //     message: 'Enter Alpha Numeric Only',
                  //   },
                  // ]}
                >
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>{' '}
              </Col>

              {/* testing for custom fields */}

              {/* {customFields.map((data) => {
                if (!data.isDefault && data.Area === 'personalDetails') {
                  return (
                    <Col className='gutter-row' span={6}>
                      <CustomFields data={data} />
                    </Col>
                  );
                }
              })} */}

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='epnNumber'
                  label='EPN Number'
                  // rules={[{ required: true, message: 'Enter EPN Number' }]}
                >
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>

              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='cardType'
                  label='Card Type'
                  rules={[{ required: true, message: 'Enter Card' }]}>
                  <Select size='large' disabled={isDisabled}>
                    <Option value='C'>C</Option>
                    <Option value='R'>R</Option>
                  </Select>
                </Form.Item>
              </Col> */}
              {/* {shouldDisplayEpnButton && (
              <Col className='gutter-row' span={6}>
                <Form.Item name='epnRenew' label='EPN Renew'>
                  <DatePicker
                    size='large'
                    disabled={isDisabled}
                    style={{ width: '100%' }}
                    format='DD-MM-YYYY'
                  />
                </Form.Item>{' '}
              </Col>
              )} 
              {/* {shouldDisplayEpnButton && ( */}
              {(initialValues?.status === 'active' ||
                initialValues?.status === 'approved') && (
                <Col
                  className='gutter-row'
                  span={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button type='primary' size='large' onClick={handleModal}>
                      EPN Renew Records
                    </Button>
                  </Form.Item>
                </Col>
              )}
              <Modal
                title='EPN Renew'
                open={modalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                destroyOnClose={true}
                width='1000px'>
                <Form
                  layout='vertical'
                  initialValues={initialValuesFromHistory}
                  // ref={formRef1}
                  form={modalForm}>
                  <Row gutter={16}>
                    <Col className='gutter-row' span={6}>
                      <Form.Item
                        name='epnRenew'
                        label='EPN Renew Date'
                        rules={[
                          {
                            required: true,
                            message: 'Select EPN Renew Date',
                          },
                        ]}>
                        <DatePicker
                          size='large'
                          disabled={isDisabled}
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name='epnValidityFromDate'
                        label='EPN validity From Date'
                        rules={[
                          {
                            required: true,
                            message: 'Select EPN validity From Date',
                          },
                        ]}>
                        <DatePicker
                          size='large'
                          disabled={isDisabled}
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name='epnValidityToDate'
                        label='EPN validity To Date'
                        rules={[
                          {
                            required: true,
                            message: 'Select EPN validity To Date',
                          },
                        ]}>
                        <DatePicker
                          size='large'
                          disabled={isDisabled}
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item>
                    </Col>
                    <Col className='gutter-row' span={6}>
                      <Form.Item
                        name='cardType'
                        label='Card Type'
                        rules={[
                          { required: true, message: 'Enter Card Type' },
                        ]}>
                        <Select size='large' disabled={isDisabled}>
                          <Option value='C'>C</Option>
                          <Option value='R'>R</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className='gutter-row' span={6}>
                      <Form.Item
                        name='cardLostType'
                        label='Card Lost Type'
                        rules={[
                          {
                            required: true,
                            message: 'Enter Card Lost Type',
                          },
                        ]}>
                        <Select size='large' disabled={isDisabled}>
                          <Option value='R'>R</Option>
                          <Option value='L'>L</Option>
                        </Select>
                      </Form.Item>{' '}
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name='cardLostDate'
                        label='Card Lost Date'
                        rules={[
                          {
                            required: true,
                            message: 'Enter Card Lost Date',
                          },
                        ]}>
                        <DatePicker
                          size='large'
                          disabled={isDisabled}
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name='oldEpnCancelDate'
                        label='Old EPN Cancel Date'
                        rules={[
                          {
                            required: true,
                            message: 'Enter Old EPN Cancel Date',
                          },
                        ]}>
                        <DatePicker
                          size='large'
                          disabled={isDisabled}
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name='cardLostRemark'
                        label='Card Lost Remark'
                        rules={[
                          {
                            required: true,
                            message: 'Enter Card Lost Remark',
                          },
                        ]}>
                        <Input size='large' disabled={isDisabled} />
                      </Form.Item>
                    </Col>
                    <Col
                      className='gutter-row'
                      span={6}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                          type='primary'
                          size='large'
                          onClick={handleEpnRenew}>
                          Renew EPN No.
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <Space
                  direction='vertical'
                  style={{ margin: '22px 12px', display: 'flex' }}>
                  <Table
                    bordered
                    dataSource={initialValues?.epnRenewHistory}
                    columns={columns}
                    // title={Title}
                    rowKey={(record) => record.id || undefined}
                    // rowKey={(record, index) => (record.id ? record.id.toString() + index.toString() : index)}
                  />
                </Space>
              </Modal>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='locationId'
                  label='Location ID'
                  rules={[{ required: true, message: 'Enter Location' }]}>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange(
                        { target: { name: 'locationId', value } },
                        handleLocationChange
                      )
                    }>
                    {/* {location?.Response2?.slice(1).map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.id}
                      </Option>
                    ))} */}
                    <Option value={userConfig.location}>
                      {userConfig.location}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='hazardousProcessArea'
                  label='Hazardous Process Area'
                  rules={
                    [
                      // { required: true, message: "Enter Hazardous Process Area" },
                    ]
                  }>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({
                        target: { name: 'hazardousProcessArea', value },
                      })
                    }>
                    <Option value='yes'>Yes</Option>
                    <Option value='no'>No</Option>
                  </Select>
                </Form.Item>
              </Col> */}
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='technicalDetails'
                  label='Technical Details'
                  rules={
                    [
                      // { required: true, message: "Enter Technical Details" },
                    ]
                  }>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({ target: { name: 'technicalDetails', value } })
                    }>
                    {technicalDetails?.map((area, index) => (
                      <Option key={index} value={area}>
                        {area}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>{' '}
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='natureOfWork'
                  label='Type of Contract'
                  rules={
                    [
                      // { required: true, message: "Enter Technical Details" },
                    ]
                  }>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({ target: { name: 'natureOfWork', value } })
                    }>
                    {natureOfWork?.map((area, index) => (
                      <Option key={index} value={area}>
                        {area}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>{' '}
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='policeVerification'
                  label='Police Verification'
                  //  rules={[{ required: true, message: "Enter Police Verification" }]}
                >
                  <Select size='large' disabled={isDisabled}>
                    <Option value='yes'>Yes</Option>
                    <Option value='no'>No</Option>
                  </Select>
                </Form.Item>{' '}
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='gateNumber'
                  label='Gate Number'
                  rules={
                    [
                      // { required: true, message: "Enter Hazardous Process Area" },
                    ]
                  }>
                  <Select
                    size='large'
                    disabled={isDisabled}
                    onChange={(value) =>
                      onChange({ target: { name: 'gateNumber', value } })
                    }>
                    {gateNumber?.map((area, index) => (
                      <Option key={index} value={area}>
                        {area}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='safetyTrainingFromDate'
                  label='Safety Training From Date'
                  // rules={[{ required: true, message: "Enter Nature Of Work" }]}
                >
                  <DatePicker
                    size='large'
                    disabled={isDisabled}
                    format='DD-MM-YYYY'
                    style={{ width: '100%' }}
                  />
                </Form.Item>{' '}
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='safetyTrainingUptoDate'
                  label='Safety Training Upto Date'
                  // rules={[{ required: true, message: "Enter Nature Of Work" }]}
                >
                  <DatePicker
                    size='large'
                    disabled={isDisabled}
                    format='DD-MM-YYYY'
                    style={{ width: '100%' }}
                  />
                </Form.Item>{' '}
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='safetyTrainingRemarks'
                  label='Safety Training Remarks'
                  // rules={[{ required: true, message: "Enter Nature Of Work" }]}
                >
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>
              {/* <Col span={6}></Col> */}
              {/* <Col className='gutter-row' span={6}>
                <Form.Item
                  name='blockRemark'
                  label='Block Remark'
                  // rules={[{ required: true, message: "Enter Nature Of Work" }]}
                >
                  <Input
                    size='large'
                    disabled={isDisabled}
                    onChange={onChange}
                    name='blockRemark'
                  />
                </Form.Item>
              </Col> */}

              <Col className='gutter-row' span={6}>
                <Form.Item name='esicexempt' valuePropName='checked'>
                  <Checkbox
                    onClick={Esictoggle}
                    size='large'
                    className='exempt'
                    value={!EsicDisabled}
                    // disabled={isDisabled}
                    disabled={isDisabled || isEditingDisabled}>
                    ESIC Exempt
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='esicno'
                  label='ESI Number'
                  rules={[
                    {
                      required: EsicDisabled || isDisabled ? false : true,
                      message: 'Enter ESI Number',
                    },

                    {
                      pattern:
                        '^([0-9]{2})[–-]([0-9]{2})[–-]([0-9]{6})[–-]([0-9]{3})[–-]([0-9]{4})$',
                      message: 'Invalid ESIC number',
                    },
                  ]}>
                  <Input
                    size='large'
                    placeholder='Ex: 41-00-123456-000-0001'
                    disabled={EsicDisabled || isDisabled || isEditingDisabled}
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='Uan' valuePropName='checked'>
                  <Checkbox
                    onClick={Uantoggle}
                    size='large'
                    value={!UanDisabled}
                    className='exempt'
                    // disabled={isDisabled}
                    disabled={isDisabled || isEditingDisabled}>
                    UAN Exempt
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='uan'
                  label='UAN'
                  rules={[
                    {
                      required: UanDisabled || isDisabled ? false : true,
                      message: 'Enter UAN Number',
                    },

                    // {
                    //   min: 12,
                    //   max: 12,
                    //   message: "UAN number should be 12 digits",
                    // },
                  ]}>
                  <Input
                    size='large'
                    // disabled={UanDisabled || isDisabled || initialValues?.Uan}
                    disabled={UanDisabled || isDisabled || isEditingDisabled}
                    placeholder='Ex: 123456789012'
                    onChange={onchange}
                  />
                </Form.Item>
              </Col>

              <Col className='gutter-row' span={6}>
                <Form.Item name='pf' valuePropName='checked'>
                  <Checkbox
                    onClick={Pftoggle}
                    size='large'
                    value={!PfDisabled}
                    className='exempt'
                    // disabled={isDisabled}
                    disabled={isDisabled || isEditingDisabled}>
                    PF Exempt
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6}>
                <Form.Item
                  name='pfAccountNo'
                  label='PF Account Number'
                  rules={[
                    {
                      required: PfDisabled || isDisabled ? false : true,
                      message: 'Enter PF Account Number',
                    },

                    {
                      pattern: '[A-Z]{5}[0-9]{17}',
                      message: 'Invalid PF Account number ',
                    },
                  ]}>
                  <Input
                    size='large'
                    placeholder='Ex: BGBNG24718350000010018'
                    disabled={PfDisabled || isDisabled || isEditingDisabled}
                    onChange={onchange}
                  />
                </Form.Item>
              </Col>
              <Col className='gutter-row' span={6} hidden={!PfDisabled}>
                <Form.Item
                  label=' Form-11 Attachment'
                  name='caseForm11'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: !PfDisabled ? false : true,
                      message: 'Upload Case Form-11 Attachment',
                    },

                    // {
                    //   min: 12,
                    //   max: 12,
                    //   message: "UAN number should be 12 digits",
                    // },
                  ]}>
                  <Upload
                    // type="file"
                    listType='picture'
                    accept='image/*'
                    onPreview={handlePreview}
                    maxCount={1}
                    beforeUpload={() => false}
                    size='large'
                    style={{ width: '100%' }}
                    // disabled={PfDisabled || isDisabled}
                    onChange={onchange}>
                    <Button
                      icon={<UploadOutlined />}
                      size='large'
                      style={{ width: '100%' }}
                      onChange={onchange}>
                      Upload Image
                    </Button>
                  </Upload>
                </Form.Item>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}>
                  <img
                    alt='example'
                    style={{
                      width: '100%',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </Col>
              {/* <Col span={6} hidden={!EsicDisabled}>
                <Form.Item
                  label='WC/EC Policy Number'
                  name='wcpn'
                  rules={[
                    // {
                    //   pattern: '^[0-9]*$',
                    //   message: 'Only Numbers Allowed',
                    // },
                    {
                      required: !EsicDisabled ? false : true,
                      message: 'Enter WC/EC Policy Number',
                    },
                  ]}>
                  <Input size='large' disabled={isDisabled} />
                </Form.Item>
              </Col>

              <Col span={6} hidden={!EsicDisabled}>
                <Form.Item
                  label='WC/EC Insurance Valid From'
                  name='wcvf'
                  rules={[
                    // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                    {
                      required: !EsicDisabled ? false : true,
                      message: 'Enter WC/EC Insurance Valid From',
                    },
                  ]}>
                  <DatePicker
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                    disabled={isDisabled}
                    size='large'
                  />
                </Form.Item>
              </Col>
              <Col span={6} hidden={!EsicDisabled}>
                <Form.Item
                  label='WC/EC Insurance Valid To'
                  name='wcvt'
                  rules={[
                    // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                    {
                      required: !EsicDisabled ? false : true,
                      message: 'Enter WC/EC Insurance Valid To',
                    },
                  ]}>
                  <DatePicker
                    placeholder='DD-MM-YYYY'
                    format='DD-MM-YYYY'
                    style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                    disabled={isDisabled}
                    size='large'
                  />
                </Form.Item>
              </Col> */}

              {initialValues?.status &&
                initialValues?.status != 'pending' &&
                initialValues?.status !== '' &&
                userConfig.role === 'supervisor' && (
                  <Col className='gutter-row' span={6}>
                    <Form.Item
                      name='statusToBeImposed'
                      label='Employment Status'
                      // rules={[{ required: true, message: "Enter Status" }]}
                    >
                      <Select
                        size='large'
                        onChange={handleStatusChange}
                        placeholder='Active'>
                        <Option value='active'>Active</Option>
                        <Option value='retired'>Retired</Option>
                        <Option value='terminated'>Terminated</Option>
                        <Option value='rehired'>Rehired</Option>
                        <Option value='suspended'>Suspended</Option>
                        <Option value='blocked'>Blocked</Option>
                        <Option value='blacklisted'>Blacklisted</Option>
                      </Select>
                    </Form.Item>
                    {employmentStatus === 'active' ? (
                      <div>
                        <Form.Item name='statusFromDate' label='Active Date'>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>

                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'retired' ? (
                      <div>
                        {/*<Form.Item name='statusFromDate' label='Retired Date'>
                        <DatePicker
                           size='large'
                           style={{ width: '100%' }}
                           format='DD-MM-YYYY'
                         />
                       </Form.Item> */}
                        <Form.Item
                          name='statusFromDate'
                          label='Retired Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a retired date',
                            },
                          ]}>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'terminated' ? (
                      <div>
                        {/* <Form.Item name='statusFromDate' label='Terminated Date'>
                        <DatePicker
                          size='large'
                          style={{ width: '100%' }}
                          format='DD-MM-YYYY'
                        />
                      </Form.Item> */}
                        <Form.Item
                          name='statusTerminateNoticeDate'
                          label='Terminated Notice Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a terminated notice date',
                            },
                          ]}>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='statusFromDate'
                          label='Terminated Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a terminated date',
                            },
                          ]}>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'rehired' ? (
                      <div>
                        {/* <Form.Item name='statusFromDate' label='Rehired Date'>
                         <DatePicker
                           size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item> */}
                        <Form.Item
                          name='statusFromDate'
                          label='Rehired Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a rehired date',
                            },
                          ]}>
                          <DatePicker
                            size='large'
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'suspended' ? (
                      <div>
                        <Form.Item
                          name='statusFromDate'
                          label='Suspended From Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a from date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='statusToDate'
                          label='Suspended To Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a to date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'blocked' ? (
                      <div>
                        <Form.Item
                          name='statusFromDate'
                          label='Blocked From Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a from date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='statusToDate'
                          label='Blocked To Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a to date',
                            },
                          ]}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                          />
                        </Form.Item>
                        <Form.Item
                          name='blockType'
                          label='Block Type'
                          rules={[
                            {
                              required: true,
                              message: 'Please select Dropdown',
                            },
                          ]}>
                          <Select>
                            <Option value='permanent'>Permanent</Option>
                            <Option value='temporary'>Temporary</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                    {employmentStatus === 'blacklisted' ? (
                      <div>
                        {/* <Form.Item name='statusFromDate' label='Blacklisted Date'>
                        <DatePicker size='large' style={{ width: '100%' }} />
                      </Form.Item> */}
                        <Form.Item
                          name='statusFromDate'
                          label='Blacklisted Date'
                          rules={[
                            {
                              required: true,
                              message: 'Please select a blacklisted date',
                            },
                          ]}>
                          <DatePicker size='large' style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                          name='remark'
                          label='Remarks'
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Block Remark',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </div>
                    ) : null}
                  </Col>
                )}
            </Row>
          </Panel>
        </Collapse>
        <Divider></Divider>

        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button type='primary' htmlType='submit'>
            Next
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Personaldetails;
