import React, { useEffect, useState } from 'react';
import { Steps, notification, Divider } from 'antd';
// import Personaldetails from '../components/PermanentEmployeeOnboard/Personneldetails';
import Personaldetails from '../components/PermanentEmployeeOnboard/Personeldetails';
import Jobdetails from '../components/PermanentEmployeeOnboard/Jobdetails';
import Wagedetails from '../components/PermanentEmployeeOnboard/Wagedetails';
import Uploaddocument from '../components/PermanentEmployeeOnboard/Uploaddocument';
import Summarygeneration from '../components/PermanentEmployeeOnboard/Summarygeneration';
import createPass from '../services/employeeonboard';
import useAuth from '../hooks/useAuth';
import { Email } from '../services/auth';
import {
  getEmployeeList,
  searchEmployee,
  getWorkmenLimit,
  getExcelData,
} from '../services/auth';
import Quickonboarding from '../quickonboarding/quickonboarding';
// import EmployeeList from './EmployeeList';
import axios from '../services/axiosInstance';
import { logout } from '../services/auth';

const PermanentEmployeeOnboard = () => {
  const { user, userConfig, logout } = useAuth();
  const orgId = userConfig?.orgId;
  console.log('userConfig', userConfig);
  const [imageSrc, setImageSrc] = useState(null);
  // const [contractorCode,setContractorCode] = useState([])
  const [contractorList, setContractorList] = useState([]);
  const [jobOrderList, setJobOrderList] = useState([]);
  // Location
  const [location, setlocation] = React.useState({});
  const [idStatus, setIdStatus] = React.useState([]);
  const [masterData, setMasterData] = React.useState([]);
  useEffect(() => {
    EmployeeList();
  }, []);
  async function EmployeeList() {
    const Response = await axios.get('/org/locations');
    const Response2 = Response.data.org.locations[0].id;
    setlocation({ Response2 });
  }

  const userType = userConfig?.userType;
  const contractorId = userConfig?.supplierId;
  console.log(contractorId);
  console.log('idStatus', idStatus);

  const checkContractorStatus = (contractorId) => {
    const contractor = idStatus.find((item) => item.id === contractorId);

    if (contractor) {
      const { status } = contractor;

      if (
        userType === 'blacklist' ||
        status === 'retired' ||
        status === 'terminated' ||
        status === 'suspended' ||
        status === 'blocked'
      ) {
        notification.warning({
          message: `Contractor with ID ${contractorId} has status: ${status}.`,
        });

        // Logout after 5 seconds
        setTimeout(() => {
          logout();
        }, 5000);
      }
    }
  };

  useEffect(() => {
    checkContractorStatus(contractorId);
  }, [contractorId, idStatus]);

  /* eslint-disable */
  const DEFAULT_ADMIN_FORM_VALUE = {
    longName: '',
    firstName: '',
    lastName: '',
    gender: '',
    aadharNo: '',
    fatherName: '',
    address: '',
    village: '',
    pincode: '',
    state: '',
    district: '',
    paddress: '',
    pvillage: '',
    pstate: '',
    pdistrict: '',
    ppincode: '',
    aadharNo: '',
    dob: '',
    age: '',
    mobile: '',
    locationId: '',
    children: [],
  };
  const [currentPageNo, setCurrentPageNo] = React.useState(0);
  const [personalDetails, setpersonalDetails] = React.useState(
    DEFAULT_ADMIN_FORM_VALUE
  );
  // const [personalDetails, setpersonalDetails] = React.useState();

  const [jobDetails, setjobDetails] = React.useState();
  const [wageDetails, setwageDetails] = React.useState();
  const [uploadDocument, setuploadDocument] = React.useState();

  const [Summary, setSummary] = React.useState();
  const data = {
    personalDetails: personalDetails,
    jobDetails: jobDetails,
    wageDetails: wageDetails,
    uploadDocument: uploadDocument,
    // locationId:location.Response2,
    locationId: personalDetails.locationId,
    // livePhoto: imageSrc,
  };
  data.employementType = 'Permanent';

  data.personalDetails.statusFromDate = new Date(
    data?.personalDetails.statusFromDate
  ).getTime();

  data.personalDetails.statusToDate = new Date(
    data?.personalDetails.statusToDate
  ).getTime();
  data.lastModifiedDate = new Date().toISOString();
  console.log('data obtained', data);

  data.personalDetails.statusTerminateNoticeDate = new Date(
    data?.personalDetails.statusTerminateNoticeDate
  ).getTime();

  function submit() {
    data.isFetchedFromAadhar = data?.personalDetails?.isFetchedFromAadhar;

    delete data?.jobDetails.personalDetails?.isFetchedFromAadhar;
    createPass(data)
      .then((res) => {
        // Display the notification first
        notification.success({
          message:
            'Workman status is now in PENDING mode. Please review submitted data again and APPROVE the same',
          duration: 5, // Duration in seconds
        });

        // Delay the page reload to ensure the notification is visible
        setTimeout(() => {
          window.location.reload();
        }, 5000); // Delay for 10 seconds
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });

    Email({
      workmenName:
        data.personalDetails.firstName + ' ' + data.personalDetails.lastName,
      templateId: 'WORKMEN_ONBOARDED',
      orgId: userConfig.orgId,
      role: userConfig.role,
      level: userConfig.level,
      locationId: data.locationId,
      correctRecipientEmail: data.emailId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  }

  useEffect(() => {
    // Fetch data when the component mounts
    workmenLimit();
    getExcel();
  }, []);

  const workmenLimit = async () => {
    // console.log(contractorId);
    getWorkmenLimit(orgId)
      .then((res) => {
        // console.log(res)

        // const contractorIdsFromData = res.data.map(item => {
        //   if (item && item.addJobDetails && Array.isArray(item.addJobDetails)) {
        //     return item.addJobDetails.map(details => details?.id);
        //   }
        //   return null;
        // });

        // const flattenedContractorIds = contractorIdsFromData.flat();
        // const uniqueContractorIds = [...new Set(flattenedContractorIds)];
        // //uniquejoborders
        // // const uniqueJobOrderNumbers = [...new Set(flattenedContractorIdsAndJobOrderNumbers.map(item => item.jobOrderNumber))];

        // console.log("id is",uniqueContractorIds)
        // // console.log("job order number",uniqueJobOrderNumbers)
        // setContractorCode(uniqueContractorIds)

        // // setData(res.data);
        // // setMaxContractorLimit(res.data.maxContractors)
        // // setlln(res.data.lln)
        const contractorIdsAndJobOrderNumbers = res.data.map((item) => {
          if (item && item.addJobDetails && Array.isArray(item.addJobDetails)) {
            return item.addJobDetails.map((details) => ({
              id: details?.id,
              jobOrderNumber: details?.jobordernumber,
            }));
          }
          return null;
        });

        // Flatten the array of objects into an array of { id, jobOrderNumber }
        const flattenedContractorIdsAndJobOrderNumbers =
          contractorIdsAndJobOrderNumbers.flat();

        // Get unique contractor IDs
        const uniqueContractorIds = [
          ...new Set(
            flattenedContractorIdsAndJobOrderNumbers.map((item) => item.id)
          ),
        ];

        // Get unique contractor IDs and there status
        const uniqueContractorIdsAndStatus = res.data.map((item) => ({
          id: item?.id,
          status: item?.status,
        }));
        setIdStatus(uniqueContractorIdsAndStatus);

        // console.log("ids & status", uniqueContractorIdsAndStatus);

        // Get unique job order numbers
        const uniqueJobOrderNumbers = [
          ...new Set(
            flattenedContractorIdsAndJobOrderNumbers.map(
              (item) => item.jobOrderNumber
            )
          ),
        ];

        // console.log(uniqueContractorIds)
        // console.log(uniqueJobOrderNumbers)
        // Set state variables with unique IDs
        setContractorList(uniqueContractorIds);
        setJobOrderList(uniqueJobOrderNumbers);
      })
      .catch((error) => {
        // setLoader(false);
        console.log('error', error);
      });
  };

  const getExcel = async () => {
    getExcelData(orgId)
      .then((res) => {
        console.log('response', res);
        setMasterData(res.data.data.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const pageItems = [
    {
      key: 'personneldetails',
      title: 'Personal Details',
    },
    {
      key: 'jobdetails',
      title: 'Job Details',
    },
    {
      key: 'wagedetails',
      title: 'Wage Details',
    },
    {
      key: 'uploaddocument',
      title: 'Upload Document',
    },
    {
      key: 'summary',
      title: 'Summary Generation',
    },
  ];
  const next = (values) => {
    if (currentPageNo === 0) {
      // setpersonalDetails(values);
      setpersonalDetails((currData) => ({ ...currData, ...values }));
    }

    if (currentPageNo === 1) {
      setjobDetails(values);
    }
    if (currentPageNo === 2) {
      setwageDetails(values);
    }
    if (currentPageNo === 3) {
      setuploadDocument(values);
    }
    if (currentPageNo === 4) {
      setSummary(values);

      // submit the data
      submit(values);
      return;
    }

    setCurrentPageNo(currentPageNo + 1);
  };

  const prev = (values) => {
    if (currentPageNo === 1) {
      setjobDetails(values);
    }
    if (currentPageNo === 2) {
      setwageDetails(values);
    }
    if (currentPageNo === 3) {
      setuploadDocument(values);
    }
    if (currentPageNo === 4) {
      setSummary(values);
    }

    setCurrentPageNo(currentPageNo - 1);
  };
  return (
    <div>
      <h2>Workmen Details</h2> <br />
      <br />
      <Steps current={currentPageNo} items={pageItems} /> <Divider />
      {currentPageNo === 0 && (
        <Personaldetails
          initialValues={personalDetails}
          onNext={next}
          setImageSrc={setImageSrc}
          imageSrc={imageSrc}
          masterData={masterData}
        />
      )}
      {currentPageNo === 1 && (
        <Jobdetails
          initialValues={jobDetails}
          onNext={next}
          onPrev={prev}
          jobOrderList={jobOrderList}
          contractorList={contractorList}
          masterData={masterData}
          personalDetails={personalDetails}
        />
      )}
      {currentPageNo === 2 && (
        <Wagedetails initialValues={wageDetails} onNext={next} onPrev={prev} />
      )}
      {currentPageNo === 3 && (
        <Uploaddocument
          initialValues={uploadDocument}
          personalDetails={personalDetails}
          onNext={next}
          onPrev={prev}
        />
      )}
      {currentPageNo === 4 && (
        <Summarygeneration
          initialValues={personalDetails}
          onNext={next}
          onPrev={prev}
          jobDetails={jobDetails}
          wageDetails={wageDetails}
        />
      )}
    </div>
  );
};

export default PermanentEmployeeOnboard;
