import React, { useState } from "react";
import {
  Col,
  Typography,
  Row,
  Form,
  Button,
  Input,
  Select,
  Modal,
  Upload,
  DatePicker,
  notification,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { updatePass } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { Email } from "../../services/auth";
import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router-dom";

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Ermanage = () => {
  const { user, userConfig } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  //upload
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  //Preview
  const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewOpen, setPreviewOpen] = useState(true);

  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewOpen(false);

  const onFinish = (values) => {
    console.log("values is", values);
    navigate("/home/approver/incidentreport");

    const data = {
      // status:"pendingwithEr",

      incidentReport: [
        {
          incidentcategory: values.incidentcategory,
          severity: values.severity,
          recommendation: values.recommendation,
          terminationDate: values.terminationDate,
          suspendRange: values.suspendRange,
          date: values.date,
          feedback: {
            message: values.feedback,
            status: "",
            statustimestamp: new Date().toLocaleString(),
          },

          uploadDocument: values.uploadDocument,
        },
      ],
    };

    updatePass(id, data)
      .then((res) => {
        notification.success({ message: "Incident Report submitted" });
      })
      .catch((err) => {
        // notification.error({ message: "Employee Approved Successfully" });
      });
    Email({
      workmenName: userConfig.firstName + " " + userConfig.lastName,
      templateId: "APPROVER_DONE",
      orgId: userConfig.orgId,
      role: "supervisor",
      level: null,
      locationId: null,
      correctRecipientEmail: userConfig.emailId
    })
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };
  return (
    <>
      <Title
        level={4}
        style={{
          textAlign: "center",

          fontWeight: "bolder",
        }}
      >
        Report Incident
      </Title>
      <Form layout='vertical' onFinish={onFinish}>
        <Row>
          <Col span={6}>
            <Form.Item
              label='Date of Incident'
              name='date'
              rules={[
                // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                { required: true, message: "Select Date" },
              ]}
            >
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                size='large'
                style={{ width: "80%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name='incidentcategory'
              label='Incident Category'
              rules={[{ required: true, message: "Enter Incident Category" }]}
            >
              <Select size='large' style={{ width: "80%" }}>
                <Option value=''></Option>

                <Option value='accident'>Accident</Option>
                <Option value='inventorymismanagement'>
                  Inventory mismanagement
                </Option>
                <Option value='theft'>Theft</Option>
                <Option value='procedural'>Procedural Negligence</Option>
                <Option value='misconduct'>Misconduct</Option>
                <Option value='fraud'>Fraud</Option>
                <Option value='insubordination'>Insubordination</Option>
                <Option value='behaviourialincident'>
                  Behaviourial Incident
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name='severity'
              label='Severity'
              rules={[{ required: true, message: "Enter Severity" }]}
            >
              <Select size='large' style={{ width: "80%" }}>
                <Option value=''></Option>

                <Option value='major'>Major</Option>
                <Option value='minor'>Minor</Option>
                <Option value='others'>Others</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name='feedback'
              label='Remarks'
              rules={[{ required: true, message: "Enter Feedback" }]}
            >
              <TextArea
                placeholder='Enter FeedBack'
                allowClear
                size='large'
                style={{ width: "100%", height: "40px" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col span={6}>
            <Form.Item
              label='Upload Document'
              name='uploadDocument'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                listType='picture'
                accept='image/*'
                maxCount={4}
                beforeUpload={() => false}
                onPreview={handlePreview}
                size='large'
                style={{ width: "100%" }}
              >
                <Button
                  icon={<UploadOutlined />}
                  style={{ width: "140%" }}
                  size='large'
                >
                  Upload Document
                </Button>
              </Upload>
            </Form.Item>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt='example'
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
          </Col>
          <Col span={6}>
            <Form.Item
              name='recommendation'
              label='Recommendation'
              rules={[{ required: true, message: "Enter Recommendation" }]}
            >
              <Select size='large' style={{ width: "80%" }}>
                <Option value=''></Option>
                <Option value='suspend'>Suspend</Option>
                <Option value='terminated'>Terminate</Option>
                <Option value='recoverloss'>Recover Loss</Option>
                <Option value='warning'>Warning</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.recommendation !== curValues.recommendation
              }
            >
              {({ getFieldValue }) => {
                const recommendation = getFieldValue("recommendation");

                if (recommendation === "suspend") {
                  return (
                    <Form.Item
                      name='suspendRange'
                      label='Suspend Range'
                      rules={[{ required: true, message: "Select Date Range" }]}
                    >
                      <RangePicker
                        style={{ width: "100%" }}
                        format='DD-MM-YYYY'
                      />
                    </Form.Item>
                  );
                }

                if (recommendation === "terminated") {
                  return (
                    <Form.Item
                      name='terminationDate'
                      label='Termination Date'
                      rules={[
                        { required: true, message: "Select Termination Date" },
                      ]}
                    >
                      <DatePicker
                        size='large'
                        style={{ width: "80%" }}
                        format='DD-MM-YYYY'
                      />
                    </Form.Item>
                  );
                }

                if (recommendation === "recoverloss") {
                  return (
                    <Form.Item
                      name='lossAmount'
                      label='Loss Amount'
                      rules={[
                        { required: true, message: "Enter Loss Amount" },
                        {
                          pattern: /^[0-9]+$/,
                          message: "Only Numeric Allowed",
                        },
                      ]}
                    >
                      <Input size='large' style={{ width: "100%" }} />
                    </Form.Item>
                  );
                }

                return null; // Return null for the empty column
              }}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ float: "right", marginLeft: "10px" }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Ermanage;
