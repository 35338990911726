import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Select, Button, DatePicker, Input } from 'antd';
import axios from '../../services/axiosInstance';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const { Option } = Select;
const XLSX = require('xlsx');

const StateWiseMaleFemaleReport = () => {
    const { userConfig } = useAuth();
    const [users, setUsers] = React.useState({});
    const [selectedContractor, setSelectedContractor] = useState(null);
    const [formCompleted, setFormCompleted] = useState(false);
    const [form] = Form.useForm(); // Access form instance
    const SPACING = ['', '', ''];
    const reportDate = new Date().toLocaleDateString();
    const reportTime = new Date().toLocaleTimeString();

    useEffect(() => {
        EmployeeList();
    }, []);

    const onFinish = async (values) => {
        const payload = {
            id: values.id,
            fileType: values.fileType
        };

        axios.post('/pass/maleFemaleReport', payload, { responseType: 'blob' })
            .then((response) => {
                const contentType = response.headers['content-type'];
                const blob = new Blob([response.data], { type: contentType });

                if (values.fileType === 'PDF') {
                    // Create a temporary anchor element
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.setAttribute('download', 'Male Female Report.pdf');
                    // Append the anchor to the body and trigger the click event
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    document.body.removeChild(link);
                } else if (values.fileType === 'Excel') {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const data = new Uint8Array(e.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });
                        // Modify this part based on your Excel data structure
                        const sheetName = workbook.SheetNames[0];
                        const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

                        // Modify the Excel data to include additional details like Company Name, Division Name, Print Date, etc.
                        const modifiedExcelData = [
                            ["", "", "State Wise Male Female Report"],
                            [],
                            ["Compnay Name:", userConfig.orgId, ...SPACING, "Report Date:", reportDate],
                            ["Location Name:", values.divisionName, ...SPACING, "Report Time:", reportTime],
                            [],
                            ...excelData

                        ];

                        // Convert modified data back to Excel
                        const ws = XLSX.utils.aoa_to_sheet(modifiedExcelData);
                        const newWorkbook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(newWorkbook, ws, "State Wise Male Female Report");
                        const excelBuffer = XLSX.write(newWorkbook, { type: "buffer", bookType: "xlsx" });

                        // Trigger file download
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(new Blob([excelBuffer], { type: "application/octet-stream" }));
                        link.setAttribute('download', 'State Wise Male Female Report.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    };
                    reader.readAsArrayBuffer(blob);
                }
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
            });
    };


    useEffect(() => {
        EmployeeList();
    }, []);

    async function EmployeeList() {
        const Response = await axios.get('/supplier');
        const Response1 = Response.data.suppliers;
        setUsers({ Response1 });
    }

    const handleContractorChange = (value) => {
        setSelectedContractor(value);
        if (value === "All") {
            console.log("Downloading for all contractors");
        } else {
            console.log("Downloading for contractor:", value);
        }
    };

    const onValuesChange = () => {
        // Check if all fields are filled
        form.validateFields().then(() => {
            setFormCompleted(true);
        }).catch(() => {
            setFormCompleted(false);
        });
    };

    return (
        <div>
            <h1>State Wise Male Female Report</h1>
            <Form layout='vertical' form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label='Company Name' name="companyName" initialValue={userConfig.orgId} required>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Contractor' name="id" rules={[{ required: true, message: 'Please select a contractor' }]}>
                            <Select
                                placeholder="Select Contractor"
                                onChange={(value) => handleContractorChange(value)}
                            >
                                <Option value="All">All</Option>
                                {users.Response1?.map((option) => (
                                    <Option key={option.id} value={option.id}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label='Date' name="date" rules={[{ required: true, message: 'Please select a date' }]}>
                            <DatePicker />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} align="middle">
                    <Col span={8}>
                        <Form.Item name="divisionName" label="Location Name" labelAlign="top" rules={[{ required: true, message: 'Please select a location name' }]}>
                            <Select>
                                {users.Response1?.map((option) => (
                                    <Option key={option.id} value={option.location}>
                                        {option.location}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="fileType" label="File Type" labelAlign="top" rules={[{ required: true, message: 'Please select a file type' }]}>
                            <Select>
                                <Option value="PDF">PDF</Option>
                                <Option value="Excel">Excel</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button type='primary' htmlType='submit' style={{ marginTop: 7 }} enable={!formCompleted}>Download Report</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default StateWiseMaleFemaleReport;