// let jwt = require('jsonwebtoken');
import { jwtDecode } from 'jwt-decode';
const verifyToken = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (error) {
    console.log('value of error', error);
    return false;
  }
};

export { verifyToken };
