import React from "react";
import { Form, Input, Modal, Select } from "antd";
import { orgEmailValidator } from "../../validators";
import useAuth from "../../hooks/useAuth";

const { Option } = Select;

export default function AdminFormModal({
  open,
  onOk,
  onCancel,
  initialValues = {},
  checkIsValidAddition,
  isEdit = false,
}) {
  const { userConfig } = useAuth();
  const formRef = React.useRef();

  React.useEffect(() => {
    formRef.current?.setFieldsValue(initialValues);
  }, [open, initialValues]);

  const onSubmit = () => {
    formRef.current?.submit();
  };

  const onFinish = (value) => {
    if (checkIsValidAddition(value)) {
      onOk(value, isEdit);
    }
  };

  const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={isEdit ? "Edit" : "Add"}
      open={open}
      onOk={onSubmit}
      onCancel={_onCancel}
      okText={isEdit ? "Save" : "Add"}
    >
      <Form
        name='admin'
        ref={formRef}
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Form.Item
          label='First Name'
          name='firstName'
          rules={[
            { required: true, message: "Enter First Name" },
            { pattern: "^[a-zA-Z]*$", message: "Only Alphabetic Allowed" },
            {
              min: 3,
              message: "Minimum length must be 3 characters",
            },
            {
              max: 90,
              message: "Maximum length allowed is 90 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Last Name'
          name='lastName'
          rules={[
            { required: true, message: "Enter Last Name" },
            { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },
            {
              min: 3,
              message: "Minimum length must be 3 characters",
            },
            {
              max: 90,
              message: "Maximum length allowed is 90 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Role' name='role' hidden={true}>
          <Select disabled>
            <Option value='admin'>Admin</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label='User ID'
          name='userid'
          rules={[
            { required: true, message: "Enter User ID" },
            {
              min: 3,
              message: "Minimum length must be 3 characters",
            },
            {
              max: 90,
              message: "Maximum length allowed is 90 characters",
            },
            {
              pattern: /^[a-zA-Z0-9]+$/,
              message: "Enter Alpha Numeric Only",
            },
          ]}
          getValueFromEvent={(e) => e.target.value.toUpperCase()}
        >
          <Input autoComplete='off' />
        </Form.Item>

        <Form.Item
          label='Password'
          name='password'
          rules={[
            { required: true, message: "Enter Password" },
            {
              pattern: "^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$",
              message:
                "Must contain minimum eight characters, atleast one number and one special character",
            },
          ]}
        >
          <Input.Password maxLength={20} autoComplete='new-password' />
        </Form.Item>

        <Form.Item
          label='Email'
          name='emailId'
          rules={[
            { required: true, message: "Enter Email" },
            { type: "email", message: "Enter valid Email" },
            // {
            //   validator: (_, value) =>
            //     orgEmailValidator(value, userConfig.orgId),
            // },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
