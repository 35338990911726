import React from 'react';
import { useStore } from '../../store';
import './index.css';
import { Space, Spin } from 'antd';

const LoadingSpinner = () => {
  const {
    state: { isLoader },
  } = useStore();

  return isLoader ? (
    <>
      <div style={{ position: 'absolute', width: '100vw', height: '100vh' }}>
        {/* Your content goes here */}

        {/* Spin component */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
          }}
        >
          <Spin size='large' />
        </div>
      </div>
    </>
  ) : (
    ''
  );
};
export default LoadingSpinner;
