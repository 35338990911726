import React, { useState } from 'react';
import { Steps, notification } from 'antd';
import Personaldetails from '../components/PermanentEmployeeOnboard/Personeldetails';
import Jobdetails from '../components/PermanentEmployeeOnboard/Jobdetails';
import Wagedetails from '../components/PermanentEmployeeOnboard/Wagedetails';
import Uploaddocument from '../components/PermanentEmployeeOnboard/Uploaddocument';
import SummarygenerationUpdate from '../components/PermanentEmployeeOnboard/Summarygenerationupdate';
import { getExcelData } from '../services/auth';
import useAuth from '../hooks/useAuth';
import dayjs from 'dayjs';

import axios from '../services/axiosInstance';
import { useParams } from 'react-router-dom';

const ORG_INFO_DEFAULT = {
  firstName: '',
  lastName: '',
  mobile: '',
  aadharNo: '',
  district: '',
  fatherName: '',
  martialStatus: '',
  emergencyContactName: '',
  emergencyContactNo: '',
  uan: '',
};

// const ORG_JOB_DEFAULT = {
//   firstName:"",
//   lastName:"",
//   mobile:"",
//   aadharNo:"",
//   district:"",

// };

const PermanentEmployeeEdit = () => {
  const { user, userConfig, logout } = useAuth();
  const orgId = userConfig?.orgId;
  const [currentPageNo, setCurrentPageNo] = React.useState(0);
  // const [personalDetails, setpersonalDetails] = React.useState(ORG_INFO_DEFAULT);
  const [personalDetails, setpersonalDetails] = React.useState();
  const [masterData, setMasterData] = React.useState([]);

  const [jobDetails, setjobDetails] = React.useState();
  const [wageDetails, setwageDetails] = React.useState();
  const [uploadDocument, setuploadDocument] = React.useState();
  //  const [locationId,setlocationId]=React.useState("TOPGUN001_DAL")
  const [Summary, setSummary] = React.useState();
  const [imageSrc, setImageSrc] = React.useState(null);

  const data = {
    // personalDetails:personalDetails,
    // jobDetails: jobDetails,
    // wageDetails:wageDetails,
    // uploadDocument:uploadDocument,
    // locationId:locationId
  };

  //Api Call By Id
  const { id } = useParams();

  React.useEffect(() => {
    OrganizationInfo();
    getExcel();
  }, []);

  const OrganizationInfo = async () => {
    const response = await axios.get(`pass/details?id=${id}`);
    console.log('response is', response);
    const Normaldata = response.data.emp.personalDetails;
    const jdata = response.data.emp.jobDetails;
    const wdata = response.data.emp.wageDetails;
    const ddata = response.data.emp.uploadDocument;
    const status = response.data.emp.status;
    const orgId = response.data.emp.orgId;
    const approvedBy = response.data.emp.approvedBy;
    const approvalStage = response.data.emp.approvalStage;
    const autoApproved = response.data.emp.autoApproved;

    setpersonalDetails({
      ...Normaldata,
      autoApproved: response?.data?.emp?.autoApproved,
      aadharNo: response?.data?.emp?.aadhar[0]?.maskedAadhaarNumber || '',
      autoApproved,
      status,
      orgId: orgId,
      id: id,
      approvedBy,
      //  dob:new Date(Normaldata.dob),
      dob: Normaldata.dob ? dayjs(new Date(Normaldata.dob)) : '',
      // child:Normaldata?.children?.
      Dob: Normaldata.Dob ? dayjs(new Date(Normaldata.Dob)) : '',
      dobFather: Normaldata.dobFather
        ? dayjs(new Date(Normaldata.dobFather))
        : '',
      yearsInGujarath: Normaldata.yearsInGujarath
        ? dayjs(new Date(Normaldata.yearsInGujarath))
        : '',
      dobMother: Normaldata.dobMother
        ? dayjs(new Date(Normaldata.dobMother))
        : '',

      dobOFSpouse: Normaldata?.dobOFSpouse
        ? dayjs(new Date(Normaldata?.dobOFSpouse))
        : '',
      effDate: Normaldata?.effDate ? dayjs(new Date(Normaldata?.effDate)) : '',
      dobOFMarriage: Normaldata?.dobOFMarriage
        ? dayjs(new Date(Normaldata?.dobOFMarriage))
        : '',

      isFetchedFromAadhar: response?.data?.emp?.isFetchedFromAadhar,
      approvalStage,
      birthDate: Normaldata?.birthDate
        ? dayjs(new Date(Normaldata?.birthDate))
        : '',
      birthDate1: Normaldata?.birthDate1
        ? dayjs(new Date(Normaldata?.birthDate1))
        : '',
      birthDate2: Normaldata?.birthDate2
        ? dayjs(new Date(Normaldata?.birthDate2))
        : '',
      birthDate3: Normaldata?.birthDate3
        ? dayjs(new Date(Normaldata?.birthDate3))
        : '',
      birthDate4: Normaldata?.birthDate4
        ? dayjs(new Date(Normaldata?.birthDate4))
        : '',
      birthDate5: Normaldata?.birthDate5
        ? dayjs(new Date(Normaldata?.birthDate5))
        : '',
      effDate: Normaldata?.effDate ? dayjs(new Date(Normaldata?.effDate)) : '',
      effDate1: Normaldata?.effDate1
        ? dayjs(new Date(Normaldata?.birthDate))
        : '',
      effDate1: Normaldata?.effDate1
        ? dayjs(new Date(Normaldata?.effDate1))
        : '',
      effDate2: Normaldata?.effDate2
        ? dayjs(new Date(Normaldata?.effDate2))
        : '',
      effDate3: Normaldata?.effDate3
        ? dayjs(new Date(Normaldata?.effDate3))
        : '',
      effDate4: Normaldata?.effDate4
        ? dayjs(new Date(Normaldata?.effDate4))
        : '',
      effDate5: Normaldata?.effDate5
        ? dayjs(new Date(Normaldata?.effDate5))
        : '',
      addressRevisedDate: Normaldata?.addressRevisedDate
        ? dayjs(new Date(Normaldata?.addressRevisedDate))
        : '',

      statusTerminateNoticeDate: Normaldata?.statusTerminateNoticeDate
        ? dayjs(new Date(Normaldata?.statusTerminateNoticeDate))
        : '',

      statusFromDate: Normaldata?.statusFromDate
        ? dayjs(new Date(Normaldata?.statusFromDate))
        : '',

      statusToDate: Normaldata?.statusToDate
        ? dayjs(new Date(Normaldata.statusToDate))
        : '',

      children: Normaldata?.children || [],
    });

    setjobDetails({
      ...jdata,
      status,
      autoApproved,
      doj: jdata?.doj ? dayjs(jdata?.doj, 'YYYY-MM-DD') : '',
      dateofStartingProbation: jdata?.dateofStartingProbation
        ? dayjs(jdata?.dateofStartingProbation, 'YYYY-MM-DD')
        : '',
      effDate: jdata?.effDate ? dayjs(jdata?.effDate, 'YYYY-MM-DD') : '',
      dateofConfirmation: jdata?.dateofConfirmation
        ? dayjs(jdata?.dateofConfirmation, 'YYYY-MM-DD')
        : '',
      lastPromotion: jdata?.lastPromotion
        ? dayjs(jdata?.lastPromotion, 'YYYY-MM-DD')
        : '',
      appointmentRefDate: jdata?.appointmentRefDate
        ? dayjs(jdata?.appointmentRefDate, 'YYYY-MM-DD')
        : '',
      dateOfEvent15: jdata?.dateOfEvent15
        ? dayjs(jdata?.dateOfEvent15, 'YYYY-MM-DD')
        : '',
      dateOfEvent30: jdata?.dateOfEvent30
        ? dayjs(jdata?.dateOfEvent30, 'YYYY-MM-DD')
        : '',
      dateOfEvent35: jdata?.dateOfEvent35
        ? dayjs(jdata?.dateOfEvent35, 'YYYY-MM-DD')
        : '',
      dateOfEvent20: jdata?.dateOfEvent20
        ? dayjs(jdata?.dateOfEvent20, 'YYYY-MM-DD')
        : '',
      dateOfEvent25: jdata?.dateOfEvent25
        ? dayjs(jdata?.dateOfEvent25, 'YYYY-MM-DD')
        : '',
      longServiceAward15: jdata?.longServiceAward15
        ? dayjs(jdata?.longServiceAward15, 'YYYY-MM-DD')
        : '',
      longServiceAward30: jdata?.longServiceAward30
        ? dayjs(jdata?.longServiceAward30, 'YYYY-MM-DD')
        : '',
      longServiceAward35: jdata?.longServiceAward35
        ? dayjs(jdata?.longServiceAward35, 'YYYY-MM-DD')
        : '',
      longServiceAward20: jdata?.longServiceAward20
        ? dayjs(jdata?.longServiceAward20, 'YYYY-MM-DD')
        : '',
      longServiceAward25: jdata?.longServiceAward25
        ? dayjs(jdata?.longServiceAward25, 'YYYY-MM-DD')
        : '',
      dateofRetiremenet: jdata?.dateofRetiremenet
        ? dayjs(jdata?.dateofRetiremenet, 'YYYY-MM-DD')
        : '',
      transferFromDate: jdata?.transferFromDate
        ? dayjs(jdata?.transferFromDate, 'YYYY-MM-DD')
        : '',
      transferToDate: jdata?.transferToDate
        ? dayjs(jdata?.transferToDate, 'YYYY-MM-DD')
        : '',
      lastDateOfEmployement: jdata?.lastDateOfEmployement
        ? dayjs(jdata?.lastDateOfEmployement, 'YYYY-MM-DD')
        : '',
      eventHistory: jdata?.eventHistory || [],
      approvalStage,
    });
    setwageDetails({
      ...wdata,
      //  effectiveDate:new Date(wdata.effectiveDate)
      effectiveDate: wdata.effectiveDate
        ? dayjs(wdata.effectiveDate, 'YYYY-MM-DD')
        : '',
      approvalStage,
    });
    setuploadDocument({
      ...ddata,
      annualMedicalCheckupDate: ddata.annualMedicalCheckupDate
        ? dayjs(new Date(ddata.annualMedicalCheckupDate))
        : '',

      approvalStage,
    });
    setSummary({});
  };

  function submit() {
    // Approved(data).then((res) => {
    //   notification.success({ message: res.data.message });
    // }).catch((err) => {
    //   notification.error({ message: err.response.data.message });
    // });;
  }

  const getExcel = async () => {
    getExcelData(orgId)
      .then((res) => {
        console.log('response', res);
        setMasterData(res.data.data.data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const pageItems = [
    {
      key: 'personneldetails',
      title: 'Personal Details',
    },
    {
      key: 'jobdetails',
      title: 'Job Details',
    },
    {
      key: 'wagedetails',
      title: 'Wage Details',
    },
    {
      key: 'uploaddocument',
      title: 'Upload Document',
    },
    {
      key: 'summary',
      title: 'Summary Generation',
    },
  ];
  const next = (values) => {
    if (currentPageNo === 0) {
      setpersonalDetails((currData) => ({ ...currData, ...values }));
    }

    if (currentPageNo === 1) {
      setjobDetails((currData) => ({ ...currData, ...values }));
    }
    if (currentPageNo === 2) {
      setwageDetails(values);
    }
    if (currentPageNo === 3) {
      setuploadDocument(values);
    }
    if (currentPageNo === 4) {
      setSummary(values);

      // submit the data
      submit(values);
      return;
    }
    // setlocationId(values)

    setCurrentPageNo(currentPageNo + 1);
  };

  const prev = (values) => {
    if (currentPageNo === 1) {
      setjobDetails(values);
    }
    if (currentPageNo === 2) {
      setwageDetails(values);
    }
    if (currentPageNo === 3) {
      setuploadDocument(values);
    }
    if (currentPageNo === 4) {
      setSummary(values);
    }

    setCurrentPageNo(currentPageNo - 1);
  };

  return (
    <div>
      <h2>Workmen Details</h2> <br />
      <br />
      <Steps current={currentPageNo} items={pageItems} /> <br />
      <br />
      {currentPageNo === 0 && (
        <Personaldetails
          initialValues={personalDetails}
          onNext={next}
          isViewOnly={true}
          setImageSrc={setImageSrc}
          imageSrc={imageSrc}
          masterData={masterData}
        />
      )}
      {currentPageNo === 1 && (
        <Jobdetails
          initialValues={jobDetails}
          onNext={next}
          onPrev={prev}
          isViewOnly={true}
          masterData={masterData}
        />
      )}
      {currentPageNo === 2 && (
        <Wagedetails
          initialValues={wageDetails}
          onNext={next}
          onPrev={prev}
          isViewOnly={true}
        />
      )}
      {currentPageNo === 3 && (
        <Uploaddocument
          initialValues={uploadDocument}
          onNext={next}
          onPrev={prev}
          isViewOnly={true}
          personalDetails={personalDetails}
        />
      )}
      {currentPageNo === 4 && (
        <SummarygenerationUpdate
          initialValues={personalDetails}
          onNext={next}
          onPrev={prev}
          jobDetails={jobDetails}
          wageDetails={wageDetails}
          imageSrc={imageSrc}
          uploadDocument={uploadDocument}
        />
      )}
    </div>
  );
};

export default PermanentEmployeeEdit;
