import React from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Select,
  notification,
  Space,
  Typography,
} from 'antd';
import {
  LockOutlined,
  UserOutlined,
  ApartmentOutlined,
  LoginOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { forgotPassword } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import './index.css';

const { Link, Text } = Typography;

const { Option } = Select;

export default function Forgotpassword({ open, onCancel, isEdit = false }) {
  const formRef = React.useRef();
  const navigate = useNavigate();

  const onSubmit = () => {
    formRef.current?.submit();
  };

  const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };

  const onFinish = (value) => {
    forgotPassword({
      emailId: value.emailId,
      orgId: value.orgId,
    })
      .then((res) => {
        notification.success({ message: res.data.message });
        onCancel();
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
  };

  return (
    <Col xs={20} md={8} style={{ padding: '6px' }}>
      <Card
        title='Forgot Password'
        bordered={false}
        className='login-card'
        id='Title'>
        <Text className='text-center'>
          Enter your email and we will send you an email to reset your password.
        </Text>
        <Form name='login' initialValues={{ emailId: '' }} onFinish={onFinish}>
          <Form.Item
            // label='Email'
            name='emailId'
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email address.',
              },
              { required: true, message: 'Email is required!' },
            ]}>
            <Input placeholder='abc@gmail.com' allowClear />
          </Form.Item>
          <Form.Item
            // label='Email'
            name='orgId'
            rules={[
              {
                type: 'text',
                message: 'Please enter a valid orgId.',
              },
              { required: true, message: 'orgId is required!' },
            ]}>
            <Input placeholder='orgId' allowClear />
          </Form.Item>
          <div className='action'>
            <Form.Item>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>{' '}
            </Form.Item>

            <Link
              onClick={(e) => {
                e.preventDefault();
                onCancel();
              }}>
              <LeftOutlined /> Back To Login
            </Link>
          </div>
        </Form>
      </Card>
    </Col>
  );
}
