import React, { useState } from 'react';
import { Table, Space, Typography, Modal } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import ReportsModalContent from './ReportsModalContent';

const ContractorReport = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  function handleView(record) {
    setSelectedReport(record);
    setModalVisible(true);
  }
  const dataSource = [
    {
      key: '1',
      siNo: '1',
      name: 'Worker Detail Master',
    },
    {
      key: '2',
      siNo: '2',

      name: 'Contractor Wise Staff Report',
    },
    {
      key: '3',
      siNo: '3',
      name: 'Contractor Master Report',
    },
    {
      key: '4',
      siNo: '4',

      name: 'State Wise Male-Female Report',
    },
    // {
    //   key: '5',
    //   siNo: '5',
    //   name: 'Designation Wise Report',
    // },
    // {
    //   key: '6',
    //   siNo: '6',

    //   name: 'Finger Duplication Report',
    // },
    {
      key: '5',
      siNo: '5',
      name: 'EPN Status Report',
    },
    {
      key: '6',
      siNo: '6',

      name: 'State Wise Contract Labour Strength Chart Report',
    },
    {
      key: '7',
      siNo: '7',
      name: 'Category Wise Contract Labour Strength Chart Report',
    },
    {
      key: '8',
      siNo: '8',

      name: 'Area Wise Contract Labour Strength Chart Report',
    },
    {
      key: '9',
      siNo: '9',
      name: 'Contract Labour Age Group Chart Report',
    },
    {
      key: '10',
      siNo: '10',

      name: 'Contractors Nature of Work Chart Report',
    },
    // {
    //   key: '1',
    //   siNo: '1',
    //   name: 'Mike',
    // },
    {
      key: '11',
      siNo: '11',

      name: 'Deactivated Labour Report',
    },
    {
      key: '12',
      siNo: '12',
      name: 'Labour History Report',
    },
    {
      key: '13',
      siNo: '13',

      name: 'Worker Hibernate Report',
    },
  ];

  const columns = [
    {
      title: 'SI.No',
      dataIndex: 'siNo',
      key: 'siNo',
    },
    {
      title: 'Name Of Reports',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      dataIndex: 'key',
      render: (key) => (
        <Space>
          <Typography onClick={() => handleView(dataSource.find(item => item.key === key))}>
            <EyeFilled />
          </Typography>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table dataSource={dataSource} columns={columns} />
      <Modal
        title={selectedReport ? selectedReport.name : ''}
        visible={modalVisible}
        width='50%'
        onCancel={() => setModalVisible(false)}
        footer={null}>
        <ReportsModalContent selectedReport={selectedReport} />
      </Modal>
    </div>
  );
};

export default ContractorReport;
