import React from 'react';
import {
  LockOutlined,
  UserOutlined,
  ApartmentOutlined,
  LoginOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Card, Row, Col, Typography, Image } from 'antd';
import { Navigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import useAuth from '../hooks/useAuth';
import Forgotpassword from '../components/Forgotpassword/Forgotpassword';
import Changepassword1 from '../Changepassword/Changepassword1';
import updated from '../assets/images/updated.png';
import axios from 'axios';
import moment from 'moment';
import pako from 'pako';
import { v4 as uuidv4 } from 'uuid';

import { ssoLogin } from '../services/auth';

export default function Login() {
  const { user, userConfig, isUserLoading, login } = useAuth();
  const [isCaptchaVerified, setIsCaptchaVerified] = React.useState(false);
  const [forgotPasswordOpen, setforgotPasswordOpen] = React.useState(false);
  const [changePasswordOpen, setchangePasswordOpen] = React.useState(false);
  const [useridvalue, setuseridvalue] = React.useState();

  const onFinish = (values) => {
    setuseridvalue(values.userid);

    var userType = 'New';
    userType === 'Active' ? setchangePasswordOpen(true) : login(values);
  };

  const openAdminModal = () => {
    setforgotPasswordOpen(true);
  };

  const handleSSOLogin = () => {
    console.log('Redirecting to SSO login page...');
    // window.location.href = "http://localhost:3000/auth/saml/login"
    //  window.location.href = "https://demo.wikiworks.in/api/auth/saml/login"
    window.location.href = 'https://vyn.wikiworks.in/api/auth/saml/login';
  };

  if (user.isAuthenticated && !user?.userConfig?.isPasswordChanged) {
    return <Navigate to='/home/change-password' />;
  } else if (user.isAuthenticated) {
    return <Navigate to={userConfig.nav} />;
  }

  //Copy Paste Password
  const handleNewPasswordPaste = (e) => {
    e.preventDefault();
  };

  return (
    <Row style={{ height: '100vh' }} justify='center' align='middle'>
      <Col
        // xs={24}
        // md={12}
        xs={28}
        md={16}
        style={{
          height: '100vh',

          // backgroundImage: `url("https://www.fundoodata.com/learning-center/wp-content/uploads/background-companies-4-2.jpg")`,
          backgroundImage: `url("https://www.smartkarrot.com/wp-content/uploads/2020/12/Digital-Onboarding.jpg")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          // background:{wikimove}
          // backgroundAttachment: "fixed"
        }}>
        {/* <Col>
          <Image
            src={updated}
            preview={false}
            style={{ height: "70px", marginTop: "20px", marginLeft: "420px",color:'white',width:'250px'  }}
          />
        </Col>
        <Col className="Onboard">
          <Typography.Text className="text">
            Labour onboarding made Instant, Simple and Secure
          </Typography.Text>
        </Col> */}
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            src={updated}
            preview={false}
            style={{
              height: '70px',
              marginTop: '20px',
              color: 'white',
              width: '250px',
            }}
          />
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Text className='text'>
            Labour onboarding made Instant, Simple and Verified
          </Typography.Text>
        </Col>

        <h4
          style={{
            textAlign: 'center',
            // color: "#ade8f4",
            color: 'white',
            bottom: 0,
            position: 'absolute',
            width: '100%',
            left: 60,
          }}>
          WIKIWORKS (c) 2023 COPYRIGHT & PATENT{' '}
        </h4>
      </Col>
      {forgotPasswordOpen ? (
        <Forgotpassword onCancel={() => setforgotPasswordOpen(false)} />
      ) : (
        <Col xs={20} md={8} style={{ padding: '6px' }}>
          <Card
            title=' Login to VYN Account '
            bordered={false}
            className='login-card'
            id='Title'>
            <Form
              name='login'
              initialValues={{ userid: '', password: '', orgId: '' }}
              onFinish={onFinish}>
              <Form.Item
                name='userid'
                rules={[
                  { required: true, message: 'Please input your User Id!' },
                ]}
                hasFeedback>
                <Input
                  prefix={<UserOutlined />}
                  placeholder='UserId'
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name='password'
                rules={[
                  { required: true, message: 'Please input your Password!' },
                ]}
                hasFeedback>
                <Input.Password
                  prefix={<LockOutlined />}
                  type='password'
                  placeholder='Password'
                  onPaste={handleNewPasswordPaste}
                />
              </Form.Item>
              <Form.Item
                name='orgId'
                rules={[
                  {
                    required: true,
                    message: 'Please input your Organisation Id!',
                  },
                ]}
                hasFeedback>
                <Input
                  prefix={<ApartmentOutlined />}
                  placeholder='Organisation Id'
                  allowClear
                />
              </Form.Item>

              <Button
                type='dashed'
                onClick={openAdminModal}
                style={{ marginBottom: '15px' }}>
                Forgot Password
              </Button>

              {/* <Forgotpassword
                open={forgotPasswordOpen}
                onCancel={() => setforgotPasswordOpen(false)}
              /> */}
              {/* <Button type="dashed" onClick={openAdminModal} style={{marginBottom:"15px",marginLeft:'20px'}}>
              Change Password
            </Button> */}

              {/* <ReCAPTCHA
              style={{ marginBottom: "24px", color: "white" }}
              sitekey="6Lc9-9gjAAAAAMaLl0SwCT3s19nW788aTGUJX3r3"
              onChange={() => setIsCaptchaVerified(true)}
            />  */}

              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  // disabled={!isCaptchaVerified}
                  loading={isUserLoading}
                  icon={<LoginOutlined />}
                  // onClick={openAdminModal1}
                >
                  Log in
                </Button>
              </Form.Item>

              <Form.Item>
                <Button
                  type='dashed'
                  onClick={handleSSOLogin}
                  style={{ marginBottom: '15px' }}>
                  Login with SSO
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      )}
    </Row>
  );
}

// https://images.pexels.com/photos/14149319/pexels-photo-14149319.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load
