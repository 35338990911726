import React, { useEffect, useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { YearlyPointsColumns, beforeUpload } from './Constant';
import { saveAs } from 'file-saver';

import {
  yearlyPoints,
  getyearlyPoints,
  downloadYearlyPoints,
} from '../services/auth';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import {
  Form,
  Space,
  Row,
  Modal,
  Col,
  notification,
  Button,
  Divider,
  Input,
  Table,
  Typography,
  Tooltip,
  Upload,
} from 'antd';

const YearlyPoints = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value?.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const YearlyPointsColumns = [
    {
      title: 'Name',
      dataIndex: 'workmenName',
      key: 'workmenName',
      ...getColumnSearchProps('workmenName'),
      defaultSortOrder: 'descend', // Reverse the default sorting order
    },
    {
      title: 'PS No.',
      dataIndex: 'psNumber',
      key: 'psNumber',
      ...getColumnSearchProps('psNumber'),
    },
    {
      title: 'PTS 02',
      dataIndex: 'pts02',
      key: 'pts02',
    },
    {
      title: 'PTS 03',
      dataIndex: 'pts03',
      key: 'pts03',
    },
    {
      title: 'PTS 04',
      dataIndex: 'pts04',
      key: 'pts04',
    },
    {
      title: 'PTS 05',
      dataIndex: 'pts05',
      key: 'pts05',
    },
    {
      title: 'PTS 06',
      dataIndex: 'pts06',
      key: 'pts06',
    },
    {
      title: 'PTS 07',
      dataIndex: 'pts07',
      key: 'pts07',
    },
    {
      title: 'PTS 08',
      dataIndex: 'pts08',
      key: 'pts08',
    },
    {
      title: 'PTS 09',
      dataIndex: 'pts09',
      key: 'pts09',
    },
    {
      title: 'PTS 10',
      dataIndex: 'pts10',
      key: 'pts10',
    },
    {
      title: 'PTS 11',
      dataIndex: 'pts11',
      key: 'pts11',
    },
    {
      title: 'PTS 12',
      dataIndex: 'pts12',
      key: 'pts12',
    },
    {
      title: 'PTS 13',
      dataIndex: 'pts13',
      key: 'pts13',
    },
    {
      title: 'PTS 14',
      dataIndex: 'pts14',
      key: 'pts14',
    },
    {
      title: 'PTS 15',
      dataIndex: 'pts15',
      key: 'pts15',
    },
    {
      title: 'PTS 16',
      dataIndex: 'pts16',
      key: 'pts16',
    },
    {
      title: 'PTS 17',
      dataIndex: 'pts17',
      key: 'pts17',
    },
    {
      title: 'PTS 18',
      dataIndex: 'pts18',
      key: 'pts18',
    },
    {
      title: 'PTS 19',
      dataIndex: 'pts19',
      key: 'pts19',
    },
    {
      title: 'PTS 20',
      dataIndex: 'pts20',
      key: 'pts20',
    },
    {
      title: 'PTS 21',
      dataIndex: 'pts21',
      key: 'pts21',
    },
    {
      title: 'PTS 22',
      dataIndex: 'pts22',
      key: 'pts22',
    },
    {
      title: 'PTS 23',
      dataIndex: 'pts23',
      key: 'pts23',
    },
  ];
  const { userConfig } = useAuth();
  const navigate = useNavigate();

  const [LongService, setLongService] = React.useState([]);

  useEffect(() => {
    GetLongserviceList();
  }, []);
  const GetLongserviceList = () => {
    getyearlyPoints()
      .then((res) => {
        console.log('Get Employee List Response is', res);
        setLongService(
          res.data.longServicesList.map((row) => ({
            _id: row._id,
            workmenName: row.name,
            psNumber: row.psno,
            pts02: row.pts02,
            pts03: row.pts03,
            pts04: row.pts04,
            pts05: row.pts05,
            pts06: row.pts06,
            pts07: row.pts07,
            pts08: row.pts08,
            pts09: row.pts09,
            pts10: row.pts10,
            pts11: row.pts11,
            pts12: row.pts12,
            pts13: row.pts13,
            pts14: row.pts14,
            pts15: row.pts15,
            pts16: row.pts16,
            pts17: row.pts17,
            pts18: row.pts18,
            pts19: row.pts19,
            pts20: row.pts20,
            pts21: row.pts21,
            pts22: row.pts22,
            pts23: row.pts23,
            pts23: row.pts23,
            pts24: row.pts24,
            pts23: row.pts23,
            pts24: row.pts24,
          }))
        );
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const { Title } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {};

  const handleCancel = () => {
    // Handle the Cancel button click if needed
    setIsModalVisible(false);
  };

  const customRequest = ({ file, onSuccess }) => {
    onSuccess(file);
  };

  const beforeUpload = (file) => {
    console.log(userConfig.orgId);

    console.log('inside bfore upload');
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Access the second sheet (index 1) by its name
      const secondSheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(secondSheet);

      const allData = [];

      sheetData.forEach((item) => {
        item.orgId = userConfig.orgId;
        allData.push(item);
      });

      // Now you can send allData to the longService function
      yearlyPoints(allData)
        .then((res) => {
          notification.success({ message: res.data.message });
          window.location.reload();
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
          navigate('/home/approver/historical');
        });
      // window.location.reload();
    };

    reader.readAsArrayBuffer(file);
    return false; // Prevent default Upload behavior
  };
  const onFinish = (value) => {
    console.log('value is', value);
    value.orgId = userConfig.orgId;

    yearlyPoints(value)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
        navigate('/home/approver/historical/yearlyPoints');
      });
    window.location.reload();

    setIsModalVisible(false);
  };
  const downloadExcel = () => {
    const data = {
      reportType: 'all',
    };
    let responseType, fileExtension, contentType;

    responseType = 'blob';
    fileExtension = 'xlsx';
    contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    downloadYearlyPoints(data, responseType)
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `Yearly Points`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDownload = async () => {
    try {
      // const response = await fetch(
      //   'http://localhost:3000/api/downloadyearlypoints-excel',
      //   {
      // const response = await fetch(
      //   'https://vyn.wikiworks.in:443/api/downloadyearlypoints-excel',
      //   {
      const response = await fetch(
        // 'https://demo.wikiworks.in:443/api/downloadyearlypoints-excel',
        `${process.env.REACT_APP_API_URL}/downloadyearlypoints-excel`,
        {
          method: 'GET',
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, 'Yearly_Points_Template.xlsx');
      } else {
        console.error('Failed to download Excel sheet');
      }
    } catch (error) {
      console.error('Failed to download Excel sheet', error);
    }
  };

  return (
    <>
      <Title level={4} style={{ textAlign: 'center' }}>
        Yearly Points
      </Title>
      <Form layout='vertical'>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title='Download Sample Template '>
            <Button
              className='mr2 HeaderButtonColor'
              style={{ marginRight: '10px' }}
              onClick={handleDownload}
              disabled={userConfig.role === 'shop'}>
              Download Template
            </Button>
          </Tooltip>
          <Tooltip title='Import '>
            <Upload
              customRequest={customRequest}
              style={{ marginRight: '10px' }}
              beforeUpload={beforeUpload}
              showUploadList={false}>
              <Button
                // type='primary'
                className='mr2 HeaderButtonColor'
                style={{ marginRight: '10px' }}
                disabled={userConfig.role === 'shop'}>
                Import
              </Button>
            </Upload>
          </Tooltip>

          <Tooltip title='Export'>
            <Button
              className='mr2 HeaderButtonColor'
              style={{ marginRight: '10px' }}
              // type='primary'
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}
              onClick={downloadExcel}
              disabled={userConfig.role === 'shop'}>
              Export
            </Button>
          </Tooltip>
          <Tooltip title='Add New'>
            <Button
              className='mr2 HeaderButtonColor'
              type='primary'
              onClick={showModal}
              hidden={true}
              disabled={userConfig.role === 'shop'}>
              Add New
            </Button>
          </Tooltip>
          <Modal
            title='Yearly Points'
            open={isModalVisible}
            width='50%'
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}>
            <Form onFinish={onFinish} layout='vertical'>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label='Workman Name' name='workmanname'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='PS Number' name='psnumber'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='PTS 2023' name='pts23'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='PTS 2024' name='pts24'>
                    <Input></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ justifyContent: 'center' }}>
                  <Form.Item>
                    <Button htmlType='submit' type='primary'>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>

        <Divider></Divider>
        <Table
          className='TableDesign'
          bordered
          columns={YearlyPointsColumns}
          dataSource={LongService}
          scroll={{ x: 1500 }}
        />
      </Form>
    </>
  );
};

export default YearlyPoints;
