import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Input,
  Select,
  Col,
  Form,
  Typography,
  Button,
  notification,
} from "antd";
import * as SupervisorServices from "../../services/supervisor";
import * as SupplierServices from "../../services/supplier";

const { Option } = Select;
const { Title } = Typography;

export const ReplaceContractor = ({
  open,
  onCancel,
  initialValues = {},
  contractorsList = [],
}) => {
  const [supervisorList, setSupervisorList] = useState({
    current: [],
    replace: [],
  });

  const [replaceContractors, setReplaceContractors] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState("");

  console.log("value of contractor", contractorsList);
  const [selectedSupervisor, setSelectedSupervisor] = useState({
    current: "",
    replace: "",
  });

  const formRef = React.useRef();
  const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };
  const getSuperVisorList = (contractorId, status) => {
    setSelectedSupervisor({ ...selectedSupervisor, replace: "" });
    formRef.current?.setFieldValue("supervisorName", "");
    SupervisorServices.getSuperVisorListForContractor(contractorId).then(
      (res) => {
        console.log("value of response", res?.data?.supervisorList);
        setSupervisorList({
          ...supervisorList,
          [status]: res?.data?.supervisorList || [],
        });
      }
    );
  };
  React.useEffect(() => {
    formRef.current?.setFieldsValue(initialValues);
    console.log("value of initiala", initialValues);

    const replaceContractorsList = contractorsList.filter(
      (contractor) => contractor.id !== initialValues.id
    );
    setReplaceContractors(replaceContractorsList);
    getSuperVisorList(initialValues.id, "current");
  }, [initialValues]);

  const handleContractorChange = (e) => {
    setSelectedContractor(e);
    getSuperVisorList(e, "replace");
  };

  const onFinish = () => {
    if (
      !initialValues.id ||
      !selectedSupervisor.current ||
      !selectedContractor ||
      !selectedSupervisor.replace
    ) {
      return 0;
    }
    let payload = {
      contractorId: initialValues.id,
      supervisorId: selectedSupervisor.current,
      replaceContractorId: selectedContractor,
      replaceSupervisorId: selectedSupervisor.replace,
    };
    SupplierServices.replaceSupplier(payload).then((res) => {
      notification.success({ message: "Contractor replaced Succesfully" });
      onCancel();
    });
  };

  // const footer = () => {
  //   return [
  //     <Button key='back' onClick={onCancel}>
  //       cancel
  //     </Button>,
  //     <Button key='submit' type='primary' onClick={replaceContractor}>
  //       Replace
  //     </Button>,
  //   ];
  // };

  return (
    <>
      <Modal
        open={open}
        onCancel={_onCancel}
        title='Replace Contractors'
        // footer={footer()}
        footer={null}
      >
        <Form
          layout='vertical'
          initialValues={initialValues}
          ref={formRef}
          onFinish={onFinish}
        >
          <h3>Current Contractor</h3>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label='Contractor Name'
                name='name'
                rules={[{ required: true, message: "Select Name " }]}
              >
                <Input placeholder='Name' disabled></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Supervisor Name'
                name='contractorName'
                rules={[{ required: true, message: "Select Supervisor Name" }]}
              >
                <Select
                  defaultValue={selectedSupervisor.current}
                  onChange={(e) => {
                    console.log("change value", e);
                    setSelectedSupervisor({
                      ...selectedSupervisor,
                      current: e,
                    });
                  }}
                >
                  {supervisorList.current.map((supervisor) => {
                    return (
                      <Option value={supervisor.userid}>
                        {supervisor.firstName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <h3>Replace Contractor</h3>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label='Contractor Name'
                name='contractorname'
                rules={[{ required: true, message: "Select Contractor Name " }]}
              >
                <Select
                  onChange={handleContractorChange}
                  defaultValue={selectedContractor}
                >
                  {console.log("replaceContractors", replaceContractors)}
                  {replaceContractors.map((contractor) => {
                    return (
                      <Option value={contractor.id}>{contractor.name}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Supervisor Name'
                name='supervisorName'
                rules={[{ required: true, message: "Select Supervisor Name " }]}
              >
                <Select
                  defaultValue={selectedSupervisor.replace}
                  onChange={(e) => {
                    console.log("change value", e);
                    setSelectedSupervisor({
                      ...selectedSupervisor,
                      replace: e,
                    });
                  }}
                >
                  {supervisorList.replace.map((supervisor) => {
                    return (
                      <Option value={supervisor.userid}>
                        {supervisor.firstName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Form.Item style={{ marginLeft: "10px" }}>
                  <Button key='back' onClick={onCancel}>
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item style={{ marginLeft: "10px" }}>
                  <Button key='submit' type='primary' htmlType='submit'>
                    Replace
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ReplaceContractor;
