import React, { useEffect, useState, useRef } from 'react';
import { DesciplinaryActionsColumns, beforeUpload } from './Constant';
import useAuth from '../hooks/useAuth';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {
  desciplinaryAction,
  getdesciplinaryAction,
  downloadDesciplinayAction,
} from '../services/auth';

import {
  Form,
  Row,
  DatePicker,
  Space,
  Input,
  Modal,
  notification,
  Col,
  Button,
  Divider,
  Table,
  Typography,
  Tooltip,
  Upload,
} from 'antd';

const DesciplinaryActions = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const DesciplinaryActionsColumns = [
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
    },
    {
      title: 'Name Of Employee',
      dataIndex: 'workmenName',
      key: 'workmenName',
      ...getColumnSearchProps('workmenName'),
    },
    {
      title: 'PS No.',
      dataIndex: 'psNumber',
      key: 'psNumber',
      ...getColumnSearchProps('psNumber'),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Details Of Misconduct',
      dataIndex: 'detailsofmisconduct',
      key: 'detailsofmisconduct',
    },
    {
      title: 'Incident Date From',
      dataIndex: 'incidentdate',
      key: 'incidentdate',
    },
    {
      title: 'Incident Date To',
      dataIndex: 'incidentdateTo',
      key: 'incidentdateTo',
    },
    {
      title: 'Report Received',
      dataIndex: 'reportreceived',
      key: 'reportreceived',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: 'Disciplinary Actions',
      dataIndex: 'disciplinaryactions',
      key: 'disciplinaryactions',
    },
    {
      title: 'Date of Action Taken',
      dataIndex: 'dateofactionstaken',
      key: 'dateofactionstaken',
    },
    {
      title: 'Issued Date',
      dataIndex: 'issueddate',
      key: 'issueddate',
    },
    {
      title: 'Remark Note',
      dataIndex: 'remarknote',
      key: 'remarknote',
    },
    {
      title: 'Shift',
      // dataIndex: 'remarks',
      // key: 'remarks20',
    },
  ];
  const navigate = useNavigate();

  const { userConfig } = useAuth();
  const [LongService, setLongService] = React.useState([]);

  useEffect(() => {
    GetLongserviceList();
  }, []);
  const GetLongserviceList = () => {
    getdesciplinaryAction()
      .then((res) => {
        console.log('Get Employee List Response is', res);
        setLongService(
          res.data.longServicesList.map((row) => ({
            _id: row._id,
            remark: row['remark(text)'],

            workmenName: row.nameofemployee,
            psNumber: row.psno,
            detailsofmisconduct: row.detailsofmisconduct,
            // incidentDate: row.incidentdate,
            incidentdateTo: row.__empty,
            incidentdate: row.incidentdate,
            department: row.department,
            reportreceived: row.reportreceived,
            year: row.year,
            month: row.month,
            disciplinaryactions: row.disciplinaryactions,
            dateofactionstaken: row.dateofactionstaken,
            // issuedDate: row.issueddate,
            issueddate: row.issueddate,

            remarknote: row.remarknote,
          }))
        );
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const { Title } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [jsonData, setJsonData] = useState([]);
  // const [tableData, setTableData] = useState([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {};

  const handleCancel = () => {
    // Handle the Cancel button click if needed
    setIsModalVisible(false);
  };

  const customRequest = ({ file, onSuccess }) => {
    onSuccess(file);
  };

  const beforeUpload = (file) => {
    console.log(userConfig.orgId);

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Access the second sheet (index 1) by its name
      const secondSheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(secondSheet);

      const allData = [];

      sheetData.forEach((item) => {
        item.orgId = userConfig.orgId;
        allData.push(item);
      });

      // Now you can send allData to the longService function
      desciplinaryAction(allData)
        .then((res) => {
          notification.success({ message: res.data.message });
          window.location.reload();
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
          navigate('/home/approver/historical/desciplinaryActions');
        });
      // window.location.reload();
    };
    // window.location.reload();

    reader.readAsArrayBuffer(file);
    return false; // Prevent default Upload behavior
  };

  const onFinish = (value) => {
    console.log('value is', value);
    value.orgId = userConfig.orgId;

    desciplinaryAction(value)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
        navigate('/home/approver/historical/desciplinaryActions');
      });
    window.location.reload();

    setIsModalVisible(false);
  };
  const downloadExcel = () => {
    const data = {
      reportType: 'all',
    };
    let responseType, fileExtension, contentType;

    responseType = 'blob';
    fileExtension = 'xlsx';
    contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    downloadDesciplinayAction(data, responseType)
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `disciplinary Action`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDownload = async () => {
    try {
      // const response = await fetch(
      //   'http://localhost:3000/api/downloaddisciplinary-excel',
      //   {
      // const response = await fetch(
      //   'https://vyn.wikiworks.in:443/api/downloaddisciplinary-excel',
      //   {
      const response = await fetch(
        // 'https://demo.wikiworks.in:443/api/downloaddisciplinary-excel',
        `${process.env.REACT_APP_API_URL}/downloaddisciplinary-excel`,
        {
          method: 'GET',
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, 'Disciplinary_Action_Template.xlsx');
      } else {
        console.error('Failed to download Excel sheet');
      }
    } catch (error) {
      console.error('Failed to download Excel sheet', error);
    }
  };

  return (
    <>
      <Title level={4} style={{ textAlign: 'center' }}>
        Disciplinary Actions
      </Title>
      <Form layout='vertical'>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title='Download Sample Template '>
            <Button
              className='mr2 HeaderButtonColor'
              disabled={userConfig.role === 'shop'}
              style={{ marginRight: '10px' }}
              onClick={handleDownload}>
              Download Template
            </Button>
          </Tooltip>
          <Tooltip title='Import '>
            <Upload
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}>
              <Button
                className='mr2 HeaderButtonColor'
                style={{ marginRight: '10px' }}
                disabled={userConfig.role === 'shop'}>
                Import
              </Button>
            </Upload>
          </Tooltip>

          <Tooltip title='Export'>
            <Button
              className='mr2 HeaderButtonColor'
              style={{ marginRight: '10px' }}
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}
              onClick={downloadExcel}
              disabled={userConfig.role === 'shop'}>
              Export
            </Button>
          </Tooltip>
          <Tooltip title='Add New'>
            <Button
              className='mr2 HeaderButtonColor'
              onClick={showModal}
              type='primary'
              disabled={userConfig.role === 'shop'}
              hidden={true}>
              Add New
            </Button>
          </Tooltip>
          <Modal
            title='Disciplinary Actions'
            open={isModalVisible}
            width='50%'
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}>
            <Form onFinish={onFinish} layout='vertical'>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label='Workman Name' name='workmanname'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='PS Number' name='psnumber'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='Details Of Misconduct	'
                    name='detailsofmisconduct'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Incident Date	' name='incidentdate'>
                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Report Received	' name='reportreceived'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Year' name='year'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Month' name='month'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='Disciplinary Actions	'
                    name='disciplinaryactions'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Actions Taken	' name='dateofactionstaken'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Issued date	' name='issueddate'>
                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='Remark Note
'
                    name='remarknote'>
                    <Input></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ justifyContent: 'center' }}>
                  <Form.Item>
                    <Button htmlType='submit' type='primary'>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>

        <Divider></Divider>
        <Table
          className='TableDesign'
          bordered
          columns={DesciplinaryActionsColumns}
          dataSource={LongService}
          scroll={{ x: 1500 }}
        />
      </Form>
    </>
  );
};

export default DesciplinaryActions;
