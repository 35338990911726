import React from 'react';
import {
  Button,
  Form,
  notification,
  Popconfirm,
  Space,
  Table,
  Typography,
} from 'antd';
import { PlusOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import SupervisorFormModal from './SupervisorFormModal';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { Email } from '../../services/auth';
import axios from '../../services/axiosInstance';

const DEFAULT_ADMIN_FORM_VALUE = {
  firstName: '',
  lastName: '',
  role: 'supervisor',
  roleName: '',
  supplierId: '',
  unitId: '',
  location: '',
  userid: '',
  password: '',
  emailId: '',
};

export default function OrganizationSupervisorForm({
  initialValues,
  locationsData,
  onNext,
  onPrev,
}) {
  const [data, setData] = React.useState(initialValues);
  const { userConfig } = useAuth();
  const roleName = userConfig?.roleName;
  console.log('data is', data);
  console.log('data is', locationsData);
  const [formInitialValue, setFormInitialValue] = React.useState(
    DEFAULT_ADMIN_FORM_VALUE
  );
  const formRef = React.useRef();
  const navigate = useNavigate();

  const [editIndex, setEditIndex] = React.useState(null);
  // const isEdit = editIndex !== null;
  const [isEdit, setIsEdit] = React.useState(false);
  const [users, setUsers] = React.useState(false);

  const [adminFormOpen, setAdminFormOpen] = React.useState(false);

  React.useEffect(() => {
    EmployeeList();
    // formRef.current?.setFieldsValue(initialValues);
  }, []);

  async function EmployeeList() {
    try {
      const response = await axios.get('/supplier');
      const responseData = response.data.suppliers;
      console.log('responseData', responseData);
      setUsers(responseData || []); // Use an empty array as fallback if responseData is undefined
    } catch (error) {
      console.error('Error fetching employee list:', error);
    }
  }
  console.log('user', users);

  const tableData = React.useMemo(() => {
    return data?.map((userData, index) => ({
      index: index,
      name: userData.firstName + ' ' + userData.lastName,
      userid: userData.userid,
      role: userData.role[0].toUpperCase() + userData.role.slice(1),
      supplierId: Array.isArray(users)
        ? users
            .filter((resData) =>
              resData.addJobDetails.some(
                (contractorCodeId) =>
                  contractorCodeId.id === userData.supplierId
              )
            )
            .map((resData) => resData.name)
        : [],
      // supplierId: userData?.supplierId?.toUpperCase() + userData?.supplierId?.slice(1),
      location: userData.location,
    }));
  }, [data, users]);
  const adminData = tableData.filter((item) => item.role === 'Supervisor');

  const dataSource = userConfig?.location
    ? adminData.filter((item) => {
        const isAdmin = item.role === 'Supervisor';
        const isMatchingLocation = item.location === userConfig.location;
        return isAdmin && isMatchingLocation;
      })
    : adminData;

  const openAdminModal = () => {
    setIsEdit(false);
    setFormInitialValue(DEFAULT_ADMIN_FORM_VALUE);
    setAdminFormOpen(true);
  };

  const addAdmin = (value, isEdit = false) => {
    if (isEdit) {
      setData((currData) => {
        return currData?.map((d, index) => {
          if (index === editIndex) {
            return value;
          }

          return d;
        });
      });

      setEditIndex(null);
    } else {
      setData((currData) => [value]);
    }
    setAdminFormOpen(false);
  };

  const edit = (record) => {
    setIsEdit(true);

    setEditIndex(record.index);
    setFormInitialValue(
      data.find((d) => d.userid === record.userid) || DEFAULT_ADMIN_FORM_VALUE
    );
    setAdminFormOpen(true);
  };

  const deleteRecord = (record) => {
    setData(data?.filter((_, i) => record.index !== i));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      editable: true,
    },
    {
      title: 'User ID',
      dataIndex: 'userid',
      width: '20%',
      editable: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: '20%',
      editable: true,
    },
    {
      title: 'ContractorAgency Name',
      dataIndex: 'supplierId',
      width: '20%',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '20%',

      render: (_, record) => {
        return (
          <Space>
            <Typography.Link
              disabled={
                userConfig.roleName && !roleName?.permissions?.Org_Setup?.edit
              }
              onClick={() => edit(record)}>
              <EditFilled />
              Edit
            </Typography.Link>
            {/* <Popconfirm title='Delete' onConfirm={() => deleteRecord(record)}>
              <Typography.Link disabled={userConfig.roleName && !roleName?.Principal_Employer?.edit}>
                <DeleteFilled />
                Delete
              </Typography.Link>
            </Popconfirm>  */}
          </Space>
        );
      },
    },
  ];

  const checkIsValidAddition = (userData = {}) => {
    if (userData) {
      if (
        data?.filter(
          (user) =>
            user.userid !== formInitialValue.userid &&
            user.userid === userData.userid
        ).length > 0
      ) {
        notification.error({ message: 'User ID already exists' });
        return false;
      }

      if (
        data?.filter(
          (user) =>
            user.emailId !== formInitialValue.emailId &&
            user.emailId === userData.emailId
        ).length > 0
      ) {
        notification.error({ message: 'Email ID already exists' });
        return false;
      }

      return true;
    }
    return false;
  };

  const Title = () => {
    return (
      <Form.Item>
        <Button
          type='dashed'
          onClick={openAdminModal}
          block
          disabled={
            userConfig.roleName && !roleName?.permissions?.Org_Setup?.create
          }
          icon={<PlusOutlined />}>
          Add Supervisor
        </Button>
      </Form.Item>
    );
  };

  const onFinish = (values) => {
    navigate('/home/admin');

    console.log('Values of Admin Modal is values ', data);
    // console.log('Values of Admin Modal is data', data);

    onNext(data);
  };

  return (
    <Space
      direction='vertical'
      style={{ margin: '22px 12px', display: 'flex' }}>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        title={Title}
        rowKey='userid'
      />
      <Form initialValues={initialValues} ref={formRef} onFinish={onFinish}>
        <SupervisorFormModal
          open={adminFormOpen}
          onOk={addAdmin}
          onCancel={() => setAdminFormOpen(false)}
          checkIsValidAddition={checkIsValidAddition}
          initialValues={formInitialValue}
          isEdit={isEdit}
          locationsData={locationsData}
        />

        <Space style={{ width: '100%', justifyContent: 'end' }}>
          <Button onClick={() => onPrev(data)}>Prev</Button>
          <Form.Item noStyle>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Space>
  );
}
