import React from "react";
import Changepassword1 from "../../Changepassword/Changepassword1";

const ChangePasswordPage = () => {
  return (
    <>
      <Changepassword1 />
    </>
  );
};

export default ChangePasswordPage;
