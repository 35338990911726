import React from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useNavigate,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';

import Login from './routes/Login';
import OrganisationList from './routes/OrganisationList';
import OrganisationOnboard from './routes/OrganisationOnboard';
import ContractorList from './routes/ContractorList';
import EmployeeList from './routes/EmployeeList';
import EmployeeOnboard from './routes/EmployeeOnboard';
import ContractorOnboard from './routes/ContractorOnboard';
import HomeLayout from './layouts/HomeLayout';
import ProtectedRoute from './layouts/ProtectedRoute';
import GlobalContextLayout from './layouts/GlobalContextLayout';
import Approver1 from './routes/Approver1';
import EmployeeEdit from './routes/EmployeeEdit';
import OrganisationEdit from './routes/OrganisationEdit';
import Medical from './components/Approver2/Medical';
import './style.css';
import useAuth from './hooks/useAuth';
import Safety from './components/Approver2/Safety';
import Pfreport from './compliance/Pfreport';
import Esireport from './compliance/Esireport';
import Quickonboarding from './quickonboarding/quickonboarding';
import Togglequickonboarding from './Setting/Togglequickonboarding';
import Webcamera from './routes/Webcamera';
import Dashboard from './compliance/Dashboard';
import PermanentEmployeeList from './routes/PermanentEmployeeList';
import Reportsafety from './components/IncidentReport/Reportsafety';
import Ermanage from './components/IncidentReport/Ermanage';
import Safetymanage from './components/IncidentReport/Safetymanage';
import ChangePasswordPage from './views/AuthPage/changePassword';
import ResetPasswordPage from './views/AuthPage/resetPassword';
import CWFMPAGE from './views/AuthPage/cwfm';
import Permission from './permission/permission';
import Contractor from './routes/Contractor';
import DesciplinaryActions from './historicalReview/DesciplinaryActions';
import EmployeeGrowth from './historicalReview/EmployeeGrowth';
import EmployeeMaster from './historicalReview/EmployeeMaster';
import LongService from './historicalReview/LongService';
import YearlyLeaves from './historicalReview/YearlyLeaves';
import YearlyPoints from './historicalReview/YearlyPoints';
import PermanentEmployeeOnboard from './routes/PermanentEmployeeOnboard';
import PermanentEmployeeEdit from './routes/PermanentEmployeeEdit';
import BulkUpload from './components/PermanentEmployeeOnboard/BulkUpload';
import Audit from './audit/audit';
import LicenceManagement from './LicenceManagement';
import ContractorReport from './components/Reports/ContractorReport';
import Regular from './components/Reports/Regular';
import ContractorSummary from './components/ContractorOnboard/ContractorSummary';
import ContractorApprovalPage from './routes/ContractorApprovalPage';
import SamlResponse from './routes/SamlResponse';
const RedirectToNav = () => {
  const { user, userConfig, tokenInfo } = useAuth();
  const navigate = useNavigate();

  if (!user.isAuthenticated) {
    navigate('/login');
  }

  return <Navigate to={userConfig.nav} />;
};

const router = createBrowserRouter([
  {
    path: '/saml/response/:token?',
    element: <SamlResponse />,
  },
 
  {
    element: <GlobalContextLayout />,
    children: [
      {
        path: '/',
        element: <ProtectedRoute />,
        children: [
          // {
          //   path: '',
          //   element: <RedirectToNav />,
          // },
          {
            path: 'home',
            element: <HomeLayout />,
            children: [
              //organization Onboard
              {
                path: 'sa',
                element: <OrganisationList />,
              },
              {
                path: 'sa/onboard',
                element: <OrganisationOnboard />,
              },
              {
                path: 'sa/licence-management',
                element: <LicenceManagement />,
              },
              {
                path: 'sa/org/:id',
                element: <OrganisationEdit />,
              },

             // Supervisor Onboard
              {
                path: 'admin',
                element: <Contractor />,
                // element: {ContractorComponent},
              },
              {
                path: 'change-password',
                element: <ChangePasswordPage />,
              },
              {
                path: 'admin/organisation',
                element: <ContractorOnboard />,
                // element: PrincipalEmployerComponent,
              },

              {
                path: 'admin/compliance/pf-report',
                element: <Pfreport />,
              },
              {
                path: 'admin/compliance/esi-report',
                element: <Esireport />,
              },
              {
                path: 'admin/compliance/dashboard',
                element: <Dashboard />,
              },
              {
                path: 'admin/setting',
                element: <Togglequickonboarding />,
              },
              {
                path: 'admin/audit',
                element: <Audit />,
              },
              {
                path: 'admin/audit',
                element: <ContractorSummary />,
              },
              {
                path: 'admin/permission',
                element: <Permission />,
              },
              //Employee Onboard
              {
                path: 'supervisor/employee',
                element: <EmployeeList />,
              },
              // {
              //   path: "/supervisor/employee/qeo",
              //   element: <Quickonboarding />,
              // },
              {
                path: 'supervisor',
                element: <EmployeeOnboard />,
              },
              // {
              //   path: 'supervisor',
              //   element: <PermanentEmployeeOnboard />,
              // },
              {
                path: 'supervisor/qeo',
                element: <Quickonboarding />,
              },
              {
                path: 'supervisor/bulk',
                element: <Webcamera />,
              },
              // {
              //   path: 'supervisor/bulk',
              //   element: <BulkUpload />,
              // },
              {
                path: 'supervisor/employee/:id',
                element: <EmployeeEdit />,
              },
              // {
              //   path: 'supervisor/employee/:id',
              //   element: <PermanentEmployeeEdit />,
              // },
              {
                path: 'supervisor/incidentreport',
                element: <Reportsafety />,
              },
              //Approver
              {
                path: 'approver/list',
                element: <Approver1 />,
              },
              {
                path: 'approver/employee/:id',
                element: <EmployeeEdit />,
              },
              {
                path: 'approver/employee/:id/medical',
                element: <Medical />,
              },
              {
                path: 'approver/employee/:id/safety',
                element: <Safety />,
              },
              //INCIDENT REPORT
              {
                path: 'approver/incidentreport',
                element: <Reportsafety />,
              },
              {
                path: 'approver/incidentreport/:id',
                element: <Ermanage />,
              },
              {
                path: 'supervisor/incidentreport/:id',
                element: <Ermanage />,
              },
              {
                path: 'approver/incidentreport/:id/safety',
                element: <Safetymanage />,
              },
              // {
              //   path: 'approver/contractorApproval',
              //   element: <Contractor />,
              // },
              {
                path: 'approver/contractorApproval',
                element: <ContractorApprovalPage />,
              },
              {
                path: 'approver/report/contractor',
                element: <ContractorReport />,
              },

              //Historical Review
              {
                path: 'approver/historical/longService',
                element: <LongService />,
              },
              {
                path: 'approver/historical/employeeMaster',
                element: <EmployeeMaster />,
              },
              {
                path: 'approver/historical/yearlyPoints',
                element: <YearlyPoints />,
              },
              {
                path: 'approver/historical/desciplinaryActions',
                element: <DesciplinaryActions />,
              },
              {
                path: 'approver/historical/yearlyLeaves',
                element: <YearlyLeaves />,
              },
              {
                path: 'approver/historical/employeeGrowth',
                element: <EmployeeGrowth />,
              },
              //shop
              {
                path: 'shop/historical/longService',
                element: <LongService />,
              },
              {
                path: 'shop/historical/employeeMaster',
                element: <EmployeeMaster />,
              },
              {
                path: 'shop/historical/yearlyPoints',
                element: <YearlyPoints />,
              },
              {
                path: 'shop/historical/desciplinaryActions',
                element: <DesciplinaryActions />,
              },
              {
                path: 'shop/historical/yearlyLeaves',
                element: <YearlyLeaves />,
              },
              {
                path: 'shop/historical/employeeGrowth',
                element: <EmployeeGrowth />,
              },
              //IR
              {
                path: 'ir/employee',
                element: <PermanentEmployeeList />,
              },

              {
                path: 'ir',
                element: <PermanentEmployeeOnboard />,
              },
              // {
              //   path: 'ir/qeo',
              //   element: <Quickonboarding />,
              // },
              {
                path: 'ir/bulk',
                element: <BulkUpload />,
              },

              {
                path: 'ir/employee/:id',
                element: <PermanentEmployeeEdit />,
              },

              {
                path: 'ir/historical/longService',
                element: <LongService />,
              },
              {
                path: 'ir/historical/employeeMaster',
                element: <EmployeeMaster />,
              },
              {
                path: 'ir/historical/yearlyPoints',
                element: <YearlyPoints />,
              },
              {
                path: 'ir/historical/desciplinaryActions',
                element: <DesciplinaryActions />,
              },
              {
                path: 'ir/historical/yearlyLeaves',
                element: <YearlyLeaves />,
              },
              {
                path: 'ir/historical/employeeGrowth',
                element: <EmployeeGrowth />,
              },
              {
                path: 'ir/report/regular',
                element: <Regular />,
              },

              //contractor
              {
                path: 'contractor',
                element: <Contractor />,
              },
            ],
          },
        ],
      },
      {
        path: '/cwfm',
        element: <CWFMPAGE />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      { path: '/reset-password', element: <ResetPasswordPage /> },
    ],
  },
]);

function App() {
  const { user, userConfig, tokenInfo } = useAuth();
 
  return (
    <ConfigProvider locale={enUS}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
