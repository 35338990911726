import React, { useEffect, useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { YearlyLeavesColumns, beforeUpload } from './Constant';
import useAuth from '../hooks/useAuth';
import { saveAs } from 'file-saver';

import {
  yearlyLeaves,
  getyearlyLeaves,
  downloadYearlyLeaves,
} from '../services/auth';
import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { setLoader } from '../actions';

import {
  Form,
  Row,
  Modal,
  Space,
  Input,
  Col,
  Button,
  notification,
  Divider,
  Table,
  Typography,
  Tooltip,
  Upload,
} from 'antd';

const YearlyLeaves = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const YearlyLeavesColumns = [
    {
      title: 'Workman Name',
      dataIndex: 'workmenName',
      key: 'workmenName',
      ...getColumnSearchProps('workmenName'),
    },
    {
      title: 'PS No.',
      dataIndex: 'psNumber',
      key: 'psNumber',
      ...getColumnSearchProps('psNumber'),
    },
    {
      title: 'TL 02',
      dataIndex: 'tl02',
      key: 'tl02',
    },
    {
      title: 'TL 03',
      dataIndex: 'tl03',
      key: 'tl03',
    },
    {
      title: 'TL 04',
      dataIndex: 'tl04',
      key: 'tl04',
    },
    {
      title: 'TL 05',
      dataIndex: 'tl05',
      key: 'tl05',
    },
    {
      title: 'TL 06',
      dataIndex: 'tl06',
      key: 'tl06',
    },
    {
      title: 'TL 07',
      dataIndex: 'tl07',
      key: 'tl22',
    },
    {
      title: 'TL 08',
      dataIndex: 'tl08',
      key: 'tl08',
    },
    {
      title: 'TL 09',
      dataIndex: 'tl09',
      key: 'tl09',
    },
    {
      title: 'TL 10',
      dataIndex: 'tl10',
      key: 'tl10',
    },
    {
      title: 'TL 11',
      dataIndex: 'tl11',
      key: 'tl11',
    },
    {
      title: 'TL 12',
      dataIndex: 'tl12',
      key: 'tl12',
    },
    {
      title: 'TL 13',
      dataIndex: 'tl13',
      key: 'tl13',
    },
    {
      title: 'TL 14',
      dataIndex: 'tl14',
      key: 'tl14',
    },
    {
      title: 'TL 15',
      dataIndex: 'tl15',
      key: 'tl15',
    },
    {
      title: 'TL 16',
      dataIndex: 'tl16',
      key: 'tl16',
    },
    {
      title: 'TL 17',
      dataIndex: 'tl17',
      key: 'tl17',
    },
    {
      title: 'TL 18',
      dataIndex: 'tl18',
      key: 'tl18',
    },
    {
      title: 'TL 19',
      dataIndex: 'tl19',
      key: 'tl19',
    },
    {
      title: 'TL 20',
      dataIndex: 'tl20',
      key: 'tl20',
    },
    {
      title: 'TL 21',
      dataIndex: 'tl21',
      key: 'tl21',
    },
    {
      title: 'TL 22',
      dataIndex: 'tl22',
      key: 'tl22',
    },
  ];
  const { userConfig } = useAuth();
  const [LongService, setLongService] = React.useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    GetLongserviceList();
  }, []);
  const GetLongserviceList = () => {
    getyearlyLeaves()
      .then((res) => {
        console.log('Get Employee List Response is', res);
        setLongService(
          res.data.longServicesList.map((row) => ({
            _id: row._id,
            workmenName: row.name,
            psNumber: row.psno,
            tl02: row.tl02,
            tl03: row.tl03,
            tl04: row.tl04,
            tl05: row.tl05,
            tl06: row.tl06,

            tl07: row.tl07,
            tl08: row.tl08,
            tl09: row.tl09,
            tl10: row.tl10,
            tl11: row.tl11,
            tl12: row.tl12,
            tl13: row.tl13,

            tl14: row.tl14,
            tl15: row.tl15,
            tl16: row.tl16,
            tl17: row.tl17,
            tl18: row.tl18,
            tl19: row.tl19,
            tl20: row.tl20,

            tl21: row.tl21,
            tl22: row.tl22,
          }))
        );
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const { Title } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {};

  const handleCancel = () => {
    // Handle the Cancel button click if needed
    setIsModalVisible(false);
  };

  const customRequest = ({ file, onSuccess }) => {
    onSuccess(file);
  };

  const beforeUpload = (file) => {
    console.log(userConfig.orgId);

    console.log('inside bfore upload');
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Access the second sheet (index 1) by its name
      const secondSheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(secondSheet);

      const allData = [];

      sheetData.forEach((item) => {
        item.orgId = userConfig.orgId;
        allData.push(item);
      });

      // Now you can send allData to the longService function
      yearlyLeaves(allData)
        .then((res) => {
          notification.success({ message: res.data.message });
          window.location.reload();
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
        });
      navigate('/home/approver/historical/yearlyLeaves');
    };
    // window.location.reload();

    reader.readAsArrayBuffer(file);
    return false; // Prevent default Upload behavior
  };

  const onFinish = (value) => {
    // setLoader(true);

    value.orgId = userConfig.orgId;

    yearlyLeaves(value)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
        navigate('/home/approver/historical/yearlyLeaves');
      });

    window.location.reload();

    setIsModalVisible(false);
  };
  const downloadExcel = () => {
    const data = {
      reportType: 'all',
    };
    let responseType, fileExtension, contentType;

    responseType = 'blob';
    fileExtension = 'xlsx';
    contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    downloadYearlyLeaves(data, responseType)
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `Yearly Leaves`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDownload = async () => {
    try {
      // const response = await fetch(
      //   'http://localhost:3000/api/downloadyearlyleaves-excel',
      //   {
      // const response = await fetch(
      //   'https://vyn.wikiworks.in:443/api/downloadyearlyleaves-excel',
      //   {
      const response = await fetch(
        // 'https://demo.wikiworks.in:443/api/downloadyearlyleaves-excel',
        `${process.env.REACT_APP_API_URL}/downloadyearlyleaves-excel`,
        {
          method: 'GET',
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, 'Yearly_Leaves_Template.xlsx');
      } else {
        console.error('Failed to download Excel sheet');
      }
    } catch (error) {
      console.error('Failed to download Excel sheet', error);
    }
  };

  return (
    <>
      <Title level={4} style={{ textAlign: 'center' }}>
        Yearly Leaves
      </Title>
      <Form layout='vertical'>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title='Download Sample Template '>
            <Button
              className='mr2 HeaderButtonColor'
              style={{ marginRight: '10px' }}
              onClick={handleDownload}
              disabled={userConfig.role === 'shop'}>
              Download Template
            </Button>
          </Tooltip>
          <Tooltip title='Import '>
            <Upload
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}>
              <Button
                // type='primary'
                className='mr2 HeaderButtonColor'
                style={{ marginRight: '10px' }}
                disabled={userConfig.role === 'shop'}>
                Import
              </Button>
            </Upload>
          </Tooltip>

          <Tooltip title='Export'>
            <Button
              className='mr2 HeaderButtonColor'
              style={{ marginRight: '10px' }}
              // type='primary'
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}
              onClick={downloadExcel}
              disabled={userConfig.role === 'shop'}>
              Export
            </Button>
          </Tooltip>
          <Tooltip title='Add New'>
            <Button
              className='mr2 HeaderButtonColor'
              type='primary'
              onClick={showModal}
              hidden={true}
              disabled={userConfig.role === 'shop'}>
              Add New
            </Button>
          </Tooltip>
          <Modal
            title='Long Service'
            open={isModalVisible}
            width='50%'
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}>
            <Form onFinish={onFinish} layout='vertical'>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label='Workman Name' name='workmanname'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='PS Number' name='psnumber'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='A' name='a'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='CL 2022		' name='cl22'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='SL 2022	' name='sl22'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='LOP 2022	' name='lop22'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='UA 2022	' name='uaa22'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='PL 2022	' name='pl22'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='ACL 2022
'
                    name='acl22'>
                    <Input></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ justifyContent: 'center' }}>
                  <Form.Item>
                    <Button htmlType='submit' type='primary'>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>

        <Divider></Divider>
        <Table
          className='TableDesign'
          bordered
          columns={YearlyLeavesColumns}
          dataSource={LongService}
          scroll={{ x: 1500 }}
        />
      </Form>
    </>
  );
};

export default YearlyLeaves;
