import React, { useState, useEffect } from "react";
import {
  Col,
  Typography,
  Row,
  Form,
  Button,
  Input,
  Select,
  Modal,
  Upload,
  DatePicker,
  notification,
  Cascader,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { updatePass } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { Email } from "../../services/auth";
import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router-dom";
import axios from "../../services/axiosInstance";
import dayjs, { Dayjs } from "dayjs";
import { invalid } from "moment/moment";

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const Ermanage = () => {
  const { user, userConfig } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [Employee, setEmployee] = useState([]);
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm(); // Create a form instance using Form.useForm()
  const [recommendation, setRecommendation] = useState(""); // Define the recommendation state

  //upload
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  //Preview
  const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewOpen, setPreviewOpen] = useState(true);

  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewOpen(false);

  // const onFinish = (values) => {

  //   const passDetails = {
  //     status: values.recommendation,
  //   };

  //   // Updating the status property inside the feedback object

  //   updatePass(id, passDetails)
  //     .then((res) => {
  //       notification.success({ message: "Record updated Successfully" });
  //     })
  //     .catch((err) => {
  //       notification.error({ message: err.message });
  //     });
  //   navigate("/home/approver/incidentreport");
  // };

  //Get Manage Incident Report
  useEffect(() => {
    getEmpList();
  }, []);

  const getEmpList = async () => {
    const response = await axios.get(`pass/details?id=${id}`);
    const incidentcategory =
      response.data.emp.incidentReport[0].incidentcategory;
    const severity = response.data.emp.incidentReport[0].severity;
    const recommendation = response.data.emp.incidentReport[0].recommendation;
    const feedback = response.data.emp.incidentReport[0].feedback.message;
    const date = response.data.emp.incidentReport[0].date;
    const terminationDate = response.data.emp.incidentReport[0].terminationDate;

    const suspendRange = response.data.emp.incidentReport[0].suspendRange;
    const firstSuspendRange =
      suspendRange && suspendRange.length > 0 ? suspendRange[0] : null;

    const firstSuspendRange1 = dayjs(firstSuspendRange, "YYYY-MM-DD");

    const uploadDocument = response.data.emp.incidentReport[0].uploadDocument;

    const employeeData = {
      incidentcategory,
      severity,
      recommendation,
      feedback,
      date: dayjs(date, "YYYY-MM-DD"),
      uploadDocument,
    };
    if (firstSuspendRange) {
      employeeData.suspendRange = dayjs(firstSuspendRange, "YYYY-MM-DD");
    }
    if (terminationDate) {
      employeeData.terminationDate = dayjs(terminationDate, "YYYY-MM-DD");
    }

    setEmployee(employeeData);
    form.setFieldsValue(employeeData);

    //automatically removes
    const today = dayjs();

    //For TerminationDate

    // if (
    //   (dayjs(terminationDate, "YYYY-MM-DD").isSame(today, "day") ||
    //     dayjs(terminationDate, "YYYY-MM-DD").isBefore(today, "day")) &&
    //   userConfig.role === "supervisor"
    // ) {
    //   const passDetails = {
    //     status: recommendation,
    //   };

    //   // Updating the status property inside the feedback object
    //   updatePass(id, passDetails)
    //     .then((res) => {
    //       notification.success({ message: "Record updated Successfully" });
    //     })
    //     .catch((err) => {
    //       notification.error({ message: err.message });
    //     });
    //   navigate("/home/approver/incidentreport");
    // }

    // //For  Suspend Range
    // if (
    //   (dayjs(firstSuspendRange, "YYYY-MM-DD").isSame(today, "day") ||
    //     dayjs(firstSuspendRange, "YYYY-MM-DD").isBefore(today, "day")) &&
    //   userConfig.role === "supervisor"
    // ) {
    //   const passDetails = {
    //     status: recommendation,
    //   };

    //   // Updating the status property inside the feedback object
    //   updatePass(id, passDetails)
    //     .then((res) => {
    //       notification.success({ message: "Record updated Successfully" });
    //     })
    //     .catch((err) => {
    //       notification.error({ message: err.message });
    //     });
    //   navigate("/home/approver/incidentreport");
    // }
  };

  //Onfinish Function
  const onFinish = (Values) => {
    console.log("i am getting called ", Values);
    const today = dayjs();

    if (Employee.terminationDate) {
      // For Termination
      const isTodayOrBefore =
        Employee.terminationDate.isSame(today, "day") ||
        (Employee.terminationDate.isBefore(today, "day") &&
          userConfig.role === "supervisor");

      let passDetailsTermination;
      if (isTodayOrBefore) {
        passDetailsTermination = {
          status: Values.recommendation,
        };
        if (Employee.recommendation === "terminated") {
          passDetailsTermination.status = "Terminated"; // Apply your specific status value for "terminated" recommendation
        }
      } else {
        passDetailsTermination = {
          status: "ActiontakenforIncident",
        };
      }

      console.log("before update pass");
      // Updating the status property inside the feedback object for Termination
      updatePass(id, passDetailsTermination)
        .then((res) => {
          console.log("in respionse", res);
          notification.success({
            message: "Termination Record updated Successfully",
          });
          navigate("/home/approver/incidentreport"); // You may or may not navigate here based on your requirement
        })
        .catch((err) => {
          notification.error({ message: err.message });
        });
    }

    if (Employee.suspendRange) {
      // For SuspendRange
      const isTodayOrBefore1 =
        Employee.suspendRange.isSame(today, "day") ||
        (Employee.suspendRange.isBefore(today, "day") &&
          userConfig.role === "supervisor");

      let passDetailsSuspend;
      if (isTodayOrBefore1) {
        passDetailsSuspend = {
          status: Values.recommendation,
        };
        if (Employee.recommendation === "suspend") {
          passDetailsSuspend.status = "Suspended"; // Apply your specific status value for "suspend" recommendation
        }
      } else {
        passDetailsSuspend = {
          status: "ActiontakenforIncident",
        };
      }

      // Updating the status property inside the feedback object for SuspendRange
      updatePass(id, passDetailsSuspend)
        .then((res) => {
          notification.success({
            message: "SuspendRange Record updated Successfully",
          });
          navigate("/home/approver/incidentreport"); // You may or may not navigate here based on your requirement
        })
        .catch((err) => {
          notification.error({ message: err.message });
        });
    }
  };

  //for reject
  const Rejectfunction = () => {
    const data = {
      rejectedstatus: {
        status: "rejected",
        statusTimestamp: new Date().toLocaleString(),
      },
    };

    updatePass(id, data)
      .then((res) => {
        notification.success({ message: "Incident Report Rejected" });
      })
      .catch((err) => {
        // notification.error({ message: "Employee Approved Successfully" });
      });
    navigate("/home/approver/incidentreport");
  };

  //Action Taken
  const Actiontaken = () => {
    const passDetails = {
      status: "pendingwithSupervisor",
    };

    // Updating the status property inside the feedback object

    updatePass(id, passDetails)
      .then((res) => {
        notification.success({ message: "Record updated Successfully" });
      })
      .catch((err) => {
        notification.error({ message: err.message });
      });
    navigate("/home/approver/incidentreport");
  };
  return (
    <>
      <Title
        level={4}
        style={{
          textAlign: "center",

          fontWeight: "bolder",
        }}
      >
        Report Incident
      </Title>
      <Form layout='vertical' onFinish={onFinish} form={form}>
        <Row>
          <Col span={8}>
            <Form.Item
              name='incidentcategory'
              label='Incident Category'
              rules={[{ required: true, message: "Enter Incident Category" }]}
              initialValue={Employee.incidentcategory}
            >
              <Select size='large' style={{ width: "50%" }} disabled>
                <Option value=''></Option>

                <Option value='accident'>Accident</Option>
                <Option value='inventorymismanagement'>
                  Inventory mismanagement
                </Option>
                <Option value='theft'>Theft</Option>
                <Option value='procedural'>Procedural Negligence</Option>
                <Option value='misconduct'>Misconduct</Option>
                <Option value='fraud'>Fraud</Option>
                <Option value='insubordination'>Insubordination</Option>
                <Option value='behaviourialincident'>
                  Behaviourial Incident
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name='severity'
              label='Severity'
              rules={[{ required: true, message: "Enter Severity" }]}
            >
              <Select size='large' style={{ width: "50%" }} disabled>
                <Option value=''></Option>\ <Option value='major'>MAJOR</Option>
                <Option value='minor'>MINOR</Option>
                <Option value='others'>Others</Option>
                {/* <Option value="warning">WARNING</Option> */}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label='Date'
              name='date'
              rules={[
                // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                { required: true, message: "Select Date" },
              ]}
            >
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                size='large'
                style={{ width: "50%" }}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col span={8}>
            <Form.Item
              label='Upload Document'
              name='uploadDocument'
              valuePropName='fileList'
              getValueFromEvent={normFile}
            >
              <Upload
                listType='picture'
                accept='image/*'
                maxCount={4}
                beforeUpload={() => false}
                onPreview={handlePreview}
                size='large'
                style={{ width: "50%" }}
                disabled
              >
                <Button icon={<UploadOutlined />} disabled>
                  Upload Document
                </Button>
              </Upload>
            </Form.Item>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt='example'
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
          </Col>
          <Col span={8}>
            <Form.Item
              name='feedback'
              label='Remarks'
              rules={[{ required: true, message: "Enter Feedback" }]}
            >
              <TextArea
                placeholder='Enter FeedBack'
                allowClear
                size='large'
                style={{ width: "50%" }}
                disabled
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name='recommendation'
              label='Recommendation'
              rules={[{ required: true, message: "Enter Recommendation" }]}
            >
              <Select size='large' style={{ width: "50%" }} disabled>
                <Option value=''></Option>

                <Option value='suspend'>Suspend</Option>
                <Option value='terminated'>Terminated</Option>
                <Option value='recoverloss'>Recover loss</Option>
              </Select>
            </Form.Item>
          </Col>
          {Employee.terminationDate && (
            <Col span={8}>
              <Form.Item
                name='terminationDate'
                label='Termination Date'
                rules={[{ required: true, message: "Select Termination Date" }]}
              >
                <DatePicker
                  size='large'
                  style={{ width: "50%" }}
                  format='DD-MM-YYYY'
                  disabled
                />
              </Form.Item>
            </Col>
          )}
          {Employee.suspendRange && (
            <Col span={8}>
              <Form.Item
                name='suspendRange'
                label='Suspend Date'
                rules={[
                  { required: true, message: "Select suspendRange Date" },
                ]}
              >
                <DatePicker
                  size='large'
                  style={{ width: "50%" }}
                  format='DD-MM-YYYY'
                  disabled
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        {userConfig.level === 1 ? (
          <>
            <Form.Item style={{ float: "right", marginLeft: "10px" }}>
              <Button type='primary' onClick={Actiontaken}>
                Accept
              </Button>
            </Form.Item>
            <Form.Item style={{ float: "right", marginLeft: "10px" }}>
              <Button onClick={Rejectfunction}>Reject</Button>
            </Form.Item>
          </>
        ) : userConfig.role === "supervisor" ? (
          <Form.Item
            style={{ float: "right", marginLeft: "10px" }}
            name='submitbutton'
            label=''
          >
            <Button type='primary' htmlType='submit'>
              Action taken
            </Button>
          </Form.Item>
        ) : null}
      </Form>
    </>
  );
};

export default Ermanage;
