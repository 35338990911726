import React, { useState } from 'react';
import { notification, Space, Spin } from 'antd';
// import { userFields } from "../compliance/Esireport";

import * as userService from '../services/auth';
import { setUpAxiosInterCeptors } from '../services/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { setLoggedInUserAction } from '../actions/authAction';
import { getAppStore } from '../store';
import { verifyToken } from '../utils/jwt';

const INITIAL_DATA = {
  user: {
    isAuthenticated: false,
  },
  isUserLoading: false,
  login: (data) => {},
  logout: (data) => {},
  userConfig: null,
  tokenInfo: null,
};

export const AuthContext = React.createContext(INITIAL_DATA);

export default function AuthProvider({ children }) {
  const [isUserLoading, setUserLoading] = React.useState(false);
  const [isInfoLoading, setInfoLoading] = React.useState(true);
  const [user, setUser] = React.useState({
    isAuthenticated: false,
    userConfig: {},
    tokenInfo: [],
  });
  // const [fieldNames, setFieldNames] = useState(userFields)
  const [customDropdownValues, setCustomDropdownValues] = useState(null);
  const [permissionList, setPermissionList] = useState([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    setUpAxiosInterCeptors(navigate, setUser);
  }, [navigate]);

  

  React.useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    userService
      .info()
      .then(async (res) => {
        const decoded = await verifyToken(
          res?.data?.organisation?.productLicenseKey || ''
        );

        console.log('verify token xyz', decoded.features);

        setUser({
          isAuthenticated: true,
          userConfig: res.data,
          tokenInfo: decoded?.features,
        });
      
      })
      .finally(() => {
        setInfoLoading(false);
      });
  };

  const { loggedInUser = {} } = getAppStore();
  React.useEffect(() => {
    if (loggedInUser.token) {
      getUserInfo();
    }
  }, [loggedInUser.token]);

  const login = async (data) => {
    //console.log("in auth login")
    setUserLoading(true);
    await userService
      .login(data)
      .then(async (res) => {
        //console.log("abbc",res)
        sessionStorage.setItem('token', res.data.token);
        await setLoggedInUserAction(res.data);
        await setUser({
          isAuthenticated: true,
          userConfig: res.data,
        });
      
      })
      .catch((err) => {
        if (err.response) {
          notification.error({ message: err.response.data.message });
        } else {
          notification.error({
            message: 'Please check your internet connection',
          });
        }
      })
      .finally(() => setUserLoading(false));
  };

  const logout = () => {
    userService
      .logout()
      .then((res) => {
        // console.log(res, "val;ue of response");
        sessionStorage.removeItem('token');
        setUser({
          isAuthenticated: false,
          userConfig: {},
        });
        
        setLoggedInUserAction({});
      })
      .catch((err) => {
        if (err.response) {
          notification.error({ message: err.response.data.message });
        } else {
          notification.error({
            message: 'Please check your internet connection',
          });
        }
      });
  };
  // const enable = (condition, fieldName) => {
  //   setFieldNames(
  //     fieldNames.map((data) => {
  //       if (data.fieldName === fieldName) {
  //         return {
  //           ...data,
  //           selected: condition,
  //         };
  //       } else {
  //         return data;
  //       }
  //     })
  //   );
  // };

  const value = {
    user,
    userConfig: user.userConfig,
    // userConfig: user.userConfig.list,
    isUserLoading,
    login,
    logout,
    tokenInfo: user.tokenInfo,
    // fieldNames,
    // enable,
    customDropdownValues,
    setCustomDropdownValues
  };

  return (
    <AuthContext.Provider value={value}>
      {isInfoLoading ? (
        <Space
          style={{ width: '100vw', height: '100vh', justifyContent: 'center' }}
        >
          <Spin size='large'></Spin>
        </Space>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
