// // import { Button, Row, Table, Col, DatePicker, Select, Divider } from 'antd';
// // import React, { useEffect, useState } from 'react';
// // import { getAuditCompactLogs } from '../services/audit';
// // const { RangePicker } = DatePicker;
// // const { Option } = Select;

// // const Audit = () => {
// //   const [auditData, setAuditData] = useState([]);
// //   const [isFilter, setIsFilter] = useState(false);
// //   const [filter, setFilter] = useState({
// //     startDate: '',
// //     endDate: '',
// //     role: '',
// //     status: '',
// //     action: '',
// //   });
// //   useEffect(() => {
// //     getAudit();
// //   }, []);

// //   useEffect(() => {
// //     getAudit();
// //   }, [filter]);

// //   const getAudit = () => {
// //     getAuditCompactLogs(
// //       filter.startDate,
// //       filter.endDate,
// //       filter.role,
// //       filter.status,
// //       filter.action
// //     )
// //       .then((res) => {
// //         console.log(res.data)
// //         setAuditData(res?.data || []);
// //       })
// //       .catch((error) => {
// //         console.log('value of error', error);
// //       });
// //   };

// //   const columns = [
// //     {
// //       title: 'Date and Time',
// //       dataIndex: 'timestamp',
// //       render: (text, record) => {
// //         const formattedTime = new Date(text).toLocaleString();
// //         // Return the modified data
// //         return <span>{formattedTime}</span>;
// //       },
// //     },

// //     {
// //       title: 'User Name',
// //       dataIndex: 'email',
// //       key: 'email',
// //     },

// //     {
// //       title: 'IP Address',
// //       dataIndex: 'ipAddress',
// //       key: 'ipAddress',
// //     },

// //     {
// //       title: 'Role',
// //       dataIndex: 'role',
// //       key: 'role',
// //     },
// //     // {
// //     //   title: 'Action',
// //     //   dataIndex: 'action',
// //     //   key: 'action',
// //     // },
// //     {
// //       title: 'Status',
// //       dataIndex: 'status',
// //       key: 'status',
// //     },
// //     // {
// //     //   title: 'URL',
// //     //   dataIndex: 'url',
// //     //   key: 'url',
// //     // },
// //     {
// //       title: 'Activity',
// //       dataIndex: 'details',
// //       key: 'details',
// //     },

// //   ];
// //   return (
// //     <>
// //       <Row>
// //         <Button
// //           style={{
// //             float: 'right',
// //             backgroundColor: 'grey',
// //             color: 'white',
// //             fontWeight: 'bold',
// //           }}
// //           onClick={() => {
// //             setFilter({
// //               startDate: '',
// //               endDate: '',
// //               role: '',
// //               status: '',
// //               action: '',
// //             });
// //             setIsFilter(!isFilter);
// //           }}
// //         >
// //           {isFilter ? 'Hide Filter' : 'Filter'}
// //         </Button>
// //         {isFilter && (
// //           <Button
// //             style={{
// //               float: 'right',
// //               backgroundColor: 'grey',
// //               color: 'white',
// //               fontWeight: 'bold',
// //               marginLeft: '10px',
// //             }}
// //             onClick={() => {
// //               setFilter({
// //                 startDate: '',
// //                 endDate: '',
// //                 role: '',
// //                 status: '',
// //                 action: '',
// //               });
// //             }}
// //           >
// //             Reset
// //           </Button>
// //         )}
// //       </Row>
// //       {isFilter && (
// //         <>
// //           <Divider></Divider>
// //           <Row style={{ marginBottom: '5px' }} gutter={24}>
// //             <Col span={8}>
// //               <label>Date Range</label>
// //             </Col>
// //             <Col span={8}>
// //               <label>Status</label>
// //             </Col>
// //             <Col span={8}>
// //               <label>User Role</label>
// //             </Col>
// //             {/* <Col span={4}>
// //               <label>Action</label>
// //             </Col> */}
// //           </Row>
// //           <Row gutter={24}>
// //             <Col span={8}>
// //               <RangePicker
// //                 showTime={{ format: 'HH:mm' }}
// //                 format='YYYY-MM-DD HH:mm'
// //                 // value={[filter.startDate, filter.endDate]}
// //                 onChange={(dates, dateStrings) => {
// //                   console.log('value of date strings', dateStrings);
// //                   setFilter({
// //                     ...filter,
// //                     startDate: dateStrings[0]
// //                       ? new Date(dateStrings[0]).getTime()
// //                       : '',
// //                     endDate: dateStrings[1]
// //                       ? new Date(dateStrings[1]).getTime()
// //                       : '',
// //                   });
// //                 }}
// //               ></RangePicker>
// //             </Col>
// //             <Col span={8}>
// //               <Select
// //                 style={{ width: '100%' }}
// //                 value={filter.status}
// //                 onChange={(e) => {
// //                   console.log('gfcdshbdjnsd', e);
// //                   setFilter({ ...filter, status: e });
// //                 }}
// //               >
// //                 <Option value='Success' key='success'>
// //                   Success
// //                 </Option>
// //                 <Option value='API Failure' key='API Failure'>
// //                   API Failure
// //                 </Option>
// //                 <Option value='Server Failure' key='Server Failure'>
// //                   Server Failure
// //                 </Option>
// //               </Select>
// //             </Col>
// //             <Col span={8}>
// //               <Select
// //                 style={{ width: '100%' }}
// //                 value={filter.role}
// //                 onChange={(e) => {
// //                   setFilter({ ...filter, role: e });
// //                 }}
// //               >
// //                 <Option value='admin' key='admin'>
// //                   Admin
// //                 </Option>
// //                 <Option value='supervisor' key='supervisor'>
// //                   Supervisor
// //                 </Option>
// //                 <Option value='approver' key='approver'>
// //                   approver
// //                 </Option>
// //               </Select>
// //             </Col>
// //             {/* <Col span={4}>
// //               <Select style={{ width: '100%' }}>
// //                 <Option value='admin'>Admin</Option>
// //                 <Option value='superadmin'>Super Admin</Option>
// //               </Select>
// //             </Col> */}
// //           </Row>

// //           <Divider></Divider>
// //         </>
// //       )}

// //       <Row></Row>
// //       <Table
// //         columns={columns}
// //         dataSource={auditData}
// //         onChange={() => { }}
// //         pagination={true}
// //       />
// //     </>
// //   );
// // };

// // export default Audit;

// import { Button, Row, Table, Col, DatePicker, Select, Divider } from 'antd';
// import React, { useEffect, useState } from 'react';
// import { getAuditCompactLogs } from '../services/audit';
// const { RangePicker } = DatePicker;
// const { Option } = Select;

// const Audit = () => {
//   const [auditData, setAuditData] = useState([]);
//   const [isFilter, setIsFilter] = useState(false);
//   const [filter, setFilter] = useState({
//     startDate: '',
//     endDate: '',
//     role: '',
//     status: '',
//     action: '',
//   });
//   useEffect(() => {
//     getAudit();
//   }, []);

//   useEffect(() => {
//     getAudit();
//   }, [filter]);

//   const getAudit = () => {
//     getAuditCompactLogs(
//       filter.startDate,
//       filter.endDate,
//       filter.role,
//       filter.status,
//       filter.action
//     )
//       .then((res) => {
//         console.log(res.data);
//         setAuditData(res?.data || []);
//       })
//       .catch((error) => {
//         console.log('value of error', error);
//       });
//   };

//   const columns = [
//     {
//       title: 'Date and Time',
//       dataIndex: 'timestamp',
//       render: (text, record) => {
//         const formattedTime = new Date(text).toLocaleString();
//         // Return the modified data
//         return <span>{formattedTime}</span>;
//       },
//     },

//     {
//       title: 'User Name',
//       dataIndex: 'email',
//       key: 'email',
//     },

//     {
//       title: 'IP Address',
//       dataIndex: 'ipAddress',
//       key: 'ipAddress',
//     },

//     {
//       title: 'Role',
//       dataIndex: 'role',
//       key: 'role',
//     },
//     {
//       title: 'Status',
//       dataIndex: 'status',
//       key: 'status',
//     },
//     {
//       title: 'Action',
//       dataIndex: 'actionType',
//       key: 'actionType',
//     },
//     {
//       title: 'Activity',
//       dataIndex: 'activity',
//       key: 'activity',
//     },
//   ];
//   return (
//     <>
//       <Row>
//         <Button
//           style={{
//             float: 'right',
//             backgroundColor: 'grey',
//             color: 'white',
//             fontWeight: 'bold',
//           }}
//           onClick={() => {
//             setFilter({
//               startDate: '',
//               endDate: '',
//               role: '',
//               status: '',
//               action: '',
//             });
//             setIsFilter(!isFilter);
//           }}>
//           {isFilter ? 'Hide Filter' : 'Filter'}
//         </Button>
//         {isFilter && (
//           <Button
//             style={{
//               float: 'right',
//               backgroundColor: 'grey',
//               color: 'white',
//               fontWeight: 'bold',
//               marginLeft: '10px',
//             }}
//             onClick={() => {
//               setFilter({
//                 startDate: '',
//                 endDate: '',
//                 role: '',
//                 status: '',
//                 action: '',
//               });
//             }}>
//             Reset
//           </Button>
//         )}
//       </Row>
//       {isFilter && (
//         <>
//           <Divider></Divider>
//           <Row style={{ marginBottom: '5px' }} gutter={24}>
//             <Col span={8}>
//               <label>Date Range</label>
//             </Col>
//             <Col span={8}>
//               <label>Status</label>
//             </Col>
//             <Col span={8}>
//               <label>User Role</label>
//             </Col>
//             {/* <Col span={4}>
//               <label>Action</label>
//             </Col> */}
//           </Row>
//           <Row gutter={24}>
//             <Col span={8}>
//               <RangePicker
//                 showTime={{ format: 'HH:mm' }}
//                 format='YYYY-MM-DD HH:mm'
//                 // value={[filter.startDate, filter.endDate]}
//                 onChange={(dates, dateStrings) => {
//                   console.log('value of date strings', dateStrings);
//                   setFilter({
//                     ...filter,
//                     startDate: dateStrings[0]
//                       ? new Date(dateStrings[0]).getTime()
//                       : '',
//                     endDate: dateStrings[1]
//                       ? new Date(dateStrings[1]).getTime()
//                       : '',
//                   });
//                 }}></RangePicker>
//             </Col>
//             <Col span={8}>
//               <Select
//                 style={{ width: '100%' }}
//                 value={filter.status}
//                 onChange={(e) => {
//                   console.log('gfcdshbdjnsd', e);
//                   setFilter({ ...filter, status: e });
//                 }}>
//                 <Option value='Success' key='success'>
//                   Success
//                 </Option>
//                 <Option value='API Failure' key='API Failure'>
//                   API Failure
//                 </Option>
//                 <Option value='Server Failure' key='Server Failure'>
//                   Server Failure
//                 </Option>
//               </Select>
//             </Col>
//             <Col span={8}>
//               <Select
//                 style={{ width: '100%' }}
//                 value={filter.role}
//                 onChange={(e) => {
//                   setFilter({ ...filter, role: e });
//                 }}>
//                 <Option value='admin' key='admin'>
//                   Admin
//                 </Option>
//                 <Option value='supervisor' key='supervisor'>
//                   Supervisor
//                 </Option>
//                 <Option value='approver' key='approver'>
//                   approver
//                 </Option>
//               </Select>
//             </Col>
//             {/* <Col span={4}>
//               <Select style={{ width: '100%' }}>
//                 <Option value='admin'>Admin</Option>
//                 <Option value='superadmin'>Super Admin</Option>
//               </Select>
//             </Col> */}
//           </Row>

//           <Divider></Divider>
//         </>
//       )}

//       <Row></Row>
//       <Table
//         columns={columns}
//         dataSource={auditData}
//         onChange={() => {}}
//         pagination={true}
//       />
//     </>
//   );
// };

// export default Audit;

import { Button, Row, Table, Col, DatePicker, Select, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAuditCompactLogs } from '../services/audit';
const { RangePicker } = DatePicker;
const { Option } = Select;

const Audit = () => {
  const [auditData, setAuditData] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    role: '',
    status: '',
    action: '',
  });
  useEffect(() => {
    getAudit();
  }, []);

  useEffect(() => {
    getAudit();
  }, [filter]);

  const getAudit = () => {
    getAuditCompactLogs(
      filter.startDate,
      filter.endDate,
      filter.role,
      filter.status,
      filter.action
    )
      .then((res) => {
        console.log(res.data);
        setAuditData(res?.data || []);
      })
      .catch((error) => {
        console.log('value of error', error);
      });
  };

  const columns = [
    {
      title: 'Date and Time',
      dataIndex: 'timestamp',
      render: (text, record) => {
        const formattedTime = new Date(text).toLocaleString();
        // Return the modified data
        return <span>{formattedTime}</span>;
      },
    },

    {
      title: 'User Name',
      dataIndex: 'email',
      key: 'email',
    },

    {
      title: 'IP Address',
      dataIndex: 'ipAddress',
      key: 'ipAddress',
    },

    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'actionType',
    //   key: 'actionType',
    // },
    // {
    //   title: 'Activity',
    //   dataIndex: 'activity',
    //   key: 'activity',
    // },
  ];
  return (
    <>
      <Row>
        <Button
          style={{
            float: 'right',
            backgroundColor: 'grey',
            color: 'white',
            fontWeight: 'bold',
          }}
          onClick={() => {
            setFilter({
              startDate: '',
              endDate: '',
              role: '',
              status: '',
              action: '',
            });
            setIsFilter(!isFilter);
          }}>
          {isFilter ? 'Hide Filter' : 'Filter'}
        </Button>
        {isFilter && (
          <Button
            style={{
              float: 'right',
              backgroundColor: 'grey',
              color: 'white',
              fontWeight: 'bold',
              marginLeft: '10px',
            }}
            onClick={() => {
              setFilter({
                startDate: '',
                endDate: '',
                role: '',
                status: '',
                action: '',
              });
            }}>
            Reset
          </Button>
        )}
      </Row>
      {isFilter && (
        <>
          <Divider></Divider>
          <Row style={{ marginBottom: '5px' }} gutter={24}>
            <Col span={8}>
              <label>Date Range</label>
            </Col>
            <Col span={8}>
              <label>Status</label>
            </Col>
            <Col span={8}>
              <label>User Role</label>
            </Col>
            {/* <Col span={4}>
              <label>Action</label>
            </Col> */}
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                format='YYYY-MM-DD HH:mm'
                // value={[filter.startDate, filter.endDate]}
                onChange={(dates, dateStrings) => {
                  console.log('value of date strings', dateStrings);
                  setFilter({
                    ...filter,
                    startDate: dateStrings[0]
                      ? new Date(dateStrings[0]).getTime()
                      : '',
                    endDate: dateStrings[1]
                      ? new Date(dateStrings[1]).getTime()
                      : '',
                  });
                }}></RangePicker>
            </Col>
            <Col span={8}>
              <Select
                style={{ width: '100%' }}
                value={filter.status}
                onChange={(e) => {
                  console.log('gfcdshbdjnsd', e);
                  setFilter({ ...filter, status: e });
                }}>
                <Option value='Success' key='success'>
                  Success
                </Option>
                <Option value='API Failure' key='API Failure'>
                  API Failure
                </Option>
                <Option value='Server Failure' key='Server Failure'>
                  Server Failure
                </Option>
              </Select>
            </Col>
            <Col span={8}>
              <Select
                style={{ width: '100%' }}
                value={filter.role}
                onChange={(e) => {
                  setFilter({ ...filter, role: e });
                }}>
                <Option value='admin' key='admin'>
                  Admin
                </Option>
                <Option value='supervisor' key='supervisor'>
                  Supervisor
                </Option>
                <Option value='approver' key='approver'>
                  approver
                </Option>
              </Select>
            </Col>
            {/* <Col span={4}>
              <Select style={{ width: '100%' }}>
                <Option value='admin'>Admin</Option>
                <Option value='superadmin'>Super Admin</Option>
              </Select>
            </Col> */}
          </Row>

          <Divider></Divider>
        </>
      )}

      <Row></Row>
      <Table
        columns={columns}
        dataSource={auditData}
        onChange={() => {}}
        pagination={true}
      />
    </>
  );
};

export default Audit;
