import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function ProtectedRoute() {
  const { user, isUserLoading } = useAuth();
  console.log("Hiii",user)
  if (!user.isAuthenticated && !isUserLoading) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
}
