import React from 'react';
import { Form, Modal, Table } from 'antd'; // Removed unnecessary imports
import useAuth from '../../hooks/useAuth';
 
export default function EventHistoryModal({
  visible, // Changed prop name to 'visible'
  onCancel,
  eventHistoryData = {},
  checkIsValidAddition,
}) {
  const { userConfig } = useAuth();
  const formRef = React.useRef();
 
  const orgId = userConfig.orgId;
 
  // Calculate the width of each column based on the content
  const getColumnWidth = (data, dataIndex) => {
    if (!Array.isArray(data) || data.length === 0) {
        return 100; // Return a default width if data is not an array or empty
      }
   
      return Math.max(...data.map(item => item[dataIndex]?.toString().length * 10), 100);
    // return Math.max(...data.map(item => item[dataIndex].toString().length * 10), 100);
  };
 
 // Function to convert number of days since reference date to a date string
 const formatDateFromDays = (days) => {
    const referenceDate = new Date('1900-01-01'); // Set reference date
    const targetDate = new Date(referenceDate); // Create a copy of the reference date
    targetDate.setDate(referenceDate.getDate() + days); // Add days to the reference date
    return targetDate.toLocaleDateString(); // Convert to locale date string
  };
 
  // Define columns for the table
  const columns = [
    {
      title: 'Eff. Date',
      dataIndex: 'effDate',
      key: 'effDate',
    //   width: getColumnWidth(eventHistoryData, 'effDate'),
    //   render: (effDate) => formatDateFromDays(effDate),
      align: 'center',
     
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
    //   width: getColumnWidth(eventHistoryData, 'event'),
      align: 'center',
    },
    {
      title: 'From Cadre',
      dataIndex: 'fromCadre',
      key: 'fromCadre',
    //   width: getColumnWidth(eventHistoryData, 'fromCadre'),
      align: 'center',
    },
    {
      title: 'To Cadre',
      dataIndex: 'toCadre',
      key: 'toCadre',
    //   width: getColumnWidth(eventHistoryData, 'toCadre'),
      align: 'center',
    },
    {
      title: 'Department Code',
      dataIndex: 'departmentCode',
      key: 'departmentCode',
    //   width: getColumnWidth(eventHistoryData, 'deptartmentCode'),
      align: 'center',
    },
    {
      title: 'Function Code',
      dataIndex: 'functionCode',
      key: 'functionCode',
    //   width: getColumnWidth(eventHistoryData, 'functionCode'),
      align: 'center',
    },
  ];
 
  const _onCancel = () => {
    onCancel();
  };
 
  return (
    <Modal
      title="Event History"
      visible={visible}
      onCancel={onCancel}
    //   okText="Save"
      okButtonProps = {{hidden: true}}
      cancelButtonProps={{ hidden: true }}
      autoComplete="off"
      width="1000px"
    >
      {/* Your modal content */}
      <Form name="admin" layout="vertical">
        {/* Your existing form fields */}
      </Form>
     
      {/* Render the table */}
      <Table
        dataSource={eventHistoryData}
        columns={columns}
        pagination={false}
        bordered
        scroll={{ y: 300 }}
        // style={{ width: '100%', minWidth: '800px'}}
        />
    </Modal>
  );
}
 