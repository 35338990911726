import React, { useEffect, useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { EmployeeGrowthColumns, beforeUpload } from './Constant';
import useAuth from '../hooks/useAuth';
import { saveAs } from 'file-saver';

import {
  employeeGrowth,
  getemployeeGrowth,
  downloadEmployeeGrowth,
} from '../services/auth';
import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import {
  Form,
  Row,
  Space,
  Input,
  Modal,
  Col,
  Button,
  notification,
  Divider,
  Table,
  Typography,
  Tooltip,
  Upload,
} from 'antd';

const EmployeeGrowth = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}>
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const EmployeeGrowthColumns = [
    {
      title: 'Workman Name',
      dataIndex: 'workmenName',
      key: 'workmenName',
      ...getColumnSearchProps('workmenName'),
    },
    {
      title: 'PS No.',
      dataIndex: 'psNumber',
      key: 'psNumber',
      ...getColumnSearchProps('psNumber'),
    },
    {
      title: 'Inc 93',
      dataIndex: 'inc93',
      key: 'inc93',
    },
    {
      title: 'Inc 94',
      dataIndex: 'inc94',
      key: 'inc94',
    },
    {
      title: 'Inc 95',
      dataIndex: 'inc95',
      key: 'inc95',
    },
    {
      title: 'Inc 96',
      dataIndex: 'inc96',
      key: 'inc96',
    },
    {
      title: 'Inc 97',
      dataIndex: 'inc97',
      key: 'inc97',
    },
    {
      title: 'Inc 98',
      dataIndex: 'inc98',
      key: 'inc98',
    },
    {
      title: 'Inc 99',
      dataIndex: 'inc99',
      key: 'inc99',
    },
    {
      title: 'Inc 00',
      dataIndex: 'inc00',
      key: 'inc00',
    },
    {
      title: 'Inc 01',
      dataIndex: 'inc01',
      key: 'inc01',
    },
    {
      title: 'Inc 02',
      dataIndex: 'inc02',
      key: 'inc02',
    },
    {
      title: 'Inc 03',
      dataIndex: 'inc03',
      key: 'inc03',
    },
    {
      title: 'Inc 04',
      dataIndex: 'inc04',
      key: 'inc04',
    },
    {
      title: 'Inc 05',
      dataIndex: 'inc05',
      key: 'inc05',
    },
    {
      title: 'Inc 06',
      dataIndex: 'inc06',
      key: 'inc06',
    },
    {
      title: 'Inc 07',
      dataIndex: 'inc07',
      key: 'inc07',
    },
    {
      title: 'Inc 08',
      dataIndex: 'inc08',
      key: 'inc08',
    },
    {
      title: 'Inc 09',
      dataIndex: 'inc09',
      key: 'inc09',
    },
    {
      title: 'Inc 10',
      dataIndex: 'inc10',
      key: 'inc10',
    },
    {
      title: 'Inc 11',
      dataIndex: 'inc11',
      key: 'inc11',
    },
    {
      title: 'Inc 12',
      dataIndex: 'inc12',
      key: 'inc12',
    },
    {
      title: 'Inc 13',
      dataIndex: 'inc13',
      key: 'inc13',
    },
    {
      title: 'Inc 14',
      dataIndex: 'inc14',
      key: 'inc14',
    },
    {
      title: 'Inc 15',
      dataIndex: 'inc15',
      key: 'inc15',
    },
    {
      title: 'Inc 16',
      dataIndex: 'inc16',
      key: 'inc16',
    },
    {
      title: 'Inc 17',
      dataIndex: 'inc17',
      key: 'inc17',
    },
    {
      title: 'Inc 18',
      dataIndex: 'inc18',
      key: 'inc18',
    },
    {
      title: 'Inc 19',
      dataIndex: 'inc19',
      key: 'inc19',
    },
    {
      title: 'Inc 20',
      dataIndex: 'inc20',
      key: 'inc20',
    },
    {
      title: 'Inc 21',
      dataIndex: 'inc21',
      key: 'inc21',
    },
    {
      title: 'Inc 22',
      dataIndex: 'inc22',
      key: 'inc22',
    },
    {
      title: 'Inc 23',
      dataIndex: 'inc23',
      key: 'inc22',
    },
    // {
    //   title: 'Inc 24',
    //   dataIndex: 'inc24',
    //   key: 'inc22',
    // },
  ];
  const navigate = useNavigate();

  const { userConfig } = useAuth();
  const [LongService, setLongService] = React.useState([]);

  useEffect(() => {
    GetLongserviceList();
  }, []);
  const GetLongserviceList = () => {
    getemployeeGrowth()
      .then((res) => {
        console.log('Get Employee List Response is', res);
        setLongService(
          res.data.longServicesList.map((row) => ({
            _id: row._id,
            workmenName: row.name,
            psNumber: row.psno,
            inc93: row.inc93,
            inc94: row.inc94,
            inc95: row.inc95,
            inc96: row.inc96,
            inc97: row.inc97,
            inc98: row.inc98,
            inc99: row.inc99,
            inc00: row.inc00,

            inc01: row.inc01,
            inc02: row.inc02,
            inc03: row.inc03,
            inc04: row.inc04,
            inc05: row.inc05,
            inc06: row.inc06,
            inc07: row.inc07,
            inc08: row.inc08,
            inc09: row.inc09,
            inc10: row.inc10,
            inc11: row.inc11,
            inc12: row.inc12,
            inc13: row.inc13,
            inc14: row.inc14,
            inc15: row.inc15,
            inc16: row.inc16,
            inc17: row.inc17,
            inc18: row.inc18,
            inc19: row.inc19,
            inc20: row.inc20,
            inc21: row.inc21,
            inc22: row.inc22,
            inc23: row.inc23,
            inc24: row.inc24,
          }))
        );
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const { Title } = Typography;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {};

  const handleCancel = () => {
    // Handle the Cancel button click if needed
    setIsModalVisible(false);
  };

  const customRequest = ({ file, onSuccess }) => {
    onSuccess(file);
  };

  const beforeUpload = (file) => {
    console.log(userConfig.orgId);

    console.log('inside bfore upload');
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Access the second sheet (index 1) by its name
      const secondSheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(secondSheet);

      const allData = [];

      sheetData.forEach((item) => {
        item.orgId = userConfig.orgId;
        allData.push(item);
      });

      // Now you can send allData to the longService function
      employeeGrowth(allData)
        .then((res) => {
          notification.success({ message: res.data.message });
          window.location.reload();
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
          navigate('/home/approver/historical/employeeGrowth');
        });
      // window.location.reload();
    };

    reader.readAsArrayBuffer(file);
    return false; // Prevent default Upload behavior
  };

  const onFinish = (value) => {
    console.log('value is', value);
    value.orgId = userConfig.orgId;

    employeeGrowth(value)
      .then((res) => {
        notification.success({ message: res.data.message });
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
        navigate('/home/approver/historical/employeeGrowth');
      });
    window.location.reload();

    setIsModalVisible(false);
  };
  const downloadExcel = () => {
    const data = {
      reportType: 'all',
    };
    let responseType, fileExtension, contentType;

    responseType = 'blob';
    fileExtension = 'xlsx';
    contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    downloadEmployeeGrowth(data, responseType)
      .then((response) => {
        const file = new Blob([response.data], { type: contentType });
        const fileUrl = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', `Employee Growth`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDownload = async () => {
    try {
      // const response = await fetch(
      //   'http://localhost:3000/api/downloademployeegrowth-excel',
      //   {
      // const response = await fetch(
      //   'https://vyn.wikiworks.in:443/api/downloademployeegrowth-excel',
      //   {
      const response = await fetch(
        // 'https://demo.wikiworks.in:443/api/downloademployeegrowth-excel',
        `${process.env.REACT_APP_API_URL}/downloademployeegrowth-excel`,
        {
          method: 'GET',
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, 'Employee_Growth_Template.xlsx');
      } else {
        console.error('Failed to download Excel sheet');
      }
    } catch (error) {
      console.error('Failed to download Excel sheet', error);
    }
  };
  return (
    <>
      <Title level={4} style={{ textAlign: 'center' }}>
        Employee Growth
      </Title>
      <Form layout='vertical'>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title='Download Sample Template '>
            <Button
              className='mr2 HeaderButtonColor'
              style={{ marginRight: '10px' }}
              onClick={handleDownload}
              disabled={userConfig.role === 'shop'}>
              Download Template
            </Button>
          </Tooltip>
          <Tooltip title='Import '>
            <Upload
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}>
              <Button
                // type='primary'
                className='mr2 HeaderButtonColor'
                style={{ marginRight: '10px' }}
                disabled={userConfig.role === 'shop'}>
                Import
              </Button>
            </Upload>
          </Tooltip>

          <Tooltip title='Export'>
            <Button
              className='mr2 HeaderButtonColor'
              style={{ marginRight: '10px' }}
              // type='primary'
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}
              onClick={downloadExcel}
              disabled={userConfig.role === 'shop'}>
              Export
            </Button>
          </Tooltip>
          <Tooltip title='Add New'>
            <Button
              className='mr2 HeaderButtonColor'
              type='primary'
              hidden={true}
              onClick={showModal}
              disabled={userConfig.role === 'shop'}>
              Add New
            </Button>
          </Tooltip>
          <Modal
            title='Employee Growth'
            open={isModalVisible}
            width='50%'
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}>
            <Form onFinish={onFinish} layout='vertical'>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label='Workman Name' name='workmanname'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='PS Number' name='psnumber'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 93' name='inc93'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 95' name='inc95'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 96' name='inc96'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 97' name='inc97'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 98' name='inc98'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 99' name='inc99'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 00	' name='inc00'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 01	' name='inc01'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 02	' name='inc02'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 03	' name='inc03'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 04	' name='inc04'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 05	' name='inc05'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 06	' name='inc06'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='Inc 07	
'
                    name='inc07'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='Inc 08
'
                    name='inc08'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 09	' name='inc09'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 10	' name='inc10'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 11	' name='inc11'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 12	' name='inc12'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 13	' name='inc13'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 14	' name='inc14'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='Inc 15
'
                    name='inc15'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label='Inc 16	
'
                    name='inc16'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 17	' name='inc17'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 18	' name='inc18'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 19	' name='inc19'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 20	' name='inc20'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 21	' name='inc21'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 22	' name='inc22'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 23	' name='inc23'>
                    <Input></Input>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label='Inc 24	' name='inc24'>
                    <Input></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ justifyContent: 'center' }}>
                  <Form.Item>
                    <Button htmlType='submit' type='primary'>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </div>

        <Divider></Divider>
        <Table
          className='TableDesign'
          bordered
          columns={EmployeeGrowthColumns}
          dataSource={LongService}
          scroll={{ x: 1500 }}
        />
      </Form>
    </>
  );
};

export default EmployeeGrowth;
