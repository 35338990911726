import { LOADER, SET_LOGGED_IN_USER } from "../actions/actionType";

import { setAppStore } from "../store";

export const initialState = {
  isLoader: false,
  loggedInUser: { name: "shubham" },
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  let updatedState;
  switch (type) {
    case LOADER:
      updatedState = { ...state, isLoader: payload };
      break;
    case SET_LOGGED_IN_USER:
      updatedState = { ...state, loggedInUser: payload };
      break;
    default:
      updatedState = state;
      break;
  }
  setAppStore(updatedState);
  return updatedState;
}
