import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Modal,
  Col,
  Row,
  Select,
  Button,
  Upload,
  Checkbox,
  InputNumber,
  DatePicker,
  notification,
  Divider,
  mo,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
// import { supplierPfesi } from '../../services/auth';
import {
  AddContractors,
  supplierEsi,
  supplierPf,
  supplierPfesi,
  userCreate,
} from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import axios1 from '../../services/axiosInstance';
import { updatecontractor, Email } from '../../services/auth';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { State } from '../../JSON/data';
import moment from 'moment';
import { debounce } from 'lodash';
const { Option } = Select;

export default function AddJobDetails({
  open,
  onCancel,
  isEdit = false,
  initialValues = [],
  supplierList,
  locationsData,
}) {
  console.log('supplierList', supplierList, locationsData);
  const formRef = React.useRef();
  const showRef = useRef(null);
  const [selectedSupplierId, setSelectedSupplierId] = useState('');
  const [PfDisabled, setPfDisabled] = React.useState(true);
  const [EsicDisabled, setEsicDisabled] = React.useState(true);
  const [location, setlocation] = React.useState({});
  const [locationValue, setlocationValue] = React.useState('');
  const [isllnDateDisabled, setllnDateDisabled] = useState(false);
  const [isllnDisabled, setllnDisabled] = useState(false);

  const [showWarning, setShowWarning] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  // const [uniqueSupplierList, setUniqueSupplierList] = useState([]);
  // const [supplierListProp, setSupplierListProp] = useState([]);

  const { _id } = useParams();
  const { id } = useParams();

  React.useEffect(() => {
    EmployeeList();
    formRef.current?.setFieldsValue(initialValues);
    setIsPfApplyDateDisabled(
      initialValues.pfEstablishmentSubCode || initialValues.pfAccountNo
    );
    if (initialValues.pfEstablishmentSubCode || initialValues.pfAccountNo) {
      formRef.current?.setFieldValue('pfApplyDate', '');
    }
    supplierList.forEach((ele) => {
      if (ele.id === userConfig?.supplierId) {
        setlocationValue(ele.location);
      }
    });
  }, [initialValues]);

  async function EmployeeList() {
    const Response = await axios1.get('/org/locations');
    const Response2 = Response.data.org.locations[0].name;

    setlocation({ Response2 });
  }
  const navigate = useNavigate();
  const { user, userConfig } = useAuth();
  const isDisabled = userConfig.role == 'approver';

  const states = State;
  const Pftoggle = () => {
    setPfDisabled(!PfDisabled);
  };
  const Esictoggle = () => {
    setEsicDisabled(!EsicDisabled);
  };

  const onFinish = (value) => {
    const roleName = value.id?.selectedRoleType || initialValues.roleName;
    //unique pf esi

    const addJobDetails = [];

    // Modify the values object to include addJobDetails
    const updatedValues = {
      ...value,
      addJobDetails, // Add the empty array
      supplierId: selectedSupplierId,
    };

    // console.log('updatedValues:', updatedValues);

    if (isEdit) {
      value.Edited = userConfig.role === 'approver' ? 'No' : 'Yes';
      // value.Status = userConfig.role === 'approver' ? 'No' : 'Yes';
      // value.status = 'Pending';
      value.newStatus = 'Pending';
      value.jobOrderEdited = 'Yes';

      updatecontractor(value)
        .then((res) => {
          // notification.success({ message: res.data.message });
          // navigate('/home/admin/organisation');
        })
        .catch((err) => {
          // navigate('/');

          notification.success({ message: 'Updated Succesfully' });
        });
      if (userConfig.role == 'contractor') {
        // console.log('inside contractor');
        // console.log(userConfig.role);

        Email({
          // medicalCheckupDate: values.medicalCheckupDate,
          // workmenName: value.managerName,
          templateId: 'NOTIFICATION_COMPLETION_CONTARCTOR_AGENCY_PROFILE_VYN',
          // VYNApplication: 'https://demo.wikiworks.in/login',
          VYNApplication: process.env.REACT_APP_URL,
          orgId: userConfig.orgId,
          userId: userConfig.userid,
          // role: 'IR-Contract', //userConfig.role
          role: 'approver',
          level: 1,
          locationId:
            supplierList.length > 0 && locationValue
              ? locationsData.locations.find(
                  (element) => element.id === locationValue
                )?.id
              : undefined,
        })
          .then((res) => {
            notification.success({ message: res.data.message });
          })
          .catch((err) => {
            notification.error({ message: err.response.data.message });
          });
      }
    } else {
      // Call add API
      value.Editted = 'Yes';
      value.status = 'Pending';
      value.newStatus = 'Pending';

      value.jobOrderEdited = 'Yes';
      AddContractors(value)
        .then((res) => {
          console.log(value, res);
          // navigate('/');
          // navigate('/home/admin/organisation');

          notification.success({ message: res.data.message });
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
        });

      if (userConfig.role == 'contractor') {
        Email({
          // medicalCheckupDate: values.medicalCheckupDate,
          workmenName: value.managerName,
          templateId: 'NOTIFICATION_COMPLETION_CONTARCTOR_AGENCY_PROFILE_VYN',
          // VYNApplication: 'https://demo.wikiworks.in/login',
          VYNApplication: process.env.REACT_APP_URL,
          orgId: userConfig.orgId,
          userId: userConfig.userid,
          // role: 'IR-Contract', //userConfig.role
          role: 'approver',
          level: 1,
          locationId:
            supplierList.length > 0 && locationValue
              ? locationsData.locations.find(
                  (element) => element.id === locationValue
                )?.id
              : undefined,
        })
          .then((res) => {
            notification.success({ message: res.data.message });
          })
          .catch((err) => {
            notification.error({ message: err.response.data.message });
          });
      }
    }
    window.location.reload();
  };

  const onSubmit = () => {
    formRef.current?.submit();
  };

  const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };

  //upload
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  //Preview
  const [previewOpen, setPreviewOpen] = useState(false);

  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const handleCancel = () => {
    setIsPfApplyDateDisabled(false);

    setPreviewOpen(false);
  };

  const [form] = Form.useForm();
  const [isPfApplyDateDisabled, setIsPfApplyDateDisabled] = useState(false);

  const handleSubCodeChange = (value) => {
    setIsPfApplyDateDisabled(!!value || !!form.getFieldValue('pfAccountNo'));
  };

  const validateTotalLicenceCoverage = (_, value) => {
    const llnValue = form.getFieldValue('lln');

    if (parseInt(value, 10) >= 50) {
      // If totalLicenceCoverage is 50 or more, check if llnValue is provided
      if (llnValue) {
        return Promise.resolve();
      } else {
        // If llnValue is not provided, display an error message
        return Promise.reject(
          new Error(
            'Labour License Number is mandatory for 50 or more workers.'
          )
        );
      }
    }

    // If totalLicenceCoverage is less than 50, no need to check llnValue
    return Promise.resolve();
  };

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      return () => {};
    }
    let timeOut;
    if (showRef.current) {
      timeOut = setTimeout(() => {
        //if(setShowWarning){
        message.warning(
          'All the employees are not covered under ESI or WC/EC Insurance'
        );
        //}
      }, 1000);
    }

    return () => {
      clearTimeout(timeOut);
      // setShowWarning(false)
    };
  }, [showWarning]);

  const handleChanges = (a, b) => {
    console.log('abc', a, b);
    //  console.log("abcvvbb",a.hasOwnProperty("lln") , b.totalLicenceCoverage , parseInt(b.totalLicenceCoverage))
    if (a.hasOwnProperty('lln') && b.totalLicenceCoverage) {
      if ((a.lln && a.lln.length > 0) || !a.lln) {
        // console.log("inside");
        form.validateFields(['totalLicenceCoverage']);
      }
    }

    if (a.hasOwnProperty('nowc')) {
      // Calculate the sum of nowc and noofWorkmenCovered
      const sumNowcNoofWorkmen =
        parseInt(a.nowc, 10) + parseInt(b.noofWorkmenCovered, 10);
      if (parseInt(b.totalLicenceCoverage) > sumNowcNoofWorkmen) {
        // form.validateFields(["maxContractors"])
        setShowWarning(!showWarning);
        showRef.current = true;
      } else {
        showRef.current = false;
        setShowWarning(!showWarning);
      }
    }

    if (a.hasOwnProperty('noofWorkmenCovered')) {
      // Calculate the sum of nowc and noofWorkmenCovered
      const sumNowcNoofWorkmen =
        parseInt(a.noofWorkmenCovered, 10) + parseInt(b.nowc, 10);
      if (parseInt(b.totalLicenceCoverage) > sumNowcNoofWorkmen) {
        //form.validateFields(["maxContractors"])
        setShowWarning(!showWarning);
        showRef.current = true;
      } else {
        showRef.current = false;
        setShowWarning(!showWarning);
      }
    }
  };

  const insurenceValidator = (rule, value, callback) => {
    const { getFieldValue } = form;
    const noofWorkmenCovered = getFieldValue('noofWorkmenCovered');
    const nowc = getFieldValue('nowc');
    const totalLicenceCoverage = getFieldValue('totalLicenceCoverage');

    const sum = parseInt(noofWorkmenCovered, 10) + parseInt(nowc, 10);

    if (parseInt(totalLicenceCoverage, 10) > sum) {
      // Trigger email function here
      // sendEmail();
      Email({
        // medicalCheckupDate: values.medicalCheckupDate,
        workmenName: value.managerName,
        templateId: 'MISMATCH_INSURANCE_COVERAGE',
        // VYNApplication: 'https://demo.wikiworks.in/login',
        VYNApplication: process.env.REACT_APP_URL,
        orgId: userConfig.orgId,
        userId: userConfig.userid,
        // role: ['supervisor','admin'], //userConfig.role
        // role: 'IR-Contract',
        role: 'approver',
        // roleName: 'IR-Contract',
        level: 1,
        locationId:
          supplierList.length > 0
            ? locationsData.locations.find(
                (element) => element.id === locationValue
              )?.id
            : undefined,
        insuranceNumber: initialValues.wcpn,
        insuranceNumberESI: initialValues.esicno,
        jONumber: initialValues.jobordernumber,
      })
        .then((res) => {
          notification.success({ message: res.data.message });
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
        });

      // Show warning message without blocking the form submission
      message.warning(
        'All the employees are not covered under ESI or WFC/EC Insurance'
      );
      // Show error message
      callback();
    } else {
      callback();
    }
  };

  const LicenseExceedValidator = debounce((rule, value, callback) => {
    const { getFieldValue } = form;
    const noofWorkmenApproved = getFieldValue('maxContractors');

    const totalLicenceCoverage = getFieldValue('totalLicenceCoverage');

    if (
      parseInt(totalLicenceCoverage, 10) < parseInt(noofWorkmenApproved, 10)
    ) {
      // Trigger email function here
      // sendEmail();

      Email({
        // medicalCheckupDate: values.medicalCheckupDate,
        workmenName: userConfig.firstName,
        templateId: 'NOTIFICATION_EXCEEDING_RESOURCES_OVER_AND_ABOVE_LL',
        // VYNApplication: 'https://demo.wikiworks.in/login',
        VYNApplication: process.env.REACT_APP_URL,
        orgId: userConfig.orgId,
        contractorId: userConfig.supplierId,
        contractorName: userConfig.firstName,
        role: 'approver',
        level: 1,
        locationId:
          supplierList.length > 0
            ? locationsData.locations.find(
                (element) => element.id === locationValue
              )?.id
            : undefined,
      })
        .then((res) => {
          notification.success({ message: res.data.message });
        })
        .catch((err) => {
          notification.error({ message: err.response.data.message });
        });

      // Show warning message without blocking the form submission
      message.warning('License Limit less then workers approved as JO');
      // Show error message
      callback();
    } else {
      callback();
    }
  }, 2000);

  const handlellnEstablishmentChange = (value) => {
    console.log('value is', value);
    setllnDateDisabled(!!value);
  };
  const handlellnChange = (value) => {
    console.log('value is', value);
    setllnDisabled(value > 49 ? true : false);
  };

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().startOf('day');
  };
  const uniqueSupplierSet = new Set(
    supplierList.map((supplier) => supplier.id)
  );
  // console.log("set",uniqueSupplierSet)

  const LocalAdminGrayOut = userConfig.role === 'admin';

  // const handlePFChange = (value) => {
  //   console.log('value .......', value.target.value);
  //   const panPattern = new RegExp('[A-Z]{5}[0-9]{17');

  //   // Validate GST number pattern
  //   if (!panPattern?.test(value.target.value)) {
  //     form.setFields([
  //       {
  //         name: 'pan',
  //         errors: ['Enter Valid PF No.'],
  //       },
  //     ]);
  //     return;
  //   }

  //   // If GST number pattern is valid, call the API
  //   supplierPf({ pfAccountNo: value.target.value })
  //     .then((response) => {
  //       notification.success({ message: response.data.message });
  //     })
  //     .catch((error) => {
  //       notification.error({ message: error.response.data.message });
  //     });
  // };

  const handlePfEstablishmentChange = (value) => {
    console.log('value is...../', value?.target?.value);
    const panPattern = new RegExp('[A-Z]{5}[0-9]{17}');

    // Validate GST number pattern
    if (!panPattern?.test(value?.target?.value)) {
      form.setFields([
        {
          name: 'pfAccountNo',
          errors: ['Enter Valid PF No.'],
        },
      ]);
      return;
    }

    // If GST number pattern is valid, call the API
    supplierPf({ pfAccountNo: value?.target?.value })
      .then((response) => {
        notification.success({ message: response.data.message });
      })
      .catch((error) => {
        notification.error({ message: error.response.data.message });
      });
    console.log('Hello  is', value?.target?.value);
    // setIsPfApplyDateDisabled(
    //   !!value.target?.value || !!form.getFieldValue('pfEstablishmentSubCode')
    // );
  };

  const handleESIChange = (value) => {
    console.log('value .......', value.target.value);
    // Validate GST number pattern
    const panPattern = new RegExp(
      '^([0-9]{2})[–-]([0-9]{2})[–-]([0-9]{6})[–-]([0-9]{3})[–-]([0-9]{4})$'
    );
    if (!panPattern?.test(value.target.value)) {
      form.setFields([
        {
          name: 'esicno',
          errors: ['Enter Valid ESI No.'],
        },
      ]);
      return;
    }

    // If GST number pattern is valid, call the API
    supplierEsi({ esicno: value.target.value })
      .then((response) => {
        notification.success({ message: response.data.message });
      })
      .catch((error) => {
        notification.error({ message: error.response.data.message });
      });
  };

  return (
    <Modal
      title={isEdit ? 'Edit Contractor Agency' : ''}
      open={open}
      onOk={onSubmit}
      onCancel={_onCancel}
      // okText={isEdit ? 'Save' : 'Add'}
      okText={
        isEdit ? (userConfig.role == 'approver' ? 'Approve' : 'Save') : 'Add'
      }
      cancelText={userConfig.role === 'approver' ? 'Reject' : 'Cancel'}
      width={'75%'}>
      <Form
        name='contractor'
        layout='vertical'
        onFinish={onFinish}
        // initialValues={initialValues}
        form={form}
        ref={formRef}
        onValuesChange={
          handleChanges // Call handleChanges without passing any arguments
        }>
        <Row gutter={24}>
          <Divider>Compliance & Insurance</Divider>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='pfAccountNo'
              label='PF of Contractor Establishment'
              rules={[
                {
                  required: true,
                  message: 'Enter PF of Contractor Establishment',
                },
                {
                  pattern: '[A-Z]{5}[0-9]{17}',
                  message: 'Invalid PF Account number',
                },
              ]}>
              <Input
                placeholder='Ex: BGBNG24718350000010018'
                onChange={handlePfEstablishmentChange}
                disabled={isDisabled}
                style={
                  LocalAdminGrayOut || isDisabled
                    ? { fontWeight: 'bold', color: 'gray' }
                    : {}
                }
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label='PF Establishment Sub Code'
              name='pfEstablishmentSubCode'>
              <Input
                onChange={(e) => handleSubCodeChange(e.target.value)}
                disabled={isDisabled}
                style={
                  LocalAdminGrayOut || isDisabled
                    ? { fontWeight: 'bold', color: 'gray' }
                    : {}
                }
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label='PF Apply Date'
              name='pfApplyDate'
              rules={[
                {
                  required: !isPfApplyDateDisabled,
                  message: 'PF Apply Date is required',
                },
              ]}>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                disabled={isPfApplyDateDisabled || isDisabled}
              />
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item
              name='esicno'
              label='ESI of Contractor Establishment'
              rules={[
                {
                  required: true,
                  message: 'Enter ESI Number or NULL Establishment',
                },
                {
                  pattern:
                    '^(([0-9]{2})[–-]([0-9]{2})[–-]([0-9]{6})[–-]([0-9]{3})[–-]([0-9]{4})|NULL)$',
                  message: 'Invalid ESIC number',
                },
              ]}>
              <Input
                placeholder='Ex: 41-00-123456-000-0001'
                onChange={handleESIChange}
                disabled={isDisabled}
                style={
                  LocalAdminGrayOut || isDisabled
                    ? { fontWeight: 'bold', color: 'gray' }
                    : {}
                }
              />
            </Form.Item>
          </Col>
          {/* <Col span={6}>
            <Form.Item
              label=' ESI Valid From'
              name='esiValidFrom'
              rules={[
                // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                { required: true, message: 'Select Date' },
              ]}>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label=' ESI Valid To'
              name='esiValidTo'
              rules={[
                // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                { required: true, message: 'Select Date ' },
              ]}>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col> */}
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              label='No.of Workmen Covered Under ESI'
              name='nowc'
              rules={[
                {
                  required: true,
                  message: 'Enter No.of Workmen Covered Under ESI',
                },
              ]}>
              <Input
                disabled={isDisabled || LocalAdminGrayOut}
                style={
                  LocalAdminGrayOut || isDisabled
                    ? { fontWeight: 'bold', color: 'gray' }
                    : {}
                }
              />
            </Form.Item>
          </Col> */}

          <Col span={6}>
            <Form.Item
              label='WC/EC Insurance Number'
              name='wcpn'
              rules={[
                // {
                //   pattern: '^[0-9]*$',
                //   message: 'Only Numbers Allowed',
                // },
                {
                  required: true,
                  message: 'Enter WC/EC Insurance Number',
                },
              ]}>
              <Input
                disabled={isDisabled}
                style={
                  LocalAdminGrayOut || isDisabled
                    ? { fontWeight: 'bold', color: 'gray' }
                    : {}
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='WC/EC Insurance Registered Date'
              name='wcecRegisterDate'
              rules={[
                // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                { required: true, message: 'Enter WC/EC Insurance Valid To' },
              ]}>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='WC/EC Insurance Valid From'
              name='wcvf'
              rules={[
                // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                { required: true, message: 'Enter WC/EC Insurance Valid From' },
              ]}>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='WC/EC Insurance Valid To'
              name='wcvt'
              rules={[
                // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                { required: true, message: 'Enter WC/EC Insurance Valid To' },
              ]}>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='No. of workers covered Under WC/EC'
              name='noofWorkmenCovered'
              rules={
                [
                  // {
                  //   pattern: /^[A-Za-z\s]*$/,
                  //   message: "Only Alphabetic Allowed",
                  // },
                  // { required: true, message: 'Enter Unit Id' },
                ]
              }>
              <Input
                disabled={isDisabled}
                style={
                  LocalAdminGrayOut || isDisabled
                    ? { fontWeight: 'bold', color: 'gray' }
                    : {}
                }
              />
            </Form.Item>
          </Col>
          {/* <Col span={6}>
            <Form.Item
              label='No. of workers used'
              name='noOfWorkersUsed'
              rules={
                [
                  // {
                  //   pattern: /^[A-Za-z\s]*$/,
                  //   message: "Only Alphabetic Allowed",
                  // },
                  // { required: true, message: 'Enter Unit Id' },
                ]
              }>
              <Input
                disabled={isDisabled}
                style={{ fontWeight: 'bold', color: 'gray' }}
              />
            </Form.Item>
          </Col> */}
          <Col span={6}>
            <Form.Item
              label='Insurance Company Name'
              name='insuranceCompanyName'
              rules={[
                // {
                //   pattern: /^[A-Za-z\s]*$/,
                //   message: "Only Alphabetic Allowed",
                // },
                { required: true, message: 'Enter Insurance Company Name' },
              ]}>
              <Input disabled={isDisabled} />
            </Form.Item>
          </Col>
        </Row>
        <Divider>License & Job Details</Divider>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label='Contractor Code'
              name='id'
              rules={[{ required: true, message: 'Select Contractor Code' }]}>
              {userConfig.role === 'admin' ? (
                <Select
                  disabled={isDisabled}
                  style={{ width: '100%' }}
                  value={selectedSupplierId}
                  onChange={(value) => setSelectedSupplierId(value)}
                  dropdownStyle={{ fontWeight: 'bold', color: 'gray' }} // Apply bold font and gray color to dropdown
                >
                  {[...uniqueSupplierSet].map((uniqueId) => (
                    <Option key={uniqueId} value={uniqueId}>
                      <span style={{ fontWeight: 'bold', color: 'gray' }}>
                        {uniqueId}
                      </span>
                    </Option>
                  ))}
                </Select>
              ) : (
                // <Input
                //   id="supplierIdInput"
                //   initialValue={initialValues?.supplierId}
                //   defaultValue={userConfig?.supplierId}
                //   // value={userConfig?.supplierId}
                //   value={typeof userConfig.supplierId === 'string' ? userConfig.supplierId : ''}
                //   // disabled
                //   key={userConfig.supplierId}
                //   style={
                //     LocalAdminGrayOut || isDisabled
                //       ? { fontWeight: 'bold', color: 'gray', display: 'none' }
                //       : {}
                //   }
                //   // onChange={(e) => setSelectedSupplierId(e.target.value)}
                //   onChange={(value) => setSelectedSupplierId(value)}
                // />
                <Select
                  disabled={isDisabled}
                  style={{ width: '100%' }}
                  value={initialValues?.supplierId || userConfig?.supplierId} // Use initialValues.supplierId if available, otherwise fallback to userConfig.supplierId
                  onChange={(value) => setSelectedSupplierId(value)}
                  dropdownStyle={{ fontWeight: 'bold', color: 'gray' }}>
                  <Option value={userConfig?.supplierId}>
                    <span style={{ fontWeight: 'bold', color: 'gray' }}>
                      {userConfig?.supplierId}
                    </span>
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item
              label='Job Order Number / LOI Number'
              name='jobordernumber'
              rules={[{ required: true, message: 'Enter JO / LOI Number' }]}>
              <Input
                disabled={isDisabled}
                style={
                  LocalAdminGrayOut || isDisabled
                    ? { fontWeight: 'bold', color: 'gray' }
                    : {}
                }
              />
            </Form.Item>
          </Col>
          {/* <Col span={6}>
            <Form.Item
              label='Workmen Approved As Per JO'
              name='maxContractors'
              rules={[
                {
                  pattern: '^[0-9]*$',
                  message: 'Only Numbers Allowed',
                },
                {
                  required: true,
                  message: 'Workmen Approved As Per JO is required',
                },
              ]}>
              <Input style={{ width: '100%' }} disabled={isDisabled} />
            </Form.Item>
          </Col> */}
          {/* <Col span={6}>
            <Form.Item
              label='Average Deployment Per Day'
              name='avrageDeployement'
              rules={[
                {
                  required: true,
                  message: 'Enter Average Deployement Per Day',
                },
              ]}>
              <Input
                disabled={isDisabled || LocalAdminGrayOut}
                style={
                  LocalAdminGrayOut || isDisabled
                    ? { fontWeight: 'bold', color: 'gray' }
                    : {}
                }
              />
            </Form.Item>
          </Col> */}

          <Col span={6}>
            <Form.Item
              label='Period Of Contractor Start Date'
              name='periodContractorStartDate'>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='Period Of Contractor End Date'
              name='periodContractorEndDate'>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                disabled={isDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='Labour Licence Number'
              name='lln'
              rules={[
                {
                  required: isllnDisabled,
                  message: 'Enter Labour Licence Number',
                },
              ]}>
              <Input
                // placeholder='Ex: BGBNG24718350000010018'
                onChange={(e) => handlellnEstablishmentChange(e.target.value)}
                disabled={isDisabled}
                style={
                  LocalAdminGrayOut || isDisabled
                    ? { fontWeight: 'bold', color: 'gray' }
                    : {}
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='Total Licence Coverage'
              name='totalLicenceCoverage'
              rules={[
                // {
                //   pattern: /^[A-Za-z\s]*$/,
                //   message: "Only Alphabetic Allowed",
                // },
                {
                  required: true,
                  message: 'Enter Total Licence Coverage',
                },
                { validator: LicenseExceedValidator },
                { validator: insurenceValidator },
                { validator: validateTotalLicenceCoverage },
              ]}>
              <Input
                disabled={isDisabled}
                style={
                  LocalAdminGrayOut || isDisabled
                    ? { fontWeight: 'bold', color: 'gray' }
                    : {}
                }
                onChange={(e) => handlellnChange(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label=' LL Registered date'
              name='llregdate'
              rules={[
                // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                { required: isllnDateDisabled, message: 'Select Date' },
              ]}>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                disabled={!isllnDateDisabled || isDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label=' LL Valid From'
              name='llf'
              rules={[
                // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                { required: isllnDateDisabled, message: 'Select Date' },
              ]}>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                disabled={!isllnDateDisabled || isDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label=' LL Valid To'
              name='doe'
              rules={[
                // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                { required: isllnDateDisabled, message: 'Select Date ' },
              ]}>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%', fontWeight: 'bold', color: 'gray' }}
                disabled={!isllnDateDisabled}
                disabledDate={disabledDate || isDisabled}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label='Pool of Max No. of Workmen'
              name='poolWorkmen'
              rules={[
                { required: true, message: 'Enter Pool Of Max No.Of Workmen' },
              ]}>
              <Input
                disabled={isDisabled}
                style={
                  LocalAdminGrayOut || isDisabled
                    ? { fontWeight: 'bold', color: 'gray' }
                    : {}
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          {/* <Col span={6}>
            <Form.Item
              label='WC/EC Insurance Number'
              name='wcpn'
              rules={[
                // {
                //   pattern: '^[0-9]*$',
                //   message: 'Only Numbers Allowed',
                // },
                {
                  required: true,
                  message: 'Enter WC/EC Insurance Number',
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='WC/EC Insurance Valid From'
              name='wcvf'
              rules={[
                // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                { required: true, message: 'Enter WC/EC Insurance Valid From' },
              ]}>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='WC/EC Insurance Valid To'
              name='wcvt'
              rules={[
                // { pattern: /^[A-Za-z\s]*$/, message: "Only Alphabetic Allowed" },

                { required: true, message: 'Enter WC/EC Insurance Valid To' },
              ]}>
              <DatePicker
                placeholder='DD-MM-YYYY'
                format='DD-MM-YYYY'
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col> */}
          {/* <Col span={6}>
            <Form.Item
              label='No. of workers covered'
              name='noofWorkmenCovered'
              rules={
                [
                  // {
                  //   pattern: /^[A-Za-z\s]*$/,
                  //   message: "Only Alphabetic Allowed",
                  // },
                  // { required: true, message: 'Enter Unit Id' },
                ]
              }>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='No. of workers used'
              name='noOfWorkersUsed'
              rules={
                [
                  // {
                  //   pattern: /^[A-Za-z\s]*$/,
                  //   message: "Only Alphabetic Allowed",
                  // },
                  // { required: true, message: 'Enter Unit Id' },
                ]
              }>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label='Insurance Company Name'
              name='insuranceCompanyName'
              rules={
                [
                  // {
                  //   pattern: /^[A-Za-z\s]*$/,
                  //   message: "Only Alphabetic Allowed",
                  // },
                  // { required: true, message: 'Enter Unit Id' },
                ]
              }>
              <Input />
            </Form.Item>
          </Col>*/}
        </Row>
      </Form>
    </Modal>
  );
}
